@import "../../../styles/vars";

.upper-bars {
  position: fixed;
  letter-spacing: 0;
  top: 0;
  z-index: 999;
  width: 100%;

  .health-warning {
    background-color: #2A2C2C;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    @include XGA {
      height: 36px;
    }

    p {
      margin: 0;
      font-size: 10px;
      line-height: 12px;

      br {
        @include XGA {
          display: none;
        }
      }
    }
  }

  .new-heating-technology {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    
    @include XGA {
      flex-direction: row;
      border-bottom: 1px solid #000000;
      height: 36px;
    }

    p {
      font-size: 10px;
      line-height: 12px;
      color: #2A2C2C;
      margin: 0;
      position: relative;
      font-weight: 400;
      width: fit-content;
      text-transform: uppercase;

      strong {
        font-weight: 700;
      }

      &:first-of-type {
        @include XGA {
          padding-right: 16px;
          margin-right: 16px;
          
          &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 12px;
            background-color: black;
            right: 0;
            top: -2px;
          }
        }
      }
    }
  }
}