@import "../../../styles/_vars.scss";

.wrapper-ic-preregister {
  padding: 0;
  border-radius: 12px;

  .content {
    display: block;
    height: 100%;
  }

  &.ReactModal__Content {
    max-width: calc(min(327px, 90vw));
    min-width: 309px;
    max-height: 90%;
    width: 100%;
    padding: 0;

    @include XGA {
      max-width: 423px;
    }
  }
}

.ic-preregister-modal {
  button.exit {
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 16px;
  }

  img {
    width: 100%;
    aspect-ratio: 327/158;
  }

  .content {
    padding: 24px 32px;

    &>* {
      margin: 0;
    }

    h2 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    p {
      margin: 16px auto 0;
      color: #2A2C2C;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      max-width: 280px;
    }

    .button {
      margin-top: 24px;
    }
  }
}