@import "../../../../../styles/vars";

.clouds-fest-reward {

    .text {
        font-weight: 700 !important;
        padding: 0 24px;
    }

    .error {
        font-size: 10px;
        color: #f44;
        text-align: left;
        margin: 0;
        margin-top: 4px;
    }

    .sub {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        opacity: 0.8;
        margin-top: 12px;

        @include XGA {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #000000;
            opacity: 1;
            margin-top: 16px;
        }
    }

    .price {
        @include XGA {
            margin-top: 0 !important;
        }
    }

    .line {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
    }

    h3 {
        font-size: 18px;
        margin-top: 10px;

        @include XGA {
            font-size: 24px;
        }
    }

    form {
        margin-top: 24px;

        @include XGA {
            margin-top: 40px;
        }

        .info {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #2A2C2C;
            opacity: 1;
            text-align: left;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        label,
        input {
            display: flex;
            flex-direction: column;
            text-align: left;
        }

        label {
            margin-top: 16px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }

        input {
            display: block;
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid black;
            padding: 4px 0px;
            color: black;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            outline: none;
            transition: all 0.2s $CubicEaseInOut;

            &:focus {
                border-color: $lightorange;
            }
        }

        .postal-code {
            input {
                margin-bottom: 24px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 6px;

                @include XGA {
                    margin-bottom: 40px;
                }

                &:focus {
                    border: none;
                }
            }
        }

        // .postal-code {
        //     background-image: url("./img/postal.svg");
        //     background-repeat: no-repeat;

        //     @include XGA {
        //         margin-bottom: 40px;
        //     }


        //     input {
        //         border: none;
        //         // font-size: 14px;
        //         // letter-spacing: 14.5px;
        //         // padding-left: 4.5px;

        //         // @include XGA {
        //         //     letter-spacing: 17px;
        //         //     padding-left: 5px;
        //         // }
        //     }
        // }
    }

    .flex-wrapper {
        display: flex;
        justify-content: space-between;

        div {
            width: 190px;
        }
    }

    &.collect-reward.modal-content {
        @include XGA {
            grid-template-columns: .5fr 1fr .5fr;
        }
    }
}