@import "../../../styles/vars";

.crazy-wednesday-bar {
  position: relative;
  height: 60px;
  background-size: cover;
  background-position: center;
  background-image: url(./img/wednesday-bg-mobile.jpg);
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include XGA {
    height: 60px;
    background-image: url(./img/wednesday-bg-desktop.jpg);
    background-position: center right;
  }

  @include HD {
    background-position: center;
  }

  @include UXGA {
    background-position: center -3px;
  }

  @include FHD {
    background-position: center -6px;
  }

  @keyframes glow {
    from {
      text-shadow: 0 0 5px #ccc, 0 0 10px $yellow, 0 0 20px $orange,
        0 0 30px $orange, 0 0 40px $orange, 0 0 50px $orange, 0 0 60px $orange;
    }

    to {
      text-shadow: 0 0 10px $yellow, 0 0 20px $darkorange, 0 0 30px $darkorange,
        0 0 40px $darkorange, 0 0 50px $darkorange, 0 0 60px $darkorange,
        0 0 70px $darkorange;
    }
  }

  @keyframes wave {
    to {
      background-position: 200% center;
    }
  }

  a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .container {
    width: calc(100% - 48px);
    max-width: 100%;

    @include XGA {
      width: calc(100% - 128px);
    }
  }

  p {
    position: relative;
    margin: 0;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    margin-left: 0;
    // background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(42, 44, 44, 1) 40%, rgba(42, 44, 44, 1) 60%, rgba(255, 255, 255, 1) 80%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 35%,
      rgba(42, 44, 44, 1) 50%,
      rgba(255, 255, 255, 1) 65%
    );
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    color: transparent;
    animation: wave 2s infinite linear forwards reverse;

    @include HD {
      font-size: 18px;
      line-height: 24px;
    }

    strong {
      font-size: 15px;
      line-height: 20px;
      font-weight: 900;

      @include HD {
        font-size: 18px;
        line-height: 24px;
      }
    }

    br {
      @include HD {
        display: none;
      }
    }

    img.arrow {
      margin-left: 16px;

      @include HD {
        position: absolute;
        left: 460px;
        top: 5px;
      }
    }
    img.arrow-extra {
      margin-left: 16px;

      @include HD {
        position: absolute;
        left: 460px;
        top: 5px;
        margin-left: 80px;
      }
    }
  }

  .close-bar {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    color: #fff;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    @include XGA {
      top: 50%;
      transform: translate(0%, -50%);
    }

    span {
      font-size: 8px;
      font-weight: 900;
      margin-right: 8px;
    }

    img {
      @include XGA {
        width: 14px;
      }
    }
  }
}
