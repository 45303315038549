@import "../../../styles/vars";

.user-data {
    display: flex;
    flex-flow: row;
    color: #fff;
    text-align: right;

    @include XGA {
        flex-flow: row;
        text-align: left;
    }

    .button {
        width: max-content;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding: 5px 21px;

        @include XGA {
            margin-right: 24px;
        }

        strong {
            font-weight: 800;
        }
    }

    .user-content {
        display: flex;
        flex-flow: column-reverse;
        justify-content: center;
        margin-right: 16px;

        @include XGA {
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-right: 24px;
        }
    }

    .icon {
        font-size: 8px;
        line-height: 9px;
        color: white;
        text-decoration: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        transition: color $CubicEaseInOut .2s;

        img {
            width: 32px;
            height: 32px;
        }

        &.zone-plus {
            margin-left: 12px;

            @include XGA {
                margin-left: 18px;
            }
        }

        &:hover {
            @include XGA {
                a {
                    color: $lightorange;
                }
            }
        }

        .disabled-link {
            touch-action: none;
            pointer-events: none;
            text-decoration: none;
            cursor: none;
            color: white;

            @include XGA {
                color: black;
            }
        }

        // .active {
        //     @include XGA {
        //         color: $darkorange;
        //     }
        // }

        @include XGA {
            font-size: 12px;
            line-height: 14px;
            color: black;
            gap: 7px;
        }
    }

    button {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
        text-align: left;
        width: 50%;
        padding: 0;
        width: max-content;
    }

    p, a {
        margin: 0;

        &.user-name {
            font-size: 13px;
            line-height: 16px;
            font-weight: 900;
            text-decoration: none;
            color: white;
            
            @include XGA {
                color: #2A2C2C;
                font-size: 24px;
                line-height: 32px;
            }
        }

        &.user-points {
            font-size: 10px;
            line-height: 12px;

            @include XGA {
                color: #999999;
                margin-left: 32px;
                font-size: 16px;
                white-space: nowrap;
            }

            span {
                font-weight: 600;

                @include XGA {
                    color: #000;
                }
            }
        }
    }

    // &.new-variants {
    //     color: #fff;

    //     a.user-name {
    //         color: #fff;

    //         @include XGA {
    //             color: #2A2C2C;
    //         }
    //     }

    //     button.icon {
    //         color: #fff;

    //         @include XGA {
    //             color: #2A2C2C;
    //         }

    //         .disabled-link {
    //             color: #fff;

    //             @include XGA {
    //                 color: #2A2C2C;
    //             }
    //         }
    //     }

    //     .user-content {
    //         .button.orange {
    //             background-color: #E37E55;
    //         }
    //     }
    // }
    .disabled {
        opacity: 0.33;
        pointer-events: none;
    }
}