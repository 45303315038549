@import "../../../styles/vars";

.home-page {
    width: calc(100% - 48px);
    margin: 0 auto;

    @include XGA {
        width: calc(100% - 128px);
        padding-top: 24px;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 16px;
        font-weight: 600;

        @include XGA {
            font-size: 32px;
            line-height: 32px;
            margin-bottom: 24px;
        }
    }

    .news {
        font-weight: 600;
        position: relative;

        @include XGA {
            margin-top: 24px;
        }

        .swiper {
            position: static;
            width: 320px;
            margin-left: 0;
            margin-right: 16px;
            overflow: initial;

            @include XGA {
                margin-right: 0;
            }
        }

        .swiper-pagination {
            top: 0;
            right: 0;
            left: auto;
            width: auto;
            height: min-content;

            &-bullet {
                background-color: $lightorange;
                opacity: 0.2;
            }

            &-bullet-active {
                background-color: $lightorange;
                opacity: 1;
            }
        }

        .swiper-slide {
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            background-color: #fff;

            .slide-link {
                text-decoration: none;
                color: black;
                height: 100%;
                display: block;
            }

            .img {
                position: relative;
                width: 100%;
                border-radius: 12px 12px 0 0;
                height: 170px;
                background-size: cover;
                background-position: center;
            }

            .title {
                font-size: 12px;
                margin-left: 16px;
                margin-bottom: 0;
            }

            .category {
                font-size: 12px;
                background: $darkorange;
                color: white;
                border-radius: 12px;
                padding: 4px 8px;
                display: inline-block;
                margin-left: 16px;
                margin-top: 12px;
            }

            .category-like-wrapper {
                display: flex;
                align-items: center;
                gap: 20px;

                .like-outer {
                    position: absolute;
                    bottom: 12px;
                    left: 16px;
                }
            }

            .subtitle {
                margin: 6px 16px 8px;
                font-size: 12px;
                line-height: 16px;
                color: #868686;
                font-weight: 500;
            }

            .logo {
                position: absolute;
                right: 16px;
                bottom: 16px;
            }
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            column-gap: 32px;
            margin-top: 16px;

            .element {
                flex: 1 0 21%;
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                font-size: 14px;
                text-decoration: none;
                color: black;

                .img {
                    height: min-content;

                    img {
                        border-radius: 12px 12px 0 0;
                        width: 100%;
                    }
                }

                .title {
                    margin-top: 16px;
                    margin: 0;
                    margin-left: 24px;
                }

                .arrow {
                    margin-top: 8px;
                    margin-left: 24px;
                    margin-bottom: 24px;
                }
            }
        }

        .news-desktop {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;

            .swiper-pagination-bullet-active {
                position: relative;
                margin: 20px 0;

                &::before {
                    content: "";
                    position: absolute;
                    top: -14px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $lightorange;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -14px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $lightorange;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: $orange;
                right: 16px;
                top: 16px;
                z-index: 10;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    left: 11px;
                    top: 13px;
                    width: 9px;
                    height: 9px;
                    border-left: #fff solid 2px;
                    border-top: #fff solid 2px;
                    transform: rotate(45deg);
                }
            }

            .swiper-button-next {
                top: auto;
                bottom: 16px;

                &::before {
                    transform: rotate(-135deg);
                    top: 8px;
                }
            }

            .swiper-button-disabled {
                background: rgba(218, 218, 218, 0.45);
                cursor: not-allowed;
            }

            .img-wrapper {
                position: relative;
                height: 405px;
                width: 100%;
            }

            .img {
                position: absolute;
                left: 0;
                top: 0;
                height: 405px;
                width: 100%;
                background-size: cover;
                background-position: center;
                border-radius: 12px 0 0 12px;
                text-decoration: none;
                z-index: 2;

                .category {
                    font-size: 12px;
                    background: $darkorange;
                    color: white;
                    border-radius: 12px;
                    padding: 4px 8px;
                    display: inline-block;
                    margin-left: 40px;
                    margin-top: 24px;
                }

                // .category-like-wrapper {
                //     display: flex;
                //     align-items: center;
                //     gap: 20px;
                //     text-decoration: none;

                .like-outer {
                    position: absolute;
                    top: 60px;
                    left: 40px;
                }

                // }

                .logo {
                    position: absolute;
                    left: 40px;
                    bottom: 24px;
                    width: auto;
                    height: 24px;

                    path {
                        fill: #fff;
                    }
                }
            }

            .swiper {
                position: relative;
                height: 405px;
                width: 560px;
                min-width: 560px;
                overflow: hidden;
                border: 1px solid #CCCCCC;
                border-radius: 0 12px 12px 0;

                .content-wrapper {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    padding: 24px 40px;
                    padding-bottom: 0;
                    padding-right: 100px;
                    height: fit-content;
                }

                .swiper-slide {
                    border-radius: 0;
                    box-shadow: none;
                    // padding: 24px 40px;
                    // padding-right: 100px;
                    border-bottom: 1px solid #CCCCCC;
                    // display: flex;
                    // flex-flow: column;
                    // align-items: center;
                    // justify-content: center;
                    background-color: #fff;

                    .title {
                        font-size: 14px;
                        margin: 0;
                        margin-bottom: 6px;

                        br {
                            display: none;
                        }
                    }

                    .desc {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: #999999;
                    }

                    .arrow {
                        display: none;
                        padding-left: 40px;
                    }

                    &.selected {
                        background: linear-gradient(90deg, $darkorange 0%, $yellow 100%);

                        .title,
                        .desc {
                            color: #fff;
                            // font-weight: 800;
                        }

                        .arrow {
                            display: block;
                            margin-top: 6px;

                            svg {
                                display: block;

                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }

                    &:hover {
                        .arrow {
                            animation: arrow 1s $QuadEaseInOut infinite;
                        }
                    }
                }
            }

            .swiper-pagination {
                top: 50%;
                transform: translateY(-50%);
                left: auto;
                right: 26px;
                padding: 4px;
                border-radius: 8px;
                background-color: rgba($color: #fff, $alpha: .4);
            }
        }
    }

    .activities {
        position: relative;
        margin-top: 16px;

        @include XGA {
            grid-row: 2/4;
            grid-column: 2;
            margin-top: 40px;
        }

        .swiper {
            position: static;
            width: 320px;
            margin-left: 0;
            margin-right: 16px;
            overflow: initial;

            @include HD {
                overflow: visible;
                left: -10px;
                width: calc(100% + 20px);
                padding: 10px 10px 0;
                padding-left: 0px;
            }
        }

        .dots {
            position: absolute;
            top: 0;
            left: auto;
            right: 16px;
            width: auto;
            height: min-content;

            @include XGA {
                display: flex;
                position: absolute;
                top: 12px;
                left: auto;
                right: 52px;
                width: auto;
            }

            li {
                background-color: $lightorange;
                opacity: 0.2;
                width: 8px;
                height: 8px;
                margin-left: 6px;
                border-radius: 50%;
            }

            li.active {
                background-color: $lightorange;
                opacity: 1;
            }
        }

        .swiper-pagination {
            position: absolute;
            top: 0;
            left: auto;
            right: 16px;
            width: auto;
            height: min-content;

            @include HD {
                position: absolute;
                top: 6px;
                left: auto;
                right: 52px;
                width: auto;
            }

            &-bullet {
                background-color: $lightorange;
                opacity: 0.2;
            }

            &-bullet-active {
                background-color: $lightorange;
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                @include HD {
                    position: relative;
                    margin: 0 16px;

                    &::before {
                        content: "";
                        position: absolute;
                        left: -14px;
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $lightorange;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        right: -14px;
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $lightorange;
                    }
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: $lightorange solid 1px;
            right: 102px;
            top: 3px;
            z-index: 10;
            cursor: pointer;
            display: none;

            @include XGA {
                display: block;
            }

            &::before {
                content: "";
                position: absolute;
                left: 9px;
                top: 8px;
                width: 4.5px;
                height: 4.5px;
                border-left: $lightorange solid 2px;
                border-top: $lightorange solid 2px;
                transform: rotate(315deg);
            }
        }

        .swiper-button-next {
            top: 3px;
            right: 16px;

            &::before {
                transform: rotate(135deg);
                top: 8px;
                left: 7px;
            }
        }

        .swiper-button-disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }

        .swiper-slide {
            @include HD {
                min-width: calc(33.33% - 16px);
                width: calc(33.33% - 16px);
                max-width: calc(33.33% - 16px);
                margin-right: 16px;
            }
        }

        .activity {
            display: flex;
            padding: 0;
            // justify-content: space-between;
            // margin-bottom: 8px;
            column-gap: 12px;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            text-decoration: none;
            color: black;
            background-color: #fff;

            @include XGA {
                // margin-bottom: 56px;
                padding: 0;
                column-gap: 24px;
                min-height: 160px;
            }

            @include HD {
                // margin-bottom: 42px;
            }

            @include FHD {
                min-height: 260px;
                font-size: 16px;
                column-gap: 32px;
            }

            &:hover {
                .arrow {
                    animation: arrow 1s $QuadEaseInOut infinite;
                }
            }

            .img {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 112px;
                max-width: 112px;
                min-width: 112px;
                height: 116px;
                position: relative;
                border-radius: 12px;

                @include XGA {
                    width: 160px;
                    max-width: 160px;
                    min-width: 160px;
                    height: 185px;
                }

                @include FHD {
                    width: 260px;
                    max-width: 260px;
                    min-width: 260px;
                    height: 260px;
                }

                .points {
                    position: absolute;
                    top: 6px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    width: max-content;
                    padding: 4px 8px 4px;
                    background: $darkorange;
                    border-radius: 12px;
                    font-size: 12px;
                    font-weight: 600;
                    color: white;

                    @include XGA {
                        top: 16px
                    }

                    @include FHD {
                        transform: translate(0%, 0%);
                        left: calc(100% + 32px);
                        top: 32px;
                    }
                }
            }

            .content {
                padding: 5px;
                display: flex;
                flex-direction: column;

                @include XGA {
                    padding: 8px 8px 4px 0;
                    max-width: calc(100% - 160px);
                    height: 185px;
                }

                @include UXGA {
                    height: initial;
                    padding: 20px 16px 12px 0;
                }

                @include FHD {
                    padding: 70px 32px 22px 0;
                }

                .title {
                    font-size: 12px;
                    margin-bottom: 8px;
                    font-weight: 600;

                    @include XGA {
                        width: auto;
                        font-size: 14px;
                    }

                    @include FHD {
                        font-size: 16px;
                    }
                }

                .sub {
                    opacity: 0.4;
                    line-height: 16px;
                    font-size: 11px;
                    font-weight: 400;

                    @include XGA {
                        font-size: 11px;
                        padding: 0;
                    }

                    @include HD {
                        font-size: 12px;
                        padding-right: 16px;
                    }

                    @include FHD {
                        font-size: 16px;
                        line-height: 24px;
                        padding-right: 16px;
                    }
                }

                .title {
                    padding-right: 16px;
                }

                .type {
                    color: $darkorange;
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 0.12em;
                    text-transform: uppercase;
                    // margin-top: 5px;
                    margin-bottom: 4px;
                    font-weight: 700;

                    @include XGA {
                        margin-top: 0;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                }

                .arrow {
                    margin-top: 12px;
                    display: none;

                    @include XGA {
                        margin-top: auto;
                        margin-bottom: 28px;
                        display: block;
                    }

                    @include FHD {
                        margin-bottom: 8px;
                    }
                }
            }
            &.highlighted {
                border: 2px solid #E5451F;
                background: #E5451F;
                .type {
                    color: #F5AA04;
                }

                .title,
                .sub {
                    color: #FFFFFF;
                }
                .sub{
                    opacity: 0.7;
                }
                .points {
                    @include FHD {
                        border: 2px solid #fff;
                    }
                }
            }
        }
    }

    .ar-section {
        position: relative;
        border-radius: 12px;
        margin-top: 24px;
        margin-bottom: 48px;

        @include XGA {
            display: flex;
            flex-flow: row-reverse;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-top: 40px;
        }

        .img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(./img/glo-ar.jpg);
            background-size: cover;
            background-position: center;
            border-radius: 12px;
            overflow: hidden;

            @include XGA {
                position: relative;
                height: 390px;
                border-radius: 0 12px 12px 0;
            }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, #797979 0%, rgba(196, 196, 196, 0) 100%);
                mix-blend-mode: multiply;
            }
        }

        .text {
            position: relative;
            color: #fff;
            padding: 16px;
            text-align: left;

            @include XGA {
                background-color: #FAFAFA;
                color: #000;
                border-radius: 12px 0 0 12px;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                min-width: 40%;
            }

            h2 {
                margin-top: 8px;
                margin-bottom: 8px;
                font-weight: 600;

                @include XGA {
                    margin-bottom: 16px;
                }
            }

            p {
                font-size: 12px;

                @include XGA {
                    font-size: 14px;
                    margin-bottom: 40px;
                }
            }
        }
    }

    .awards {
        margin-top: 24px;
        position: relative;

        h2 {
            @include XGA {
                margin-top: 40px;
            }
        }

        .dots {
            position: absolute;
            top: 0;
            left: auto;
            right: 16px;
            width: auto;
            height: min-content;

            @include XGA {
                display: flex;
                position: absolute;
                top: 12px;
                left: auto;
                right: 52px;
                width: auto;
            }

            li {
                background-color: $lightorange;
                opacity: 0.2;
                width: 8px;
                height: 8px;
                margin-left: 6px;
                border-radius: 50%;
            }

            li.active {
                background-color: $lightorange;
                opacity: 1;
            }
        }

        .swiper-awards {
            overflow: visible;
            position: static;

            @include XGA {
                padding: 10px 10px 10px 0px;
            }

            @include HD {
                overflow: visible;
                left: -10px;
                width: calc(100% + 20px);
                padding: 10px;
                padding-left: 0px;
            }

            .swiper-pagination {
                position: absolute;
                top: 0;
                left: auto;
                right: 16px;
                width: auto;
                height: min-content;

                @include HD {
                    position: absolute;
                    top: 6px;
                    left: auto;
                    right: 52px;
                    width: auto;
                }

                &-bullet {
                    background-color: $lightorange;
                    opacity: 0.2;
                }

                &-bullet-active {
                    background-color: $lightorange;
                    opacity: 1;
                }

                .swiper-pagination-bullet-active {
                    @include HD {
                        position: relative;
                        // margin: 0 16px;

                        // &::before {
                        //     content: "";
                        //     position: absolute;
                        //     left: -14px;
                        //     display: block;
                        //     width: 8px;
                        //     height: 8px;
                        //     border-radius: 50%;
                        //     background-color: $lightorange;
                        // }

                        // &::after {
                        //     content: "";
                        //     position: absolute;
                        //     right: -14px;
                        //     display: block;
                        //     width: 8px;
                        //     height: 8px;
                        //     border-radius: 50%;
                        //     background-color: $lightorange;
                        // }
                    }
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: $lightorange solid 1px;
                right: 102px;
                top: 3px;
                z-index: 10;
                cursor: pointer;
                display: none;

                @include XGA {
                    display: block;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 9px;
                    top: 8px;
                    width: 4.5px;
                    height: 4.5px;
                    border-left: $lightorange solid 2px;
                    border-top: $lightorange solid 2px;
                    transform: rotate(315deg);
                }
            }

            .swiper-button-next {
                top: 3px;
                right: 16px;

                &::before {
                    transform: rotate(135deg);
                    top: 8px;
                    left: 7px;
                }
            }

            .swiper-button-disabled {
                opacity: 0.4;
                cursor: not-allowed;
            }

            .swiper-slide {
                width: 320px;
                height: auto;
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                margin-bottom: 8px;
                background-color: #fff;

                @include HD {
                    min-width: calc(25% - 12px);
                    width: calc(25% - 12px);
                    max-width: calc(25% - 12px);
                }

                * {
                    text-decoration: none;
                }

                a {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                .img {
                    height: 161px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 12px 12px 0 0;
                }

                .content {
                    padding: 11px 16px 20px;
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    @include XGA {
                        padding: 24px;
                    }

                    .points-progres {
                        display: flex;
                        margin-bottom: 10px;

                        .points {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            color: white;
                            background-color: $darkorange;
                            padding: 5px 8px 2px;
                            border-radius: 12px;

                            &.pulse-animation {
                                animation: pulse-red 2s infinite;
                            }
                        }

                        .progres {
                            margin-left: 8px;
                            color: #2F988A;
                            font-size: 12px;
                            line-height: 16px;
                            border-radius: 12px;
                            padding: 4px 8px 2px;
                            font-weight: 600;
                            border: 1px solid #2F988A;
                            display: flex;
                            justify-content: center;
                            align-items: baseline;
                            position: relative;
                            overflow: hidden;

                            p {
                                margin: 0;
                                z-index: 2;
                                white-space: nowrap;
                            }

                            img {
                                margin-left: 8px;
                                animation: rotate 2s ease-in-out infinite;
                                z-index: 2;

                                @keyframes rotate {
                                    0% {
                                        transform: rotate(0);
                                    }

                                    80% {
                                        transform: rotate(0);
                                    }

                                    100% {
                                        transform: rotate(-180deg)
                                    }
                                }
                            }

                            &.possible {
                                color: white;
                                background: #0066FF;
                                border-color: #0066FF;

                                img {
                                    animation: none;
                                }
                            }

                            .progres-bar {
                                position: absolute;
                                width: 0;
                                height: 100%;
                                z-index: 0;
                                background-color: #E5E5E5;
                                top: 0;
                                left: 0;
                                border-radius: 12px;
                                transition: width .6s $CubicEaseInOut 1s;
                            }
                        }
                    }

                    .title {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: black;

                        @include XGA {
                            margin-bottom: 16px;
                        }
                    }

                    .arrow {
                        display: none;

                        @include XGA {
                            display: block;
                            margin-top: auto;
                        }
                    }
                }

                &:hover {
                    .arrow {
                        animation: arrow 1s $QuadEaseInOut infinite;
                    }
                }

                .award {
                    background: #FFFFFF;
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
                    border-radius: 20px;
                    background-color: #fff;
                    padding-bottom: 12px;
                    overflow: hidden;

                    @include XGA {
                        width: unset;
                        height: 100%;
                        margin-top: 0;
                        display: grid;
                        grid-template-rows: auto 1fr auto auto;
                        margin-bottom: 0;
                    }

                    &>*:not(.image) {
                        margin-inline: 16px;
                    }

                    .image {
                        aspect-ratio: 304/163;
                        object-fit: cover;
                        width: 100%;
                        border-radius: 0 0 20px 20px;
                    }

                    .title {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        color: #151522;
                        margin-top: 12px;
                        min-height: 48px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }

                    .availability {
                        margin-top: 8px;
                        padding-top: 12px;
                        border-top: 1px solid rgba(228, 228, 228, 0.6);

                        p {
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 16px;
                            text-transform: uppercase;
                            color: #151522;
                            margin: 0;

                            span {
                                font-weight: 700;
                                text-transform: uppercase;
                                // color: #168B5A;
                                margin-left: 8px;
                            }
                        }

                        .scale {
                            background-color: #f1f2ff;
                            border-radius: 7.5px;
                            height: 11px;
                            margin-top: 4px;
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            overflow: hidden;
                            position: relative;

                            .bar {
                                position: absolute;
                                height: 100%;
                                left: 0;
                                background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                                border-radius: 0 7.5px 7.5px 0;
                                z-index: 1;
                            }

                            .element.active {
                                position: relative;
                                background-color: #E3E6FF;

                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    width: 2px;
                                    height: 100%;
                                    background-color: #F1F3FF;
                                    opacity: 0.5;
                                    right: -1px;
                                    z-index: 2;
                                }


                                &:last-child {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .progress {
                        display: flex;
                        align-items: center;
                        margin-top: 12px;
                        padding-top: 6px;
                        border-top: 1px solid rgba(228, 228, 228, 0.6);

                        @include XGA {
                            padding-top: 12px;
                        }

                        .cart {
                            width: 36px;
                            height: 36px;
                            display: grid;
                            place-items: center;

                            &>* {
                                grid-row: -1/1;
                                grid-column: -1/1;
                            }

                            svg {
                                display: block;
                            }

                            svg.svg-progress {
                                width: 36px;
                                height: 36px;
                                transform: rotate(-90deg);


                                .track,
                                .indicator {
                                    cx: 18px;
                                    cy: 18px;
                                    r: 16px;
                                    fill: transparent;
                                    stroke-width: 2px;
                                }

                                .track {
                                    stroke: #E4E4E4;
                                    opacity: 0.7;
                                }

                                .indicator {
                                    stroke: #E5451F;
                                    stroke-linecap: round;
                                }
                            }
                        }

                        .price {
                            display: flex;
                            flex-direction: column;
                            gap: 3px;
                            margin-right: auto;
                            margin-left: 16px;

                            p {
                                margin: 0;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                                color: #E5451F;
                            }

                            div {
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 20px;
                                color: #868686;

                                .flex-wrapper {
                                    font-weight: 800;
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: #ED8002;
                                    display: flex;
                                    gap: 8px;
                                    align-items: center;
                                }
                            }
                        }
                    }



                    .limited-yellow {
                        position: absolute;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        color: white;
                        background: #F5AA04;
                        border-radius: 24px;
                        top: 16px;
                        left: 16px;
                        padding: 3px 8px 1px;
                        display: flex;
                        align-items: center;
                        animation: pulse-yellow 2s infinite;
                    }

                    &.drop {
                        background-color: #2A2C2C;
                        border: 3px solid #E5451F;
                        position: relative;
                        overflow: hidden;

                        .title {
                            color: white;
                        }

                        .limited {
                            position: absolute;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            color: white;
                            background: #E5451F;
                            border-radius: 24px;
                            top: 16px;
                            left: 16px;
                            padding: 3px 8px 1px;
                            display: flex;
                            align-items: center;
                            animation: pulse-red 2s infinite;

                            img {
                                padding-bottom: 2px;
                                padding-left: 5px;
                            }
                        }

                        .availability {
                            p {
                                color: white;
                            }
                        }

                        .progres {
                            border-color: #E5451F;
                            color: white;

                            img {
                                filter: brightness(0) saturate(100%) invert(48%) sepia(77%) saturate(4722%) hue-rotate(347deg) brightness(90%) contrast(99%);
                            }

                            &-bar {
                                background-color: #E5451F;
                            }

                            &.possible {
                                border-color: #0066FF;

                                img {
                                    filter: brightness(0) invert(1);
                                }
                            }
                        }

                        img.arrow-desktop {
                            filter: brightness(0) invert(1);
                        }
                    }

                    // &.drop {
                    //     background-color: #2A2C2C;
                    //     border: 3px solid #E5451F;
                    //     position: relative;
                    //     border-radius: 12px;
                    //     overflow: hidden;

                    //     .img {
                    //         border-radius: 0;
                    //     }

                    //     .title {
                    //         color: white;
                    //     }

                    //     .limited {
                    //         position: absolute;
                    //         font-weight: 700;
                    //         font-size: 12px;
                    //         line-height: 16px;
                    //         color: white;
                    //         background: #E5451F;
                    //         border-radius: 24px;
                    //         top: 16px;
                    //         left: 16px;
                    //         padding: 3px 8px 1px;
                    //         display: flex;
                    //         align-items: center;
                    //         animation: pulse-red 2s infinite;

                    //         img {
                    //             padding-bottom: 2px;
                    //             padding-left: 5px;
                    //         }
                    //     }

                    //     .progres {
                    //         border-color: #E5451F;
                    //         color: white;

                    //         img {
                    //             filter: brightness(0) saturate(100%) invert(48%) sepia(77%) saturate(4722%) hue-rotate(347deg) brightness(90%) contrast(99%);
                    //         }

                    //         .progres-bar {
                    //             background-color: #E5451F;
                    //         }

                    //         &.possible {
                    //             border-color: #0066FF;

                    //             img {
                    //                 filter: brightness(0) invert(1);
                    //             }
                    //         }
                    //     }

                    //     img.arrow-desktop {
                    //         filter: brightness(0) invert(1);
                    //     }
                    // }
                }

                .more-awards-wrapper {
                    display: grid;
                    place-items: center;
                    width: 100%;
                    height: 100%;
                    isolation: isolate;
                    position: relative;
                    background: #ED8002;
                    border-radius: 12px;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        svg {
                            animation: arrow 1s $QuadEaseInOut infinite;
                        }
                    }

                    * {
                        grid-row: 1/2;
                        grid-column: 1/2;
                    }

                    img {
                        z-index: -1;
                        opacity: 0.1;
                    }

                    .content {
                        p {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 24px;
                            text-align: center;
                            color: #FFFFFF;
                            margin: 0 0 8px;
                        }

                        svg {
                            width: 52px;
                            margin-inline: auto;

                            path {
                                fill: white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.new-variants {
        position: relative;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center top;
        background-color: #DA3812;
        background-image: url(./img/novel-bg-home-mobile.webp);
        width: 100%;
        padding: 6px 24px;
        margin-top: -12px;
        z-index: 10;

        @include XGA {
            background-image: url(./img/novel-bg-home-desktop.webp);
            width: 100%;
            padding: 1px 64px;
            margin-top: 16px !important;
            padding-top: 24px !important;
            z-index: initial;
        }

        .hyper-x2-logo {
            display: none;

            @include XGA {
                display: block;
                position: absolute;
                right: 0;
                top: 4px;
            }
        }

        .swiper-pagination-bullet {
            background-color: #fff !important;
            opacity: 1 !important;

            &::before,
            &::after {
                background-color: #D83813 !important;
            }

            &.swiper-pagination-bullet-active {
                background-color: #D83813 !important;
            }
        }

        .dots {
            @include XGA {
                li {
                    background-color: #D83813 !important;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            @include XGA {
                background-color: #fff;
                border-color: #fff !important;
            }
        }

        .news-desktop {
            .swiper-button-disabled {
                background-color: rgba(218, 218, 218, 0.45);
            }
        }
    }
}