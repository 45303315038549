@font-face {
	font-family: 'Mont';
	src: url('../fonts/Mont-Heavy.otf') format('opentype');
	font-style: normal;
	font-weight: 900;
	font-display: swap;
}

@font-face {
	font-family: 'Mont';
	src: url('../fonts/Mont-Regular.otf') format('opentype');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Mont';
	src: url('../fonts/Mont-SemiBold.otf') format('opentype');
	font-style: normal;
	font-weight: 600;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'password';
	src: url('../fonts/password.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  }

$main: 'Mont', sans-serif;
