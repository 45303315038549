@import "../../../styles/vars";

.invite-page {

    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    margin-left: auto;
    margin-right: auto;

    .upper-text {
        .header {
            margin: 0;
            margin-top: 12px;
            font-size: 18px;
            line-height: 20px;
            text-align: center;

            @include XGA {
                font-size: 32px;
                line-height: 40px;
                margin-top: 0;
                text-align: left;
            }
        }

        .subtitle {
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            color: #2A2C2C;
            text-align: center;
            max-width: 450px;
            margin-inline: auto;

            br {
                display: none;
            }

            @include XGA {
                text-align: left;
                margin-left: 0;

                br {
                    display: block
                }
            }
        }

        @include XGA {
            grid-area: text;
        }

        a {
            font-weight: 900;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            text-decoration-line: underline;
            color: #ED8002;
            display: block;
            margin-inline: auto;

            @include XGA {
                text-align: left;
            }
        }
    }

    .card {
        margin-bottom: 24px;
        padding-bottom: 16px;

        @include XGA {
            grid-area: img;
            padding: 0;
            margin-left: initial;
            width: 100%;
            margin: 0;
        }

        .image {
            margin-top: 16px;
            display: grid;
            margin-bottom: 24px;
            isolation: isolate;
            min-height: 100%;
            width: 100%;
            min-height: 200px;
            max-height: 250px;
            max-width: 450px;
            margin-left: -24px;
            width: calc(100% + 48px);

            @media screen and (min-width: 451px) {
                margin-left: calc(((100vw - 450px) / 2) - 24px);
                border-radius: 12px 12px 0 0;
                overflow: hidden;
                height: 100%;
            }

            @include XGA {
                margin: 0;
                border-radius: 24px;
                max-width: initial;
                max-height: initial;
                width: 100%;
                max-height: 500px;
                height: 100%;
            }

            &>* {
                grid-row: 1 / 2;
                grid-column: 1 / 2;
            }

            .img {
                border-radius: 12px 12px 0 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 1;
                display: block;

                @include XGA {
                    border-radius: 12px;
                    height: 100%;
                    width: 100%;
                    max-height: 500px;
                }
            }

            .bg {
                width: 100%;
                height: 100%;
                z-index: 1;
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.50) 100%);
                border-radius: 12px 12px 0 0;

                @include XGA {
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 88.03%);
                }
            }

            .info-wrapper {
                display: contents;

                @include XGA {
                    display: block;
                    padding: 20px 0 16px;
                    margin: auto 24px 24px;
                    border-radius: 12px;
                    border: 1px solid #FFF;
                    z-index: 2;
                    min-height: 200px;
                    display: flex;
                    flex-direction: column;
                }

                &>* {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }
            }

            .tab-selector {
                position: relative;
                z-index: 2;
                display: flex;
                gap: 16px;
                margin-top: 24px;
                height: fit-content;
                width: fit-content;
                justify-self: center;

                @include XGA {
                    margin: 0 auto 20px;
                }

                button {
                    appearance: none;
                    background-color: transparent;
                    padding: 3px 8px 1px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    border: 1px solid #fff;
                    border-radius: 24px;
                    cursor: pointer;

                    &.active {
                        border-color: #ED8002;
                        color: #ED8002;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 4px;
                position: relative;
                z-index: 2;
                margin-top: auto;
                margin-bottom: 24px;

                @include XGA {
                    margin-bottom: 0;
                }

                h4,
                p {
                    margin: 0;
                    text-align: center;
                    color: #fff;
                }

                h4 {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                }

                p {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                }
            }
        }
    }


    .link {
        max-width: 450px;
        margin-inline: auto;

        @include XGA {
            grid-area: link;
            max-width: initial;
            margin-inline: initial;
        }

        .link-header {
            // font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-align: center;

            @include XGA {
                text-align: left;
                margin-top: 24px;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 8px;
            }
        }

        .button {
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;

            @include XGA {
                margin-left: 0;
                margin-right: 0;
                gap: 8px;
            }
        }

        .wrapper {
            display: flex;
            margin: 0 16px;
            margin-top: 16px;
            gap: 16px;
            justify-content: center;

            @include XGA {
                width: 100%;
                display: block;
                margin-top: 8px;
                margin-left: 0;
                margin-right: 0;
            }

            .button {
                gap: 8px;
                padding: 10px 16px;
                margin-left: 0;
                margin-right: 0;
                font-weight: 900;
                width: fit-content;

                @include XGA {
                    width: 100%;
                }
            }
        }

        .stats {
            text-align: center;
            margin-top: 24px;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-weight: 800;

            @include XGA {
                justify-content: left;
                margin-bottom: 20px;
            }

            img {
                filter: brightness(0);
            }

            img:first-of-type {
                margin-right: 12px;
            }

            img:last-of-type {
                margin-left: 4px;
            }

            &-content {
                background: linear-gradient(90deg, $darkorange 0%, $yellow 100%);
                border-radius: 12px;
                margin: 0 16px;
                margin-top: 8px;
                color: white;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                padding-top: 16px;
                padding-bottom: 16px;

                @include XGA {
                    padding-left: 32px;
                    padding-right: 24px;
                    margin-left: 0;
                    width: 100%;
                }

                h3 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    font-size: 12px;
                    line-height: 12px;
                    gap: 20px;
                    text-align: left;

                    @include XGA {
                        justify-content: flex-start;
                    }

                    &:last-of-type {
                        margin-top: 16px;
                    }

                    img {
                        margin-right: 8px;
                    }

                    br {
                        @include XGA {
                            display: none;
                        }
                    }

                    strong {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        line-height: 20px;
                        width: 24px;
                        height: 24px;
                        background-color: rgba(255, 255, 255, 0.2);
                        border-radius: 50%;

                        @include XGA {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }

    .points-red {
        border-radius: 24px;
        background-color: #E5451F;
        padding: 11px 24px 9px;
        width: fit-content;
        font-size: 14px;
        font-weight: 900;
        line-height: 20px;
        display: block;
        margin: 16px auto 0;
        color: #fff;

        @include XGA {
            margin: 0 auto;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            padding: 3px 8px 1px;
        }
    }

    .invite-process,
    .benefits {
        margin-bottom: 40px;
        background-color: #fff;

        @include XGA {
            height: auto;
            margin-bottom: 0;
        }

        .image {
            display: grid;
            margin-bottom: 24px;
            margin-left: -24px;
            width: calc(100% + 48px);
            max-width: 450px;

            @media screen and (min-width: 451px) {
                margin-left: calc(((100vw - 450px) / 2) - 24px);
                border-radius: 12px 12px 0 0;
                overflow: hidden;
            }

            // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 88.03%);;

            @include XGA {
                margin-bottom: 0;
                overflow: hidden;
                margin-left: initial;
                width: 100%;
                max-width: initial;
            }

            &>* {
                grid-row: 1 / 2;
                grid-column: 1 / 2;
            }

            .img {
                border-radius: 12px 12px 0 0;
                width: 100%;
                min-height: 120px;
                object-fit: cover;
                position: relative;
                z-index: 1;
                // mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 88.03%);
            }

            .bg {
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6859122401847575) 14%, rgba(255, 255, 255, 0) 100%);
                z-index: 1;
            }

            .text {
                align-self: end;
                color: white;
                margin-left: 24px;
                margin-bottom: 16px;
                z-index: 4;
                width: fit-content;
                margin-inline: auto;

                h3 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;

                    @include XGA {
                        width: 100%;
                    }

                    br {
                        display: none;
                    }
                }
            }
        }

        .content {
            @include XGA {
                padding-top: 32px;

                &>.paragraph:first-child {
                    h3 {
                        margin-top: 0;
                    }
                }
            }
        }

        .check-out {
            text-align: right;
            margin-right: 24px;
            margin-left: auto;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $lightorange;
            font-size: 12px;
            line-height: 16px;
            cursor: pointer;
            width: fit-content;
            font-weight: 600;

            img {
                margin-left: 4px;
            }

            @include XGA {
                margin: 16px auto 8px 32px;
                padding: 0;
                display: none;
            }
        }
    }

    .invite-process {
        @include XGA {
            .image {
                aspect-ratio: auto;

                @include XGA {
                    margin-bottom: 0;
                }

                .text {
                    h3 {
                        width: fit-content;
                        margin-inline: auto;
                    }
                }
            }
        }

        .content {
            max-width: 380px;
            margin-inline: auto;

            @include XGA {
                max-width: initial;
                margin-inline: initial;
                margin-top: 0;
                padding: 24px;
                border: 1px solid #E5E5E5;
                border-radius: 0 0 12px 12px;
            }

            h3 {
                margin: 24px 0 0;
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                text-align: center;
            }

            p {
                margin: 8px 0 0;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-align: center;
            }
        }
    }

    .benefits {
        @include XGA {

            .image {
                .img {
                    aspect-ratio: auto;
                }
            }

            .image>.text {
                width: 40%;

                @include XGA {
                    width: 46%;
                }

                @include HD {
                    width: 100%;
                }
            }
        }

        .content {
            position: relative;

            @include XGA {
                padding: 24px;
                border: 1px solid #E5E5E5;
                border-radius: 0 0 12px 12px;
                border-top: none;
            }


            .for-you {
                h4 {
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 20px;
                    margin: 32px 0 0;
                    text-align: center;

                    @include XGA {
                        margin-top: 8px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }

                p {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: center;
                    margin: 16px 0 16px;
                }


                .awards-box {
                    margin-top: 32px;
                    padding: 16px;
                    border: 2px solid #F5AA04;
                    border-radius: 12px;
                    position: relative;
                    max-width: 400px;
                    margin-inline: auto;

                    @include QHD {
                        max-width: initial;
                        margin-inline: initial;
                        display: flex;
                        gap: 16px;
                        padding: 24px;
                    }

                    h4 {
                        margin: 12px 0 0;

                        @include QHD {
                            text-align: center;
                            font-size: 15px;
                            font-weight: 700;
                            line-height: 20px;
                        }

                        br {
                            display: none;

                            @include QHD {
                                display: block;
                            }
                        }
                    }

                    p.or {
                        @include QHD {
                            position: absolute;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                            bottom: 21px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    p.info {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin: 0;
                        min-width: 203px;
                        background-color: white;
                    }

                    img {
                        width: 100%;
                        display: block;
                    }
                }
            }

            .invited {
                span {
                    text-align: right;
                    font-size: 32px;
                    line-height: 32px;
                    opacity: 0.1;
                    font-weight: bold;
                    display: block;
                    margin-bottom: 8px;

                    @include XGA {
                        margin-bottom: 49px;
                    }
                }
            }

            .element {
                display: grid;
                grid-template-columns: 42% auto;
                margin-bottom: 12px;
                gap: 8px;
                aspect-ratio: 277/66;

                @include XGA {
                    aspect-ratio: initial;
                    height: 82px;
                }

                @include UXGA {
                    height: 75px;
                }

                .img {
                    aspect-ratio: auto;
                    width: 100%;
                    border-radius: 16px;
                    height: 100%;
                    object-fit: cover;

                    @include XGA {
                        height: 80px;
                    }

                    @include UXGA {
                        height: 73px;
                    }
                }

                .text {
                    .title {
                        margin: 0;
                        font-size: 12px;
                        line-height: 14px;
                        margin-bottom: 2px;
                        font-weight: 600;
                        white-space: pre-wrap;
                    }

                    .subtitle {
                        font-size: 12px;
                        line-height: 14px;
                        margin: 0;
                        opacity: 0.4;
                    }
                }

                @include XGA {
                    &:last-of-type {
                        margin-bottom: 24px;
                    }
                }
            }

            .element-wrapper {
                display: flex;
                flex-direction: column;
                row-gap: 0px;

                @include XGA {
                    row-gap: 0px;
                }

                .subtitle {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    opacity: 0.6;
                    margin-bottom: 8px;
                    margin-top: 0px;
                }

                .wrapper {
                    // max-height: 100%;
                    // min-height: 100%;
                    display: grid;
                    max-width: 170px;

                    &>* {
                        grid-column: 1/2;
                        grid-row: 1/2;
                    }

                    p {
                        margin: 0;
                        align-self: center;
                        text-align: center;
                        color: #FFFFFF;
                        line-height: 32px;
                        font-size: 32px;
                        font-weight: 700;
                        margin-top: 4px;
                    }
                }

                .element {
                    margin-bottom: 16px;
                    border: 1px solid #E5E5E5;
                    border-radius: 12px;
                    gap: 16px;
                    margin-bottom: 24px;

                    .img {
                        border-radius: 12px 0 0 12px;
                    }

                    .text {
                        display: flex;
                        align-self: center;
                        padding: 8px;
                        padding-left: 0px;
                    }

                    .title {
                        // padding-right: 20px;
                        margin-bottom: 0px;
                        font-size: 10px;

                        @include XGA {
                            // padding-right: 6px;
                            max-width: 240px;
                        }

                        @include HD {
                            // padding-right: 20px;
                        }
                    }
                }
            }
        }
    }

    .benefits-invited {
        display: block;
        margin-bottom: 24px;

        @include XGA {
            margin-bottom: 0;
            // display: none;
        }
    }

    .bottom-grid {
        position: relative;
        z-index: 2;

        @include XGA {
            display: grid;
            gap: 24px;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 64px;

            &>div {
                display: grid;
                grid-template-rows: auto 1fr;
            }
        }

        @include HD {
            gap: 26px;
        }

        @include UXGA {
            gap: 33px;
        }

        @include FHD {
            gap: 45px;
        }
    }


    .main-panel {
        @include XGA {
            display: grid;
            grid-template-areas:
                "text img img"
                "link img img";
            grid-template-rows: minmax(100px, 1fr);
            grid-template-columns: repeat(3, 1fr);
            column-gap: 24px;

            @include HD {
                gap: 26px;
            }

            @include UXGA {
                gap: 33px;
            }

            @include FHD {
                gap: 45px;
            }
        }
    }

    .br-mobile {
        display: block;

        @include XGA {
            display: none;
        }
    }

    .br-desktop {
        display: none;

        @include XGA {
            display: block;
        }
    }


    .points {
        font-size: 12px;
        background: $darkorange;
        color: white;
        border-radius: 12px;
        padding: 4px 8px 2px;
        display: inline-block;
        font-weight: 600;
        line-height: 16px;
    }

    .referral {
        .button {
            max-width: calc(100% - 32px);
        }
    }

    .benefits-invited.benefits {
        .for-you {
            max-width: 400px;
            margin-inline: auto;

            @include XGA {
                max-width: initial;
                margin-inline: initial;
            }

            p.info {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                margin: 12px 0 0;

                @include XGA {
                    margin-top: 8px;
                }
            }

            h4 {
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                margin: 12px 0 0;
            }

            img {
                width: 100%;
                margin-top: 24px;
                display: block;

                @include XGA {
                    max-width: 300px;
                    margin-inline: auto;
                }
            }
        }
    }
}

.send-invitation {
    .header {
        h2 {
            margin: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;

            @include XGA {
                font-size: 24px;
                line-height: 32px;
            }
        }

        p {
            margin: 0;
            margin-top: 4px;
            margin-bottom: 16px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;

            @include XGA {
                font-size: 18px;
                line-height: 16px;
                margin-top: 4px;
                margin-bottom: 30px;
            }
        }
    }

    .boxes {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include XGA {
            flex-direction: row;
            margin-top: 24px;
        }
    }

    button.box {
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding: 0;
    }

    .box {
        display: grid;
        // background: linear-gradient(79.34deg, #000000 -4.32%, rgba(0, 0, 0, 0) 89.75%);        
        color: white;
        overflow: hidden;
        border-radius: 12px;
        text-decoration: none;

        @include XGA {
            margin: 0;
        }

        &>* {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
        }

        img {
            width: 100%;
            border-radius: 12px;
            z-index: -1;
        }

        h3 {
            font-size: 18px;
            line-height: 20px;
            text-align: left;
            margin-left: 16px;
            margin-top: 24px;
            font-weight: 600;
        }

        .bottom {
            align-self: flex-end;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            img {
                width: 55px;
                margin-left: 16px;
                margin-bottom: 16px;
                z-index: 1;
            }

            span {
                margin-right: 16px;
                opacity: 0.5;
                font-weight: 900;
                font-size: 80px;
                line-height: 80px;
                margin-bottom: -18px;
            }
        }
    }

    .coppy {
        opacity: 0.3;
        border: 1px solid #000000;
        border-radius: 12px;
        text-align: left;
        padding: 5px 12px;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 24px;
    }
}