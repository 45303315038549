@import "../../../../../styles/vars";

.dwa-slawy {

    .text {
        font-weight: 700 !important;
    }

    .sub {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        opacity: 0.8;
        margin-top: 12px;

        @include XGA {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #000000;
            opacity: 1;
            margin-top: 16px;
        }
    }

    .price {
        @include XGA {
            margin-top: 0 !important;
        }
    }

    .line {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
    }

    h3 {
        font-size: 18px;
        margin-top: 10px;

        @include XGA {
            font-size: 24px;
        }
    }

    form {
        margin-top: 24px;

        @include XGA {
            margin-top: 40px;
        }

        label,
        input {
            display: flex;
            flex-direction: column;
            text-align: left;
        }

        label {
            margin-top: 16px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }

        input {
            display: block;
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid black;
            padding: 4px 0px;
            color: black;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            outline: none;
            transition: all 0.2s $CubicEaseInOut;

            &:focus {
                border-color: $lightorange;
            }
        }

        .postal-code {
            input {
                margin-bottom: 24px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 6px;

                @include XGA {
                    margin-bottom: 40px;
                }

                &:focus {
                    border: none;
                }
            }
        }

        // .postal-code {
        //     background-image: url("./img/postal.svg");
        //     background-repeat: no-repeat;

        //     @include XGA {
        //         margin-bottom: 40px;
        //     }


        //     input {
        //         border: none;
        //         // font-size: 14px;
        //         // letter-spacing: 14.5px;
        //         // padding-left: 4.5px;

        //         // @include XGA {
        //         //     letter-spacing: 17px;
        //         //     padding-left: 5px;
        //         // }
        //     }
        // }
    }

    .flex-wrapper {
        display: flex;
        justify-content: space-between;

        div {
            width: 190px;
        }
    }

    &.collect-reward.modal-content {
        @include XGA {
            grid-template-columns: .5fr 1fr .5fr;
        }
    }

    &.taco.collect-reward {
        .card {
            background-color: white;
        }

        .text {
            opacity: 1;
            color: #2A2C2C;

            h3 {
                margin-bottom: 4px;

                @include XGA {
                    margin-top: 37px;
                }
            }

            .sub-header {
                font-weight: 700;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.12em;
                text-transform: uppercase;
                margin-top: 4px;
                margin-bottom: 16px;
                color: black;
            }

            p {
                padding-left: 16px;
                padding-right: 16px;
                font-weight: 500;
                margin-top: 16px;
                opacity: 1;

                @include XGA {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    color: #2A2C2C;
                }
            }
        }

        .reward-info {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            padding-left: 16px;
            padding-right: 16px;
            margin-top: 25px;
            color: #2A2C2C;
        }

        .desktop-grid {
            @include XGA {
                display: grid;
                grid-column: 2/4;
                width: 100%;
                grid-template-columns: 105px 259px;
                justify-content: center;

                @include HD {
                    gap: 35px;
                }

                .grid-points {
                    grid-row: 1/2;
                }

                .reward-info {
                    padding: 0;
                }
            }
        }

        .grid-icons {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 17px;

            .date,
            .statute {
                margin-top: 0;
                margin-bottom: 0;

                img {
                    margin-top: 0;
                }

                p,
                a {
                    margin-bottom: 0;
                    max-width: 115px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        .statute,
        .date {
            @include XGA {
                max-width: 143px;
                padding: 0 10px;
                margin-bottom: 37px;
            }
        }

        .date {
            @include XGA {
                margin-left: auto;
            }
        }

        .form-text {
            h4 {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                color: #000000;
                text-align: left;
                margin: 0;
                margin-top: 32px;
            }

            p {
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                color: #000000;
                text-align: left;
                margin: 0;
                margin-top: 8px;
            }
        }

        .agreements {
            margin-top: 8px;

            @include XGA {
                margin-bottom: 16px;
            }

            .checkbox {
                position: relative;

                input[type="checkbox"] {
                    display: none;
                }

                label {
                    position: relative;
                    display: block;
                    width: 100%;
                    padding-left: 30px;
                    margin-bottom: 12px;
                    min-height: 24px;
                    cursor: pointer;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 20px;
                        height: 20px;
                        border: $darkorange solid 1px;
                    }
                }

                input[type="checkbox"]:checked+label {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 7px;
                        top: 4px;
                        width: 5px;
                        height: 8px;
                        border-right: $darkorange solid 2px;
                        border-bottom: $darkorange solid 2px;
                        transform: rotate(35deg);
                    }
                }
            }

            .switches {
                margin-top: 8px;
                margin-bottom: 8px;
            }

            p {
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                text-align: left;
                max-width: 100%;
                width: 100%;
                margin-top: 0;
            }

            .link {
                display: inline;
                color: black;
                font-weight: 500;
            }

            .wrapper {


                h3 {
                    color: #ED8002;
                    text-align: left;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 10px;
                    padding-top: 3px;
                }
            }

            &>p:last-of-type {
                margin-top: 8px;
            }
        }

        &.modal-content {
            grid-template-columns: 1fr;
            padding-bottom: 0;

            .card {
                margin: 0;
            }
        }

        .form-separation {
            display: grid;
            grid-template-columns: 44% 56%;

            .grey {
                position: relative;
                background-color: #F7F7F7;

                &::before {
                    content: "";
                    width: calc(100% + 20px);
                    height: calc(100% + 66px);
                    background-color: #F7F7F7;
                    position: absolute;
                    top: -46px;
                    left: 0;
                    z-index: -1;
                }

                form {
                    margin: 0 auto;
                    max-width: 414px;
                }
            }

            .form-text {
                @include XGA {
                    padding: 11px 64px 64px 48px;
                    h4 {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        margin-top: 0;
                    }

                    p {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;

                        strong {
                            font-weight: 700;
                        }
                    }

                    img {
                        display: block;
                        width: 100%;
                        border-radius: 12px;
                        margin-top: 18px;
                        margin-bottom: 18px;
                    }
                }
            }
        }
    }
}