.logout-warning {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 12px 5%;
	text-align: center;
	background-color: rgba($color: #000000, $alpha: 0.9);
	color: #fff;
	z-index: 9999;

	p {
		margin: 0;
		font-size: 12px;
	}
}