@import "../../../styles/vars";

.register-page {
    --height: 660px;

    position: relative;
    height: var(--height);
    background-image: url(../LoginPage/img/bg-mobile.webp);
    background-position: left center;
    // background-size: 100% 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    transition: height 200ms linear;

    @include XGA {
        background-image: url("./img/bg-desktop.webp");
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
    }

    .mobile {
        @include XGA {
            display: none;
        }
    }

    .logo {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding-top: calc(max(6vh, 40px));
        padding-bottom: 24px;

        @include XGA {
            padding-top: calc(max(8vh, 48px));
            gap: 31px;
        }

        img {
            width: calc(min(30vw, 140px));
        }
    }

    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }

    .selector {
        max-width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 9vh;

        div {
            width: 50%;
            color: rgba(255, 255, 255, 0.5);
            border-bottom: 1px solid white;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            padding-top: 9px;
            padding-bottom: 8px;

            a {
                color: rgba(255, 255, 255, 0.5);
                text-decoration: none;
            }

            &.selected {
                border-radius: 12px 12px 0px 0px;
                border: 1px solid white;
                border-bottom: none;
                color: white;
            }
        }
    }

    .referral {
        margin: 0 auto;
        max-width: 440px;

        h3 {
            span {
                color: $orange;
            }
        }

        .swiper {
            margin-left: initial;
            margin-right: initial;

            @include XGA {
                margin-top: calc(max(18vh, 120px)) !important;
            }
        }

        .button-wrapper {
            margin-top: calc(max(6vh, 48px)) !important;
        }

        .swiper-referral {
            padding-top: 52px;

            h2 {
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                max-width: 215px;
                margin: 0 auto;

                @include XGA {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    max-width: initial;
                }
            }

            h3 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
            }

            .wrapper {
                padding: 16px 0px;
                background-color: rgba($color: #000000, $alpha: .5);
                border-radius: 12px;
                max-width: 327px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 23px;

                .icon {
                    width: 32px;
                    height: 32px;
                    background-color: black;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    margin: 0 auto 8px;

                    img {
                        width: 15px;
                        height: 15px;
                        margin: 0;
                        display: block;
                    }
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                position: absolute;
                top: 0px;
                // left: calc(max(50%,60px));
                left: calc(50% - 92px);
                width: 32px;
                height: 32px;
                border: 2px solid #ED8002;
                border-radius: 50%;
                z-index: 2;
                cursor: pointer;
                // display: none;

                @include XGA {
                    // display: block;
                    left: calc(50% - 240px);
                    top: 48px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    border-left: 3px solid $orange;
                    border-bottom: 3px solid $orange;
                    width: 6px;
                    height: 6px;
                }
            }

            .swiper-button-next {
                left: auto;
                right: calc(50% - 92px);

                @include XGA {
                    right: calc(50% - 240px);
                }

                &::before {
                    left: 9px;
                    transform: translateY(-50%) rotate(-135deg);
                }
            }

            .swiper-button-disabled {
                border-color: #C6C6C5;

                &::before {
                    border-color: #C6C6C5;
                }
            }
        }

        @include XGA {
            margin-top: calc(max(6vh, 48px));
        }

        .swiper-pagination {
            margin-top: 0;
            top: 8px;
            z-index: -1;

            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
                background-color: white;
                border-radius: 50%;
                opacity: 1;
                transition: width .2s $QuadEaseInOut;

                &.swiper-pagination-bullet-active {
                    width: 24px;
                    height: 8px;
                    border-radius: 24px;
                    background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                }
            }
        }
    }

    .shapes {
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        gap: 11px;

        @include XGA {
            margin-bottom: 17px;
        }

        .rect {
            width: 24px;
            height: 8px;
            border-radius: 24px;
            background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
        }

        .oval {
            width: 8px;
            height: 8px;
            background-color: white;
            border-radius: 50%;
        }
    }

    .organic {
        .welcome {
            font-size: 22px;
            line-height: 133%;
            margin-inline: auto;
            margin-bottom: 24px;
            max-width: 306px;
            width: 100%;

            @include XGA {
                max-width: 585px;
            }

            h1 {
                font-weight: 900;
                font-size: 28px;
                line-height: 40px;
                text-transform: uppercase;
                color: #FFFFFF;
                margin: 0;
                text-align: left;

                @include XGA {
                    text-align: center;
                    font-size: 40px;
                    line-height: 40px;
                    margin-bottom: 11px;
                }


                &.orange {
                    font-family: 'Mont';
                    font-style: italic;
                    font-weight: 900;
                    font-size: 32px;
                    line-height: 40px;
                    text-transform: uppercase;
                    color: #FF782C;
                    text-align: right;

                    @include XGA {
                        font-size: 46px;
                        line-height: 40px;
                    }
                }
            }

            @include XGA {
                margin-top: calc(max(18vh, 120px)) !important;
            }
        }

        .button-wrapper {
            @include XGA {
                margin-top: calc(max(18vh, 120px)) !important;
            }
        }
    }

    .register-form {
        label {
            float: left;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: white !important;

            a {
                color: $lightorange;
                text-decoration: none;
                font-weight: 700;
            }
        }

        input, select {
            color: white;
            display: block;
            width: 100%;
            margin-bottom: 12px;
            background: transparent;
            border: none;
            border-bottom: 1px solid white;
            padding: 4px 0px;
            color: white !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            outline: none;

            &:focus {
                border-color: $lightorange;
                transition: all 0.2s $CubicEaseInOut;
            }
        }

        .text {
            margin-top: 32px;
            margin-bottom: 16px;

            @include XGA {
                margin-bottom: 13px;
            }

            h3 {
                text-align: center;
                margin: 0;
                margin-bottom: 16px;
            }

            h4 {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                margin: 0 auto;
                margin-top: 4px;
                max-width: 279px;

                @include XGA {
                    margin-top: 8px;
                }
            }

            .wrapper {
                padding: 12px 0;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 12px;
                margin-top: 16px;
                max-width: 400px;
                margin: 0 auto;
            }

            .logos {
                margin-top: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;

                img {
                    display: block;
                }
            }
        }

        .selector {
            @include XGA {
                margin-top: calc(max(5vh, 32px)) !important;
            }
        }

        .button-wrapper {
            @include XGA {
                margin-top: 6vh !important;
            }
        }

        .button {
            width: 200px;
        }
    }

    .blur {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        transition: backdrop-filter 500ms ease-in-out;

        &.active {
            backdrop-filter: blur(30px);
        }
    }

    .register-page-inner {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        height: 100%;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: column;

        @include XGA {
            height: fit-content;
        }

        .main {
            display: flex;
            flex-direction: column;
            height: 100%;

            img {
                display: block;
            }

            &>div {
                display: contents;

                *:first-child {
                    margin-top: auto;
                }

            }

            form {
                display: flex;
                flex-direction: column;
                height: 100%;

                .user-data {
                    width: 100%;
                }

                .buttons {
                    margin-top: auto;
                }
            }

            .button-wrapper {
                padding-bottom: calc(max(6vh, 40px));
                margin-top: auto;
            }
        }

        svg {
            width: 80px;
            height: auto;
        }

        h2 {
            font-weight: 600;
        }

        .user-data {
            display: block;
            margin: 0 auto;
            max-width: 400px;

            input {
                display: block;
                width: 100%;
                margin-bottom: 12px;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    border-color: #fff;
                    background-color: transparent;
                    -webkit-text-fill-color: #fff;
                    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
                    transition: background-color 5000s ease-in-out 0s;
                }
            }

            label {
                color: #000;
            }

            .input {
                position: relative;

                .valid-icon {
                    position: absolute;
                    top: 20px;
                    right: 0;
                }

                &.postalcode {
                    label {
                        position: relative;
                        display: block;
                        width: 100%;
                        text-align: left;
                    }

                    input {
                        width: 100px;
                    }
                }

                &.birthdate {
                    label {
                        display: block;
                        width: 100%;
                        text-align: left;
                    }

                    .rsd__react-select-datepicker, .select-datepicker  {
                        width: 100%;
                        display: flex;
                        position: relative;
                        justify-content: space-between;
                        column-gap: 24px;
                        padding-top: 4px;

                        .rsd__select-container-month, .rsd__select-container-day, .rsd__select-container-year {
                            width: 100%;
                        }

                        select {
                            option {
                                color: #000;
                            }
                        }
                    }
                }

                &.group {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    column-gap: 24px;
                    align-items: center;

                    .phone {
                        width: 100%;
                    }

                    .button {
                        width: 190px;
                        text-align: center;
                        font-weight: 700;
                        margin-right: 0;
                    }

                    .send-otp {
                        .wait-until {
                            width: 190px;
                            font-size: 12px;
                            text-align: center;
                        }
                    }

                    .verify-otp {
                        
                    }
                }
            }
        }

        .disclaimer {
            margin: 0 auto;
            display: block;
            width: 90%;
            max-width: 600px;
            font-size: 10px;
            margin-top: 24px;
        }

        .error {
            position: relative;
            top: -6px;
            font-size: 12px;
            color: #EA1D1D;
            text-align: left;

            &.long {
                font-size: 10px;

                @include vHD {
                    font-size: 12px;
                }
            }

            &:before {
                content: "!";
                position: relative;
                top: -2px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background-color: #EA1D1D;
                color: #fff;
                border-radius: 50%;
                margin-right: 6px;
                font-size: 9px;
                line-height: 15px;
                font-weight: 700;
                text-align: center;
            }
        }

        .success {
            position: relative;
            top: -6px;
            font-size: 12px;
            color: #22BA7A;
            text-align: left;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            column-gap: 8px;

            span {
                display: inline-block;
            }

            &.long {
                font-size: 10px;

                @include vHD {
                    font-size: 12px;
                }
            }
        }
    }

    .button {
        // margin-top: 24px; 
        width: 200px;
    }

    .switch {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 40px;
        height: 24px;


        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(237, 128, 2, 0.1);
            transition: .4s;
            border-radius: 34px;
            border: 1px solid white;
            border-radius: 12px;


            &:before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 3px;
                bottom: 2px;
                background-color: white;
                transition: .2s;
                border-radius: 50%;
            }
        }

        input:checked+.slider {
            background-color: $lightorange;

            &:before {
                background-color: #FDF2E6;
            }
        }

        input:checked+.slider:before {
            transform: translateX(16px);
        }
    }

    .consents {
        .shapes {
            margin-bottom: 16px;
            margin-top: 32px;
        }

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        p {
            margin-top: 0;
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
        }

        .consent-required {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: #FFFFFF;
            text-align: left;
            margin: 16px 0;
        }

        .info {
            width: 100%;
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
            margin-top: 16px;
            text-align: left;

            &:last-child {
                margin-bottom: 24px;
            }

            a {
                text-decoration: none;
                color: $lightorange;
            }
        }

        .link {
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
            text-decoration-line: underline;
            color: #ED8002;
            text-align: left;
        }

        .checkbox {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            gap: 17px;
            margin-bottom: 7px;
            margin-bottom: 12px;

            input {
                width: 40px;
            }

            label {
                text-align: left;
                width: 100%;
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                padding-left: 54px;
                z-index: 10;
                min-height: 24px;
            }
        }
    }

    .thankyou {

        .selector {
            @include XGA {
                margin-top: calc(max(5vh, 32px)) !important;
            }
        }

        .button-wrapper {
            padding-bottom: calc(max(9vh, 40px)) !important;

            @include XGA {
                margin-top: 6vh !important;
            }
        }

        h3 {
            margin-bottom: 0;
            margin-top: 32px;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
        }

        p {
            margin-top: 24px;
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 0;

            &:first-of-type {
                margin-top: 16px;
            }
        }

        .small {
            font-size: 10px;
            line-height: 14px;
            margin-top: 24px;
        }
    }
}