@import "../../../styles/vars";

.campaign-wrapper {
    position: relative;
    min-height: 100vh;

    .campaign-loader {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
    }

    .template-content {
        img {
            width: 100%;
        }
    }
}