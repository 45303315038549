@import "../../../styles/vars";

.push-notification-modal {
  width: 100%;

  .upper-bar {
    height: 80px;
    margin: -20px -20px 0;
    width: calc(100% + 40px);
    display: grid;
    place-items: center;
    background-image: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
    position: relative;

    button {
      border: none;
      padding: 0;
      margin: 0;
      background-color: transparent;
      position: absolute;
      cursor: pointer;
      top: 16px;
      right: 16px;

      @include XGA {
        top: 20px;
        right: 24px;
      }

      .exit {
        &::before {
          content: "\2715";
          color: white;
          height: fit-content;
          font-size: 18px;
          font-weight: 900;
          line-height: 0;
          height: 100%;
        }
      }
    }
  }

  .text {
    margin-top: 32px;

    @include XGA {
      margin-top: 40px;
    }

    * {
      margin: 0;
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #2A2C2C;

      @include XGA {
        color: #000000;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }
    }

    p {
      margin-top: 8px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #2A2C2C;

      @include XGA {
        margin-top: 16px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    }

  }

  .button-wrapper {
    margin-top: 24px;
    justify-content: center;
    gap: 14px;

    @include XGA {
      gap: 24px;
    }
  }
}