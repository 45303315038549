@import "../../../styles/vars";

.easter-egg {
  z-index: 4;
  position: relative;
  width: fit-content;
  height: fit-content;

  img {
    display: block;
  }

  img {
    width: 25px;
  }
}

.easter-popup {
  background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
  border: 1px solid #F59517;
  border-radius: 12px;
  min-width: 266px;
  max-width: 266px;
  z-index: 300;

  .inner {
    position: relative;
    padding: 16px 24px;

    p.easter-popup-p,
    h4.easter-popup-h4 {
      margin: 0;
      color: white;
      white-space: pre-wrap;
      text-align: left;
    }

    h4.easter-popup-h4 {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
    }

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      p.easter-popup-p {
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.12em;
        color: #FFFFFF;
      }

      button.popup-close {
        padding-inline: 8px;
        margin-right: -12px;
        width: fit-content !important;
        cursor: pointer;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .popup-wrapper {
      p.easter-popup-p {
        margin-top: 4px !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #F7F7F7;
      }

      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px !important;

        .button.transparent {
          background-color: transparent;
          color: white;
          border-color: white;
          border-width: 2px;
          padding: 11px 40px 11px 40px;
        }
      }
    }
  }
}

.easter-counter {
  display: flex;
  align-items: center;
  margin-top: 6px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C6C6C5;
  width: calc(100% - 48px);
  max-width: 386px;
  margin: 0 auto;
  padding-top: 6px;
  margin-bottom: 20px;
  z-index: 5;

  @include XGA {
    position: absolute;
    transform: translateY(14px);
    right: 64px;
    padding-top: 0;
  }

  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #676767;
    margin: 0;

    strong {
      color: #000000;
      font-weight: 900;
    }
  }

  .counter {
    display: flex;
    gap: 6px;
    margin-left: auto;

    &.finished {
      margin-right: auto;
    }

    @include XGA {
      gap: 8px;
    }

    .button {
      padding: 3px 8px 1px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }

    svg {
      width: 11px;

      @include XGA {
        width: initial;
      }

      &.hidden {
        path {
          fill: #C6C6C5;
        }
      }
    }

  }

  button.cancel {
    margin-left: 5px;

    @include XGA {
      margin-left: 21px;
    }

    .inner {
      height: 14px;
      padding-inline: 5px;
      display: grid;
      place-items: center;

      svg {
        display: block;

        path {
          fill: #E5451F;
        }
      }
    }
  }
}

.easter-egg,
.easter-counter,
.easter-popup {
  button:not(.button) {
    appearance: none;
    border: none;
    background-color: transparent;
  }
}

div.easter-info-modal[role="dialog"] {
  padding: 0;
  max-width: calc(min(90vw, 640px));

  .content {
    display: block;
  }

  .easter-modal-inner {
    button {
      cursor: pointer;
    }

    .top-bar {
      height: 80px;
      display: grid;
      background-image: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);

      @include XGA {
        height: 88px;
      }

      &>* {
        grid-row: 1/-1;
        grid-column: 1/-1;
      }

      .gift {
        place-self: center;
      }

      button {
        appearance: none;
        border: none;
        background-color: transparent;
        width: fit-content;
        height: fit-content;
        padding-inline: 5px;
        margin: 11px 11px 0 auto;

        @include XGA {
          margin: 20px 21px 0 auto;
        }
      }
    }

    .content-wrapper {
      padding: 32px 16px 24px;

      @include XGA {
        padding: 40px 40px 32px;
      }

      h3,
      p {
        margin: 0;
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        max-width: 442px;
        margin-inline: auto;
      }

      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #2A2C2C;
        margin-top: 8px;
      }

      button.button {
        margin-top: 24px;

        @include XGA {
          margin-top: 32px;
        }
      }
    }
  }
}