.like-outer {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    column-gap: 12px;

    .like-wrapper {
        position: relative;
        z-index: 5;
        height: 32px;
        display: grid;
        grid-template-columns: 32px 1fr;
        background: #F7F7F7;
        border-radius: 16px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
    
        &.clicked {
            .circle-bigger {
                animation: circle-expand-big 1.5s cubic-bezier(0.64, -0.02, 0.18, 1);
            }
    
            .circle-smaller {
                animation: circle-expand-small 1.5s cubic-bezier(0.64, -0.02, 0.18, 1);
            }
    
            .icon {
                animation: bounce 1s cubic-bezier(0.87, 0, 0.13, 1) 0.5s;
            }
        }
    
        .like-button {
            width: 100%;
            height: 32px;
            border: none;
            padding: 0;
            margin: 0;
            appearance: none;
            background-color: #ED8002;
            border-radius: 50%;
            position: relative;
            transition: background-color 0.3s linear;
            cursor: pointer;
    
            &.liked {
                background-color: white;
                
                .icon img {
                    filter: invert(16%) sepia(1%) saturate(194%) hue-rotate(22deg) brightness(96%) contrast(90%);
                }
            }
    
            &:not(.liked) {
                &:hover {
                    background-color: #E5451F;
                }
            }
    
            .icon {
                height: 32px;
                width: 32px;
                display: grid;
                place-items: center;
    
                img {
                    display: block;
                }
    
                @keyframes bounce {
                    0% {
                        transform: translateY(0);
                    }
    
                    50% {
                        transform: translateY(-5px);
                    }
    
                    100% {
                        transform: translateY(0);
                    }
                }
            }
        }
    
        .like-count {
            color: #ED8002;
            height: 32px;
            padding: 7px 16px 2px 8px;
            cursor: default;
        }
    
        .inner {
            position: relative;
    
            .animation {
                position: absolute;
                z-index: -1;
                width: 100px;
                aspect-ratio: 1/1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: grid;
                place-items: center;
                pointer-events: none;
    
                .circle-bigger {
                    transform-origin: center;
                    transform: scale(0);
    
                    @keyframes circle-expand-big {
                        0% {
                            transform: scale(0.4);
                            stroke-width: 0;
                        }
    
                        45% {
                            stroke-width: 6;
                        }
    
                        100% {
                            stroke-width: 0;
                            transform: scale(1);
                        }
                    }
                }
    
                .circle-smaller {
                    transform-origin: center;
                    transform: scale(0);
    
                    @keyframes circle-expand-small {
                        0% {
                            transform: scale(0.4) rotate(90deg);
                            stroke-width: 0;
                        }
    
                        45% {
                            stroke-width: 6;
                        }
    
                        100% {
                            stroke-width: 0;
                            transform: scale(0.8) rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}