@import "../../../../styles/vars";

.letnie-brzmienia-page {
  width: calc(100% - 48px);
  border-radius: 12px 12px 0px 0px;
  margin: 0 auto;

  @include XGA {
    width: calc(100% - 128px);
    border-radius: 0px;
    margin-top: 40px;
  }


  .header-mobile {
    display: grid;
    grid-template-columns: 25px 1fr 25px;
    margin-bottom: 20px;
    margin-top: 14px;
    position: relative;

    h2 {
      grid-column: 2/3;
      text-align: center;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
    }

    .back-button {
      border: none;
      padding: 0;
      margin: 0;
      background-color: transparent;
      cursor: pointer;
      width: 100%;
      z-index: 10;

      div {
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: left;
        align-items: center;
      }
    }
  }

  .top-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;

    @include XGA {
      margin-top: 40px;
      justify-content: left;
      column-gap: 24px;

      .back-button {
        border: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        width: fit-content;
        z-index: 10;
        align-self: flex-start;

        div {
          width: fit-content;
          height: 100%;
          display: grid;
          justify-content: left;
          align-items: center;
          margin-top: 4px;

          img {
            height: 24px;
          }
        }
      }
    }

    .header {
      @include XGA {
        font-size: 32px;
        margin: 0;
        margin-right: 40px;
        line-height: 40px;
      }
    }

    p,
    a {
      opacity: 0.4;
      font-size: 12px;
      padding: 4px 8px;
      border: 1px solid transparent;
      cursor: pointer;
      text-decoration: none;
      font-weight: 500;
      color: black;
      margin-top: 0;
      margin-bottom: 0;

      @include XGA {
        padding: 6px 16px;
        font-size: 14px;
        margin: 0 !important;
      }
    }

    .selected {
      color: $lightorange;
      padding: 4px 8px 2px;
      border: 1px solid $lightorange;
      border-radius: 24px;
      opacity: 1;
      line-height: 1.2;
      font-weight: 600;

      @include XGA {
        padding: 6px 16px;
        font-weight: 800;
      }
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 48px);
      left: -24px;
      // background-color: lightgrey;
      background-size: cover;
      background-image: url("./img/bg-mobile.jpg");
      aspect-ratio: 563 / 842;
      z-index: -1;

      @include XGA {
        background-image: url("./img/bg-desktop.jpg");
        width: calc(100% + 128px);
        min-height: 500px;
        left: -64px;
        aspect-ratio: 180 / 77;
      }
    }

    .text {
      padding-top: 60vw;

      @include XGA {
        padding-top: 2vw;
        width: 50%;
        margin-left: auto;
      }

      @include HD {
        padding-top: 7.5vw;
      }

      p,
      li {
        color: white;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 2px;
        font-weight: 700;

        strong {
          font-weight: 700;
        }

        @include XGA {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 0;
          font-weight: 400;

          strong {
            font-weight: 700;
          }
        }
      }

      p {
        margin: 0;

        @include XGA {
          margin-bottom: 16px;
        }
      }

      ul {
        margin-left: 2px;

        li {
          list-style: initial;
          margin-left: 16px;
        }
      }
    }

    .card-button {
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
        cursor: initial;
      }
    }

    .card-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 24px;
      margin-top: 30px;

      @include XGA {
        gap: 32px;
        margin-top: 7.5vw;
        grid-template-columns: repeat(auto-fit, minmax(300px, 20%));
      }

      .card {
        border: 3px solid #E5451F;
        border-radius: 12px;
        width: fit-content;
        display: grid;
        position: relative;
        color: white;
        width: 100%;

        img {
          display: block;
        }

        .img-wrapper {
          aspect-ratio: 304/164;

          img {
            border-radius: 9px 9px 0 0;
            aspect-ratio: 304/164;
            width: 100%;
          }
        }

        .limited {
          background-color: #E5451F;
          height: 20px;
          width: fit-content;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          display: grid;
          gap: 5px;
          margin: 16px;
          padding: 3px 8px 2px;
          border-radius: 24px;
          position: absolute;
          align-items: baseline;
          grid-template-columns: 1fr auto;

          p {
            line-height: 100%;
            margin: 0;
            margin-top: 2px;
          }

          img {
            height: fit-content;
            align-self: center;
            display: block;
          }
        }

        .content {
          align-self: flex-end;
          background-color: #E5451F;
          padding: 16px 16px 12px;
          margin-top: -1px;
          border-radius: 0 0 9px 9px;

          p {
            margin: 0;
            margin-bottom: 12px;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: left;
          }

          img {
            filter: brightness(0) invert(1);
          }
        }

        &.loading {
          border-color: $skeletonStart;

          .limited,
          .content {
            color: transparent;
            animation: skeleton-loading 1.5s linear infinite alternate;

            * {
              visibility: hidden;
            }
          }

          .img-wrapper {
            background-color: white;
            border-radius: 9px 9px 0 0;

            img {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  .wrapper.second {
    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 48px);
      left: -24px;
      background-size: cover;
      background-image: url("./img/bg-mobile-second-step.jpg");
      aspect-ratio: 563 / 842;
      z-index: -1;

      @include XGA {
        background-image: url("./img/bg-desktop.jpg");
        width: calc(100% + 128px);
        min-height: 500px;
        left: -64px;
        aspect-ratio: 180 / 77;
      }
    }

    .card-button {
      text-decoration: none;

      &.disable {
        pointer-events: none;
      }
    }

    .card-wrapper {
      padding-top: 24vw;

      @include XGA {
        padding-top: 0;
      }

      .card {
        position: relative;

        &.soon {
          border-color: #C6C6C5;

          .content {
            background-color: #C6C6C5;
          }

          .limited {
            background-color: #C6C6C5;
          }
        }

        &.expired {
          background-color: white;
          border-color: rgba($color: #E5451F, $alpha: 0.5);

          &::before {
            content: "pula biletów wyczerpana";
            position: absolute;
            padding: 11px 24px 9px 24px;
            background-color: white;
            border-radius: 24px;
            color: black;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            left: 50%;
            transform: translateX(-50%);
            top: 72px;
            opacity: 1;
            z-index: 2;
            text-align: center;
          }

          &>* {
            opacity: 0.15;
          }
        }

        .content {
          h3 {
            margin: 0;
            margin-bottom: 4px;
            text-align: left;
          }

          img {
            margin-top: 10px;
          }

          div {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            gap: 11px;

            img {
              margin: 0;
              margin-top: -2px;
            }

            p {
              margin: 0;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .city-selector-desktop {
      display: none;

      @include XGA {
        display: block;

        h1 {
          font-weight: 900;
          font-size: 48px;
          line-height: 56px;
          letter-spacing: -0.02em;
          color: #FFFFFF;
          margin: 0;
          margin-bottom: 24px;
        }

        p {
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          color: #FFFFFF;
          margin-bottom: 20px;
        }
      }

      .flex-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        max-width: 550px;
        margin-right: auto;

        button {
          background-color: transparent;
          border: 1px solid white;
          border-radius: 24px;
          color: white;
          padding: 3px 8px 1px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          cursor: pointer;

          &:disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .fixed-button {
    position: relative;
    z-index: 5;

    button {
      border: none;
      max-width: 295px;
      width: 100%;
      position: fixed;
      bottom: 90px;
      left: 50%;
      transform: translateX(-50%);
      color: $lightorange;
    }

    @include XGA {
      display: none;
    }
  }

  .city-selector {
    position: fixed;
    top: 50px;
    left: 0;
    height: calc(100% - 50px);
    width: 100vw;
    background-color: #E5451F;
    z-index: 999;
    color: white;
    padding: 24px;
    padding-bottom: 55px;
    display: flex;
    flex-direction: column;
    left: 100vw;
    transition: all 400ms ease-out;

    button {
      border: none;
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    .upper {
      display: flex;
      justify-content: space-between;

      img {
        max-width: 100px;
      }

      button {
        margin-right: 10px;
        width: 14px;

        .exit {
          &::before {
            content: "\000D7";
            color: white;
            font-size: 30px;
            line-height: 30px;
          }
        }
      }
    }

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin-top: 33px;
      margin-bottom: 33px;
    }

    .cities-flex {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      max-height: 550px;
      margin-top: auto;

      button {
        font-weight: 900;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: white;
        cursor: pointer;

        &:disabled {
          cursor: default;
          opacity: 0.5;
        }
      }
    }

    &.show {
      left: 0;
    }

    @include XGA {
      display: none;
    }
  }
}