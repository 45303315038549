@import "../../../styles/vars";

.subscriptions-page {
  .disclaimer {
    font-size: 12px;
    text-align: center;
    color: #868686;
    margin-top: 8px;

    @include XGA {
      margin: 0 auto;
      margin-top: 16px;
    }
  }

  .top-bar {
    background: #fff;
    padding: 10px 20px 24px 20px;

    .title {
      font-size: 15px;
      text-align: center;
      margin: 0;
      margin-bottom: 16px;

      @include XGA {
        font-size: 32px;
        margin-top: 50px;
      }
    }

    .desc {
      text-align: center;
      font-size: 15px;
      margin: 0 auto;
      font-weight: 500;
      max-width: 600px;
      line-height: 20px;
    }
  }

  .feature-box {
    background: url("./img/feature-bg.webp");
    background-position: center center;
    background-size: cover;
    min-height: 850px;

    @include XGA {
      min-height: 550px;
      background: url("./img/feature-bg-big.webp");
      background-position: center center;
      background-size: cover;
    }

    @include FHD {
      background: url("./img/feature-bg-big.webp");
      background-position: top center;
    }

    .box-container {
      margin: 0 24px;
      padding-top: 53px;

      @include XGA {
        display: flex;
        max-width: 1310px;
        margin: 0 auto;
      }

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 300px;
        margin: 0 auto;

        .title {
          text-align: center;
          color: #fff;
          margin: 0;
          font-size: 15px;
          font-weight: 600;
        }

        .desc {
          text-align: center;
          color: #fff;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }

    .sub-box {
      border: 1px solid #fff;
      border-radius: 12px;
      margin: 33px 24px;
      padding: 32px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include XGA {
        max-width: 1310px;
        margin: 48px 24px;
      }

      @include HD {
        margin: 48px auto;
      }

      .small {
        font-size: 12px;
        text-align: center;
        max-width: 280px;

        @include XGA {
          max-width: 376px;
        }

      }

      .points {
        border-radius: 24px;
        background-color: #e5451f;
        color: #fff;
        padding: 2px 7px;
      }

      .title {
        color: #fff;
        margin-top: 0;

        @include XGA {
          font-size: 24px;
        }
      }

      .desc {
        color: #fff;
      }

      .link {
        background-color: #ed8002;
        color: #fff;
        border-radius: 24px;
        font-weight: 900;
        min-width: 200px;
        height: 40px;
        font-size: 14px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        @include XGA {
          margin-top: 10px;
          min-width: 130px;
        }
      }
    }
  }

  .options {
    margin-top: -120px;

    @include XGA {
      margin-top: -100px;
    }

    &.desktop {
      display: flex;
      justify-content: space-evenly;
      max-width: 1310px;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;

      @include HD {
        justify-content: space-between;
      }

      button.option-box-button {
        margin-top: 50px;
        appearance: none;
        border: none;
        display: block;
        padding: 0;
        background-color: transparent;
        border-radius: 12px;
        overflow: hidden;
        min-width: 100%;
        cursor: pointer;

        @include XGA {
          min-width: 45%;
        }

        @include HD {
          max-width: 300px;
          min-width: 300px;
        }
      }

      .option-box {
        margin: 0;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 0;
        min-width: 100%;

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(70.55deg, rgba(196, 38, 0, 0.12) 26.61%, rgba(255, 180, 13, 0.12) 98.64%);
        }

        &.active {
          background: #E5451F;

          .bg {
            background: #E5451F;
          }
        }

        .cta {
          margin-top: auto;
        }
      }

      .cta {
        background-color: #ed8002;
        color: #fff;

        &.active {
          background: #fff;
          color: #ed8002;
        }
      }
    }

    .nav {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 327px;
      column-gap: 16px;
      row-gap: 16px;
      margin: 0 auto;
      margin-bottom: 15px;

      .nav-btn {
        position: relative;
        font-size: 12px;
        background: transparent;
        border: none;
        color: #fff;
        flex-shrink: 0;
        text-align: center;
        margin: 0;
        padding: 0;
        border: 1px solid #fff;
        border-radius: 50px;

        padding: 4px;
        width: 90px;

        &.active {
          background-color: #ed8002;
          color: #fff;
          border: 1px solid #ed8002;
        }
      }
    }
  }

  .option-box {
    position: relative;
    background: #fff;
    width: 100%;
    max-width: 327px;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);

    .cta {
      width: 100%;
      display: block;
      border-radius: 50px;
      background: #fff;
      min-height: 40px;
      color: #ed8002;
      font-weight: 900;
      border: none;
      margin-top: 24px;
    }

    .logo {
      position: absolute;
      top: 24px;
      right: 24px;

      svg {
        fill: #c6c6c5;
      }
    }

    &.active {
      background-color: #E5451F;

      .bg {
        background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
      }

      .logo {
        svg {
          path {
            fill: #fff;
          }
        }
      }

      .option-top {
        position: relative;
        z-index: 2;
      }

      .title,
      .desc,
      .price {
        color: #fff;
      }

      .desc {
        background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
      }

      .desc-left {
        color: #fff;
      }

      .desc-details {
        color: #fff;
      }

      .value {
        color: #fff;
      }
    }

    .title {
      font-size: 30px;
      color: #000;
      font-weight: 900;
    }

    .price {
      color: #868686;
      font-size: 12px;
      line-height: 16px;
    }

    .value {
      color: #000;
    }

    .desc {
      position: relative;
      z-index: 2;
      font-size: 12px;
      color: #fff;
      padding: 20px 24px;
      background-color: #fff;
      border-radius: 12px;
    }

    .option-top {
      padding: 20px 24px;
    }

    .desc-container {
      display: flex;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .desc-left {
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      text-align: right;
      display: block;
      color: #000;
      font-weight: 700;
    }

    .desc-details {
      margin-left: 6px;
      font-weight: 300;
      color: #2A2C2C;
      text-align: left;
    }
  }

  .subscription-testimonials {
    h3 {
      margin: 40px 0 16px;
      text-align: center;
      padding-inline: 24px;

      @include HD {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin: 32px 0 24px;
      }
    }

    .testimonials-swiper-wrapper {

      @include HD {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        margin-inline: 36px;
      }

      @include QHD {
        max-width: 1310px;
        margin-inline: auto;
      }

      button.swiper-navigation {
        display: none;
        appearance: none;
        border: none;
        background-color: transparent;
        padding: 5px;
        cursor: pointer;
        height: fit-content;

        @include HD {
          display: block;
        }

        &.right {
          order: 3;
          transform: rotate(180deg);
        }

        &.swiper-button-disabled {
          path {
            fill: #C6C6C5;
          }
        }

        .inner {
          svg {
            display: block;
          }
        }
      }
    }

    .testimonials-swiper {
      @include XGA {
        max-width: 1088px;
      }

      @include HD {
        margin: 0;
      }

      .swiper-slide {
        height: auto;
        border: 1px solid #E5E5E5;
        border-radius: 12px;
        overflow: hidden;
        display: grid;
        grid-template-rows: auto 1fr;

        @include HD {
          grid-template-rows: initial;
          grid-template-columns: 355px 1fr;
        }

        img {
          display: block;
          max-width: 100%;
        }

        .content {
          margin: 20px 0 24px;
          padding-inline: 16px;
          display: flex;
          flex-direction: column;

          @include HD {
            justify-content: center;
            padding-inline: 64px;
          }

          p.copy {
            margin: 0 0 16px;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #2A2C2C;
          }

          .bundle {
            margin-top: auto;
            display: flex;
            gap: 8px;

            @include HD {
              margin-top: 0;
            }

            svg {
              filter: brightness(0);
              display: block;
            }

            span {
              font-weight: 900;
              font-size: 16px;
              line-height: 20px;
              color: #000000;
            }
          }
        }

      }
    }
  }

  .subscription-form-info {
    margin: 40px 0 48px;

    @include HD {
      margin-top: 64px;
    }

    h3 {
      text-align: center;
      padding-inline: 24px;
      margin: 0 auto;
      max-width: 727px;
    }

    .button {
      display: block;
      margin: 16px auto;
      width: fit-content;
    }
  }

  .what-to-do {
    margin: 0 24px;

    @include XGA {
      margin: 0;
    }

    .content {
      @include XGA {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        max-width: 1310px;
        margin: 0 auto;
        margin-top: 80px;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          right: 50%;
          top: 0;
          background-color: #f7f7f7;
          height: 100%;
          width: calc(min(100vw, 1920px));
          z-index: -1;
          transform: translateX(30%);
        }
      }
    }

    .left {
      @include XGA {
        background: #f7f7f7;
        padding-left: 107px;
        display: flex;
        flex-direction: column;
        max-width: 736px;

        justify-content: center;
      }
    }

    .right {
      @include XGA {
        background: #f7f7f7;
      }
    }

    .title {
      font-size: 32px;
      font-weight: 700;
      margin: 32px 0 0;
      text-align: left;

      @include XGA {
        margin-top: 0;
      }
    }

    .desc {
      margin: 8px 0 0;
      text-align: left;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #2A2C2C;

      @include XGA {
        width: 280px;
      }
    }

    .option-selector {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      margin-bottom: 50px;

      @include XGA {
        margin-top: 24px;
        margin-bottom: 0;
        gap: 32px;
      }

      .button {
        padding: 3px 8px 1px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        @include XGA {
          padding: 12px 24px 9px;
          font-weight: 900;
          font-size: 14px;
          line-height: 20px;
        }

        &.white {
          background-color: transparent !important;

          &:hover {
            padding: 11px 23px 8px;
          }
        }

        .orange {
          &:hover {
            color: #fff;
          }
        }
      }
    }

    .bg {
      width: 100%;
      display: block;

      @include XGA {
        width: 400px;
      }

      @include HD {
        width: 528px;
      }
    }

    .footer {
      position: relative;
      max-width: 1310px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 55px;

      @include XGA {
        flex-direction: row;
        gap: 32px;
        //  margin-top: 60px;
        z-index: 5;
        background: #fff;
        padding-top: 60px;
        display: flex;
        justify-content: center;
        // padding-left: 107px;
      }

      &::before {
        content: "";
        display: block;
        background: url("./img/arrow.svg");
        height: 102px;
        width: 64px;
        background-size: cover;

        position: absolute;
        right: 5%;
        top: 15%;
        z-index: -1;

        @include XGA {
          display: none;
        }
      }

      &::after {
        content: "";
        display: block;
        background: url("./img/arrow.svg");
        height: 102px;
        width: 64px;
        background-size: cover;

        position: absolute;
        right: 5%;
        bottom: 22%;
        z-index: -1;

        @include XGA {
          display: none;
        }
      }

      .box {
        position: relative;
        // min-height: 150px;

        .disclaimer {
          font-size: 12px;
          color: #868686;
          padding-bottom: 24px;
          text-align: left;

          @include XGA {
            padding-bottom: 0;
            margin-left: 0;
          }
        }

        @include XGA {
          // min-height: 240px;
          display: flex;
          flex-direction: column;
        }

        @include HD {
          margin-right: 30px;
        }
      }

      .box::before {
        display: block;
        font-size: 184px;
        font-weight: 900;
        color: #f7f7f7;
        position: absolute;
        top: -47px;

        @include XGA {
          font-size: 200px;
        }
      }

      .box:nth-of-type(1) {
        margin-top: 25px;

        @include XGA {
          &::after {
            content: "";
            display: block;
            background: url("./img/arrow.svg");
            background-size: cover;

            height: 131px;
            width: 82px;
            background-size: cover;

            position: absolute;
            right: 50px;
            top: 5%;

            z-index: -1;
            transform: rotate(-90deg);
          }
        }
      }

      .box:nth-of-type(2) {
        @include XGA {
          &::after {
            content: "";
            display: block;
            background: url("./img/arrow.svg");
            background-size: cover;

            height: 131px;
            width: 82px;
            background-size: cover;

            position: absolute;
            right: 50px;
            top: 5%;

            z-index: -1;
            transform: rotate(-90deg);
          }
        }
      }

      .box:nth-of-type(1)::before {
        content: "1";
        z-index: -1;
      }

      .box:nth-of-type(2)::before {
        content: "2";
        z-index: -1;
      }

      .box:nth-of-type(3)::before {
        content: "3";
        z-index: -1;
      }

      .title {
        font-size: 18px;
        text-align: left;
        margin: 0;
        margin-bottom: 8px;

        @include XGA {
          margin-top: 0;
          margin-top: 50px;
        }
      }

      .desc {
        margin-top: 0;
        text-align: left;
        font-size: 15px;
        width: 300px;

        strong {
          font-weight: 900;
        }
      }

      @include XGA {
        display: flex;

        .box:nth-of-type(1) {
          margin-top: 0;
        }
      }

      .info {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        max-width: 300px;
        color: #2A2C2C;
        margin: 8px 0 0;
      }
    }
  }

  .packshots {
    background: url("./img/packshot-bg.png");
    background-size: cover;
    padding: 40px 0;
    min-height: 265px;

    @include XGA {
      background: url("./img/packshot-bg-big.png");
      background-size: cover;
      min-height: 328px;
      max-width: 1350px;
      margin: 0 auto;
    }

    .title {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 24px;
      color: #fff;
      text-align: center;
      line-height: 32px;

      @include XGA {
        text-align: left;
        padding-left: 107px;
        font-size: 32px;
      }
    }

    .container {
      margin: 0 24px;
    }

    .nav {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: center;
      min-height: 60px;
      column-gap: 24px;

      @include XGA {
        padding-left: 107px;
        justify-content: flex-start;
      }
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      @include XGA {
        width: max-content;
      }
    }

    .btn {
      background: transparent;
      color: #fff;
      font-size: 12px;
      width: max-content;
      padding: 2px 6px;
      border: 1px solid #fff;
      border-radius: 50px;
      cursor: pointer;
      margin-bottom: 12px;

      @include XGA {
        font-size: 14px;
        font-weight: 900;
        margin-bottom: 16px;
        margin-right: 20px;
        padding: 2px 10px;
        font-weight: 400;
        border: none;
      }

      &.active {
        border: 1px solid #ed8002;
        border-radius: 50px;
        color: #ed8002;
      }
    }
  }

  .packs {
    @include XGA {
      margin: 0 auto;
      margin-top: -150px;
      max-width: 1110px;
      padding: 0 50px;
    }

    .container {
      margin: 0 auto;

      @include XGA {
        margin: 0;
      }
    }

    .container-flex {
      display: flex;
      justify-content: space-between;
      max-width: 260px;
      text-align: center;

      @include XGA {
        max-width: min-content;
        justify-content: flex-start;
      }
    }

    .pack {
      @include XGA {
        margin-right: 100px;
      }
    }

    .photo {
      width: 65px;
      height: 105px;
      margin-top: -40px;

      @include XGA {
        margin-top: 0px;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      @include XGA {
        width: 120px;
        height: 200px;
      }
    }

    .desc {
      font-size: 12px;
      text-align: center;

      @include XGA {
        font-size: 18px;
        width: 120%;

        transform: translateX(-10%);
      }
    }
  }

  .bottom {
    position: relative;
    margin-top: 20px;

    @include HD {
      margin-top: 0;
    }
  }

  .footer {
    padding: 0 24px;
    position: relative;

    @include HD {
      max-width: 1310px;
      margin: 0 auto;
      padding: 50px;
    }

    .card {
      margin-top: 30px;

      @include HD {
        width: 440px;
        background: linear-gradient(90deg,
            rgba(229, 69, 31, 1) 0%,
            rgba(245, 170, 4, 1) 84%);
        border-radius: 12px;
        position: absolute;
        top: -323px;
        right: 65px;
        padding: 32px 32px 24px;

        display: flex;
        flex-wrap: wrap;
      }
    }

    .logo {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 16px;

      svg {
        width: 100%;
        width: 57px;

        @include HD {
          width: 101px;
        }
      }

      path {
        fill: #000;
      }

      img {
        width: 80px;
        height: 16px;
        display: block;

        @include HD {
          filter: brightness(0) invert(1);
        }
      }

      @include HD {
        order: 3;
        width: fit-content;
        margin-right: 0;
        margin-left: 25px;

        path {
          fill: #fff;
        }
      }
    }

    .copy {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      color: #000000;
      max-width: 327px;
      margin-inline: auto;
      margin-top: 20px;
      margin-bottom: 16px;

      @include HD {
        text-align: left;
        width: 100%;
        order: 1;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        max-width: initial;
        margin-top: 0;
        margin-bottom: 32px;
      }
    }

    .link {
      background-color: #ed8002;
      color: #fff;
      border-radius: 24px;
      font-weight: 900;
      width: 200px;
      height: 40px;
      font-size: 14px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin: 0 auto 36px;

      @include HD {
        width: 130px;
        order: 2;
        background: #fff;
        color: #ed8002;
        margin: 0;
      }
    }
  }
}

.subscription-form-page {
  position: relative;
  z-index: 1;
  background-color: white;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    height: 212px;
    background-image: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #070707 100%), url("./img/form-img.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px 12px 0px 0px;

    @include XGA {
      display: none;
    }
  }

  &.date {
    &::before {
      background-image: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #070707 100%), url("./img/date-img.jpg");
    }

    .button {
      display: block;
      margin-top: 72px;
      width: calc(min(100%, 327px));
      margin-inline: auto;
    }
  }

  &.finish {
    &::before {
      background-image: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #070707 100%), url("./img/finish-img.jpg");
    }

    .content {
      &>* {
        max-width: 640px;
        margin-inline: auto !important;
      }

      &>img {
        display: block;
        margin-inline: auto;
      }

      h2 {
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.02em;
        color: #101828;
        text-align: center;
        margin-top: 16px;

        @include XGA {
          margin-bottom: 0;
        }
      }

      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        text-align: center;
        margin: 40px 0 16px;

        &.remind {
          @include XGA {
            border-top: 1px solid #C6C6C5;
            padding-top: 32px;
            margin-top: 24px;
          }
        }
      }

      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #2A2C2C;
        margin: 0;

        &.info {
          margin-top: 16px;

          @include XGA {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            margin-top: 8px;
          }
        }

        &:last-of-type {
          max-width: 640px;
          margin-inline: auto;
        }

        strong {
          font-weight: 900;
        }

      }

      .next {
        padding: 40px 0 24px;
        background-color: #F7F7F7;
        margin-top: 40px;

        @include XGA {
          padding: 24px 0 0;
          margin-top: 32px;
          border-top: 1px solid #C6C6C5;
        }

        .flex-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 8px;

          @include HD {
            flex-direction: row;
            justify-content: center;
            margin-top: 24px;
          }
        }

        p,
        h4 {
          margin: 0 auto;
          max-width: 327px;
        }

        h4 {
          color: #2A2C2C;
        }

        img {
          max-height: 52px;

          @include HD {
            transform: rotate(270deg);
          }
        }
      }

      .button {
        display: block;
        margin-top: 40px;
        width: calc(min(100%, 327px));
        margin-inline: auto;

        @include XGA {
          width: fit-content;
        }
      }
    }
  }

  .header {
    display: grid;
    grid-template-columns: 14px 1fr 14px;
    align-items: center;

    button {
      width: 100% !important;
      height: 100% !important;

      svg {
        display: block;
        height: 12px !important;

        @include XGA {
          height: 24px !important;
        }
      }
    }

    h2 {
      text-align: center;
      color: white;

      @include XGA {
        color: black;
        margin-top: 0;
        text-align: left;
      }
    }
  }

  .desktop-layout {
    @include XGA {
      display: grid;
      grid-template-columns: 304px 1fr;
      gap: 64px;
    }

    @include QHD {
      gap: 144px;
    }

    @include UXGA {
      grid-template-columns: 420px 1fr;
      gap: 180px;
    }
  }

  .side {
    margin-bottom: 72px;

    @include XGA {
      margin-top: 50px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include QHD {
      margin-bottom: 56px;
    }

    img {
      display: none;
      width: 100%;
      aspect-ratio: 304/203;

      @include XGA {
        display: block;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      max-width: 328px;
      margin: 24px auto 0;

      @include XGA {
        flex-direction: column;
        gap: 32px;
        margin-left: 0;
      }

      li {
        @include XGA {
          display: flex;
          gap: 16px;
        }

        .text {
          &:not(.active) {
            * {
              @include XGA {
                color: #868686;
              }
            }
          }

          &>* {
            // margin: 0;
            max-width: 155px;

            @include XGA {
              max-width: 256px;
            }
          }
        }

        &:first-of-type {
          .number::before {
            content: "";
            display: block;
            position: absolute;
            width: 143px;
            height: 3px;
            top: 50%;
            right: 0;
            transform: translate(100%, -50%);
            background-image: linear-gradient(90deg, #F5AA04 0%, #E5451F 100%);

            @include XGA {
              right: initial;
              width: 65px;
              left: -17px;
              top: 63px;
              transform: translate(0, 0) rotate(90deg);
            }
          }
        }

        h4 {
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #FFFFFF;
          margin: 8px 0 0;

          @include XGA {
            color: #020246;
            margin-top: 0;
            text-align: left;
          }
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #FFFFFF;
          margin: 4px 0 0;

          @include XGA {
            color: #2A2C2C;
            text-align: left;
          }
        }

        .number {
          width: 32px;
          height: 32px;
          background-image: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
          border-radius: 50%;
          display: grid;
          place-items: center;
          margin-inline: auto;
          position: relative;

          @include XGA {
            margin: 0;
          }

          .inner {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: grid;
            place-items: center;

            span {
              margin-top: 2px;
              color: #FFFFFF;
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              background-clip: initial;
              -webkit-background-clip: initial;
              -webkit-text-fill-color: initial;
              text-fill-color: initial;
            }

            &:not(.active) {
              background-color: white;

              span {
                background-image: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .content {
    @include XGA {
      background-color: #F7F7F7;
      padding: 32px 36px 24px;
      overflow: hidden;

      @include HD {
        padding-inline: 64px;
      }

      @include QHD {
        padding: 64px 92px 56px;
      }

      @include FHD {
        padding-inline: 200px;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
      margin: 0 0 16px;

      @include XGA {
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.02em;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      margin: 40px 0 24px;
    }

    .title-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 72px 0 16px;

      @include XGA {
        margin: 0 0 32px;
      }

      h2,
      h3 {
        margin: 0;
      }

      img {
        display: block;
        height: fit-content;

        @include HD {
          display: none;
        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include XGA {
      max-width: 416px;
    }

    .input-flex {
      display: flex;
      gap: 16px;

      &>* {
        flex-basis: 100%;
      }
    }

    label {
      position: relative;
      height: fit-content;

      p {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        margin: 0;

        &.error {
          color: red;
          margin-top: 2px;
        }
      }

      input {
        display: block;
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid #C6C6C5;
        padding: 2px 0px 4px;
        color: black;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        outline: none;
        transition: all 0.2s $CubicEaseInOut;
        font-family: "Mont";

        &:focus {
          border-color: $lightorange;
        }
      }
      
      select, option {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        background-color: transparent;
        border-radius: 4px;
      }

      .input-wrapper {
        display: grid;
        grid-template-columns: 42px 1fr;
        column-gap: 36px;
        position: relative;

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 12px;
          height: 2px;
          background-color: #000;
          top: 50%;
          transform: translateY(-50%);
          left: 54px;
        }
      }

      button {
        display: block;
        padding: 5px;
        appearance: none;
        background-color: transparent;
        border: none;
        height: fit-content;
        width: fit-content;

        &.popup-open-btn {
          position: absolute;
          z-index: 2;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        svg {
          display: block;
        }

      }
    }

    .popup-window {
      min-width: initial;
      max-width: 500px;

      @include XGA {
        max-width: 420px;
      }

      button {
        appearance: none;
        background-color: transparent;
        border: none;
        transform: scale(1.3);
      }

      .popup-wrapper {
        p.popup-p {
          margin-top: 16px !important;
          font-size: 16px !important;
          line-height: 120%;
          
          @include XGA {
            line-height: 145% !important;
          }

          strong {
            font-weight: 900;
          }
        }
      }
    }

    .button {
      margin-top: 16px;
      width: calc(min(100%, 327px));
      margin-inline: auto;

      @include XGA {
        max-width: 218px;
        margin-left: 0;
      }
    }
  }

  .subscription-slot-swiper {
    overflow: visible;

    @include XGA {
      overflow: hidden;
    }

    &+.title-flex {
      margin: 40px 0 24px;

      @include XGA {
        margin-top: 48px;
      }
    }

    .swiper-slide {
      width: 80px;
      height: 72px;
      background-color: white;
      border-radius: 12px;

      @include XGA {
        background-color: transparent;
      }

      button {
        padding: 0;
        background-color: transparent;
        border: 1px solid #2A2C2C;
        border-radius: 12px;
        cursor: pointer;
        width: 80px;
        height: 72px;
        overflow: hidden;

        &:disabled {
          border-color: #C6C6C5;

          .inner {

            h4,
            p {
              color: #C6C6C5;
            }
          }
        }

        &.selected {
          border-color: #ED8002;
          border-width: 2px;

          .inner {

            h4,
            p {
              color: #ED8002;
            }
          }
        }

        .inner {
          display: grid;
          place-items: center;

          &>* {
            margin: 0;
          }

          h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #000000;
          }

          p {
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            color: #2A2C2C;
          }
        }
      }
    }

    &.slot-hour {
      .swiper-slide {
        width: 155px;

        button {
          width: 155px;
        }
      }
    }
  }
}