@import "../../../styles/vars";

.bar-switcher {
  margin-top: 50px;

  @include XGA {
    margin-top: 36px;
  }

  .swiper-bar {
    .swiper-slide:empty {
      display: none;
    }

    .swiper-pagination-progressbar-fill {
      background-color: #F5AA04;

    }
  }
}