@import "../../../styles/vars";

.migration-page {
  margin: 0;
  width: 100%;
  padding-bottom: 0;
  @include XGA {
    margin-top: 16px;
  }
  .mobile {
    .hero {
      text-align: center;
      background-image: url("./img/mobile-bg.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 12px 12px 0 0;
      margin-top: -12px;
      z-index: 10;
      position: relative;
      color: white;
      div {
        max-width: 281px;
        margin: 0 auto;
      }
      .title {
        padding-top: 52px;
      }
      .logos {
        margin-top: 20px;
      }
      .content {
        margin-top: 24px;
        padding: 36px 0;
      }
      .logos {
        .image {
          width: 100%;
        }
      }
    }
    .points-transport {
        margin-bottom: 12px;
      text-align: center;
      div {
        max-width: 300px;
        margin: 0 auto;
      }
      .transfer {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 22px;
        .content {
          p {
            margin: 0;
            font-weight: 700;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;

          .points {
            color: $lightorange;
            border-radius: 50px;
            border: 1px solid $lightorange;
            width: fit-content;
            padding: 3px 8px 1px 8px;
            font-size: 12px;
          }
        }
      }
      .asterix {
        max-width: 327px;
        margin: 0 auto;
        font-size: 12px;
        margin-top: 24px;
      }
      .disclaimer {
        margin-top: 12px;
        background-image: url("./img/warning-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
      }
    }
    .cta-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-image: url("./img/cta-mobile-bg.jpg");
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      min-height: 250px;
      .cta-header {
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 24px; /* 133.333% */
        max-width: 294px;
        text-align: center;
        color: white;
      }
    }
  }
  .desktop {
    background-image: url("./img/desktop-bg.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    padding: 64px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(204, 204, 204, 0.8);
      padding-bottom: 24px;
      h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 900;
        margin: 0 0 8px 0;
      }
      .imgs-holder {
        display: flex;
        align-items: center;
        gap: 20px;
        @include QHD {
          gap: 32px;
        }
        .arrow {
          margin-right: 12px;
          margin-left: -12px;
        }
      }
    }
    .main {
      margin-top: 68px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .content-holder {
        font-size: 15px;
        p {
          margin: 8px 0;
        }
        .dbstrong {
          font-weight: 900;
          margin-bottom: 16px;
          font-size: 18px;
          display: block;
        }
        max-width: 352px;
        text-align: center;
      }
      .check-bg {
        background-image: url("./img/check-bg.svg");
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
      }
      .transfer-holder {
        max-width: 302px;
        .transfer {
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 22px;
          .content {
            p {
              margin: 0;
              font-weight: 700;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            .points {
              color: $lightorange;
              border-radius: 50px;
              border: 1px solid $lightorange;
              width: fit-content;
              padding: 3px 8px 1px 8px;
              font-size: 12px;
            }
            .points-title {
              line-height: 24px;
              font-size: 18px;
            }
          }
        }
        .asterix {
          font-size: 12px;
          font-weight: 100;
          line-height: 16px;
          margin-top: 24px;
          a {
            color: white;
            font-weight: 700;
          }
        }
      }
      .disclamer {
        background-image: url("./img/warning-bg.svg");
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 160px;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .disclamer-header {
          line-height: 24px;
          font-size: 18px;
        }
      }
    }
    .cta-section {
      padding-top: 54px;
      border-top: 1px solid rgba(204, 204, 204, 0.8);
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      .cta-header {
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
        margin: 0;
      }
    }
  }
}
