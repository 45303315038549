@import "../../../styles/vars";

header {
    position: relative;
    margin-top: 50px;
    background: linear-gradient(to right, $darkorange 0%, $yellow 100%);
    padding-top: 11px;
    height: 76px;

    @include XGA {
        height: 80px;
        padding-top: 16px;
        background: transparent;
    }

    &.double-bar-open {
        margin-top: 50px;

        @include XGA {
            margin-top: 70px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 12px;
        width: 100%;
        background-color: #fff;
        border-radius: 12px 12px 0 0;
        
        @include XGA {
            display: none;
        }
    }
    
    .header-container {
        margin: 0 auto;
        width: calc( 100% - 48px );
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        @include XGA {
            width: calc( 100% - 128px );
        }
    }

    .header-left {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        @include XGA {
            justify-content: flex-start;
            margin-top: 8px;
        }
    }

    @include XGA {
        margin-top: 36px;
    }

    &.new-variants {
        background: #DA3812;
        color: #fff;

        @include XGA {
            background-color: transparent;
        }

        &::after {
            background-color: #DA3812;
        }
        
        .az-logo {
            path, g {
                fill: #fff;
            }
        }
    }
}