@import "../../../styles/vars";

.news-page {
    
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    
    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    margin-left: auto;
    margin-right: auto;


    .top-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 24px;

        @include XGA {
            margin-top: 40px;
            justify-content: left;
            column-gap: 24px;
            margin-bottom: 36px;
        }

        .header {
            @include XGA {
                font-size: 32px;
                margin: 0;
                margin-right: 40px;
                line-height: 40px;
            }
        }

        p, a {
            opacity: 0.4;
            font-size: 12px;
            padding: 4px 4px 2px;
            border: 1px solid transparent;
            cursor: pointer;
            text-decoration: none;
            font-weight: 500;
            color: black;

            @include XGA {
                padding: 6px 16px;
                font-size: 14px;
            }
        }

        .active {
            color: $lightorange;
            padding: 4px 4px 2px;
            border: 1px solid $lightorange;
            border-radius: 24px;
            opacity: 1;
            font-weight: 600;

            @include XGA {
                padding: 6px 16px;
                font-weight: 800;
            }
        }
    }

    .news-list {
        @include XGA {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include HD {
            justify-content: flex-start;
            column-gap: 32px;
        }

        .news-item {
            position: relative;
            background-color: #fff;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            margin-bottom: 16px;

            @include XGA {
                min-width: calc( 50% - 16px );
                max-width: calc( 50% - 16px );
                width: calc( 50% - 16px );
                margin-bottom: 32px;
            }

            @include HD {
                min-width: calc( 25% - 24px );
                max-width: calc( 25% - 24px );
                width: calc( 25% - 24px );

                &:nth-child(1), &:nth-child(2) {
                    min-width: calc( 50% - 16px );
                    max-width: calc( 50% - 16px );
                    width: calc( 50% - 16px );
                    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);

                    .news-image {
                        height: 300px;
                        border-radius: 12px;
                    }

                    .news-content {
                        padding: 0;
                        padding-bottom: 16px;

                        h3 {
                            font-size: 24px;
                        }

                        .news-desc {
                            font-size: 15px;
                            color: #2A2C2C;
                        }
                    }

                    .news-data {
                        margin-left: 0;
                        margin-bottom: 24px;
                    }

                    .news-data, .news-content {
                        padding-left: 24px;
                        padding-right: 24px;
                    }
                }
            }

            a {
                position: relative;
                z-index: 1;
            }

            .like-outer {
                // position: absolute;
                // top: 124px;
                // left: 16px;
                // z-index: 5;
                position: initial;
                align-self: flex-end;
                margin: 0px 0px 14px 16px;

                @include HD {
                    // top: 20px;
                    // left: 20px;
                    align-self: flex-start;
                    margin: 24px 0 0 24px;
                }
            }

            .news-image {
                height: 170px;
                background-size: cover;
                background-position: center;
                border-radius: 12px 12px 0px 0px;
                display: grid;
                isolation: isolate;

                @include HD {
                    height: 160px;
                }

                & > * {
                    grid-row: 1/2;
                    grid-column: 1/2;
                }
            }

            .news-data {
                // position: absolute;
                // left: 16px;
                // top: 16px;
                // margin-bottom: 24px;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                height: fit-content;
                margin: 16px 0 0 16px;

                @include HD {
                    // position: relative;
                    // top: 0;
                    // left: 0;
                    margin-top: 24px;
                    margin-left: 24px;
                    margin-bottom: 0;
                }
        
                &>div:not(.like-outer) {
                    padding: 4px 8px;
                    padding-top: 6px;
                    color: #fff;
                    border-radius: 12px;
                    font-size: 12px;
                    font-weight: 500;
                    margin-right: 16px;
                    height: fit-content;
        
                    &.news-category {
                        background-color: $darkorange;
                    }
        
                    &.news-publication {
                        background-color: $yellow;
                    }
                }
            }

            .news-content {
                padding: 16px 24px;

                h3 {
                    font-size: 15px;
                    font-weight: 500;
                    margin: 0;
                    margin-bottom: 8px;
                }

                .news-desc {
                    margin: 0;
                    font-size: 12px;
                    color: #868686;
                }
            }

            a {
                text-decoration: none;
                color: #000;
            }

            .arrow {
                display: none;

                @include XGA {
                    display: block;
                    margin-top: 8px;
                }
            }

            &:hover {
                .arrow {
                    animation: arrow 1s $QuadEaseInOut infinite;
                }
            }
        }
    }
}