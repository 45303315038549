@import "../../../styles/_vars.scss";

.wrapper-novel {
    padding: 0;
}

.novel-modal {
    .novel-modal-top {
        background-size: cover;
        background-position: center;
        background-image: url(./img/novel-popup-top-mobile.jpg);
        height: 140px;

        @include vHD {
            background-image: url(./img/novel-popup-top.jpg);
            height: 112px;
        }

        button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 12px;
        }
    }

    .novel-content {
        padding: 32px 24px;

        @include XGA {
            padding: 32px 40px;
        }

        h2 {
            margin: 0;
            margin-bottom: 8px;
            font-size: 24px;
            line-height: 32px;
            font-weight: 700;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            margin: 0;
            margin-bottom: 24px;
        }
    }
}