@import "../../../styles/vars";

.join-vuse-page {
    position: relative;
    min-height: 600px;
    background-color: #000;

    .button {
        display: block;
        margin: 18px auto;
        width: 250px;
        border-radius: 4px;
        color: #fff;

        &.yellow {
            background-color: $yellow;
        }

        &.orange-gradient {
            background: linear-gradient(to right, $darkorange 0%, $yellow 100%)
        }
    }

    .join-page-inner {
        text-align: center;
        color: #fff;
        padding: 0;
        margin: 0;
        height: 100vh;
        display: flex;
        flex-flow: column;
        flex-wrap: nowrap;

        h3 {
            font-size: 20px;
            margin: 0;
            margin-bottom: 8px;
            font-weight: 800;

            @include XGA {
                font-size: 30px;
            }
        }

        p {
            font-size: 12px;
            line-height: 20px;
            margin: 0;

            @include XGA {
                font-size: 14px;
            }

            &.second {
                margin-top: 36px;

                @include XGA {
                    margin-top: 0;
                }
            }
        }

        .join-title {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin: 24px 0;

            h3 {
                text-align: left;
                margin-bottom: 0;
                font-weight: 400;
                margin-right: 16px;

                span {
                    display: block;
                    font-weight: 800;

                    @include HD {
                        display: inline-block;
                    }
                }
            }

            img {
                @include HD {
                    width: 110px;
                }
            }
        }

        .top-section, .bottom-section {
            min-height: 300px;
            background-size: cover;
            background-position: center;
            align-self: stretch;
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding: 16px;
        }

        .top-section {
            background-image: url(./img/vuse-join-top-mobile.jpg);
            background-position: bottom;
            height: 58%;
            min-height: 400px;
            
            @include XGA {
                background-image: url(./img/vuse-join-top-desktop.jpg);
            }

            @include HD {
                min-height: 340px;
            }

            &>p {
                max-width: 600px;
                margin: 0 auto;
            }

            .box {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;

                &>p {
                    margin-left: 18px;
                    text-align: left;
                }
            }
        }

        .bottom-section {
            background-image: url(./img/vuse-join-bottom-mobile.jpg);
            background-position: top;
            height: 42%;
            min-height: 260px;
            
            @include XGA {
                background-image: url(./img/vuse-join-bottom-desktop.jpg);
            }

            @include HD {
                background-position: center;
            }

            p {
                @include XGA {
                    max-width: 410px;
                    margin: 0 auto;
                }
            }
        }
    }
}

.vuse-join-modal {
    .button {
        display: block;
        margin: 18px auto;
        width: 250px;
        border-radius: 4px;
        color: #fff;

        &.yellow {
            background-color: $yellow;
        }
    }

    .check {
        margin-bottom: 12px;

        @include XGA {
            width: 32px;
            margin-top: -16px;
        }
    }
}