@import "../../../styles/vars";

.store-locator-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    position: relative;
    
    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    .map-outer {
        position: relative;
    }

    .preloader {
        position: absolute;
        top: calc( 50% - 16px );
        left: calc( 50% - 16px );
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;

        &.show {
            opacity: 1;
            visibility: visible;
        }

        span {
            animation: spin 1s $QuadEaseInOut infinite;
            display: block;
            width: 32px;
            height: 32px;
            border: $lightorange solid 4px;
            border-bottom: $darkorange solid 4px;
            border-radius: 50%;
        }
    }

    .map-cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.7);
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;
        border-radius: 12px;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    #map {
        position: relative;
        width: 100%;
        height: 400px;
        border-radius: 12px;
        margin-bottom: 32px;

        .leaflet-control-zoom {
            display: none;
        }

        .leaflet-popup-content {
            img {
              max-width: 100%;
              max-height: 40px;
            }
        }

        .cluster {
            background-color: #000;
            border-radius: 50%;
            text-align: left;
            color: white;
            font-weight: 400;
            box-shadow: rgba(0, 0, 0, 0.45) 3px 3px 10px;
        }
      
        .digits-1 {
            font-size: 13px;
            height: 26px;
            width: 26px;
            line-height: 1;
            margin-top: 0;
            margin-left: -10px;
            padding-top: 7px;
            padding-left: 9px;
        }
    
        .digits-2 {
            font-size: 13px;
            height: 30px;
            width: 30px;
            line-height: 1;
            margin-top: 0;
            margin-left: -12px;
            padding-top: 9px;
            padding-left: 8px;
        }
    
        .digits-3 {
            font-size: 12px;
            height: 30px;
            width: 30px;
            line-height: 1;
            margin-top: 0;
            margin-left: -15px;
            padding-top: 10px;
            padding-left: 5px;
        }
    
        .digits-4 {
            font-size: 12px;
            height: 40px;
            width: 40px;
            line-height: 1;
            margin-top: 0;
            margin-left: -20px;
            padding-top: 15px;
            padding-left: 6px;
        }
    }

    .search {
        position: absolute;
        top: 60px;
        left: 8px;
        background-color: #fff;
        width: calc( 100% - 16px );
        max-width: 330px;
        z-index: 100;
        padding: 16px;

        @include XGA {
            left: 40px;
            top: 110px;
        }

        .search-inner {
            display: none;

            &.show {
                display: block;
            }
        }

        .filters-top {
            position: relative;

            h4 {
                margin-bottom: 0;
            }

            .show {
                position: absolute;
                top: 0;
                right: 0;
                width: 14px;
                height: 14px;
                border-left: $lightorange solid 2px;
                border-bottom: $lightorange solid 2px;
                transform: rotate(-45deg);
            }

            &.open {
                .show {
                    top: 4px;
                    transform: rotate(135deg);
                }

                h4 {
                    margin-bottom: 12px;
                }
            }
        }

        h4 {
            margin: 0;
            margin-bottom: 8px;
            font-weight: 500;
        }

        .search-input {
            position: relative;

            input {
                display: block;
                width: 100%;
                border: none;
                border-bottom: #000 solid 1px;
            }

            .get-loc {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
            }
        }

        .map-nav {
            display: block;
            margin-top: 8px;

            .filters {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                margin-top: 12px;

                h4 {
                    font-weight: 700;
                    font-size: 12px;
                    width: 60px;
                    min-width: 60px;
                    margin: 0;
                }
            }

            ul {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                gap: 12px;

                li {
                    position: relative;
                    font-size: 12px;
                    color: #868686;
                    padding-left: 20px;
                    cursor: pointer;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 12px;
                        height: 12px;
                        border: #868686 solid 1px;
                    }

                    &.active {
                        color: $lightorange;

                        &::before {
                            border-color: $lightorange;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            left: 5px;
                            top: 2px;
                            width: 3px;
                            height: 6px;
                            border-bottom: $lightorange solid 1px;
                            border-right: $lightorange solid 1px;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .filters.radio {
                ul {
                    li {
                        &::before {
                            border-radius: 50%;
                        }

                        &.active {
                            &::after {
                                left: 4px;
                                top: 4px;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                border: none;
                                background-color: $lightorange;
                                transform: rotate(0);
                            }
                        }
                    }
                }
            }
        }
    }
}