@import "../../../styles/vars";

.cover-page {
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 50px);
  background-image: url("./img/bg.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px 40px 24px;
  color: #fff;

  @include XGA {
    padding: 70px 24px 70px 24px;
    height: calc(100vh - 230px);
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    height: 100%;

    h1 {
      font-size: 32px;
      font-weight: 900;
      line-height: 40px;
      text-transform: uppercase;
      width: 260px;
      margin: 2px;
      @include XGA {
        font-size: 48px;
        margin: 8px;
        width: 90%;
      }
    }
    h2 {
      margin: 2px;
      width: 260px;
      color: #ff782c;
      text-align: right;
      font-family: Mont;
      font-size: 32px;
      font-style: italic;
      font-weight: 900;
      line-height: 40px;
      text-transform: uppercase;
      @include XGA {
        font-size: 48px;
        margin: 8px;
        width: 90%;
      }
    }
    p {
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      span {
        font-weight: 400;
        opacity: 0.9;
      }
    }
  }
}
