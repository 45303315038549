@import "../../../../styles/vars";
@import "../../../../styles/animations";

.template {
  width: calc(100% - 48px);
  border-radius: 12px 12px 0px 0px;
  margin: 0 auto;
  white-space: pre-wrap;
  margin-bottom: 32px;
  white-space: pre-wrap;

  @include XGA {
    width: calc(100% - 128px);
    border-radius: 0px;
    margin-top: 24px;
  }

  h2 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;

    @include XGA {
      margin-top: 40px;
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
    }
  }

  .header {
    display: grid;
    grid-template-columns: 5% 90% 5%;
    margin-top: 12px;

    @include XGA {
      display: flex;
      gap: 32px;
      align-items: center;
    }

    .title {
      text-align: center;

      @include XGA {
        margin: 0;
        font-weight: 600;
      }
    }

    button {
      background: none;
      cursor: pointer;
      outline: none;
      border: none;
      text-align: left;
      height: fit-content;
      width: 50%;
      padding: 0;

      @include XGA {
        width: fit-content;
      }
    }

    .reward {
      @include XGA {
        display: inline-flex;
        align-items: center;
        margin-top: 0;
        gap: 16px;
        margin-left: auto;

        .points {
          margin: 0;
        }
      }
    }
  }

  .progress {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-weight: 700;
      font-size: 15px;
      border: 1px solid $lightorange;
      color: $lightorange;
    }

    .active {
      background: linear-gradient(
        53.04deg,
        #ee4823 37.12%,
        #ee4f22 41.3%,
        #f06022 47.18%,
        #f37820 54.09%,
        #f7951d 61.71%,
        #faa61a 65.4%,
        #faa61a 65.47%
      );
      color: white;
      border: none;
    }
  }

  .card {
    margin-top: 16px;
    border-radius: 12px;
    z-index: 10;
    background: white;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
    padding-bottom: 24px;

    @include XGA {
      margin-top: 40px;
      padding: 0;
      box-shadow: none;
      position: relative;
      min-height: 564px;
    }

    &::after {
      content: "";
      width: 150%;
      height: 100%;
      bottom: -48%;
      left: -12.5%;
      position: absolute;
      z-index: -10;
      background: #f7f7f7;

      @include XGA {
        display: none;
      }
    }

    .image {
      width: 100%;
      border-radius: 12px 12px 0 0;
      z-index: -1;

      @include XGA {
        border-radius: 12px;
        min-height: 500px;
        object-fit: cover;
        aspect-ratio: 16/7;
      }

      @include FHD {
        aspect-ratio: 16/6;
      }
    }

    .content {
      @include XGA {
        position: absolute;
        padding: 3vh 4vw;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 12px;
        min-width: 400px;
      }

      @include HD {
        padding: 3vh 5vw;
      }

      @include UXGA {
        padding: 3.5vh 5vw;
      }
    }

    .title,
    h3 {
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      // margin-top: 24px;
      font-weight: 600;
      width: 255px;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        font-size: 24px;
        line-height: 32px;
        margin-top: 0;
        width: 495px;
      }
    }

    .subtitle,
    p {
      font-size: 15px;
      line-height: 20px;
      color: #868686;
      text-align: center;
      margin-top: 16px;
      font-weight: 500;
      width: 255px;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        font-size: 15px;
        line-height: 20px;
        width: 495px;
      }

      @include UXGA {
        min-width: 500px;
      }
    }
  }

  .statute {
    font-size: 12px;
    line-height: 12px;
    margin: 0 auto;
    margin-top: 24px;
    width: fit-content;
    font-weight: 900;

    a {
      color: $lightorange;
    }
  }

  .button-wrapper {
    margin-top: 24px;
    justify-content: center;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    width: min-content;

    @include XGA {
      margin-top: 42px;
    }
  }

  .points-holder {
    position: relative;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 12px;

    .points {
      width: 90px;
      text-align: center;
      margin: 0 8px;
      margin-top: 8px;

      &.yellow {
        background-color: $yellow;
        animation: pulse-yellow 2s infinite !important;
      }
    }

    &.animate {
      position: relative;

      .points {
        animation: pulse-red 2s infinite !important;

        &.yellow {
          animation: pulse-yellow 2s infinite !important;
        }
      }

      .points.bonus {
        animation: pulse-red 2s 1, bonus-left 1.5s 1 forwards,
          scalebang 0.4s 1 forwards 1s, pulse-red-big 2s infinite 3s !important;

        &.yellow {
          animation: pulse-yellow 2s 1, bonus-right 1.5s 1 forwards !important;
        }
      }

      .pyro {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        height: 400px;
        z-index: 9999;

        .before {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: 50%;
          margin-top: 50%;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          box-shadow: $box-shadow2;
          @include animation(
            (
              1.5s bang ease-out 1 backwards,
              1.5s gravity ease-in 1 backwards,
              5s position linear 1 backwards
            )
          );
          animation-delay: 1s;
        }
      }
    }
  }

  .monthly-bonus-intro {
    p {
      color: #000000;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      width: auto;
      margin-inline: 40px;
    }
  }
  .monthly-bonus-how-to {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    img {
      width: 22px;
      margin-bottom: 10px;
    }
  }
  .monthly-bonus-subtitle {
    @include XGA {
      background: #f7f7f7;
      margin-left: -4vw;
      margin-right: -4vw;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @include HD {
      margin-left: -5vw;
      margin-right: -5vw;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @include UXGA {
      margin-left: -5vw;
      margin-right: -5vw;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .monthly-bonus-popup-content {
    p {
      margin-top: 5px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #2a2c2c;
      margin-inline: 40px;
      width: auto;
      @include XGA {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #2a2c2c;
      }
    }
  }
  .monthly-bonus-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    @include XGA {
      margin-top: 20px;
    }
  }
  .monthly-bonus-info {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #868686;
    max-width: 266px;
    margin: 0 auto 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      font-weight: 900;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      text-decoration-line: underline;
      color: #ed8002;
    }
  }

  .points {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background: $darkorange;
    border-radius: 12px;
    padding: 4px 8px 2px;
    width: fit-content;
    margin: 0 auto;
    margin-top: 8px;
  }

  .reward {
    color: #868686;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin-top: 40px;
  }

  button.how-to {
    background: none;
    cursor: pointer;
    outline: none;
    border: none;
    color: $lightorange;
    font-size: 12px;
    line-height: 12px;
    margin: 0 auto;
    margin-top: 17px;
    width: 100%;
    text-decoration: underline;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-top: -3px;
    }
  }

  .popup {
    background-color: $yellow;
    border-radius: 12px;
    width: 95%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    position: absolute;
    z-index: 10;

    @include XGA {
      margin-top: 24px;
      min-height: 64px;
      width: 80%;
    }

    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      background: none;
      cursor: pointer;
      outline: none;
      border: none;
    }

    &-content > p {
      padding: 16px 40px;
      color: white;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      width: 100%;

      @include XGA {
        font-size: 15px;
        line-height: 20px;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .game-wrapper {
    padding-top: 40px;

    .progress {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 4px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        font-weight: 700;
        font-size: 15px;
        border: 1px solid $lightorange;
        color: $lightorange;
      }

      .active {
        background: linear-gradient(
          53.04deg,
          #ee4823 37.12%,
          #ee4f22 41.3%,
          #f06022 47.18%,
          #f37820 54.09%,
          #f7951d 61.71%,
          #faa61a 65.4%,
          #faa61a 65.47%
        );
        color: white;
        border: none;
      }
    }
  }

  &.result {
    .card {
      padding-top: 32px;
      padding-bottom: 32px;

      @include XGA {
        padding-top: 0;
        padding-bottom: 0px;
      }

      .background-image {
        @include XGA {
          width: 100%;
          border-radius: 12px;
          position: absolute;
          height: 100%;
          object-fit: cover;
        }
      }

      .points {
        margin-bottom: 24px;

        &.pulse-animation {
          animation: pulse-red 2s infinite;
        }
      }

      .image {
        width: 50vw;
        border-radius: 12px;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;
      }

      .subtitle {
        width: 295px;
        &:has(ul) {
          p {
            text-align: left;
            padding: 0;
            @include XGA {
              text-align: center;
            }
          }
        }
        ul {
          padding-left: 20px;
          margin: 20px 16px;
          line-height: 1.6;
          @include XGA {
            width: fit-content;
            margin-inline: auto;
          }
          li {
            list-style: initial !important;
            text-align: left;
          }
        }
        a {
          color: $lightorange;
          font-weight: 600;
        }

        @include XGA {
          width: initial;
        }
      }

      .content {
        .subtitle {
          @include XGA {
            margin-bottom: 0px;
          }
        }
      }

      div {
        .subtitle {
          margin-bottom: 24px;
          margin-top: 0;
        }
      }

      .text {
        .subtitle {
          @include XGA {
            margin-bottom: 24px;
          }
        }
      }

      .button-wrapper {
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        .button {
          text-align: center;
        }

        @include XGA {
          margin-bottom: 0;
        }
      }

      .title {
        @include XGA {
          margin-bottom: 16px;
        }
      }
    }

    &.wrong {
      .title {
        margin-bottom: 8px;
      }
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      animation: op 1s $QuadEaseInOut infinite;
      margin-top: 20px;

      @include XGA {
        margin-top: 0;
      }

      svg {
        display: block;
        transform: scale(2);
      }
    }
  }

  &.contest {
    .center {
      margin-inline: auto;
      display: block;
      margin-top: 32px;

      @include XGA {
        margin-top: 48px;
      }
    }

    .inputs-desktop {
      @include XGA {
        width: 90%;
        padding: 5.5vh 0 !important;

        .inputs {
          width: 70%;
          column-gap: 32px;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }

      @include HD {
        width: 78%;
      }

      @include UXGA {
        width: 70%;
      }
    }

    .long {
      @include XGA {
        min-height: 600px;

        & > img {
          min-height: 600px !important;
        }
      }
    }

    .packs-desktop {
      @include XGA {
        width: 90%;
        padding: 2.5vh 0 !important;

        .packs {
          width: calc(max(90%, 245px));
        }
      }

      @include HD {
        width: 78%;
      }

      @include UXGA {
        width: 70%;
      }

      @include FHD {
        padding: 5.5vh 0 !important;
      }
    }

    .side-by-side {
      img {
        display: none;
      }

      @include XGA {
        display: grid;
        grid-template-columns: calc(min(50%, 800px)) 1fr;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);

        img {
          display: block;
          max-height: 600px;
          border-radius: 12px 0 0 12px;
          // height: calc(min(600px, 40%));
        }

        .content {
          position: relative;
          height: fit-content;
          padding: 0 !important;
        }
      }
    }

    .card {
      padding-bottom: 16px;

      @include XGA {
        padding-bottom: 0;
      }

      & > img {
        display: none;

        @include XGA {
          width: 100%;
          min-height: 500px;
          display: block;
          object-fit: cover;
          border-radius: 12px;
        }
      }

      // &:last-of-type {
      //     margin-top: 24px;
      // }
    }

    .inputs {
      margin-top: 0;

      input {
        height: 24px;
      }
    }

    .input-wrapper {
      margin-top: 24px;

      label {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: black;
        text-decoration: none;
        opacity: 1;
      }
    }

    .question {
      max-width: initial;
      width: calc(max(70%, 280px));

      @include XGA {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      span {
        color: $orange;
      }

      &-sub {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        max-width: 280px;
        margin-inline: auto;
        margin-top: 8px;
        max-width: none;
        width: calc(max(70%, 280px));

        @include XGA {
          width: calc(max(600px, 80%));
        }
      }
    }

    .packs {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(114px, 1fr));
      column-gap: 16px;
      row-gap: 16px;
      margin-inline: auto;
      width: calc(max(70%, 245px));

      @include XGA {
        grid-template-columns: repeat(6, minmax(100px, 1fr));
      }

      @include HD {
        // grid-template-columns: repeat(6, minmax(114px, 1fr));
      }

      button {
        border: 1px solid transparent;
        // width: 113px;
        // height: 113px;
        aspect-ratio: 1/1;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        align-self: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 12px;
          display: block;
        }

        &.selected {
          border: 1px solid $lightorange;
          border-radius: 12px;
        }
      }
    }

    button[disabled] {
      border-color: #c6c6c5;

      svg {
        path {
          stroke: #c6c6c5;
        }
      }
    }

    .more-info {
      font-size: 10px;
      opacity: 0.6;
    }

    .error {
      font-size: 11px;
      color: #f44;
    }

    .game-wrapper {
      margin-bottom: 32px;
    }

    h3 {
      text-align: center;
      padding: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 24px;
      padding-top: 32px;
      margin-top: 0;
      padding-inline: 24px;
      width: calc(min(95%, calc(732px)));

      @include XGA {
        padding-top: 0;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .content {
      @include HD {
        padding: 6.5vh 4vw;
      }

      @include UXGA {
        padding: 6.5vh 5vw;
      }
    }

    .reward-wrapper {
      margin-bottom: 0px;

      @include XGA {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        margin-top: 40px;
      }

      @include HD {
        gap: 24px;
      }

      .element {
        border: 1px solid #f7f7f7;
        border-radius: 12px;
        display: grid;
        grid-template-columns: 120px auto;
        margin: 0 16px;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include XGA {
          width: 250px;
          margin-left: 0;
          margin-right: 0;
          margin: 0;
        }

        @include HD {
          width: 300px;
        }

        .reward-img {
          aspect-ratio: 1/1;
          border-radius: 12px 0 0 12px;
          width: 120px;
          height: 120px;
          height: 100%;
          object-fit: cover;
        }

        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px 4px 10px 22px;
          width: initial !important;

          .sub {
            opacity: 1;
            color: #000000;
            margin: 0;
            font-size: 12px;
          }

          .place {
            font-size: 12px;
            line-height: 16px;
            color: #868686;
            font-weight: 600;
            width: initial !important;
          }

          .rewared-content {
            margin-bottom: 0;
            margin-top: 8px;
            text-align: left;
            padding: 0;
            width: initial !important;
            line-height: 1.5 !important;
            margin-left: 0;

            @include XGA {
              font-size: 12px;
            }

            @include HD {
              font-size: unset;
            }
          }
        }
      }
    }

    .points {
      margin-top: 16px;
      margin-bottom: 0;
    }

    .button-next {
      margin: 0 auto;
      margin-top: 32px;

      @include XGA {
        margin-top: 40px;
      }
    }

    .steps {
      max-width: 280px;
      margin: 0 auto;
      margin-top: 32px;
      display: grid;
      row-gap: 16px;

      .text {
        display: grid;
        grid-template-columns: 24px auto;
        column-gap: 12px;
      }

      h3 {
        text-align: left;
        padding: 0;
        margin: 0;
        grid-column: 2/3;
        width: fit-content !important;
      }

      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 4px;
        width: 24px !important;
        height: 24px !important;
        border-radius: 50%;
        font-weight: 700;
        font-size: 15px;
        border: 1px solid $lightorange;
        background: linear-gradient(
          53.04deg,
          #ee4823 37.12%,
          #ee4f22 41.3%,
          #f06022 47.18%,
          #f37820 54.09%,
          #f7951d 61.71%,
          #faa61a 65.4%,
          #faa61a 65.47%
        );
        color: white;
        border: none;
        grid-column: 1/2;
        margin: 0;
        opacity: 1;
        min-width: initial !important;
        max-width: initial !important;
      }

      p {
        grid-column: 2/3;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        opacity: 0.8;
        padding: 0;
        margin: 0;
        margin-top: 4px;
      }
    }

    .user-info {
      margin-top: 16px;
      margin-bottom: 24px;
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        margin: 0;
      }
    }

    .title {
      width: fit-content;
      max-width: initial;
      min-width: initial;
    }

    p {
      width: fit-content !important;
      min-width: unset !important;
      max-width: unset !important;
      margin-left: 0;
      margin-right: 0;
      text-align: left;
    }

    .label {
      margin-bottom: 3px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }

    input[type="text"] {
      width: 100%;
      border: none;
      border-bottom: 1px solid black;
      margin-bottom: 16px;
      font-size: 14px;
      background-color: white;

      // @include XGA {
      //     background: rgba(243, 243, 243, 0.65);
      // }

      &::placeholder {
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.4);
      }

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    input[type="file"] {
      visibility: hidden;
      height: 0px;
      margin: 0;

      &::file-selector-button {
        display: none;
      }
    }

    label {
      display: block;
      margin-top: 6px;
      font-weight: 800;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: $lightorange;
      opacity: 0.8;
      cursor: pointer;
    }

    .checkbox {
      margin-top: 16px;
      display: flex;
      gap: 8px;
      align-items: center;

      p {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        margin-bottom: -3px;
      }

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        border-radius: 2px;
      }
    }

    .user-info + .button-wrapper {
      margin-bottom: 0;
    }

    .background-desktop {
      @include XGA {
        width: 100%;
        border-radius: 12px;
        margin-top: 24px;
      }
    }

    .desktop-wrapper {
      @include XGA {
        // grid-template-columns: 45% 28%;
        background-position: center;
        background-size: cover;
        margin: 0;
        margin-top: 40px;
        gap: 35px;
        // place-content: center;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 4vw;
        padding-bottom: 4vw;
      }
    }

    .desktop {
      @include XGA {
        padding: 4vw;
        padding-top: 40px !important;
        padding-bottom: 40px !important;

        &:first-of-type {
          width: 45%;
          margin-top: 0 !important;
        }

        &:last-of-type {
          width: 28%;
          margin-top: 0 !important;
        }

        .steps {
          max-width: unset;
          margin-top: 0;
        }

        .text {
          display: flex;
          flex-direction: column;
          align-items: center;

          h3 {
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            margin-top: 10px;
            max-width: 438px;
          }

          p {
            opacity: 0.6;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            width: 88%;
            max-width: 438px;
            text-align: center;
          }

          .number {
            opacity: 1;
            width: 24px;
          }
        }

        .button-wrapper {
          margin-top: 24px;
        }
      }
    }

    &-result {
      .card {
        .title {
          // margin-top: 24px;
          margin-bottom: 16px;
        }
      }

      img {
        border-radius: 12px;
      }

      .content.result {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        div {
          p {
            margin: 0;
          }
        }
      }

      .spec {
        p {
          font-size: 15px;
          line-height: 20px;
          color: #868686;
          margin: 0;
          text-align: center;
          margin: 0;
          width: 100%;
          min-width: initial;
          max-width: initial;

          strong {
            color: black;
          }
        }
      }
    }
  }

  .br-mobile {
    @include XGA {
      display: none;
    }
  }

  .br-desktop {
    display: none;

    @include XGA {
      display: block;
    }
  }

  &.cloud-fest {
    @include XGA {
      margin-bottom: 0px;
      padding: 0px;
    }

    .hd {
      display: none;

      @include HD {
        display: block;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: block;
      }
    }

    .mobile {
      @include XGA {
        display: none;
      }
    }

    .card {
      padding: 24px 16px 16px;
      display: grid;
      justify-content: center;
      background-image: url("./konkurs3_cf/img/bg-mobile.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .title,
      .sub {
        width: 100%;
        max-width: 270px;
      }

      h2,
      h3,
      p {
        margin: 0;
        color: white;
        width: 100%;
        max-width: 270px;
      }

      p {
        margin-top: 8px;
        font-weight: 400;
      }

      @include XGA {
        margin: 0 auto;
        background-color: white;
        background-image: none;
        justify-items: center;
        min-height: initial !important;
        padding: 64px 80px 40px;

        h3,
        p {
          color: black;
          width: 100%;
        }

        .title,
        .title > h3 {
          max-width: 703px;
          width: 100%;
        }

        .sub,
        .sub > p {
          max-width: 592px;
          width: 100%;
        }

        p:first-of-type {
          color: #2a2c2c;
          margin-top: 24px;
        }

        p:last-of-type {
          color: #868686;
        }
      }
    }

    .select {
      padding: 24px 0px 16px;
      grid-template-columns: 1fr;

      @include XGA {
        grid-template-columns: 1fr 1fr;
        padding: 48px 80px 24px;
        column-gap: 80px;

        .desktop {
          grid-column: 1/3;
          border-top: 1px solid #e5e5e5;
          width: 100%;
        }
      }

      .chunk {
        margin-top: 24px;
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid white;

        .button {
          &.disabled {
            color: white;
            opacity: 0.6;

            @include XGA {
              color: black;
            }
          }
        }

        &:first-of-type {
          margin-top: 10px;

          @include XGA {
            grid-column: 1/3;
            border-bottom: 1px solid #e5e5e5;

            .title,
            h2,
            .sub,
            p {
              width: 100%;
              max-width: unset;
              max-width: 553px;
            }

            h2 {
              font-weight: 600;
              font-size: 24px;
              line-height: 32px;
            }

            p {
              font-weight: 400;
              color: #868686;
              font-size: 15px;
              line-height: 20px;
            }
          }
        }

        @include XGA {
          h3 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;

            span {
              color: #ed8002;
            }
          }

          p {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: black;
            max-width: 592px;
            min-width: initial !important;
            width: 100%;

            span {
              color: #868686;
            }
          }
        }

        &:nth-of-type(3) {
          border: none;
        }
      }

      h2,
      p {
        margin: 0 auto;
      }

      h2 {
        @include XGA {
          color: black;
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
        font-weight: 600;

        @include XGA {
          font-weight: 400;
          font-size: 15px;
          margin-top: 8px !important;
          line-height: 20px;
        }
      }

      .title,
      .sub,
      h3 {
        width: 100% !important;
        max-width: 295px;
        margin: 0 auto;
      }

      .button {
        margin-top: 16px;
      }
    }

    .transparent {
      color: white;
      background-color: transparent;
      border: 1px solid white;
      width: fit-content;

      @include XGA {
        color: black;
        border-color: black;
      }

      &:hover {
        background: $orange;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        color: white;
        border-color: white;
      }

      &.selected {
        background: white;
        color: #ed8002;
        border-color: #ed8002;
      }

      &.disabled {
        background: #c6c6c5;
        color: #c6c6c5;
      }
    }

    .instruction {
      h2 {
        text-align: center;
        margin-bottom: 24px;
        margin-top: 24px;
      }

      .steps {
        .text {
          display: grid;
          grid-template-columns: 36px 1fr;
          row-gap: 4px;
          margin-bottom: 16px;
          max-width: 279px;

          &:last-of-type {
            margin-bottom: 24px;
          }

          h3 {
            grid-column: 2/3;
            text-align: left;
          }

          p:not(.number) {
            grid-column: 2/3;
            text-align: left;
            margin: 0;
          }
        }

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 4px;
          width: 24px !important;
          height: 24px !important;
          border-radius: 50%;
          font-weight: 700;
          font-size: 15px;
          border: 1px solid $lightorange;
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          grid-column: 1/2;
          margin: 0;
          opacity: 1;
          min-width: initial !important;
          max-width: initial !important;
          align-self: flex-start;
        }
      }

      @include XGA {
        max-width: 100%;
        width: 100%;
        padding: 32px 40px 40px;
        display: block;
        align-self: flex-start;
        margin-top: 32px;
        margin-bottom: 32px;

        h2 {
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #e86215;
          margin: 0;
          text-align: left;
          margin-bottom: 24px;
        }

        .steps {
          .text {
            max-width: 559px;
            width: 100%;
            margin-bottom: 24px;

            &:last-of-type {
              margin-bottom: 0;
            }

            h3 {
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              max-width: 100%;
              width: 100%;
            }

            p:not(.number) {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #868686;
            }
          }
        }
      }
    }

    .form {
      background-image: none;
      display: block;
      padding: 32px 24px 24px;
      margin-top: 24px;

      h3 {
        margin: 0 auto;
      }

      .user-info {
        max-width: 280px;
        margin: 0 auto;

        @include XGA {
          max-width: 100%;
        }
      }

      p {
        color: black !important;
      }

      input[type="file"] {
        visibility: hidden;
        height: 0px;
        margin: 0;

        &::file-selector-button {
          display: none;
        }
      }

      input[type="text"] {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        border: none;
        border-bottom: 1px solid black;
        margin-bottom: 16px;
        font-size: 14px;
        background-color: white;

        // @include XGA {
        //     background: rgba(243, 243, 243, 0.65);
        // }

        &::placeholder {
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.4);
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      .label {
        margin-bottom: 3px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        text-align: left;
      }

      label[for="files"] {
        display: block;
        margin-top: 6px;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
        text-decoration-line: underline;
        color: $lightorange;
        opacity: 0.8;
        cursor: pointer;
      }

      h3 {
        color: black;
        margin-bottom: 16px;
      }

      .more-info {
        font-weight: 400;
        font-size: 9px;
        line-height: 14px;
        text-align: left;

        strong {
          font-weight: 400;
        }
      }

      .agreements {
        margin-top: 8px;

        .checkbox {
          position: relative;

          input[type="checkbox"] {
            display: none;
          }

          label {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 30px;
            margin-bottom: 12px;
            min-height: 24px;
            cursor: pointer;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              border: $darkorange solid 1px;
            }
          }

          input[type="checkbox"]:checked + label {
            &::after {
              content: "";
              position: absolute;
              left: 7px;
              top: 4px;
              width: 5px;
              height: 8px;
              border-right: $darkorange solid 2px;
              border-bottom: $darkorange solid 2px;
              transform: rotate(35deg);
            }
          }
        }

        .switches {
          margin-top: 8px;
          margin-bottom: 8px;
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          text-align: left;
          max-width: 100%;
          width: 100%;
          margin-top: 0;
        }

        .link {
          display: inline;
          color: #ed8002;
          font-weight: 600;
        }

        .wrapper {
          .checkbox {
            &.select-all {
              label {
                margin-top: 16px;
                margin-bottom: 16px;
              }

              h3 {
                color: #ed8002;
                text-align: left;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 10px;
                padding-top: 4px;
              }
            }
          }
        }

        & > p:last-of-type {
          margin-top: 8px;
        }
      }

      @include XGA {
        width: 100%;
        padding: 40px 48px 32px;
        align-self: flex-start;
        margin-top: 32px;
        margin-bottom: 32px;

        .title {
          max-width: 100%;
          text-align: left;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #e86215;
          margin-bottom: 24px;
        }

        .user-info {
          input {
            max-width: 100%;
          }

          .label {
            margin-top: 0px;
          }

          label[for="select-all"] {
            h3 {
              width: 100%;
              max-width: 100%;
              text-align: left;
              font-weight: 600;
              margin-bottom: 8px;
            }
          }

          .switches {
            margin-bottom: 16px;
          }

          .agreements {
            margin-top: 16px;

            .wrapper {
              row-gap: 16px;
            }
          }

          .more-info {
            max-width: 100%;
            width: 100%;
            display: flex;
            font-size: 10px;
            margin-top: 8px;

            strong {
              font-weight: 600;

              &:nth-of-type(2) {
                margin-right: 1ch;
              }
            }

            br {
              display: none;
            }
          }
        }
      }

      .error {
        color: red !important;
        margin: 0 auto !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }

    .summary {
      h2 {
        margin-bottom: 24px;
        margin-top: 16px;
      }

      .sub,
      h3,
      p {
        max-width: 295px;
        width: 100%;
      }

      .box {
        margin: 32px 0 8px;
        border: 1px solid #ffffff;
        border-radius: 12px;
        padding: 16px 0;

        p {
          margin: 0;

          &:last-of-type {
            margin-top: 8px;
          }
        }
      }

      &.win {
        .box {
          p {
            max-width: 230px;
            margin: 0 auto;
          }
        }
      }

      .button-wrapper {
        margin-top: 0;
      }

      @include XGA {
        padding: 48px 80px 24px;

        .title,
        .sub,
        h2,
        h3,
        p {
          max-width: 421px !important;
          width: 100%;
        }

        h2 {
          color: black;
          margin: 0;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
        }

        .sub > * {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #868686;
          margin-top: 8px !important;
        }

        .box {
          border: 1px solid #e5451f;
          border-radius: 12px;
          margin-top: 24px;
          margin-bottom: 2px;
          width: 100%;
          width: 100%;

          p {
            max-width: 369px;
            text-align: center;
            margin: 0 auto;
          }

          * {
            color: #e5451f !important;
          }
        }

        .button-wrapper {
          margin-bottom: 0;
        }

        &.win {
          .sub,
          h3 {
            max-width: 467px !important;
          }

          .box {
            p {
              color: #868686 !important;
            }

            .points {
              color: white !important;
            }
          }
        }
      }
    }

    .button-wrapper {
      width: fit-content;

      @include XGA {
        margin-top: 24px;
      }

      button:not(.button) {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
        width: 32px;
        height: 32px;
        background-color: white;
        border-radius: 50%;
        border: 1px solid #ed8002;

        div {
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          margin-left: 1px;
        }

        &.disabled {
          border: 1px solid #c6c6c5;

          svg {
            path {
              stroke: #c6c6c5;
            }
          }
        }
      }
    }

    .link,
    .button {
      margin: 0 auto;
      margin-top: 24px;
      width: fit-content;
      display: block;

      a {
        color: #ed8002;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .background-desktop {
      @include XGA {
        width: calc(100% + 128px);
        margin-left: -64px;
        background-image: url("./konkurs3_cf/img/bg-desktop.jpg");
        background-position: right;
        background-repeat: no-repeat;
        margin-top: 40px;
        display: grid;
        place-items: center;
        min-height: 540px;
        justify-content: center;

        &.side-by-side {
          grid-template-columns: repeat(2, minmax(0, 636px));
          column-gap: 34px;
          padding-left: 64px;
          padding-right: 64px;

          @include UXGA {
            column-gap: 70px;
          }
        }
      }

      @include HD {
        min-height: 700px;
        background-position: center;
      }

      @include UXGA {
        min-height: 746px;
      }
    }

    &.flight_reservation {
      .location {
        display: flex;
        margin-top: 8px;

        @include XGA {
          margin: 0 auto;
          margin-top: 16px;
          align-items: center;
        }

        p {
          margin: 0;
          margin-left: 12px;
          font-size: 12px;
          line-height: 16px;
          white-space: pre-wrap;
          text-align: left;

          @include XGA {
            white-space: initial;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #2a2c2c;
            max-width: initial;
            width: fit-content;
            min-width: initial;
          }
        }

        @include XGA {
          svg {
            path {
              fill: #000000;
            }
          }
        }
      }

      .clouds-fest {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
      }

      .info {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #2a2c2c;
        margin-top: 16px;
      }

      .card {
        background-image: none;
        padding: 0;
        padding-bottom: 32px;
        display: block;

        @include XGA {
          width: 636px;
          padding-bottom: 40px;
          margin-top: 32px;
          margin-bottom: 32px;
        }

        &.date {
          .credentials,
          .date-picker {
            padding-left: 24px;
            padding-right: 24px;
          }

          .credentials {
            & > * {
              text-align: left;

              @include XGA {
                text-align: center;
                max-width: 100%;
              }
            }

            p.name {
              max-width: initial;
              margin-top: 24px;

              strong {
                color: black;
                font-size: 12px;
                line-height: 16px;

                @include XGA {
                  margin-top: 16px;
                  font-size: 15px;
                  line-height: 20px;
                  color: #2a2c2c;
                }
              }
            }

            p.e-mail {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              opacity: 0.4;

              @include XGA {
                margin-top: 0;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #868686;
                opacity: 1;
              }
            }
          }

          .date-picker {
            margin-top: 16px;

            h3.title {
              color: black;
              text-align: left;
              margin-bottom: 16px;
              margin-top: 24px;

              @include XGA {
                text-align: center;
                margin-top: 16px;
                margin-bottom: 8px;

                &.hour {
                  margin-top: 24px;
                  margin-bottom: 16px;
                }
              }
            }

            .dates {
              display: flex;
            }

            .hours {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));

              @include XGA {
                grid-template-columns: repeat(6, 64px);
              }
            }

            .hours,
            .dates {
              gap: 8px;

              @include XGA {
                justify-content: center;
              }

              button {
                background: none;
                cursor: pointer;
                outline: none;
                border: none;
                text-align: left;
                padding: 0;
                display: block;
              }
            }

            .date {
              height: 64px;
              width: 64px;
              border: 1px solid #f5aa04 !important;
              border-radius: 24px;

              div {
                margin: 0;
                font-size: 16px;
                font-weight: 900;
                color: #f5aa04;
                line-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &.selected {
                background-color: #f5aa04;

                div {
                  color: white;
                }
              }
            }

            .hours {
              margin-bottom: 13px;

              .hour {
                border: 1px solid #e86215;
                border-radius: 20px;
                width: 64px;
                height: 33px;

                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 900;
                  font-size: 12px;
                  line-height: 24px;
                  text-align: center;
                  color: #e86215;
                }

                &.selected {
                  background: #e86215;

                  div {
                    color: white;
                  }
                }
              }
            }

            .hour,
            .date {
              &[disabled] {
                border: 1px solid #c6c6c5 !important;
                pointer-events: none;

                div {
                  color: #c6c6c5;
                }
              }
            }
          }

          .button-wrapper {
            @include XGA {
              margin-top: 32px;
            }
          }
        }

        .upper {
          display: grid;
          place-items: center;
          width: 100%;
          border-radius: 12px 12px 0 0;
          background-image: url("./konkurs3_cf/img/bg-mobile.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          padding: 27px 24px 20px;

          @include XGA {
            background-image: none;
            padding-top: 40px;
            padding-bottom: 0;
          }

          h2.title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            max-width: 187px;

            @include XGA {
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              color: #e86215;
              max-width: initial;
            }
          }
        }

        .form {
          padding-bottom: 0;
          padding-top: 0;

          @include XGA {
            margin-top: 24px;
            margin-bottom: 0;
          }

          h3.title {
            text-align: left;

            @include XGA {
              font-weight: 700;
              font-size: 15px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 8px;
            }
          }

          .agreements {
            margin-top: 24px;

            label[for="select-all"] {
              display: flex;
              align-items: center;

              h3 {
                margin-bottom: 0;
              }
            }
          }
        }

        .button-wrapper {
          @include XGA {
            margin-bottom: 0;
          }
        }
      }

      .block {
        background-image: url("./konkurs3_cf/img/bg-mobile.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 24px;
        padding: 32px 25px 34px;

        @include XGA {
          background-image: none;
          padding: 0;
        }

        .header {
          display: flex;
          justify-content: center;
          flex-direction: column;

          @include XGA {
            margin: 0;
            gap: 8px;
            margin-top: 50px;
          }

          svg {
            margin-left: auto;
            margin-right: auto;
          }

          h2.title {
            margin-top: 8px;
            color: white;
          }
        }

        .ticket {
          margin-bottom: 24px;
          margin-top: 24px;
          width: calc(min(100%, 277px));
          height: 121px;
          max-lines: 121px;
          background-image: url("./img/ticket.svg");
          background-repeat: no-repeat;
          background-size: cover;
          display: grid;
          grid-template-columns: 94px auto;
          margin-left: auto;
          margin-right: auto;

          @include XGA {
            margin-bottom: 40px;
            margin-top: 40px;
            width: 549px;
            height: 240px;
            grid-template-columns: 186px auto;
          }

          .ballon {
            background: #f7f7f7;
            max-height: 121px;
            display: grid;
            place-items: center;

            @include XGA {
              max-height: 240px;

              svg {
                height: 140px;
                width: 90px;
              }
            }
          }

          .text {
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            margin-top: auto;
            margin-bottom: auto;

            @include XGA {
              margin-left: 48px;
            }

            h1 {
              margin: 0;
              font-weight: 700;
              font-size: 18px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 8px;

              @include XGA {
                margin-bottom: 16px;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
              }
            }

            p.time {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              opacity: 0.4;
              margin: 0;

              @include XGA {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }

        .location {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          justify-content: center;

          @include XGA {
            p {
              color: white;
            }

            svg {
              path {
                fill: white;
              }
            }
          }
        }

        .button-wrapper {
          @include XGA {
            margin-top: 32px;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  &.coffe {
    @include XGA {
      margin-bottom: 0px;
      padding: 0px;
    }

    .hd {
      display: none;

      @include HD {
        display: block;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: block;
      }
    }

    .mobile {
      @include XGA {
        display: none;
      }

      .points-wrapper-mobile {
        margin-top: 24px;
        padding: 18px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        border: 1px #e5e5e5 solid;
        border-radius: 12px;

        .points-description {
          max-width: 260px;
          margin: 0;
          margin-top: 8px;
        }
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        @include UXGA {
          min-width: 110px;
        }
      }

      @include XGA {
        background-image: url("./konkurs4_lipiec/img/bg-desktop.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .mobile-bg {
        min-height: 176px;
        min-width: 342px;
        width: 100%;
        background-image: url("./konkurs4_lipiec/img/bg-desktop.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 12px 12px 0 0;

        @include XGA {
          display: none;
        }
      }

      .title,
      .sub {
        width: 100%;
        max-width: 270px;
      }

      .title {
        margin-top: 25px;
      }

      .points-wrapper-dekstop {
        display: none;

        @include XGA {
          font-size: 12px;
          text-align: center;
          background: #f7f7f7;
          border-radius: 12px;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          min-width: 640px;
          min-height: 108px;

          .points-description {
            max-width: none;
            margin: 0;
          }

          .points {
            color: white;
            display: inline;
            max-width: 110px;
            min-width: 100px;
            margin: 0;
          }
        }
      }

      h2,
      h3,
      p {
        margin: 0;
        color: #000000;
        width: 100%;
        max-width: 270px;
      }

      p {
        margin-top: 8px;
        font-weight: 400;

        @include XGA {
          max-width: none;
        }
      }

      h3 {
        margin-top: 25px;
      }

      h2 {
        max-width: none;
      }

      @include XGA {
        margin: 0 auto;
        background-color: white;
        background-image: none;
        justify-items: center;
        min-height: initial !important;
        padding: 20px 80px 40px;

        h3,
        p {
          color: black;
          width: 100%;
        }

        .title,
        .title > h3 {
          max-width: 703px;
          width: 100%;
        }

        .sub,
        .sub > p {
          max-width: 592px;
          width: 100%;
        }

        p:first-of-type {
          color: #2a2c2c;
          margin-top: 24px;
        }

        // p:last-of-type {
        //     color: #868686;
        // }
      }
    }

    .reward-wrapper {
      display: flex;
      width: 295px;
      border: 1px solid #f7f7f7;
      border-radius: 12px;
      margin-top: 8px;

      @include XGA {
        width: inherit;
      }

      .icon-wrapper {
        background: #f7f7f7;
        border-radius: 12px 0px 0px 12px;
        height: 97px;
        width: 97px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 35px;
      }

      .content-reward {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 16px;
        text-align: left;

        @include XGA {
          justify-content: flex-start;
        }

        .plages {
          color: #e5451f;
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          max-width: none;
          width: auto;
        }

        p {
          text-align: left;
          font-weight: 700;
          font-size: 15px;
        }
      }
    }

    .reward-sub-text {
      margin: 20px auto 0 !important;
      color: #2a2c2c !important;
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 20px;
      max-height: 230px;
      margin: 0 auto;
    }

    .select {
      padding: 24px 0px 16px;
      grid-template-columns: 1fr;

      @include XGA {
        grid-template-columns: 1fr 1fr;
        padding: 48px 80px 24px;
        column-gap: 80px;

        .desktop {
          grid-column: 1/3;
          border-top: 1px solid #e5e5e5;
          width: 100%;
        }
      }

      .chunk {
        margin-top: 24px;
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid white;

        .button {
          &.disabled {
            color: white;
            opacity: 0.6;

            @include XGA {
              color: black;
            }
          }
        }

        &:first-of-type {
          margin-top: 10px;

          @include XGA {
            grid-column: 1/3;
            border-bottom: 1px solid #e5e5e5;

            .title,
            h2,
            .sub,
            p {
              width: 100%;
              max-width: unset;
              max-width: 553px;
            }

            h2 {
              font-weight: 600;
              font-size: 24px;
              line-height: 32px;
            }

            p {
              font-weight: 400;
              color: #868686;
              font-size: 15px;
              line-height: 20px;
            }
          }
        }

        @include XGA {
          h3 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;

            span {
              color: #ed8002;
            }
          }

          p {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: black;
            max-width: 592px;
            min-width: initial !important;
            width: 100%;

            span {
              color: #868686;
            }
          }
        }

        &:nth-of-type(3) {
          border: none;
        }
      }

      h2,
      p {
        margin: 0 auto;
      }

      h2 {
        @include XGA {
          color: black;
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
        font-weight: 600;

        @include XGA {
          font-weight: 400;
          font-size: 15px;
          margin-top: 8px !important;
          line-height: 20px;
        }
      }

      .title,
      .sub,
      h3 {
        width: 100% !important;
        max-width: 295px;
        margin: 0 auto;
      }

      .button {
        margin-top: 16px;
      }
    }

    .transparent {
      color: white;
      background-color: transparent;
      border: 1px solid white;
      width: fit-content;

      @include XGA {
        color: black;
        border-color: black;
      }

      &:hover {
        background: $orange;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        color: white;
        border-color: white;
      }

      &.selected {
        background: white;
        color: #ed8002;
        border-color: #ed8002;
      }

      &.disabled {
        background: #c6c6c5;
        color: #c6c6c5;
      }
    }

    .instruction {
      h2 {
        text-align: center;
        margin-bottom: 24px;
        margin-top: 24px;
      }

      .steps {
        display: flex;
        flex-direction: column;
        padding-top: 27px;

        @include XGA {
          padding-top: 0;
        }

        .text {
          display: flex;
          justify-content: center;
          flex-direction: row;
          margin-bottom: 16px;
          max-width: 279px;

          @include XGA {
            flex-direction: column;
          }

          &:last-of-type {
            margin-bottom: 24px;
          }

          .number-content {
            padding-left: 12px;

            @include XGA {
              padding-left: none;
            }

            h3,
            p {
              text-align: left;
              max-width: none;

              @include XGA {
                text-align: center;
              }
            }

            h3 {
              grid-column: 2/3;
              margin-top: 0;
            }

            p:not(.number) {
              grid-column: 2/3;
              text-align: left;
              margin: 0;
            }
          }
        }

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 4px;
          width: 24px !important;
          height: 24px !important;
          border-radius: 50%;
          font-weight: 700;
          font-size: 15px;
          border: 1px solid $lightorange;
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          grid-column: 1/2;
          margin: 0;
          opacity: 1;
          min-width: initial !important;
          max-width: initial !important;
          align-self: flex-start;

          @include XGA {
            margin: 0 auto 17px auto;
          }

          span {
            padding: 10px;
          }
        }
      }

      @include XGA {
        max-width: 100%;
        width: 100%;
        padding: 32px 40px 40px;
        display: block;
        align-self: flex-start;
        margin-top: 32px;
        margin-bottom: 32px;

        h2 {
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #e86215;
          margin: 0;
          text-align: left;
          margin-bottom: 24px;
        }

        .steps {
          .text {
            max-width: 559px;
            width: 100%;
            margin-bottom: 24px;

            &:last-of-type {
              margin-bottom: 0;
            }

            h3 {
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              max-width: 100%;
              width: 100%;
            }

            p:not(.number) {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #868686;
            }
          }
        }
      }
    }

    .form {
      background-image: none;
      display: block;
      margin-top: 24px;

      h3 {
        margin: 0 auto;
      }

      .user-info {
        max-width: 280px;
        margin: 20px auto 0;

        @include XGA {
          max-width: 100%;
        }
      }

      p {
        color: black !important;
      }

      input[type="file"] {
        visibility: hidden;
        height: 0px;
        margin: 0;

        &::file-selector-button {
          display: none;
        }
      }

      input[type="text"] {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        border: none;
        border-bottom: 1px solid black;
        margin-bottom: 16px;
        font-size: 14px;
        background-color: white;

        // @include XGA {
        //     background: rgba(243, 243, 243, 0.65);
        // }

        &::placeholder {
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.4);
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      .label {
        margin-bottom: 3px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        text-align: left;
      }

      .update-wrapper {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include XGA {
          align-items: center;
          flex-direction: row;
        }

        .update-button-wrapper {
          width: 100%;
          text-align: center;

          @include XGA {
            width: auto;
          }
        }
      }

      .file-button {
        border: 2px $lightorange solid;
        width: 100%;
        padding: 12px 20px 10px 20px;
        border-radius: 50px;

        @include XGA {
          width: fit-content;
        }
      }

      #files {
        position: absolute;
      }

      label[for="files"] {
        display: block;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
        color: $lightorange;
        opacity: 0.8;
        cursor: pointer;
      }

      h3 {
        color: black;
        margin-bottom: 16px;
      }

      .more-info {
        font-weight: 400;
        font-size: 9px;
        line-height: 14px;
        text-align: left;

        strong {
          font-weight: 400;
        }
      }

      .agreements {
        margin-top: 8px;

        .checkbox {
          position: relative;

          &.select-all {
            label {
              margin-top: 16px;
              margin-bottom: 16px;
            }

            h3 {
              color: #ed8002;
              text-align: left;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 10px;
              padding-top: 4px;
            }
          }

          input[type="checkbox"] {
            display: none;
          }

          label {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 30px;
            margin-bottom: 12px;
            min-height: 24px;
            cursor: pointer;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              border: $darkorange solid 1px;
            }
          }

          input[type="checkbox"]:checked + label {
            &::after {
              content: "";
              position: absolute;
              left: 7px;
              top: 4px;
              width: 5px;
              height: 8px;
              border-right: $darkorange solid 2px;
              border-bottom: $darkorange solid 2px;
              transform: rotate(35deg);
            }
          }
        }

        .switches {
          margin-top: 8px;
          margin-bottom: 8px;
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          text-align: left;
          max-width: 100%;
          width: 100%;
          margin-top: 0;
        }

        .link {
          display: inline;
          color: #ed8002;
          font-weight: 600;
        }

        .wrapper {
          h3 {
            color: #ed8002;
            text-align: left;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
          }
        }

        & > p:last-of-type {
          margin-top: 8px;
        }
      }

      @include XGA {
        width: 100%;
        padding: 40px 110px 32px;
        align-self: flex-start;
        margin-top: 32px;
        margin-bottom: 32px;

        .title {
          max-width: 100%;
          text-align: left;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #e86215;
          margin-bottom: 24px;
        }

        .user-info {
          .labels-inputs-wrapper {
            display: flex;
            justify-content: space-between;
          }

          .label-input-wrapper {
            display: flex;
            flex-direction: column;
          }

          input {
            max-width: 192px;
          }

          .label {
            margin-top: 0px;
          }

          label[for="select-all"] {
            h3 {
              width: 100%;
              max-width: 100%;
              text-align: left;
              font-weight: 600;
              margin-bottom: 8px;
            }
          }

          .switches {
            margin-bottom: 16px;
          }

          .agreements {
            margin-top: 16px;

            .wrapper {
              row-gap: 16px;
            }
          }

          .more-info {
            max-width: 100%;
            width: 100%;
            display: flex;
            font-size: 10px;
            margin-top: 2px;

            strong {
              font-weight: 600;

              &:nth-of-type(2) {
                margin-right: 1ch;
              }
            }

            br {
              display: none;
            }

            @include XGA {
              margin-left: 25px;
            }
          }
        }
      }

      .error {
        color: red !important;
        margin: 0 auto !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }

    .summary {
      h2 {
        margin-bottom: 24px;
        margin-top: 16px;
      }

      .sub,
      h3,
      p {
        max-width: 295px;
        width: 100%;
      }

      .box {
        margin: 32px 0 8px;
        border: 1px solid #ffffff;
        border-radius: 12px;
        padding: 16px 0;

        p {
          margin: 0;

          &:last-of-type {
            margin-top: 8px;
          }
        }
      }

      &.win {
        .box {
          p {
            max-width: 230px;
            margin: 0 auto;
          }
        }
      }

      .button-wrapper {
        margin-top: 0;
      }

      @include XGA {
        padding: 48px 80px 24px;

        .title,
        .sub,
        h2,
        h3,
        p {
          max-width: 421px !important;
          width: 100%;
        }

        h2 {
          color: black;
          margin: 0;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
        }

        .sub > * {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #868686;
          margin-top: 8px !important;
        }

        .box {
          border-radius: 12px;
          margin-top: 24px;
          margin-bottom: 2px;
          width: 100%;
          width: 100%;

          p {
            max-width: 369px;
            text-align: center;
            margin: 0 auto;
          }

          * {
            color: #e5451f !important;
          }
        }

        .button-wrapper {
          margin-bottom: 0;
        }

        &.win {
          .sub,
          h3 {
            max-width: 467px !important;
          }

          .box {
            p {
              color: #868686 !important;
            }

            .points {
              color: white !important;
            }
          }
        }
      }
    }

    .button-wrapper {
      width: fit-content;

      @include XGA {
        margin-top: 24px;
      }

      button:not(.button) {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
        width: 32px;
        height: 32px;
        background-color: white;
        border-radius: 50%;
        border: 1px solid #ed8002;

        div {
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          margin-left: 1px;
        }

        &.disabled {
          border: 1px solid #c6c6c5;

          svg {
            path {
              stroke: #c6c6c5;
            }
          }
        }
      }
    }

    .link,
    .button {
      margin: 0 auto;
      margin-top: 24px;
      width: fit-content;
      display: block;

      a {
        color: #ed8002;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .background-desktop {
      @include XGA {
        width: 100%;
        background-image: url("./konkurs4_lipiec/img/bg-desktop.jpg");
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        margin-top: 40px;
        display: grid;
        place-items: center;
        min-height: 540px;
        justify-content: center;
        margin: 40px 0;
        padding: 45px;

        &.side-by-side {
          grid-template-columns: repeat(2, minmax(0, 636px));
          column-gap: 34px;
          padding-left: 64px;
          padding-right: 64px;

          @include UXGA {
            column-gap: 70px;
          }
        }
      }

      @include HD {
        min-height: 700px;
        background-position: center;
      }

      @include UXGA {
        min-height: 746px;
      }
    }

    &.flight_reservation {
      .location {
        display: flex;
        margin-top: 8px;

        @include XGA {
          margin: 0 auto;
          margin-top: 16px;
          align-items: center;
        }

        p {
          margin: 0;
          margin-left: 12px;
          font-size: 12px;
          line-height: 16px;
          white-space: pre-wrap;
          text-align: left;

          @include XGA {
            white-space: initial;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #2a2c2c;
            max-width: initial;
            width: fit-content;
            min-width: initial;
          }
        }

        @include XGA {
          svg {
            path {
              fill: #000000;
            }
          }
        }
      }

      .clouds-fest {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
      }

      .info {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #2a2c2c;
        margin-top: 16px;
      }

      .card {
        background-image: none;
        padding: 0;
        padding-bottom: 32px;
        display: block;

        @include XGA {
          width: 636px;
          padding-bottom: 40px;
          margin-top: 32px;
          margin-bottom: 32px;
        }

        &.date {
          .credentials,
          .date-picker {
            padding-left: 24px;
            padding-right: 24px;
          }

          .credentials {
            & > * {
              text-align: left;

              @include XGA {
                text-align: center;
                max-width: 100%;
              }
            }

            p.name {
              max-width: initial;
              margin-top: 24px;

              strong {
                color: black;
                font-size: 12px;
                line-height: 16px;

                @include XGA {
                  margin-top: 16px;
                  font-size: 15px;
                  line-height: 20px;
                  color: #2a2c2c;
                }
              }
            }

            p.e-mail {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              opacity: 0.4;

              @include XGA {
                margin-top: 0;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #868686;
                opacity: 1;
              }
            }
          }

          .date-picker {
            margin-top: 16px;

            h3.title {
              color: black;
              text-align: left;
              margin-bottom: 16px;
              margin-top: 24px;

              @include XGA {
                text-align: center;
                margin-top: 16px;
                margin-bottom: 8px;

                &.hour {
                  margin-top: 24px;
                  margin-bottom: 16px;
                }
              }
            }

            .dates {
              display: flex;
            }

            .hours {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));

              @include XGA {
                grid-template-columns: repeat(6, 64px);
              }
            }

            .hours,
            .dates {
              gap: 8px;

              @include XGA {
                justify-content: center;
              }

              button {
                background: none;
                cursor: pointer;
                outline: none;
                border: none;
                text-align: left;
                padding: 0;
                display: block;
              }
            }

            .date {
              height: 64px;
              width: 64px;
              border: 1px solid #f5aa04 !important;
              border-radius: 24px;

              div {
                margin: 0;
                font-size: 16px;
                font-weight: 900;
                color: #f5aa04;
                line-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &.selected {
                background-color: #f5aa04;

                div {
                  color: white;
                }
              }
            }

            .hours {
              margin-bottom: 13px;

              .hour {
                border: 1px solid #e86215;
                border-radius: 20px;
                width: 64px;
                height: 33px;

                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 900;
                  font-size: 12px;
                  line-height: 24px;
                  text-align: center;
                  color: #e86215;
                }

                &.selected {
                  background: #e86215;

                  div {
                    color: white;
                  }
                }
              }
            }

            .hour,
            .date {
              &[disabled] {
                border: 1px solid #c6c6c5 !important;
                pointer-events: none;

                div {
                  color: #c6c6c5;
                }
              }
            }
          }

          .button-wrapper {
            @include XGA {
              margin-top: 32px;
            }
          }
        }

        .upper {
          display: grid;
          place-items: center;
          width: 100%;
          border-radius: 12px 12px 0 0;
          background-image: url("./konkurs3_cf/img/bg-mobile.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          padding: 27px 24px 20px;

          @include XGA {
            background-image: none;
            padding-top: 40px;
            padding-bottom: 0;
          }

          h2.title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            max-width: 187px;

            @include XGA {
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              color: #e86215;
              max-width: initial;
            }
          }
        }

        .form {
          padding-bottom: 0;
          padding-top: 0;

          @include XGA {
            margin-top: 24px;
            margin-bottom: 0;
          }

          h3.title {
            text-align: left;

            @include XGA {
              font-weight: 700;
              font-size: 15px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 8px;
            }
          }

          .agreements {
            margin-top: 24px;

            label[for="select-all"] {
              display: flex;
              align-items: center;

              h3 {
                margin-bottom: 0;
              }
            }
          }
        }

        .button-wrapper {
          @include XGA {
            margin-bottom: 0;
          }
        }
      }

      .block {
        background-image: url("./konkurs4_lipiec/img/bg-desktop.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 24px;
        padding: 32px 25px 34px;

        @include XGA {
          background-image: none;
          padding: 0;
        }

        .header {
          display: flex;
          justify-content: center;
          flex-direction: column;

          @include XGA {
            margin: 0;
            gap: 8px;
            margin-top: 50px;
          }

          svg {
            margin-left: auto;
            margin-right: auto;
          }

          h2.title {
            margin-top: 8px;
            color: white;
          }
        }

        .ticket {
          margin-bottom: 24px;
          margin-top: 24px;
          width: calc(min(100%, 277px));
          height: 121px;
          max-lines: 121px;
          background-image: url("./img/ticket.svg");
          background-repeat: no-repeat;
          background-size: cover;
          display: grid;
          grid-template-columns: 94px auto;
          margin-left: auto;
          margin-right: auto;

          @include XGA {
            margin-bottom: 40px;
            margin-top: 40px;
            width: 549px;
            height: 240px;
            grid-template-columns: 186px auto;
          }

          .ballon {
            background: #f7f7f7;
            max-height: 121px;
            display: grid;
            place-items: center;

            @include XGA {
              max-height: 240px;

              svg {
                height: 140px;
                width: 90px;
              }
            }
          }

          .text {
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            margin-top: auto;
            margin-bottom: auto;

            @include XGA {
              margin-left: 48px;
            }

            h1 {
              margin: 0;
              font-weight: 700;
              font-size: 18px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 8px;

              @include XGA {
                margin-bottom: 16px;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
              }
            }

            p.time {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              opacity: 0.4;
              margin: 0;

              @include XGA {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }

        .location {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          justify-content: center;

          @include XGA {
            p {
              color: white;
            }

            svg {
              path {
                fill: white;
              }
            }
          }
        }

        .button-wrapper {
          @include XGA {
            margin-top: 32px;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  &.slow-life {
    @include XGA {
      margin-bottom: 0px;
      padding: 0px;
    }

    .hd {
      display: none;

      @include HD {
        display: block;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: flex;
        flex-direction: column;
        button {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }

    .mobile {
      button {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      @include XGA {
        display: none;
      }

      .points-wrapper-mobile {
        margin-top: 24px;
        padding: 18px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        border: 1px #e5e5e5 solid;
        border-radius: 12px;

        .points-description {
          max-width: 260px;
          margin: 0;
          margin-top: 8px;
        }
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        @include UXGA {
          min-width: 110px;
        }
      }

      @include XGA {
        background-image: url("./konkurs5_lipiec_2023/img/bg.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .mobile-bg-limited {
        // min-height: 176px;
        aspect-ratio: 342/176;
        // min-width: 342px;
        width: 100%;
        background-image: url("./konkurs5_lipiec_2023/img/bg.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 12px 12px 0 0;

        @include XGA {
          display: none;
        }
      }

      .title,
      .sub {
        width: 100%;
        max-width: 315px;
      }
      .title-summer,
      .sub-summer {
        margin: 20px 10px 5px 10px;
        max-width: 675px;
        h3 {
          @include XGA {
            max-width: 575px;
            margin-top: 20px;
          }
        }
      }

      .title {
        margin-top: 32px;
        margin-bottom: 16px;
      }

      .points-wrapper-dekstop {
        display: none;

        @include XGA {
          font-size: 12px;
          text-align: center;
          border-radius: 12px;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          min-width: 640px;
          min-height: 108px;

          .points-description {
            max-width: none;
            margin: 0;
          }

          .points {
            color: white;
            display: inline;
            max-width: 110px;
            min-width: 100px;
            margin: 0;
          }
        }
      }

      h2,
      h3,
      p {
        margin: 0;
        color: #000000;
        width: 100%;
        max-width: 295px;
      }

      p {
        font-weight: 400;

        @include XGA {
          margin-top: 8px;
          max-width: none;
        }
      }

      // h3 {
      //     margin-top: 25px;
      // }

      h2 {
        max-width: none;
      }

      .title > h3 {
        font-size: 18px;
      }

      .category-sub {
        color: #2a2c2c;
        font-weight: 500;
        font-size: 15px !important;
        line-height: 20px;
        margin-top: 20px;
        max-width: 315px;
      }

      @include XGA {
        margin: 0 auto;
        background-color: white;
        background-image: none;
        justify-items: center;
        min-height: initial !important;
        padding: 20px 80px 40px;

        h3,
        p {
          color: black;
          width: 100%;
        }

        .title,
        .title > h3 {
          max-width: 703px;
          width: 100%;
        }

        .title > h3 {
          font-size: 24px;
        }

        .sub,
        .sub > p {
          max-width: 592px;
          width: 100%;
        }

        p:first-of-type {
          color: #2a2c2c;
          margin-top: 24px;
        }

        // p:last-of-type {
        //     color: #868686;
        // }
      }

      .category-bg {
        @include XGA {
          background-color: #f7f7f7;
          box-shadow: 0 0 0 100vmax #f7f7f7;
          clip-path: inset(0 -100vmax);
          margin-top: 32px;
          padding: 16px 0;
        }
      }

      .category-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 300px;
        margin-inline: auto;
        margin-bottom: 25px;
        @include XGA {
          margin-bottom: 0px;
          max-width: 500px;
        }
        .button {
          margin: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px 8px 1px;
          background-color: transparent;
          border: 1px solid #2a2c2c;
          border-radius: 24px;
          @include XGA {
            font-size: 14px;
            line-height: 20px;
            border: 2px solid #2a2c2c;
            padding: 11px 24px 9px;
            width: 144px;
          }
        }
        .active {
          background: #ed8002;
          border-radius: 24px;
          border: none;
          color: white;
        }
      }
      .device-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .device-list-element {
          display: flex;
          padding: 1px 100px 0px 1px;
          align-items: center;
          flex-shrink: 0;
          border-radius: 11px;
          border: 1px solid var(--light-grey, #e5e5e5);
          background: var(--white, #fff);
          .device-wrapper {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
            @include XGA {
              flex-direction: row;
            }
          }
          .device-title-color {
            background: var(
              --gradient,
              linear-gradient(90deg, #e5451f 0%, #f5aa04 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            margin: 0;
            width: fit-content;
          }
          .device-title {
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            margin: 0;
            width: fit-content;
            margin: 0px 5px 0px 0px;
          }
        }
      }
      .device-list-select {
        display: flex;
        flex-direction: column;
        @include XGA {
          flex-direction: row;
          gap: 20px;
        }
        .button {
          min-width: 200px;
          padding: 0;
          background-color: #f7f7f7;
          border-radius: 12px;
          border: 2px solid var(--very-dark-grey, #2a2c2c);
        }
        .active {
          padding: 0;
          border-radius: 12px;
          border: 2px solid var(--primary, #ed8002);
        }
      }
    }

    .category-wrapper {
      display: flex;
      justify-content: center;
      h4 {
        background: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        max-width: 335px;
        margin-top: 0px;
        @include XGA {
          font-size: 24px;
          line-height: 32px;
          max-width: 635px;
        }
      }
    }

    .reward-wrapper {
      display: flex;
      width: 295px;
      border: 1px solid #f7f7f7;
      border-radius: 12px;
      margin-top: 8px;

      @include XGA {
        width: inherit;
      }

      .icon-wrapper {
        background: #f7f7f7;
        border-radius: 12px 0px 0px 12px;
        height: 97px;
        width: 97px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 35px;
      }

      .content-reward {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 16px;
        text-align: left;

        @include XGA {
          justify-content: flex-start;
        }

        .plages {
          color: #e5451f;
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          max-width: none;
          width: auto;
          letter-spacing: 0.12em;
        }

        p {
          text-align: left;
          font-weight: 700;
          font-size: 15px;
        }
      }
    }

    .reward-sub-text {
      margin: 20px auto 0 !important;
      color: #2a2c2c !important;
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 20px;
      max-height: 230px;
      margin: 0 auto;
    }

    .select {
      padding: 24px 0px 16px;
      grid-template-columns: 1fr;

      @include XGA {
        grid-template-columns: 1fr 1fr;
        padding: 48px 80px 24px;
        column-gap: 80px;

        .desktop {
          grid-column: 1/3;
          border-top: 1px solid #e5e5e5;
          width: 100%;
        }
      }

      .chunk {
        margin-top: 24px;
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid white;

        .button {
          &.disabled {
            color: white;
            opacity: 0.6;

            @include XGA {
              color: black;
            }
          }
        }

        &:first-of-type {
          margin-top: 10px;

          @include XGA {
            grid-column: 1/3;
            border-bottom: 1px solid #e5e5e5;

            .title,
            h2,
            .sub,
            p {
              width: 100%;
              max-width: unset;
              max-width: 553px;
            }

            h2 {
              font-weight: 600;
              font-size: 24px;
              line-height: 32px;
            }

            p {
              font-weight: 400;
              color: #868686;
              font-size: 15px;
              line-height: 20px;
            }
          }
        }

        @include XGA {
          h3 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;

            span {
              color: #ed8002;
            }
          }

          p {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: black;
            max-width: 592px;
            min-width: initial !important;
            width: 100%;

            span {
              color: #868686;
            }
          }
        }

        &:nth-of-type(3) {
          border: none;
        }
      }

      h2,
      p {
        margin: 0 auto;
      }

      h2 {
        @include XGA {
          color: black;
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
        font-weight: 600;

        @include XGA {
          font-weight: 400;
          font-size: 15px;
          margin-top: 8px !important;
          line-height: 20px;
        }
      }

      .title,
      .sub,
      h3 {
        width: 100% !important;
        max-width: 295px;
        margin: 0 auto;
      }

      .button {
        margin-top: 16px;
      }
    }

    .transparent {
      color: white;
      background-color: transparent;
      border: 1px solid white;
      width: fit-content;

      @include XGA {
        color: black;
        border-color: black;
      }

      &:hover {
        background: $orange;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
        color: white;
        border-color: white;
      }

      &.selected {
        background: white;
        color: #ed8002;
        border-color: #ed8002;
      }

      &.disabled {
        background: #c6c6c5;
        color: #c6c6c5;
      }
    }

    .limited-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin: 15px 0px;
      @include XGA {
        flex-direction: row;
      }
      .limited-title-text {
        margin: 0;
        font-size: 18px;
        @include XGA {
          text-align: left;
          width: fit-content;
        }
      }
      .limited-category-step {
        font-size: 18px;
        text-transform: uppercase;
        margin: 0;
        @include XGA {
          text-align: left;
        }
      }
    }

    .instruction {
      h2 {
        text-align: center;
        margin-bottom: 24px;
        margin-top: 24px;
      }

      .steps {
        display: flex;
        flex-direction: column;
        padding-top: 8px;
        align-items: center;
        @include XGA {
          background-color: #f7f7f7;
          box-shadow: 0 0 0 100vmax #f7f7f7;
          clip-path: inset(0 -100vmax);
          margin-top: 32px;
          padding: 16px 0 !important;
        }

        .text {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 16px;
          max-width: 279px;

          &:last-of-type {
            margin-bottom: 24px;
          }

          .number-content {
            padding-left: 12px;

            @include XGA {
              padding-left: none;
            }

            h3,
            p {
              text-align: left;
              max-width: none;

              @include XGA {
                text-align: center;
              }
            }

            h3 {
              grid-column: 2/3;
              margin-top: 0;
            }

            p:not(.number) {
              grid-column: 2/3;
              text-align: center;
              margin: 0;
            }
          }
        }

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 0px;
          width: 24px !important;
          height: 24px !important;
          border-radius: 50%;
          font-weight: 700;
          font-size: 15px;
          border: 1px solid $lightorange;
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          grid-column: 1/2;
          margin: 0 auto;
          opacity: 1;
          min-width: initial !important;
          max-width: initial !important;
          align-self: flex-start;
          margin-bottom: 8px;

          @include XGA {
            margin: 0 auto 8px auto;
          }

          span {
            padding: 10px;
          }
        }
      }
      .steps-limited {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        @include XGA {
          align-items: flex-start;
        }
        .text2 {
          font-weight: 700;
          font-size: 18px;
          background: var(
            --gradient,
            linear-gradient(90deg, #e5451f 0%, #f5aa04 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include XGA {
            font-size: 24px;
            background: var(
              --gradient,
              linear-gradient(90deg, #000 0%, #000 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0;
            line-height: 24px;
            text-align: left;
          }
        }
        .text {
          font-size: 15px;
          font-weight: 500;
          @include XGA {
            font-size: 18px;
            margin: 0;
            text-align: left;
          }
        }
      }

      @include XGA {
        max-width: 100%;
        width: 100%;
        padding: 32px 40px 40px;
        display: block;
        align-self: flex-start;
        margin-top: 32px;
        margin-bottom: 32px;

        h2 {
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #e86215;
          margin: 0;
          text-align: left;
          margin-bottom: 24px;
        }

        .steps {
          .text {
            max-width: 559px;
            width: 100%;
            margin-bottom: 24px;

            &:last-of-type {
              margin-bottom: 0;
            }

            h3 {
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              max-width: 100%;
              width: 100%;
            }

            p:not(.number) {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #868686;
            }
          }
        }
      }
    }

    .form {
      background-image: none;
      display: block;
      margin-top: 24px;

      h3 {
        margin: 0 auto;
      }

      .user-info {
        max-width: 295px;
        margin: 32px auto 0;

        @include XGA {
          max-width: 100%;
        }
      }

      p {
        color: black !important;
      }

      input[type="file"] {
        visibility: hidden;
        height: 0px;
        margin: 0;

        &::file-selector-button {
          display: none;
        }
      }

      input[type="text"] {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        border: none;
        border-bottom: 1px solid black;
        margin-bottom: 16px;
        font-size: 14px;
        background-color: #f7f7f7;

        @include XGA {
          background-color: white;
        }

        // @include XGA {
        //     background: rgba(243, 243, 243, 0.65);
        // }

        &::placeholder {
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.4);
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      .label {
        margin-bottom: 3px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        text-align: left;
      }

      .update-wrapper {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include XGA {
          align-items: center;
          flex-direction: row;
        }

        .update-button-wrapper {
          width: 100%;
          text-align: center;

          @include XGA {
            width: auto;
          }
        }
      }

      .file-button {
        border: 2px $lightorange solid;
        width: 100%;
        padding: 12px 20px 10px 20px;
        border-radius: 50px;

        @include XGA {
          width: fit-content;
        }
      }

      #files {
        position: absolute;
      }

      label[for="files"] {
        display: block;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
        color: $lightorange;
        opacity: 0.8;
        cursor: pointer;
      }

      h3 {
        color: black;
        margin-bottom: 16px;
      }

      .more-info {
        font-weight: 400;
        font-size: 9px;
        line-height: 14px;
        text-align: left;

        strong {
          font-weight: 400;
        }
      }

      .agreements {
        margin-top: 8px;

        .checkbox {
          position: relative;

          &.select-all {
            label {
              margin-top: 16px;
              margin-bottom: 16px;
            }

            h3 {
              color: #ed8002;
              text-align: left;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 10px;
              padding-top: 4px;
            }
          }

          input[type="checkbox"] {
            display: none;
          }

          label {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 30px;
            margin-bottom: 12px;
            min-height: 24px;
            cursor: pointer;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              border: $darkorange solid 1px;
            }
          }

          input[type="checkbox"]:checked + label {
            &::after {
              content: "";
              position: absolute;
              left: 7px;
              top: 4px;
              width: 5px;
              height: 8px;
              border-right: $darkorange solid 2px;
              border-bottom: $darkorange solid 2px;
              transform: rotate(35deg);
            }
          }
        }

        .switches {
          margin-top: 8px;
          margin-bottom: 8px;
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          text-align: left;
          max-width: 100%;
          width: 100%;
          margin-top: 0;
        }

        .link {
          display: inline;
          color: #ed8002;
          font-weight: 600;
        }

        .wrapper {
          h3 {
            color: #ed8002;
            text-align: left;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
          }
        }

        & > p:last-of-type {
          margin-top: 8px;
        }
      }

      @include XGA {
        width: 100%;
        padding: 40px 110px 32px;
        align-self: flex-start;
        margin-top: 32px;
        margin-bottom: 32px;

        .title {
          max-width: 100%;
          text-align: left;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #e86215;
          margin-bottom: 24px;
        }

        .user-info {
          .labels-inputs-wrapper {
            display: flex;
            justify-content: space-between;
          }

          .label-input-wrapper {
            display: flex;
            flex-direction: column;
          }

          input {
            max-width: 192px;
          }

          .label {
            margin-top: 0px;
          }

          label[for="select-all"] {
            h3 {
              width: 100%;
              max-width: 100%;
              text-align: left;
              font-weight: 600;
              margin-bottom: 8px;
            }
          }

          .switches {
            margin-bottom: 16px;
          }

          .agreements {
            margin-top: 16px;

            .wrapper {
              row-gap: 16px;
            }
          }

          .more-info {
            max-width: 100%;
            width: 100%;
            display: flex;
            font-size: 10px;
            margin-top: 2px;

            strong {
              font-weight: 600;

              &:nth-of-type(2) {
                margin-right: 1ch;
              }
            }

            br {
              display: none;
            }

            @include XGA {
              margin-left: 25px;
            }
          }
        }
      }

      .error {
        color: red !important;
        margin: 0 auto !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }

    .summary {
      h2 {
        margin-bottom: 24px;
        margin-top: 16px;
      }

      .sub,
      h3,
      p {
        max-width: 295px;
        width: 100%;
      }

      .box {
        margin: 32px 0 8px;
        border: 1px solid #ffffff;
        border-radius: 12px;
        padding: 16px 0;

        p {
          margin: 0;

          &:last-of-type {
            margin-top: 8px;
          }
        }
      }

      &.win {
        .box {
          p {
            max-width: 230px;
            margin: 0 auto;
          }
        }
      }

      .button-wrapper {
        margin-top: 0;
      }

      @include XGA {
        padding: 48px 80px 24px;

        .title,
        .sub,
        h2,
        h3,
        p {
          max-width: 421px !important;
          width: 100%;
        }

        h2 {
          color: black;
          margin: 0;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
        }

        .sub > * {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #868686;
          margin-top: 8px !important;
        }

        .box {
          border-radius: 12px;
          margin-top: 24px;
          margin-bottom: 2px;
          width: 100%;
          width: 100%;

          p {
            max-width: 369px;
            text-align: center;
            margin: 0 auto;
          }

          * {
            color: #e5451f !important;
          }
        }

        .button-wrapper {
          margin-bottom: 0;
        }

        &.win {
          .sub,
          h3 {
            max-width: 467px !important;
          }

          .box {
            p {
              color: #868686 !important;
            }

            .points {
              color: white !important;
            }
          }
        }
      }
    }

    .button-wrapper {
      width: fit-content;

      @include XGA {
        margin-top: 24px;
      }

      button:not(.button) {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
        width: 32px;
        height: 32px;
        background-color: white;
        border-radius: 50%;
        border: 1px solid #ed8002;

        div {
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          margin-left: 1px;
        }

        &.disabled {
          border: 1px solid #c6c6c5;

          svg {
            path {
              stroke: #c6c6c5;
            }
          }
        }
      }
    }

    .link,
    .button {
      margin: 0 auto;
      margin-top: 24px;
      width: fit-content;
      display: block;

      a {
        color: #ed8002;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .background-desktop {
      @include XGA {
        width: 100%;
        background-image: url("./konkurs7_wrzesien/img/bg-desktop.jpg");
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        margin-top: 40px;
        display: grid;
        place-items: center;
        min-height: 540px;
        justify-content: center;
        margin: 40px 0;
        padding: 45px;

        &.side-by-side {
          grid-template-columns: repeat(2, minmax(0, 636px));
          column-gap: 34px;
          padding-left: 64px;
          padding-right: 64px;

          @include UXGA {
            column-gap: 70px;
          }
        }
      }

      @include HD {
        min-height: 706px;
        background-position: center;
      }

      @include UXGA {
        min-height: 746px;
      }
    }

    &.valentines-contest {
      .background-desktop {
        @include XGA {
          background-image: url("./konkurs1_luty_2023/img/bg.jpg");
        }
      }

      .card {
        &::after {
          display: none;
        }

        @include XGA {
          overflow: hidden;
        }

        .mobile-bg {
          background-image: url("./konkurs1_luty_2023/img/bg.jpg");
        }

        .title {
          margin-top: 24px;
          margin-bottom: 8px;

          @include XGA {
            max-width: 396px;
          }
        }

        .sub {
          @include XGA {
            padding: 24px 0;
            background-color: #f7f7f7;
            box-shadow: 0 0 0 100vmax #f7f7f7;
            clip-path: inset(0 -100vmax);
            margin-top: 16px;

            p {
              margin-top: 0;
            }
          }
        }

        &.reward {
          .title {
            margin-bottom: 24px;

            @include XGA {
              h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
              }
            }
          }

          .rewards-container {
            @include XGA {
              background-color: #f7f7f7;
              box-shadow: 0 0 0 100vmax #f7f7f7;
              clip-path: inset(0 -100vmax);
              margin-top: 32px;
              padding: 16px 0;
            }

            .reward-wrapper {
              @include XGA {
                border: 1px solid #e5e5e5;
                overflow: hidden;
              }

              .content-reward {
                @include XGA {
                  background-color: white;
                  width: 100%;
                  padding-left: 40px;
                  margin-left: 0;
                  justify-content: center;
                }

                p {
                  max-width: 166px;
                  margin-top: 2px;

                  @include XGA {
                    max-width: initial;
                  }

                  &.plages {
                    margin-top: 0;
                    line-height: initial;
                  }
                }
              }
            }
          }
        }

        &.instruction {
          &::after {
            content: "";
            width: 150%;
            height: 150vh;
            bottom: -48%;
            left: -12.5%;
            top: 60%;
            position: absolute;
            z-index: -10;
            background: #f7f7f7;
            display: block;
          }

          .title {
            @include XGA {
              max-width: initial;
              margin-bottom: 24px;
            }

            h3 {
              @include XGA {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
              }
            }
          }

          .steps {
            padding-top: 0;

            .number-content {
              padding-left: 0;

              p {
                @include XGA {
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 20px;
                  max-width: 547px;
                }
              }
            }

            .text {
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }

        &.summary {
          .box {
            border: 1px solid #e5e5e5;
            width: 100%;
            max-width: calc(100% - 48px);
            margin-inline: auto;

            img {
              margin-inline: auto;
              display: block;
              margin-bottom: 8px;
            }

            p {
              color: black !important;
              margin-inline: auto;

              strong {
                color: black !important;
              }
            }
          }
        }
      }

      .form .user-info {
        max-width: 400px;

        @include XGA {
          max-width: none;
        }
      }

      .labels-inputs-wrapper {
        @include XGA {
          gap: 32px;
        }

        .label-input-wrapper {
          margin-top: 16px;

          input {
            border-color: #c6c6c5;
            width: 100%;
            max-width: initial;

            @include XGA {
              max-width: 192px;
            }
          }
        }
      }

      .update-wrapper {
        margin-top: 32px;

        & > div:not(.update-button-wrapper) {
          margin: 24px 0;
          margin-inline: auto;

          p {
            margin: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;

            strong {
              font-weight: 700;
            }
          }
        }
      }

      .agreements {
        margin-top: 0;
      }

      &.spring-conest {
        .background-desktop {
          @include XGA {
            background-image: url("./konkurs5_lipiec_2023/img/bg.jpg");
          }
        }

        .card {
          &::after {
            display: none;
          }

          @include XGA {
            overflow: hidden;
          }

          .mobile-bg {
            background-image: url("./konkurs5_lipiec_2023/img/bg.jpg");
            background-position: center;
            background-size: cover;
            height: 30vh;
            width: 100%;
            border-radius: 12px 12px 0px 0px;
            @include XGA {
              display: none;
            }
          }
        }
      }
      &.festival-conest {
        .background-desktop {
          @include XGA {
            background-image: url("./konkurs4_czerwiec_2023/img/bg.jpg");
          }
        }

        .card {
          &::after {
            display: none;
          }

          @include XGA {
            overflow: hidden;
          }

          .mobile-bg {
            background-image: url("./konkurs4_czerwiec_2023/img/bgMobile.jpg");
          }
        }
      }
    }

    &.flight_reservation {
      .location {
        display: flex;
        margin-top: 8px;

        @include XGA {
          margin: 0 auto;
          margin-top: 16px;
          align-items: center;
        }

        p {
          margin: 0;
          margin-left: 12px;
          font-size: 12px;
          line-height: 16px;
          white-space: pre-wrap;
          text-align: left;

          @include XGA {
            white-space: initial;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #2a2c2c;
            max-width: initial;
            width: fit-content;
            min-width: initial;
          }
        }

        @include XGA {
          svg {
            path {
              fill: #000000;
            }
          }
        }
      }

      .clouds-fest {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
      }

      .info {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #2a2c2c;
        margin-top: 16px;
      }

      .card {
        background-image: none;
        padding: 0;
        padding-bottom: 32px;
        display: block;

        @include XGA {
          width: 636px;
          padding-bottom: 40px;
          margin-top: 32px;
          margin-bottom: 32px;
        }

        &.date {
          .credentials,
          .date-picker {
            padding-left: 24px;
            padding-right: 24px;
          }

          .credentials {
            & > * {
              text-align: left;

              @include XGA {
                text-align: center;
                max-width: 100%;
              }
            }

            p.name {
              max-width: initial;
              margin-top: 24px;

              strong {
                color: black;
                font-size: 12px;
                line-height: 16px;

                @include XGA {
                  margin-top: 16px;
                  font-size: 15px;
                  line-height: 20px;
                  color: #2a2c2c;
                }
              }
            }

            p.e-mail {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              opacity: 0.4;

              @include XGA {
                margin-top: 0;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #868686;
                opacity: 1;
              }
            }
          }

          .date-picker {
            margin-top: 16px;

            h3.title {
              color: black;
              text-align: left;
              margin-bottom: 16px;
              margin-top: 24px;

              @include XGA {
                text-align: center;
                margin-top: 16px;
                margin-bottom: 8px;

                &.hour {
                  margin-top: 24px;
                  margin-bottom: 16px;
                }
              }
            }

            .dates {
              display: flex;
            }

            .hours {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));

              @include XGA {
                grid-template-columns: repeat(6, 64px);
              }
            }

            .hours,
            .dates {
              gap: 8px;

              @include XGA {
                justify-content: center;
              }

              button {
                background: none;
                cursor: pointer;
                outline: none;
                border: none;
                text-align: left;
                padding: 0;
                display: block;
              }
            }

            .date {
              height: 64px;
              width: 64px;
              border: 1px solid #f5aa04 !important;
              border-radius: 24px;

              div {
                margin: 0;
                font-size: 16px;
                font-weight: 900;
                color: #f5aa04;
                line-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &.selected {
                background-color: #f5aa04;

                div {
                  color: white;
                }
              }
            }

            .hours {
              margin-bottom: 13px;

              .hour {
                border: 1px solid #e86215;
                border-radius: 20px;
                width: 64px;
                height: 33px;

                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 900;
                  font-size: 12px;
                  line-height: 24px;
                  text-align: center;
                  color: #e86215;
                }

                &.selected {
                  background: #e86215;

                  div {
                    color: white;
                  }
                }
              }
            }

            .hour,
            .date {
              &[disabled] {
                border: 1px solid #c6c6c5 !important;
                pointer-events: none;

                div {
                  color: #c6c6c5;
                }
              }
            }
          }

          .button-wrapper {
            @include XGA {
              margin-top: 32px;
            }
          }
        }

        .upper {
          display: grid;
          place-items: center;
          width: 100%;
          border-radius: 12px 12px 0 0;
          background-image: url("./konkurs3_cf/img/bg-mobile.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          padding: 27px 24px 20px;

          @include XGA {
            background-image: none;
            padding-top: 40px;
            padding-bottom: 0;
          }

          h2.title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            max-width: 187px;

            @include XGA {
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              color: #e86215;
              max-width: initial;
            }
          }
        }

        .form {
          padding-bottom: 0;
          padding-top: 0;

          @include XGA {
            margin-top: 24px;
            margin-bottom: 0;
          }

          h3.title {
            text-align: left;

            @include XGA {
              font-weight: 700;
              font-size: 15px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 8px;
            }
          }

          .agreements {
            margin-top: 24px;

            label[for="select-all"] {
              display: flex;
              align-items: center;

              h3 {
                margin-bottom: 0;
              }
            }
          }
        }

        .button-wrapper {
          @include XGA {
            margin-bottom: 0;
          }
        }
      }

      .block {
        background-image: url("./konkurs4_lipiec/img/bg-desktop.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 24px;
        padding: 32px 25px 34px;

        @include XGA {
          background-image: none;
          padding: 0;
        }

        .header {
          display: flex;
          justify-content: center;
          flex-direction: column;

          @include XGA {
            margin: 0;
            gap: 8px;
            margin-top: 50px;
          }

          svg {
            margin-left: auto;
            margin-right: auto;
          }

          h2.title {
            margin-top: 8px;
            color: white;
          }
        }

        .ticket {
          margin-bottom: 24px;
          margin-top: 24px;
          width: calc(min(100%, 277px));
          height: 121px;
          max-lines: 121px;
          background-image: url("./img/ticket.svg");
          background-repeat: no-repeat;
          background-size: cover;
          display: grid;
          grid-template-columns: 94px auto;
          margin-left: auto;
          margin-right: auto;

          @include XGA {
            margin-bottom: 40px;
            margin-top: 40px;
            width: 549px;
            height: 240px;
            grid-template-columns: 186px auto;
          }

          .ballon {
            background: #f7f7f7;
            max-height: 121px;
            display: grid;
            place-items: center;

            @include XGA {
              max-height: 240px;

              svg {
                height: 140px;
                width: 90px;
              }
            }
          }

          .text {
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            margin-top: auto;
            margin-bottom: auto;

            @include XGA {
              margin-left: 48px;
            }

            h1 {
              margin: 0;
              font-weight: 700;
              font-size: 18px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 8px;

              @include XGA {
                margin-bottom: 16px;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
              }
            }

            p.time {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              opacity: 0.4;
              margin: 0;

              @include XGA {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }

        .location {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          justify-content: center;

          @include XGA {
            p {
              color: white;
            }

            svg {
              path {
                fill: white;
              }
            }
          }
        }

        .button-wrapper {
          @include XGA {
            margin-top: 32px;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  &.festival-content {
    .card {
      .title {
        &.festival {
          max-width: 600px;
          padding: 0px 5px;
          h3 {
            max-width: 900px;
            margin-bottom: 0;
          }
        }
      }
      .sub {
        p {
          color: var(--very-dark-grey, #2a2c2c);
          text-align: center;
          font-size: 15px;
          font-family: Mont;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .category-bg-festival {
        background-color: transparent;
        .sub {
          font-size: 18px;
          font-family: Mont;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 20px;
          @include XGA {
            margin: 30px 0 -20px 0;
            font-size: 24px;
            background-color: #f7f7f7;
          }
        }
        .category-list {
          max-width: 500px;
          justify-content: center;
          gap: 20px;
          .button {
            padding: 20px 10px;
            border-radius: 10px;
          }
          .active {
            background-color: transparent;
            border: 2px solid #ed8002;
          }
        }
        .rewards-container {
          &.festival {
            @include XGA {
              margin-top: 0;
            }
            .reward-wrapper {
              margin: 0;
              height: 96px;
              p {
                margin: 0;
              }
            }
            .content-reward {
              &festiva p {
                max-width: 400px;
              }
            }
          }
        }
      }
      .button-wrapper {
        @include XGA {
          margin: 0 auto;
        }
      }
      .festival-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        font-family: Mont;
        font-weight: 500;
        line-height: 20px;

        span {
          font-size: 10px;
          font-weight: 700;
          line-height: 16px;
        }
        p {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          background: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin-top: 20px;
        }
      }
    }
    .sub {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .festival-form-area {
      width: 100%;
      min-height: 100px;
      margin-top: 20px;
      border-radius: 12px;
      border: 1px solid var(--grey, #c6c6c5);
      background: var(--white, #fff);
      max-width: 100%;
    }
    .info {
      &.festival {
        text-align: center;
      }
    }
    .bottom {
      &.festival {
        text-align: center;
        a {
          color: #ed8002;
        }
      }
    }

    .desktop-festival-text {
      display: flex;
      flex-direction: column;
      .container {
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        h3 {
          text-align: left;
          margin: 0;
          width: fit-content;
          font-weight: 500;
          line-height: 24px;
          font-size: 20px;
        }
        span {
          margin: 0;
          width: fit-content;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          font-size: 20px;
        }
      }
      .festival-form {
        display: flex;
        justify-content: left;
        align-items: start;
        p {
          font-size: 24px;
          font-family: Mont;
          font-weight: 700;
          line-height: 32px;
          background: #000;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: left;
          @include XGA {
            margin: 10px 0;
          }
        }
      }
    }

    .bottom-wrapper {
      display: flex;
      align-items: center;
      .bottom {
        &.festival {
          text-align: left;
        }
      }
    }
    .user-info-festival-desktop {
      display: flex;
      width: 100%;
      gap: 30px;
      background: var(--very-light-grey, #f7f7f7);
      box-shadow: 0 0 0 100vmax #f7f7f7;
      -webkit-clip-path: inset(0 -100vmax);
      clip-path: inset(0 -100vmax);
      padding: 10px 0 30px 0;
      .festival-form-area {
        max-width: 350px;
      }
      .info {
        font-size: 12px;
        text-align: left;
      }
      form {
        width: 50%;
      }
      .labels-inputs-wrapper {
        width: 50%;
        .label-input-wrapper {
          input {
            max-width: none;
            background: var(--very-light-grey, #f7f7f7);
          }
        }
      }
    }
    .box-festival {
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      border: 1px solid var(--light-grey, #e5e5e5);
      justify-content: center;
      align-items: center;
      padding: 20px 40px;
      margin-top: 20px;
      text-align: center;
      img {
        width: 24px;
      }
      @include XGA {
        background: var(--very-light-grey, #f7f7f7);
        box-shadow: 0 0 0 100vmax #f7f7f7;
        -webkit-clip-path: inset(0 -100vmax);
        clip-path: inset(0 -100vmax);
        border: none;
      }
    }
    .sub-festival {
      text-align: center;
    }
    .sub-festival-desktop {
      .sub-festival-desktop-text {
        margin-top: 0 !important;
      }
    }
  }

  &.ten-word {
    .card {
      overflow: hidden;
    }

    .card .sub {
      @include XGA {
        margin-top: 24px;
        padding: 24px 0 24px;
        position: relative;
        z-index: 2;

        &::before {
          position: absolute;
          content: "";
          width: 200%;
          height: 100%;
          background-color: #f7f7f7;
          left: -50%;
          z-index: -1;
          top: 0;
        }
      }
    }

    .card .sub > p {
      margin-top: 8px;

      &:first-of-type {
        margin-top: 0;
      }

      @include XGA {
        max-width: 530px;
        margin-inline: auto;
      }
    }

    .chunk {
      .sub {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #2a2c2c;
        max-width: 279px;
        margin-inline: auto;
      }

      .points,
      .points-description {
        @include XGA {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .link {
        margin-top: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #2a2c2c;

        @include XGA {
          display: none;
        }
      }

      .reward-wrapper {
        background-color: #f7f7f7;
        display: block;
        margin-bottom: 16px;
        border-radius: 50%;
        width: 174px;
        height: 174px;
        display: grid;
        place-items: center;
        margin-inline: auto;
        border: none;

        @include XGA {
          background-color: white;
          width: 96px;
          height: 96px;
          margin-bottom: 0%;
          margin-top: 0;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .reward-desktop-wrapper {
        @include XGA {
          display: flex;
          flex-direction: column;
          gap: 8px;
          position: relative;
          z-index: 2;
          padding: 16px 0;

          &::before {
            position: absolute;
            content: "";
            width: 200%;
            height: 100%;
            background-color: #f7f7f7;
            left: -50%;
            z-index: -1;
            top: 0;
          }
        }

        & > * {
          @include XGA {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .sub {
          @include XGA {
            margin-top: 8px;
            max-width: 583px;

            &::before {
              display: none;
            }
          }
        }

        .link {
          display: none;

          @include XGA {
            display: block;
          }
        }
      }
    }

    .instruction {
      p {
        margin-inline: auto;
      }

      h3 {
        @include XGA {
          font-weight: 700 !important;
          font-size: 18px !important;
          line-height: 24px !important;
        }
      }
    }

    .form {
      max-width: initial;

      .user-info {
        max-width: 327px;

        @include XGA {
          padding-top: 33px;
          position: relative;
          z-index: 2;

          &::before {
            position: absolute;
            content: "";
            width: 200%;
            left: -50%;
            height: calc(100% - 72px);
            background-color: #f7f7f7;
            z-index: -1;
          }
        }

        input {
          max-width: 327px;

          @include XGA {
            &:not([type="submit"]) {
              background-color: transparent;
            }
          }
        }

        @include XGA {
          display: grid;
          grid-template-columns: 1fr 1fr;
          max-width: initial;
          gap: 34px;

          .labels-inputs-wrapper {
            grid-column: 2/3;
            flex-direction: column;
          }

          form {
            .inner {
              grid-column: 1/2;
              grid-row: 1/2;
            }

            @include XGA {
              display: contents;
            }

            textarea {
              margin-top: 0;
            }
          }

          .button-wrapper {
            grid-column: 1/3;
            margin-top: 32px;
            margin-bottom: 0;

            input {
              margin-top: 0;
            }
          }
        }
      }
    }

    form {
      textarea {
        margin-top: 16px;
        resize: none;
        background: #ffffff;
        border: 1px solid #c6c6c5;
        border-radius: 12px;
        height: 120px;
        max-width: 327px;
        width: 100%;
        padding: 12px;
      }

      p.info {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }
    }

    .ten .summary {
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
      }

      .title {
        margin-bottom: 0;

        @include XGA {
          margin-top: 0;
        }
      }

      .sub {
        @include XGA {
          &::before {
            display: none;
          }
        }
      }

      .box {
        padding: 0;
        margin-top: 8px;

        p {
          max-width: 279px !important;
          width: 100%;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #2a2c2c;
        }
      }

      &.win {
        .points-wrapper-mobile {
          margin-top: 24px;
          padding: 18px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          border: 1px #e5e5e5 solid;
          border-radius: 12px;

          @include XGA {
            border: none;
            position: relative;
            z-index: 2;

            &::before {
              position: absolute;
              content: "";
              width: 200%;
              left: -50%;
              height: 100%;
              background-color: #f7f7f7;
              z-index: -1;
            }
          }

          .points-description {
            max-width: 260px;
            margin: 0;
            margin-top: 8px;
          }

          .points {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
            background: $darkorange;
            border-radius: 12px;
            padding: 4px 8px 2px;
            width: fit-content;
            margin: 0 auto;
            margin-top: 8px;
          }
        }
      }
    }
  }

  &.glo-birthday {
    .intro {
      .mobile {
        margin-top: 24px;

        & > * {
          max-width: 295px;
          margin-inline: auto;
        }

        .title h3 {
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #2a2c2c;
          margin: 0;
        }

        .sub p {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #2a2c2c;
          margin: 8px 0 0;
        }
      }

      @include XGA {
        .title {
          margin-bottom: 24px;

          &:nth-of-type(4) {
            margin-top: 24px;
            max-width: 450px;
            margin: 24px auto 0;

            h3 {
              font-weight: 700;
              font-size: 15px;
              line-height: 20px;
              text-align: center;
              color: #2a2c2c;
            }
          }
        }

        .sub {
          margin-top: 0;

          &:nth-of-type(5) {
            margin-top: 8px;
            padding: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }

    .rewards {
      .title {
        h2 {
          @include XGA {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;
          }
        }
      }

      .card {
        overflow: hidden;
      }

      .rewards-wrapper {
        padding: 16px 0;
        position: relative;
        z-index: 1;

        &::before {
          width: 100vw;
          height: 100%;
          background-color: #f7f7f7;
          z-index: -1;
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0;
        }

        .reward-wrapper {
          display: flex !important;
          margin-bottom: 16px !important;
          width: 295px !important;
          height: initial !important;
          margin-inline: initial !important;
          border: 1px solid #e5e5e5 !important;
          border-radius: 12px !important;
          margin-top: 8px !important;
          background-color: white !important;

          @include XGA {
            width: 100% !important;
          }

          .content-reward {
            @include XGA {
              width: 100%;

              p:first-of-type {
                margin-top: 0;
              }

              p:last-of-type {
                margin-top: 2px;
              }
            }
          }
        }
      }
    }

    .card.form.instruction {
      @include XGA {
        .title {
          @include XGA {
            margin-bottom: 0;
          }

          h3 {
            @include XGA {
              text-align: left !important;
              font-weight: 700 !important;
              font-size: 24px !important;
              line-height: 32px !important;
              color: #000000 !important;
              margin-bottom: 0 !important;
            }
          }

          & + p {
            text-align: left !important;
            margin-top: 8px !important;
            font-weight: 500 !important;
            font-size: 15px !important;
            line-height: 20px !important;
          }
        }
      }
    }

    .user-info {
      @include XGA {
        &::before {
          height: calc(100% - 50px) !important;
        }
      }

      .button-wrapper {
        grid-column: 2/3 !important;

        @include XGA {
          height: fit-content;
          margin: 0 !important;
          align-self: center !important;
          justify-self: flex-end !important;
        }
      }
    }

    .bottom {
      width: fit-content;
      margin-inline: auto;
      margin-top: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #000000;

      @include XGA {
        grid-column: 1/2;
        grid-row: 2/3;
        width: fit-content !important;
        height: fit-content;
        margin: 0 !important;
        align-self: center !important;
        justify-self: flex-start !important;
      }

      a {
        color: #ed8002;
      }
    }

    .summary.card {
      .title {
        margin-top: 0;
      }

      @include XGA {
        overflow: hidden;
      }

      .points-wrapper-mobile {
        padding: 24px 0px;
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        margin-inline: 12px;

        @include XGA {
          background-color: #f7f7f7;
          border: none;
          position: relative;
          z-index: 1;
          margin-inline: 0;

          &::before {
            content: "";
            position: absolute;
            width: 100vw;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #f7f7f7;
            z-index: -1;
          }
        }

        img {
          margin-inline: auto;
          display: block;
        }

        p.points-description {
          margin-top: 12px;
        }

        .points {
          margin-top: 12px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          padding: 3px 8px 1px;
          width: fit-content;
          margin-inline: auto;
        }
      }
    }
  }

  &.subscription-info {
    .card {
      .image {
        @include XGA {
          min-height: 600px;
        }
      }
    }

    .content {
      @include XGA {
        width: 830px;
        padding-top: 16px;
        padding-bottom: 24px;
      }

      @include HD {
        width: 900px;
      }

      @include XGA {
        padding: 40px 50px;
      }

      .embeded {
        color: #ed8002;
        text-decoration: underline;
        font-weight: 600;
      }

      h2 {
        max-width: 255px;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        margin: 0 auto;
        margin-top: 24px;
        text-align: center;

        @include XGA {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          max-width: 604px;
          width: 100%;
        }
      }

      p {
        max-width: 295px;
        width: 100%;
        color: #2a2c2c;

        @include XGA {
          color: #868686;
          max-width: 553px;
        }
      }

      .wrapper {
        @include XGA {
          background: #f7f7f7;
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }

      .button-wrapper {
        @include XGA {
          margin-top: 24px;
          margin-bottom: 0px;
        }

        &.multiple {
          width: fit-content;
          display: flex;
          flex-direction: column;
          gap: 24px;

          @include XGA {
            flex-direction: row;
          }
        }
      }

      .how-to {
        img {
          margin: 0 auto;
          display: block;
        }

        p {
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #000000;
          margin-top: 4px;
        }
      }

      .box {
        padding-top: 24px;
        padding-bottom: 24px;
        background: #f7f7f7;

        @include XGA {
          padding: 0;
        }

        & > * {
          margin-left: auto;
          margin-right: auto;
          display: block;
          text-align: center;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.second {
          .grid-wrapper {
            @include XGA {
              display: grid;
              grid-template-columns: repeat(3, minmax(0, 1fr));
              column-gap: 24px;
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          a {
            color: $lightorange;
          }

          h2 {
            font-size: 15px;
            line-height: 20px;
          }

          div {
            @include XGA {
              & > * {
                max-width: 100%;
                min-width: unset;
              }
            }

            img {
              margin-top: 16px;
            }

            h3 {
              font-size: 12px;
              line-height: 16px;
              margin-top: 4px;
              margin-bottom: 4px;
            }

            p {
              max-width: 300px;
              margin-top: 4px;
              margin-bottom: 0;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        h2,
        p {
          max-width: 292px;
          margin-top: 8px;

          @include XGA {
            font-size: 12px;
            line-height: 16px;
            color: #2a2c2c;
            max-width: 473px;
          }
        }

        p {
          margin-bottom: 0;

          @include XGA {
            font-size: 12px;
            line-height: 16px;
            margin-top: 0;
          }
        }

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 2px;
          width: 24px !important;
          height: 24px !important;
          border-radius: 50%;
          font-weight: 700;
          font-size: 15px;
          border: 1px solid $lightorange;
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          grid-column: 1/2;
          opacity: 1;
          min-width: initial !important;
          max-width: initial !important;
          margin-top: 16px;

          @include XGA {
            display: none;
          }

          &:first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
  }

  &.game {
    background-color: white;
    width: 100%;
    padding: 0 24px;
    padding-bottom: 32px;
    margin: 0;

    @include XGA {
      padding: 0 64px;
    }
  }
}

.game {
  white-space: pre-wrap;

  button {
    background: none;
    cursor: pointer;
    outline: none;
    border: none;
  }

  .button-next {
    border: 1px solid $lightorange;
    border-radius: 50%;
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding-right: 3px;
    background-color: white;

    @include XGA {
      width: 40px;
      height: 40px;
    }

    @include HD {
      width: 50px;
      height: 50px;
      border-width: 2px;
    }

    svg {
      width: 100%;
    }

    &.disabled {
      border-color: #c6c6c5;

      svg {
        path {
          stroke: #c6c6c5;
        }
      }
    }
  }

  .question {
    max-width: 279px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }

  .answers-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 287px;
    margin-left: auto;
    margin-right: auto;

    .answer {
      margin-top: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #000000;
      border-radius: 24px;
      padding-top: 11px;
      padding-bottom: 9px;
      height: 60px;

      &.img {
        height: unset;
      }

      p {
        width: 100%;
        font-size: 12px;
        margin: 4px 0;
      }
    }

    .selected {
      color: $lightorange;
      border-color: $lightorange;
      position: relative;
      border-radius: 6px;
    }

    .selected::before {
      content: "";
      position: absolute;
      top: 0px; // border: 2px + offset: 1px
      right: 0px; // border: 2px + offset: 1px
      bottom: 0px; // border: 2px + offset: 1px
      left: 0px; // border: 2px + offset: 1px
      border-radius: 12px; // border—radius: 6px + offset: 1px
    }

    &.optin-consents {
      text-align: left;
      font-size: 12px;
      display: block !important;
      padding: 24px 0;

      @include XGA {
        padding: 24px 0 !important;
      }

      .phone-number {
        margin-top: 24px;
        position: relative;

        @include vHD {
          margin-top: 0;
        }

        &::before {
          content: "+48";
          position: absolute;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          width: 20px;
          height: 16px;
          left: 0;
          top: 25px;
        }

        label {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          display: block;
        }

        input {
          border: none;
          border-bottom: 1px solid #c6c6c5;
          width: 100%;
          padding: 4px 0;
          padding-left: 34px;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          margin-top: 2px;
          background: transparent;
          outline: none;

          @include XGA {
            max-width: 325px;
          }

          &:focus {
            border-color: #ed8002;
          }
        }
      }

      .button-next {
        width: 100% !important;
      }

      .otp-flex {
        @include vHD {
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-top: 24px;
        }
      }

      .phone-outer {
        max-width: 300px;
      }

      .otp-outer {
        max-width: 242px;
      }

      .code-inputs {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        column-gap: 16px;
        margin: 20px 0;

        .code-input {
          input {
            border: $lightorange solid 1px;
            border-radius: 12px;
            text-align: center;
            width: 48px;
            height: 64px;
            font-size: 24px;
            line-height: 64px;
            font-weight: 700;
          }
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        margin: 0;
        color: #000;
        width: 100%;

        &.info {
          margin-bottom: 16px;
        }
      }

      .checkbox {
        position: relative;
        min-height: 24px;
        margin-top: 8px;

        label {
          position: relative;
          display: block;
          padding-left: 60px;
          z-index: 2;
          user-select: none;
          cursor: pointer;
          min-height: 24px;
        }

        .switch {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  &.moment-range {
    .mobile {
      @include XGA {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: block;
        .button-wrapper {
          display: flex;
        }
      }
    }

    .button-wrapper {
      @include XGA {
        margin-right: 0;
      }
      .button {
        display: flex;
        align-items: center;
        gap: 10px;
        &.triger-shake {
          animation: shake 0.82s $QuadEaseInOut both;
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;
          perspective: 1000px;
        }
      }
    }

    .desktop-background {
      @include XGA {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        min-height: 707px;
        margin-top: 40px;
        margin-bottom: 24px;
      }

      &.find-difference {
        h3.question-title {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;

          @include XGA {
            max-width: 624px;
            margin-inline: auto;
          }
        }

        .answers-wrapper {
          display: grid;
          gap: 16px;
          background-color: #f7f7f7;
          margin-inline: auto;
          width: 100%;
          max-width: 524px;

          @include XGA {
            border-radius: 0 12px 12px 0;
            padding-top: 40px;
            padding-bottom: 40px;
            justify-content: center;
            width: fit-content;
            max-width: initial;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
          }

          .answer {
            border: 2px solid #2a2c2c;
            border-radius: 24px;
            padding: 11px 24px 9px;
            max-width: 242px;
            width: 100%;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            background-color: #f7f7f7;

            @include XGA {
              width: 242px;
            }

            &.selected {
              border-color: #ed8002;
              color: #ed8002;
            }
          }
        }

        .content.upper-desktop {
          margin-top: 24px;

          @include XGA {
            width: 100%;
            padding: 32px 0;
          }

          .grid-wrapper {
            @include XGA {
              display: grid;
              grid-template-columns: 416px 416px;
              margin-inline: auto;
              gap: 32px;
            }
          }

          .image {
            aspect-ratio: initial;
            position: relative;
            max-width: 420px;

            @include XGA {
              display: block;
            }

            .clip-path.selected {
              background-color: #ed8002;
            }

            @media (max-width: 420px) {
              margin-left: -24px;
              width: calc(100% + 48px);
            }

            @media (min-width: 420px) {
              margin-left: calc(-24px + ((100vw - 420px) / 2));
            }

            @include XGA {
              margin-left: initial;
            }
          }
        }

        .button-wrapper {
          width: 100%;

          @include XGA {
            margin: 0 auto;
            grid-column: 1/3;
            max-width: 304px;
          }

          .button {
            width: 100%;
            max-width: 327px;
            margin-inline: auto;
            margin-top: 0;
            display: block;
          }
        }
      }
    }

    .content {
      .content-header-title {
        text-align: center;
        @include XGA {
          text-align: left;
        }
      }
      img {
        display: none;
      }
      @include XGA {
        display: flex;
        background-color: #f7f7f7;
        border-radius: 0;
        padding: 70px 80px 120px 80px;
        gap: 30px;
        justify-content: space-between;
        img {
          display: block;
          width: 100%;
          height: 100%;
          max-width: 600px;
        }
      }
      h1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        @include XGA {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #000000;
        }
      }
      .progress {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 16px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          padding-top: 4px;
          width: 8px;
          height: 8px;
          border-radius: 24px;
          font-weight: 700;
          font-size: 15px;
          background-color: #f7f7f7;
          border: 1px solid #f7f7f7;
          color: $lightorange;
          @include XGA {
            background-color: #fff;
            border: 1px solid #fff;
          }
        }

        .active {
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          width: 24px;
          height: 8px;
        }
      }

      .question {
        border-radius: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
        max-width: 500px;
        display: grid;
        position: relative;
        z-index: 2;
        background-color: #f7f7f7;
        padding: 10px 20px;
        @include XGA {
          margin-top: 0;
          margin-bottom: 24px;
          padding: 0px;
        }

        &-title {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          max-width: 400px;
          color: #000000;
          text-align: start;
          @include XGA {
            max-width: none;
            background: #ffffff;
            border-radius: 12px;
            padding: 10px 20px;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
          }
        }

        &-sub {
          margin: 0;
          text-align: left;
          margin-top: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        .flex-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .button-next {
            min-width: 32px;
            min-height: 32px;
            margin-left: 16px;
          }
        }
      }

      .question-image {
        border-radius: 12px;

        .button-next {
          margin-top: 16px;

          &.disabled {
            background-color: transparent;
          }

          @include XGA {
            padding-top: 0;
          }
        }
      }

      .answers-wrapper {
        margin: 0;
        display: flex;
        flex-direction: row;
        width: initial;
        gap: 24px;
        padding: 12px 0 12px;
        width: fit-content;
        margin-inline: auto;

        .answer {
          border: none;
          margin: 0;
          height: fit-content;
          align-items: center;
          padding: 0;
          background-color: white;
          border-radius: 12px;

          &.selected {
            .inner {
              border-color: #ed8002;

              .sub {
                background-color: #ed8002;
                padding-top: 11px;
              }
            }
          }

          .inner {
            max-width: 151px;
            width: 100%;
            border: 1px solid #2a2c2c;
            border-radius: 12px;
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr;

            & > * {
              grid-row: 1/2;
              grid-column: 1/2;
            }

            img {
              width: 100%;
              display: block;
            }

            .sub {
              max-width: 151px;
              padding: 5px 0 4px;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              color: white;
              background-color: #2a2c2c;
              height: fit-content;
              align-self: flex-end;
            }
          }

          input[type="radio"] {
            min-height: 24px;
            min-width: 24px;
            appearance: none;
            border: 1px solid black;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            &:checked {
              border-color: #ed8002;

              &:after {
                content: "";
                position: relative;
                width: 16px;
                height: 16px;
                background-color: #ed8002;
                display: block;
                border-radius: 50%;
                top: 3px;
                left: 3px;
              }
            }
          }

          &-content {
            color: #2a2c2c;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            margin-left: 12px;
            cursor: pointer;

            &.selected {
              color: #ed8002;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        @include XGA {
          margin-inline: 0;
          & + .button-wrapper {
            margin-top: 32px;
            margin-bottom: 0;
          }
        }
      }

      .image {
        width: calc(100% + 48px);
        margin-left: -24px;
        aspect-ratio: 1/1;
        position: relative;
        z-index: 1;

        @include XGA {
          display: none;
        }

        img {
          display: block;
          width: 100%;
        }

        .clip-path,
        .inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;

          &.selected {
            // background: rgb(238, 72, 35);
            // background: linear-gradient(133deg, rgba(238, 72, 35, 1) 0%, rgba(238, 79, 34, 1) 10%, rgba(240, 96, 34, 1) 20%, rgba(243, 120, 32, 1) 30%, rgba(247, 149, 29, 1) 40%, rgba(250, 166, 26, 1) 50%, rgba(238, 72, 35, 1) 60%, rgba(238, 79, 34, 1) 70%, rgba(240, 96, 34, 1) 80%, rgba(243, 120, 32, 1) 90%, rgba(250, 166, 26, 1) 100%);
            background-color: white;
          }
        }

        .inner {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          pointer-events: none;
          z-index: 4;
        }

        & + .button {
          margin-top: 32px;
          margin-inline: auto;
          display: block;
        }
      }
    }

    @include XGA {
      .upper-desktop {
        padding: 16px 16px 16px 32px;
        display: flex;
        width: 664px;

        .question-image {
          margin-bottom: 0;

          .flex-wrapper {
            gap: 77px;
          }
        }
      }
    }

    .desktop-path {
      @include XGA {
        width: calc(min(calc(100% + 128px), 1920px));
        margin-left: -64px;
        border-radius: 0;
        aspect-ratio: 16/7;
        min-height: initial;

        .clip-path,
        .inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;

          &.selected {
            // background: rgb(238, 72, 35);
            // background: linear-gradient(133deg, rgba(238, 72, 35, 1) 0%, rgba(238, 79, 34, 1) 10%, rgba(240, 96, 34, 1) 20%, rgba(243, 120, 32, 1) 30%, rgba(247, 149, 29, 1) 40%, rgba(250, 166, 26, 1) 50%, rgba(238, 72, 35, 1) 60%, rgba(238, 79, 34, 1) 70%, rgba(240, 96, 34, 1) 80%, rgba(243, 120, 32, 1) 90%, rgba(250, 166, 26, 1) 100%);
            background-color: white;
          }
        }

        .inner {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          pointer-events: none;
          z-index: 4;
        }
      }
    }
  }

  &.tiles-quiz {
    .mobile {
      @include XGA {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: block;
        .button-wrapper {
          display: flex;
        }
      }
    }

    .button-wrapper {
      @include XGA {
        margin-right: 0;
      }
      .button {
        display: flex;
        align-items: center;
        gap: 10px;
        &.triger-shake {
          animation: shake 0.82s $QuadEaseInOut both;
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;
          perspective: 1000px;
        }
      }
    }

    .desktop-background {
      @include XGA {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        min-height: 707px;
        margin-top: 40px;
        margin-bottom: 24px;
      }

      &.find-difference {
        h3.question-title {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;

          @include XGA {
            max-width: 624px;
            margin-inline: auto;
          }
        }

        .answers-wrapper {
          display: grid;
          gap: 16px;
          background-color: #f7f7f7;
          margin-inline: auto;
          width: 100%;
          max-width: 524px;

          @include XGA {
            border-radius: 0 12px 12px 0;
            padding-top: 40px;
            padding-bottom: 40px;
            justify-content: center;
            width: fit-content;
            max-width: initial;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
          }

          .answer {
            border: 2px solid #2a2c2c;
            border-radius: 24px;
            padding: 11px 24px 9px;
            max-width: 242px;
            width: 100%;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            background-color: #f7f7f7;

            @include XGA {
              width: 242px;
            }

            &.selected {
              border-color: #ed8002;
              color: #ed8002;
            }
          }
        }

        .content.upper-desktop {
          margin-top: 24px;

          @include XGA {
            width: 100%;
            padding: 32px 0;
          }

          .grid-wrapper {
            @include XGA {
              display: grid;
              grid-template-columns: 416px 416px;
              margin-inline: auto;
              gap: 32px;
            }
          }

          .image {
            aspect-ratio: initial;
            position: relative;
            max-width: 420px;

            @include XGA {
              display: block;
            }

            .clip-path.selected {
              background-color: #ed8002;
            }

            @media (max-width: 420px) {
              margin-left: -24px;
              width: calc(100% + 48px);
            }

            @media (min-width: 420px) {
              margin-left: calc(-24px + ((100vw - 420px) / 2));
            }

            @include XGA {
              margin-left: initial;
            }
          }
        }

        .button-wrapper {
          width: 100%;

          @include XGA {
            margin: 0 auto;
            grid-column: 1/3;
            max-width: 304px;
          }

          .button {
            width: 100%;
            max-width: 327px;
            margin-inline: auto;
            margin-top: 0;
            display: block;
          }
        }
      }
    }

    .content {
      .content-header-title {
        text-align: center;
        @include XGA {
          text-align: left;
        }
      }
      img {
        display: none;
      }
      @include XGA {
        display: flex;
        background-color: #f7f7f7;
        border-radius: 0;
        padding: 70px 80px 120px 80px;
        gap: 30px;
        justify-content: space-between;
        img {
          display: block;
          width: 100%;
          height: 100%;
          max-width: 356px;
        }
      }
      @include HD {
        img {
          max-width: 468px;
        }
      }
      @include QHD {
        img {
          max-width: 600px;
        }
      }
      h1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        @include XGA {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #000000;
        }
      }
      .progress {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 16px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          padding-top: 4px;
          width: 8px;
          height: 8px;
          border-radius: 24px;
          font-weight: 700;
          font-size: 15px;
          background-color: #f7f7f7;
          border: 1px solid #f7f7f7;
          color: $lightorange;
          @include XGA {
            background-color: #fff;
            border: 1px solid #fff;
          }
        }

        .active {
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          width: 24px;
          height: 8px;
        }
      }

      .question {
        border-radius: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
        max-width: 500px;
        display: grid;
        position: relative;
        z-index: 2;
        background-color: #f7f7f7;
        padding: 10px 20px;
        @include XGA {
          margin-top: 0;
          margin-bottom: 24px;
          padding: 0px;
        }

        &-title {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          max-width: 400px;
          color: #000000;
          text-align: start;
          @include XGA {
            max-width: none;
            background: #ffffff;
            border-radius: 12px;
            padding: 10px 20px;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
          }
        }

        &-sub {
          margin: 0;
          text-align: left;
          margin-top: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        .flex-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .button-next {
            min-width: 32px;
            min-height: 32px;
            margin-left: 16px;
          }
        }
      }

      .question-image {
        border-radius: 12px;

        .button-next {
          margin-top: 16px;

          &.disabled {
            background-color: transparent;
          }

          @include XGA {
            padding-top: 0;
          }
        }
      }
      .answers-holder {
        display: flex;
        flex-direction: column;

        @include XGA {
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
        }

        @include UXGA {
          justify-content: space-around;
        }

        .pack {
          width: 108px;
          display: block;
          margin: 0 auto;
          margin-bottom: -78px;
          @include XGA {
            padding-right: 8px;
            margin: 0;
          }
          @include HD {
            padding-right: 0;
          }
        }

        .answers-wrapper {
          margin: 0;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          width: initial;
          width: fit-content;
          margin-inline: auto;

          @include XGA {
            grid-column-gap: 30px;
            margin-bottom: 90px;
          }

          .answer {
            border: none;
            margin: 0;
            height: fit-content;
            align-items: center;
            padding: 0;
            background-color: white;
            border-radius: 12px;

            &.selected {
              .inner {
                img {
                  outline: solid 3px #2a2c2c;
                  outline-offset: -3px;
                }
              }
            }

            .inner {
              max-width: 151px;
              width: 100%;
              // border: 1px solid #ff4f4f;
              overflow: hidden;
              display: grid;
              grid-template-columns: 1fr;

              & > * {
                grid-row: 1/2;
                grid-column: 1/2;
              }

              img {
                outline: solid 3px #f7f7f7;
                outline-offset: -3px;
                width: 100%;
                display: block;
                border-radius: 12px;
                @include XGA {
                  outline: solid 3px transparent;
                }
              }
            }

            input[type="radio"] {
              min-height: 24px;
              min-width: 24px;
              appearance: none;
              border: 1px solid black;
              border-radius: 50%;
              position: relative;
              cursor: pointer;

              &:checked {
                border-color: #ed8002;

                &:after {
                  content: "";
                  position: relative;
                  width: 16px;
                  height: 16px;
                  background-color: #ed8002;
                  display: block;
                  border-radius: 50%;
                  top: 3px;
                  left: 3px;
                }
              }
            }

            &-content {
              color: #2a2c2c;
              font-weight: 900;
              font-size: 14px;
              line-height: 20px;
              margin-left: 12px;
              cursor: pointer;

              &.selected {
                color: #ed8002;
              }
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          @include XGA {
            margin-inline: 0;
            & + .button-wrapper {
              margin-top: 32px;
              margin-bottom: 0;
            }
          }
        }
      }

      .image {
        width: calc(100% + 48px);
        margin-left: -24px;
        aspect-ratio: 1/1;
        position: relative;
        z-index: 1;

        @include XGA {
          display: none;
        }

        img {
          display: block;
          width: 100%;
        }

        .clip-path,
        .inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;

          &.selected {
            // background: rgb(238, 72, 35);
            // background: linear-gradient(133deg, rgba(238, 72, 35, 1) 0%, rgba(238, 79, 34, 1) 10%, rgba(240, 96, 34, 1) 20%, rgba(243, 120, 32, 1) 30%, rgba(247, 149, 29, 1) 40%, rgba(250, 166, 26, 1) 50%, rgba(238, 72, 35, 1) 60%, rgba(238, 79, 34, 1) 70%, rgba(240, 96, 34, 1) 80%, rgba(243, 120, 32, 1) 90%, rgba(250, 166, 26, 1) 100%);
            background-color: white;
          }
        }

        .inner {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          pointer-events: none;
          z-index: 4;
        }

        & + .button {
          margin-top: 32px;
          margin-inline: auto;
          display: block;
        }
      }
    }

    @include XGA {
      .upper-desktop {
        padding: 16px 16px 16px 32px;
        display: flex;
        width: 664px;

        .question-image {
          margin-bottom: 0;

          .flex-wrapper {
            gap: 77px;
          }
        }
      }
    }

    .desktop-path {
      @include XGA {
        width: calc(min(calc(100% + 128px), 1920px));
        margin-left: -64px;
        border-radius: 0;
        aspect-ratio: 16/7;
        min-height: initial;

        .clip-path,
        .inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;

          &.selected {
            // background: rgb(238, 72, 35);
            // background: linear-gradient(133deg, rgba(238, 72, 35, 1) 0%, rgba(238, 79, 34, 1) 10%, rgba(240, 96, 34, 1) 20%, rgba(243, 120, 32, 1) 30%, rgba(247, 149, 29, 1) 40%, rgba(250, 166, 26, 1) 50%, rgba(238, 72, 35, 1) 60%, rgba(238, 79, 34, 1) 70%, rgba(240, 96, 34, 1) 80%, rgba(243, 120, 32, 1) 90%, rgba(250, 166, 26, 1) 100%);
            background-color: white;
          }
        }

        .inner {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          pointer-events: none;
          z-index: 4;
        }
      }
    }
    .button-wrapper {
      width: auto;
      .button {
        width: 100%;
        display: block;
      }
      @include XGA {
        width: min-content;
        .button {
          display: flex;
        }
      }
    }
  }

  .true-false {
    flex-direction: row;
    justify-content: center;
    gap: 24px;

    &.image {
      gap: 8px;
      padding-left: 16px;
      padding-right: 16px;

      .img {
        width: 100%;
        padding: 0;
        border: 1px solid #c6c6c5;
        padding: 1px;

        img {
          width: 100%;
          display: block;
          margin: 0 auto;
        }
      }

      .selected {
        border-width: 2px;
        border-color: $lightorange;
        padding: 0;
      }
    }

    .answer.img {
      width: unset !important;
    }

    button {
      padding: 11px 24px 9px;
      width: 100px !important;
    }
  }

  .subscription {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    width: 100%;
    column-gap: 15px;

    @include XGA {
      column-gap: 24px;
      margin-bottom: 20px;
    }

    &-text {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      grid-column: 1/4;
      text-align: center;
      margin-bottom: 0;

      @include XGA {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
      }
    }

    .answer {
      padding: 11px 24px 9px;
    }
  }

  .desktop-wrapper {
    @include XGA {
      display: grid;
      grid-template-columns: 40% 60%;
      border-radius: 12px;
      box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
      margin: 40px 64px;

      .card {
        margin-top: 0px;
        min-height: initial;
        padding-top: 0;
        margin-top: 20px;

        @include HD {
          margin-top: 40px;
        }

        @include UXGA {
          margin-top: 50px;
        }

        @include FHD {
          margin-top: 80px;
        }
      }

      img {
        width: 100%;
        border-radius: 12px 0 0 12px;
      }

      .img-desktop {
        aspect-ratio: 1/1;
        height: 100%;
        object-fit: cover;
      }

      .question {
        font-weight: 700;
        max-width: 90%;

        @include HD {
          max-width: 594px;
          font-size: 24px;
          line-height: 32px;
        }
      }

      .answers-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: initial;
        column-gap: 24px;
        row-gap: 16px;
        max-width: 90%;

        @include UXGA {
          max-width: 645px;
        }

        .answer {
          margin: 0;
          padding: 11px 25px 9px;
          width: initial;
          height: 62px;

          @include XGA {
            padding: 2px 15px 1px;
          }
        }

        &.true-false {
          width: fit-content;

          .answer {
            width: fit-content;
          }
        }

        &.image {
          height: min-content;
          min-height: unset;

          .answer {
            max-width: 160px;
            height: min-content;
          }
        }
      }

      .game-content {
        background-color: white;

        @include XGA {
          border-radius: 0 12px 12px 0;
        }

        @include HD {
          display: flex;
          flex-direction: column;
        }
      }

      .button-wrapper {
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 20px;

        @include HD {
          margin-bottom: 40px;
          margin-top: auto;
          display: flex;
        }
      }
    }
  }

  .agreements {
    p {
      font-size: 10px;
    }
  }

  .switches {
    .wrapper {
      display: grid;
      grid-template-columns: auto auto;
      row-gap: 8px;
      column-gap: 16px;

      p {
        margin: 0;
        opacity: 0.8;
      }
    }

    input {
      align-self: flex-start;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(237, 128, 2, 0.1);
      transition: 0.4s;
      border-radius: 34px;
      border: 1px solid $lightorange;
      border-radius: 12px;

      &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 2px;
        background-color: $lightorange;
        transition: 0.2s;
        border-radius: 50%;
      }
    }

    input:checked + .slider {
      background-color: $lightorange;

      &:before {
        background-color: #fdf2e6;
      }
    }

    input:checked + .slider:before {
      transform: translateX(16px);
    }
  }

  .link {
    color: $lightorange;
    font-weight: 600;
    font-size: 10px;
  }

  .pick-color {
    .desktop {
      display: none;

      @include XGA {
        display: block;
      }
    }

    input[type="range"] {
      height: min-content;
      -webkit-appearance: none;
      margin: 0;
      width: 100%;
      --range-background: rgba(128, 128, 128, 0.5);
    }

    input[type="range"]:focus {
      outline: none;
      box-shadow: 0px 0px 7px 2px var(--range-background);
      // animation: breathe 1.5s ease-in-out infinite alternate;

      @keyframes breathe {
        from {
          box-shadow: 0px 0px 7px 3px var(--range-background);
        }

        to {
          box-shadow: 0px 0px 4px 0px var(--range-background);
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      input[type="range"]:focus {
        outline: none;
        box-shadow: 0px 0px 7px 2px var(--range-background);
      }
    }

    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: var(--color);
      border-radius: 1px;
      border: 0px solid #000000;
    }

    input[type="range"]::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000000;
      height: 11px;
      width: 11px;
      border-radius: 25px;
      background: var(--color);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -4px;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
      background: var(--color);
    }

    input[type="range"]::-moz-range-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: var(--color);
      border-radius: 1px;
      border: 0px solid #000000;
    }

    input[type="range"]::-moz-range-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 1px solid var(--color);
      height: 18px;
      width: 18px;
      border-radius: 25px;
      background: var(--color);
      cursor: pointer;
    }

    input[type="range"]::-ms-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    input[type="range"]::-ms-fill-lower {
      background: var(--color);
      border: 0px solid #000000;
      border-radius: 2px;
      box-shadow: 0px 0px 0px #000000;
    }

    input[type="range"]::-ms-fill-upper {
      background: var(--color);
      border: 0px solid #000000;
      border-radius: 2px;
      box-shadow: 0px 0px 0px #000000;
    }

    input[type="range"]::-ms-thumb {
      margin-top: 1px;
      box-shadow: 0px 0px 0px #000000;
      border: 1px solid var(--color);
      height: 18px;
      width: 18px;
      border-radius: 25px;
      background: #a1d0ff;
      cursor: pointer;
    }

    input[type="range"]:focus::-ms-fill-lower {
      background: var(--color);
    }

    input[type="range"]:focus::-ms-fill-upper {
      background: var(--color);
    }

    .red {
      --color: #ff0000;
    }

    .green {
      --color: #00ff00;
    }

    .blue {
      --color: #0000ff;
    }

    .sliders {
      padding-inline: 16px 24px;
      grid-column: 1/3;

      & > div {
        display: grid;
        grid-template-columns: 16px auto;
        align-items: center;
        gap: 16px;
        margin-top: 48px;

        @include XGA {
          margin-top: 32px;
        }

        .dot {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 16px;
          background-color: var(--color);
          margin: 0;
          color: white;
          display: grid;
          place-items: center;
          padding-top: 1px;
          font-size: 14px;
        }

        .range {
          position: relative;
        }

        .value {
          position: absolute;
          font-weight: 700;
          font-size: 11px;
          line-height: 12px;
          top: -5px;
          width: min-content;
          transform: translateX(-50%);
          pointer-events: none;
          user-select: none;
        }
      }
    }

    .top {
      display: grid;
      grid-template-columns: 50% 50%;
      background: #f7f7f7;
      padding: 16px 0 8px;
      margin-top: 16px;
      place-items: center;

      h4 {
        margin: 0;
        margin-top: 8px;
        max-width: 94px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        align-self: flex-start;
      }

      div {
        width: 50%;
        border-radius: 50%;
        background-color: white;
        width: 120px;
        height: 120px;
        display: grid;
        place-items: center;

        img,
        svg {
          max-height: 78px;
        }
      }

      .result {
        --device-color: rgb(128, 128, 128);
        // --panel-color: rgb(0, 0, 0);

        svg {
          path {
            fill: var(--device-color);
          }
        }

        .panel {
          & > path:first-child {
            fill: #3a3a3a;
          }

          & > path:last-child {
            fill: var(--device-color);
          }
        }
      }

      @include XGA {
        display: flex;
        width: min-content;
        flex-direction: column;
        background-color: white;

        .result {
          background-color: #f7f7f7;
          width: 144px;
          height: 144px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h4 {
            margin: 0;
            margin-top: 12px;
            display: block;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-inline: auto;
          }
        }

        .source {
          display: none;
          background-color: #f7f7f7;
        }

        h4 {
          display: none;
        }
      }
    }

    @include XGA {
      display: grid;
      grid-template-columns: min-content auto;
      column-gap: 7%;
      align-items: center;
      margin-inline: 5%;
      border: 1px solid #e5e5e5;
      border-radius: 12px;
      margin-top: 32px;
      padding: 32px 15px;

      .top {
        grid-column: 1/2;
        margin: 0;
        padding: 0;
      }

      .sliders {
        grid-column: 2/3;
        margin: 0;
        padding: 0;

        & > div:first-child {
          margin-top: 0;
        }
      }
    }

    @include HD {
      padding: 32px 50px 32px 40px;
      column-gap: 40px;
      margin-bottom: 40px;
      margin-inline: auto;
      max-width: calc(min(80%, 600px));
    }

    @include UXGA {
      max-width: calc(min(80%, 770px));
      margin-top: 80px;
      column-gap: 12%;
    }
  }

  .poll {
    padding-bottom: 0;

    @include XGA {
      &.internal {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .button-wrapper.desktop {
        .button-next {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .mobile {
      @include XGA {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: initial;
      }
    }

    .grey {
      background: #f7f7f7;
      width: 100%;
      max-width: initial;
      border-radius: 12px 12px 0px 0px;
      padding: 24px 24px 16px;

      .question {
        margin: 0;
        margin-top: 16px;
        text-align: left;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
      }

      .sub {
        margin: 0;
        margin-top: 8px;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }
    }

    .answers-wrapper {
      width: 100%;
      max-width: initial;
      padding: 24px;
      margin: 0;

      .custom-input {
        margin-left: 36px;
      }

      .custom-input {
        label {
          display: block;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
        }

        input {
          border: 0;
          border-bottom: 1px solid #000000;
          margin-top: 3px;
          width: 100%;
        }
      }

      .range {
        flex-direction: column;
      }

      .answer {
        border: 0;
        margin: 0 0 24px 0;
        display: flex;
        align-items: center;
        padding: 0;
        height: fit-content;

        p {
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: black;
          width: fit-content;
          min-width: initial;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        input[type="radio"] {
          min-height: 24px;
          min-width: 24px;
          appearance: none;
          border: 1px solid black;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          background-color: white;

          &:checked {
            border-color: #ed8002;

            &:after {
              content: "";
              position: absolute;
              width: 16px;
              height: 16px;
              background-color: #ed8002;
              display: block;
              border-radius: 50%;
              top: 3px;
              left: 3px;
            }
          }
        }

        input[type="range"] {
          -webkit-appearance: none;
          margin: 10px 0;
          width: 100%;
        }

        input[type="range"]:focus {
          outline: none;
        }

        input[type="range"]::-webkit-slider-runnable-track {
          width: 100%;
          height: 7px;
          cursor: pointer;
          animate: 0.2s;
          box-shadow: 0px 0px 0px #000000;
          background: #e5e5e5;
          border-radius: 7px;
          border: 0px solid #000000;
        }

        input[type="range"]::-webkit-slider-thumb {
          box-shadow: 0px 0px 1px #000000;
          border: 0px solid #000000;
          height: 20px;
          width: 20px;
          border-radius: 50px;
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -6.5px;
        }

        input[type="range"]:focus::-webkit-slider-runnable-track {
          background: #e5e5e5;
        }

        input[type="range"]::-moz-range-track {
          width: 100%;
          height: 7px;
          cursor: pointer;
          animate: 0.2s;
          box-shadow: 0px 0px 0px #000000;
          background: #e5e5e5;
          border-radius: 7px;
          border: 0px solid #000000;
        }

        input[type="range"]::-moz-range-thumb {
          box-shadow: 0px 0px 1px #000000;
          border: 0px solid #000000;
          height: 20px;
          width: 20px;
          border-radius: 50px;
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          cursor: pointer;
        }

        input[type="range"]::-ms-track {
          width: 100%;
          height: 7px;
          cursor: pointer;
          animate: 0.2s;
          background: transparent;
          border-color: transparent;
          color: transparent;
        }

        input[type="range"]::-ms-fill-lower {
          background: #e5e5e5;
          border: 0px solid #000000;
          border-radius: 14px;
          box-shadow: 0px 0px 0px #000000;
        }

        input[type="range"]::-ms-fill-upper {
          background: #e5e5e5;
          border: 0px solid #000000;
          border-radius: 14px;
          box-shadow: 0px 0px 0px #000000;
        }

        input[type="range"]::-ms-thumb {
          box-shadow: 0px 0px 1px #000000;
          border: 0px solid #000000;
          height: 20px;
          width: 20px;
          border-radius: 50px;
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          cursor: pointer;
        }

        input[type="range"]:focus::-ms-fill-lower {
          background: #e5e5e5;
        }

        input[type="range"]:focus::-ms-fill-upper {
          background: #e5e5e5;
        }

        .legend-wrapper {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 10px;

          .legend {
            color: #2a2c2c;
            text-align: center;
            font-weight: 900;
            font-size: 14px;
            line-height: 17px;
            width: 15px;

            &:first-of-type {
              margin-left: 3px;
            }

            &:last-of-type {
              margin-right: 3px;
            }
          }
        }

        &-content {
          color: #2a2c2c;
          font-weight: 900;
          font-size: 14px;
          line-height: 20px;
          margin-left: 12px;
          cursor: pointer;

          &.selected {
            color: #ed8002;
          }
        }
      }
    }

    .desktop-wrapper {
      @include XGA {
        .desktop-background {
          min-height: 558px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .card {
          margin-top: 0;
          border-radius: 0 12px 12px 0;

          .grey {
            border-radius: 0 12px 12px 0;

            @include FHD {
              padding: 40px;
            }

            & > * {
              margin-left: auto;
              margin-right: auto;
              text-align: center;
            }

            .progress {
              width: fit-content;
              margin-left: auto;
              margin-right: auto;
              gap: 12px;
            }

            h2.question {
              width: calc(min(476px, 90%));
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
            }

            p.sub {
              width: calc(min(519px, 90%));
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
            }
          }
        }

        .game {
          height: 100%;
        }

        .answers-wrapper {
          display: flex;
          width: fit-content;
          padding: 24px 20px 20px;
          justify-content: center;
          gap: 16px;
          margin-left: auto;
          margin-right: auto;
          height: 100%;

          @include FHD {
            gap: 30px;
          }

          .answer {
            margin-bottom: 0px;
            width: fit-content;
          }

          .range {
            min-width: 283px;
          }
        }
      }
    }
  }

  &.find-packs {
    .desktop-wrapper {
      @include XGA {
        margin: 20px 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 50px;
        border-radius: 0;
        box-shadow: none;
        background-color: #f7f7f7;
        padding: 50px 60px 120px 60px;
      }
      .desktop-background {
        display: none;
        @include XGA {
          display: block;
          min-height: fit-content;
          margin-top: 20px;
          img {
            border-radius: 0;
          }
        }
      }
    }

    .desktop-wrapper-find {
      @include XGA {
        margin: 20px 0;
        display: flex;
        border-radius: 0;
        box-shadow: none;
        background-color: #f7f7f7;
        padding: 50px 60px 40px 60px;
      }
    }
    .desktop-path {
      @include XGA {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
        border-radius: 0;
        aspect-ratio: 16/7;
        min-height: initial;

        .clip-path,
        .inner-frag {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;

          &.selected {
            background: linear-gradient(
              133deg,
              rgba(238, 72, 35, 1) 0%,
              rgba(238, 79, 34, 1) 10%,
              rgba(240, 96, 34, 1) 20%,
              rgba(243, 120, 32, 1) 30%,
              rgba(247, 149, 29, 1) 40%,
              rgba(250, 166, 26, 1) 50%,
              rgba(238, 72, 35, 1) 60%,
              rgba(238, 79, 34, 1) 70%,
              rgba(240, 96, 34, 1) 80%,
              rgba(243, 120, 32, 1) 90%,
              rgba(250, 166, 26, 1) 100%
            );
          }
        }

        .inner-frag {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          pointer-events: none;
          z-index: 4;
        }
      }
    }

    .inner {
      margin: 10px 0;
      margin-bottom: 100px;

      .progress {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 16px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          padding-top: 4px;
          width: 8px;
          height: 8px;
          border-radius: 24px;
          font-weight: 700;
          font-size: 15px;
          background-color: #f7f7f7;
          border: 1px solid #f7f7f7;
          color: $lightorange;
          @include XGA {
            background-color: #fff;
            border: 1px solid #fff;
          }
        }

        .active {
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          width: 24px;
          height: 8px;
        }
      }

      .question {
        margin: 20px 0;
        max-width: none;
        &-title {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          text-align: left;
          color: #000000;
          margin: 0;
          @include XGA {
            font-size: 24px;
          }
        }

        &-sub {
          margin: 0;
          text-align: left;
          margin-top: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        .flex-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .question-image {
        border-radius: 12px;

        .button-next {
          margin-top: 16px;

          &.disabled {
            background-color: transparent;
          }

          @include XGA {
            padding-top: 0;
          }
        }
      }

      .answers-wrapper {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .answer {
          display: flex;
          border: none;
          margin: 0;
          height: fit-content;
          align-items: center;
          padding: 0;
          border-radius: 12px;

          &.selected {
            .inner {
              border-color: #ed8002;

              .sub {
                background-color: #ed8002;
              }
            }
          }

          input[type="radio"] {
            min-height: 24px;
            min-width: 24px;
            appearance: none;
            border: 1px solid black;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            &:checked {
              border-color: #ed8002;

              &:after {
                content: "";
                position: relative;
                width: 16px;
                height: 16px;
                background-color: #ed8002;
                display: block;
                border-radius: 50%;
                top: 3px;
                left: 3px;
              }
            }
          }

          &-content {
            color: #2a2c2c;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-left: 12px;
            cursor: pointer;

            &.selected {
              color: #ed8002;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .inner-find {
      margin: 10px 0;
      margin-bottom: 40px;
      width: 100%;
      @include XGA {
        margin-bottom: -20px;
      }

      .progress {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 16px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          padding-top: 4px;
          width: 8px;
          height: 8px;
          border-radius: 24px;
          font-weight: 700;
          font-size: 15px;
          background-color: #f7f7f7;
          border: 1px solid #f7f7f7;
          color: $lightorange;
          @include XGA {
            background-color: #fff;
            border: 1px solid #fff;
          }
        }

        .active {
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          width: 24px;
          height: 8px;
        }
      }

      .question {
        margin: 20px 0;
        max-width: none;
        &-title {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          text-align: left;
          color: #000000;
          margin: 0;
          @include XGA {
            font-size: 24px;
          }
        }

        &-sub {
          margin: 0;
          text-align: left;
          margin-top: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        .flex-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .image {
        width: 100%;
        aspect-ratio: 1/1;
        position: relative;
        z-index: 1;

        @include XGA {
          display: none;
        }

        img {
          display: block;
          width: 100%;
        }

        .clip-path,
        .inner-frag {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;

          &.selected {
            background: rgb(238, 72, 35);
            background: linear-gradient(
              133deg,
              rgba(238, 72, 35, 1) 0%,
              rgba(238, 79, 34, 1) 10%,
              rgba(240, 96, 34, 1) 20%,
              rgba(243, 120, 32, 1) 30%,
              rgba(247, 149, 29, 1) 40%,
              rgba(250, 166, 26, 1) 50%,
              rgba(238, 72, 35, 1) 60%,
              rgba(238, 79, 34, 1) 70%,
              rgba(240, 96, 34, 1) 80%,
              rgba(243, 120, 32, 1) 90%,
              rgba(250, 166, 26, 1) 100%
            );
            background-color: white;
          }
        }

        .inner-frag {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          pointer-events: none;
          z-index: 4;
        }

        & + .button {
          margin-top: 32px;
          margin-inline: auto;
          display: block;
        }
      }
    }

    .button-wrapper {
      display: flex;
      margin: 20px auto;
      @include XGA {
        margin-right: 0;
      }
      button {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        @include XGA {
          min-width: 200px;
        }
      }
    }
  }

  &.find-difference {
    h3.question-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;

      @include XGA {
        max-width: 624px;
        margin-inline: auto;
      }
    }

    .answers-wrapper {
      display: grid;
      gap: 16px;
      background-color: #f7f7f7;
      margin-inline: auto;
      width: 100%;
      max-width: 524px;

      @include XGA {
        border-radius: 0 12px 12px 0;
        padding-top: 40px;
        padding-bottom: 40px;
        justify-content: center;
        width: fit-content;
        max-width: initial;
        margin: 0 auto;
        grid-template-columns: 1fr 1fr;
      }

      .answer {
        border: 2px solid #2a2c2c;
        border-radius: 24px;
        padding: 11px 24px 9px;
        max-width: 242px;
        width: 100%;
        font-weight: 900;
        font-size: 14px;
        line-height: 20px;
        background-color: #f7f7f7;

        @include XGA {
          width: 242px;
        }

        &.selected {
          border-color: #ed8002;
          color: #ed8002;
        }
      }
    }

    .content.upper-desktop {
      margin-top: 24px;

      @include XGA {
        max-width: calc(min(calc(100vw - 96px), 1088px));
        width: 100%;
        padding: 32px 0;
      }

      .grid-wrapper {
        @include XGA {
          display: grid;
          grid-template-columns: 416px 416px;
          margin-inline: auto;
          gap: 32px;
        }
      }

      .image {
        aspect-ratio: initial;
        position: relative;
        max-width: 420px;

        @include XGA {
          display: block;
        }

        .clip-path.selected {
          background-color: #ed8002;
        }

        @media (max-width: 420px) {
          margin-left: -24px;
          width: calc(100% + 48px);
        }

        @media (min-width: 420px) {
          margin-left: calc(-24px + ((100vw - 420px) / 2));
        }

        @include XGA {
          margin-left: initial;
        }
      }
    }

    .button-wrapper {
      width: 100%;

      @include XGA {
        margin: 0 auto;
        grid-column: 1/3;
        max-width: 304px;
      }

      .button {
        width: 100%;
        max-width: 327px;
        margin-inline: auto;
        margin-top: 0;
        display: block;
      }
    }
  }

  .word-search {
    .desktop-wrapper {
      @include XGA {
        margin: 0;
        display: block;
        box-shadow: none;
        border-radius: 12px;
        background-image: var(--bg-image);
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 75px;
        margin-top: 32px;
      }
    }

    .inner {
      @include XGA {
        max-width: 863px;
        // max-height: 552px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        border-radius: 12px;
        padding-top: 64px;
        padding-bottom: 32px;
      }
    }

    .question {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin-top: 16px;
      max-width: 311px;

      @include XGA {
        margin-top: 0;
        max-width: 527px;
      }
    }

    .board {
      margin-top: 40px;
      position: relative;
      max-width: 380px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(10, minmax(0, 1fr));

      @include XGA {
        margin-top: 56px;
        margin-bottom: 56px;
        max-width: 330px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 1500px;
        height: calc(100% + 48px);
        background-color: #f7f7f7;
        top: -24px;
        left: -500px;
        z-index: 0;

        @include XGA {
          width: calc(100% + 48px);
          height: calc(100% + 48px);
          background-color: initial;
          top: -24px;
          left: -24px;
          border: 1px solid #ed8002;
          border-radius: 12px;
        }
      }

      div {
        z-index: 2;
        user-select: none;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #2a2c2c;
        height: 33px;

        &.selected {
          font-weight: 900;
          text-align: center;
          color: #ed8002;
        }
      }

      // .row {
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     height: 30px;

      //     div {
      //         z-index: 2;
      //         user-select: none;
      //         width: 100%;
      //         text-align: center;
      //         font-weight: 500;
      //         font-size: 15px;
      //         line-height: 20px;
      //         color: #2A2C2C;

      //         &.selected {
      //             font-weight: 900;
      //             text-align: center;
      //             color: #ED8002;
      //         }
      //     }
      // }
    }

    .button-wrapper {
      margin-top: 48px;

      @include XGA {
        margin-top: 32px;
        margin-bottom: 0;
      }
    }
  }

  &.wyladuj-na-wakacjach {
    .desktop-wrapper {
      @include XGA {
        box-shadow: none;
        grid-template-columns: initial;
        margin: 0;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 24px;
        padding: 90px 40px;
      }
    }

    .inner {
      @include XGA {
        max-width: 1089px;
        width: 100%;
        margin: 0 auto;
        background-color: white;
        border-radius: 12px;
        height: 552px;

        & > .game {
          display: grid;
          grid-template-columns: auto 58%;
          height: 100%;

          & > * {
            height: 100%;
          }
        }
      }

      .question-content {
        @include XGA {
          display: flex;
          flex-direction: column;
          align-items: center;

          & > * {
            width: fit-content;
            margin-left: 0;
            margin-right: 0;
            width: 239px;
          }

          .sub {
            text-align: left;
            margin-top: 64px;
          }

          .question {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: left;
            margin-top: 8px;
          }

          .button-wrapper {
            margin-top: 24px;
            margin-left: 0;
            display: block;
          }
        }
      }

      .sub {
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: #000000;
        margin-top: 24px;
      }

      .question {
        max-width: initial;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        margin-top: 12px;
      }

      .find {
        text-align: center;
        margin-top: 12px;
        font-size: 12px;
        line-height: 150%;

        @include XGA {
          text-align: left;
        }

        p {
          margin: 0;
          margin-bottom: 4px;
          font-weight: 700;

          &.info {
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 8px;

            @include XGA {
              margin-top: 0;
            }
          }

          @include XGA {
            margin-bottom: 6px;
          }
        }
      }

      .map-wrapper,
      .counter-wrapper,
      .pick-wrapper {
        @include XGA {
          border-radius: 0 12px 12px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f7f7f7;

          div {
            width: 100%;
            margin: 0;
          }
        }
      }

      .map-wrapper {
        background-color: #f7f7f7;
      }

      .map {
        position: relative;
        aspect-ratio: 1/1;
        background-image: url("./czerwiec3_2022/img/bg.jpg");
        background-size: cover;
        width: 375px;
        max-width: 375px;
        margin: 0 auto;
        margin-top: 24px;

        @media (max-width: 375px) {
          margin-left: -24px;
        }

        @media (max-width: 420px) {
          margin-left: calc(-24px + ((100vw - 375px) / 2));
        }

        @include XGA {
          transform: scale(1.2);
        }

        @include HD {
          transform: scale(1.4);
        }

        & > div {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: transparent;
          cursor: pointer;

          &.selected {
            background-color: #ed8002;
          }
        }

        .svg {
          position: absolute;
          width: 0;
          height: 0;
        }
      }

      .counter {
        background-color: #f7f7f7;
        margin-top: 24px;

        &-flex {
          display: flex;
          justify-content: center;
          gap: 8px;
          padding-top: 64px;

          button {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #ed8002;

            &:first-of-type {
              margin-bottom: 4px;
            }
          }

          h1 {
            font-weight: 900;
            font-size: 48px;
            line-height: 56px;
            text-align: center;
            letter-spacing: -0.02em;
            margin: 0;
          }
        }

        .packs {
          display: flex;
          justify-content: center;
          margin-top: 58px;
          padding-bottom: 76px;
          margin-bottom: 24px;

          .empty {
            width: 9.5%;
            padding-top: 1.2%;
            padding-bottom: 9.7%;
          }

          img {
            width: 27%;
            margin-left: -9%;
            margin-right: -9%;

            &:only-child {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }

      .pick {
        padding-top: 24px;
        padding-bottom: 24px;
        background-color: #f7f7f7;
        margin-top: 24px;
        margin-bottom: 24px;

        .answers {
          display: flex;
          justify-content: center;
          gap: 24px;
          margin-top: 48px;
          margin-bottom: 48px;

          button {
            margin: 0;
            padding: 0;
            border: 2px solid transparent;
            border-radius: 12px;

            &.selected {
              border-color: #ed8002;
            }

            img {
              border-radius: 12px;
              display: block;
            }
          }
        }
      }
    }
  }

  &.jigsaw {
    .mobile {
      @include XGA {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: block;
      }
    }

    .desktop-wrapper {
      @include XGA {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--bg-image);
        min-height: 707px;
        display: block;
        padding-top: 80px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-left: initial;
        margin-right: initial;
      }
    }

    .inner {
      @include XGA {
        max-width: 864px;
        min-height: 400px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        display: block;
        border-radius: 12px;
      }

      .title {
        @include XGA {
          padding-top: 64px;
        }
      }

      .question {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        max-width: 327px;
        margin-bottom: 0;
        margin-top: 16px;

        @include XGA {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #000000;
          text-align: center;
          max-width: 415px;
          margin-top: 0;
        }
      }

      .sub {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        margin-top: 4px;
        max-width: 327px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @include XGA {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #000000;
          text-align: center;
          max-width: 400px;
          margin-top: 20px;
        }
      }

      .game-container {
        width: 100vw;
        max-width: 500px;
        margin: 0 auto;
        margin-top: 24px;
        background-color: #f7f7f7;

        @media (max-width: 500px) {
          margin-left: -24px;
        }

        @media (min-width: 500px) {
          margin-left: calc(-24px + ((100vw - 500px) / 2));
        }

        @include XGA {
          margin-left: initial;
          background-color: transparent;
        }

        @include XGA {
          width: 100%;
          margin-top: 0;
          border-radius: 0 12px 12px 0;
          margin-left: auto;
          margin-right: auto;
          // width: fit-content;
          max-width: initial;
        }

        .answers {
          display: grid;
          gap: 16px;
          background-color: #f7f7f7;
          padding: 24px;
          max-width: 290px;
          margin-left: auto;
          margin-right: auto;

          @include XGA {
            border-radius: 0 12px 12px 0;
            padding-top: 40px;
            padding-bottom: 40px;
            background-color: transparent;
            justify-content: center;
            width: fit-content;
            max-width: initial;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
          }

          .answer {
            border: 2px solid #2a2c2c;
            border-radius: 24px;
            padding: 11px 24px 9px;
            max-width: 242px;
            width: 100%;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            background-color: white;

            @include XGA {
              width: 242px;
            }

            &.selected {
              border-color: #ed8002;
              color: #ed8002;
            }
          }
        }

        .board {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));

          @include XGA {
            width: 100%;
            border-radius: 0 12px 12px 0;
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 40px;
          }

          .tile {
            aspect-ratio: 1/1;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            touch-action: none;
            cursor: pointer;

            @include XGA {
              &:first-child {
                border-radius: 12px 0 0 0;
              }

              &:nth-child(3) {
                border-radius: 0 12px 0 0;
              }

              &:nth-child(7) {
                border-radius: 0 0 0 12px;
              }

              &:last-child {
                border-radius: 0 0 12px 0;
              }
            }
          }
        }
      }

      .button-wrapper {
        @include XGA {
          padding-bottom: 48px;
          margin-top: 20px;
        }
      }
    }
  }

  &.jigsaw-new {
    .mobile {
      @include XGA {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: block;
      }
    }

    .desktop-wrapper {
      display: flex;
      flex-direction: column-reverse;
      .desktop-background {
        display: none;
        @include XGA {
          display: block;
          width: 50%;
          img {
            border-radius: 0;
          }
        }
      }

      @include XGA {
        background-image: none;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 100px;
        padding: 64px 76px;
        border-radius: 0;
        box-shadow: none;
        margin: 40px 0;
        min-height: fit-content;
      }
    }

    .inner {
      @include XGA {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0;
        background-color: #f7f7f7;
      }

      .progress {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 16px;
        margin-top: 10px;
        @include XGA {
          margin-top: 0;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          padding-top: 4px;
          width: 8px;
          height: 8px;
          border-radius: 24px;
          font-weight: 700;
          font-size: 15px;
          background-color: #f7f7f7;
          border: 1px solid #f7f7f7;
          color: $lightorange;
          @include XGA {
            background-color: #fff;
            border: 1px solid #fff;
          }
        }

        .active {
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          width: 24px;
          height: 8px;
        }
      }

      .title {
        @include XGA {
          padding-top: 20px;
          background-color: #f7f7f7;
        }
      }

      .question {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        margin-top: 16px;
        text-align: left;
        max-width: none;
        @include XGA {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #000000;
          max-width: 415px;
          margin: 0;
        }

        &.last {
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;

          @include XGA {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            max-width: 522px;
            margin-bottom: 32px;
          }
        }
      }

      .sub {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        margin-top: 4px;
        max-width: none;
        margin-left: auto;
        margin-right: auto;
        text-align: left;

        @include XGA {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #000000;
          max-width: 400px;
          margin: 10px 0 0 0;
        }
      }

      .game-container {
        width: 100vw;
        max-width: 500px;
        margin: 0;
        margin-top: 0;
        background-color: #fff;
        @include XGA {
          margin-left: initial;
          background-color: transparent;
        }

        @include XGA {
          width: 100%;
          margin-top: 0;
          border-radius: 0 12px 12px 0;
          margin-left: auto;
          margin-right: auto;
          // width: fit-content;
          max-width: initial;
        }

        .wrapper {
          padding: 24px 0 24px;
          margin-top: -8px;
          margin: 0;
          @include XGA {
            width: fit-content;
            margin: 0;
            background-color: #f7f7f7;
            box-shadow: 0 0 0 100vmax #f7f7f7;
            clip-path: inset(0 -100vmax);
          }

          &.center {
            width: fit-content;
            margin-inline: auto;
          }
        }

        .checkbox {
          position: relative;

          &:last-of-type {
            label {
              margin-bottom: 0;
            }
          }

          input[type="checkbox"] {
            display: none;
          }

          p {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            margin: 0;

            span.circle {
              border: 1px solid #2a2c2c;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              display: inline-block;
              margin-right: 4px;

              &:first-of-type {
                margin-left: 10px;
              }

              &:last-of-type {
                margin-right: 0;
              }

              &.full {
                background-color: #2a2c2c;
              }
            }
          }

          label {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 30px;
            margin-bottom: 12px;
            min-height: 24px;
            cursor: pointer;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: -2px;
              width: 18px;
              height: 18px;
              border: 1px solid black;
              border-radius: 50%;
            }
          }

          input[type="checkbox"]:checked + label {
            &::after {
              content: "";
              position: absolute;
              left: 4px;
              top: 2px;
              width: 12px;
              height: 12px;
              background-color: #ed8002;
              border-radius: 50%;
            }

            p {
              color: #ed8002;

              span {
                border-color: #ed8002;

                &.full {
                  background-color: #ed8002;
                }
              }
            }

            &::before {
              border-color: #ed8002;
            }
          }
        }

        .answers {
          display: grid;
          gap: 16px;
          background-color: #f7f7f7;
          padding: 24px;
          max-width: 290px;
          margin-left: auto;
          margin-right: auto;

          @include XGA {
            border-radius: 0 12px 12px 0;
            padding-top: 40px;
            padding-bottom: 40px;
            background-color: transparent;
            justify-content: center;
            width: fit-content;
            max-width: initial;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
          }

          .answer {
            border: 2px solid #2a2c2c;
            border-radius: 24px;
            padding: 11px 24px 9px;
            max-width: 242px;
            width: 100%;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            background-color: white;

            @include XGA {
              width: 242px;
            }

            &.selected {
              border-color: #ed8002;
              color: #ed8002;
            }
          }
        }

        .dropdown-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 48px;
          padding: 24px;

          @include XGA {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: fit-content;
            margin-inline: auto;
            padding: 40px 0;
          }

          .dropdown-wrapper {
            max-width: 327px;
            width: 100%;
            position: relative;

            @include XGA {
              max-width: 192px;
              display: flex;
              height: 87px;
              flex-direction: column;
              justify-content: space-between;
            }
          }

          .dropdown-question {
            margin: 0 0 2px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
          }

          button {
            padding: 0;
            width: 100%;

            .top {
              display: grid;
              // grid-template-columns: 36px 1fr;
              align-items: center;

              &.gray {
                opacity: 0.3;
              }

              &.active {
                img {
                  transform: rotate(180deg);
                }
              }

              .answer {
                display: flex;
                border-bottom: 1px solid #c6c6c5;
                padding-bottom: 4px;

                p {
                  margin: 0;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 16px;
                  color: #000000;
                  text-align: left;
                }

                img {
                  margin-left: auto;

                  @include XGA {
                    width: initial;
                  }
                }
              }
            }
          }

          .selector {
            flex-direction: column;
            gap: 16px;
            width: 100%;
            padding: 16px 20px;
            background: #ffffff;
            border-bottom: 1px solid #c6c6c5;
            position: absolute;
            display: none;
            z-index: 2;
            top: calc(100% - 1px);

            &.visible {
              display: flex;
            }

            button .content {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              text-align: left;
            }
          }
        }
      }
    }
    .board {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @include XGA {
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
      }

      .tile {
        aspect-ratio: 1/1;
        width: 101%;
        height: 101%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        touch-action: none;
        cursor: pointer;
        transform: scale(1);
        transform-origin: center;

        &.animate {
          @keyframes right {
            0% {
              transform: scale(1);
            }

            25% {
              transform: scale(0.8) translateX(0);
            }

            75% {
              transform: scale(0.8) translateX(100%);
            }

            100% {
              transform: scale(1) translateX(100%);
            }
          }

          @keyframes left {
            0% {
              transform: scale(1);
            }

            25% {
              transform: scale(0.8) translateX(0);
            }

            75% {
              transform: scale(0.8) translateX(-100%);
            }

            100% {
              transform: scale(1) translateX(-100%);
            }
          }

          @keyframes down {
            0% {
              transform: scale(1);
            }

            25% {
              transform: scale(0.8) translateY(0);
            }

            75% {
              transform: scale(0.8) translateY(100%);
            }

            100% {
              transform: scale(1) translateY(100%);
            }
          }

          @keyframes up {
            0% {
              transform: scale(1);
            }

            25% {
              transform: scale(0.8) translateY(0);
            }

            75% {
              transform: scale(0.8) translateY(-100%);
            }

            100% {
              transform: scale(1) translateY(-100%);
            }
          }

          &.right {
            animation: right 500ms ease-in-out;
          }

          &.left {
            animation: left 500ms ease-in-out;
          }

          &.up {
            animation: up 500ms ease-in-out;
          }

          &.down {
            animation: down 500ms ease-in-out;
          }
        }

        @include XGA {
          border-radius: 0;
        }

        img {
          width: 100%;
          touch-action: none;
        }
      }
    }
    .button-wrapper {
      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
      }
      @include XGA {
        margin-right: 0;
        margin-top: 0;
      }
    }
  }

  &.jigsaw-relax-neo {
    .mobile {
      @include XGA {
        display: none;
      }
    }

    .desktop {
      display: none;

      @include XGA {
        display: block;
      }
    }

    .desktop-wrapper {
      @include XGA {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--bg-image);
        min-height: 707px;
        display: block;
        padding-top: 80px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-left: initial;
        margin-right: initial;
      }
    }

    .inner {
      @include XGA {
        max-width: 864px;
        min-height: 400px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        display: block;
        border-radius: 12px;
      }

      .title {
        @include XGA {
          padding-top: 64px;
        }
      }

      .question {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        max-width: 327px;
        margin-bottom: 0;
        margin-top: 16px;

        @include XGA {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #000000;
          text-align: center;
          max-width: 781px;
          margin-top: 0;
        }
      }

      .sub {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        margin-top: 4px;
        max-width: 327px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @include XGA {
          font-weight: 900;
          font-size: 24px;
          line-height: 32px;
          color: #000000;
          text-align: center;
          max-width: 400px;
          margin-top: 20px;
        }
      }

      .sub-board {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin-top: 4px;
        max-width: 327px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @include XGA {
          font-size: 15px;
          line-height: 20px;
          color: #000000;
          text-align: center;
          max-width: 250px;
          margin: 20px 0 0 0;
        }
      }

      .board-game-desktop-container {
        display: flex;

        .info-content {
          min-width: 417px;
          display: flex;
          padding: 0 50px;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
        }

        .game-container {
          @include XGA {
            max-width: 417px;
            margin-right: 0;
          }
        }

        .sub-board,
        .question {
          text-align: left;
        }

        .button-wrapper {
          margin: 0;
        }
      }

      .game-container {
        width: 100vw;
        max-width: 500px;
        margin: 0 auto;
        margin-top: 24px;

        @media (max-width: 500px) {
          margin-left: -24px;
        }

        @media (min-width: 500px) {
          margin-left: calc(-24px + ((100vw - 500px) / 2));
        }

        @include XGA {
          margin-left: initial;
          background-color: transparent;
        }

        @include XGA {
          width: 100%;
          margin-top: 0;
          border-radius: 0 12px 12px 0;
          margin-left: auto;
          margin-right: auto;
          // width: fit-content;
          max-width: initial;
        }

        .answers {
          display: flex;
          gap: 24px;
          padding: 24px;
          max-width: fit-content;
          margin-left: auto;
          margin-right: auto;
          flex-wrap: nowrap;
          align-items: center;
          position: relative;
          background: linear-gradient(
            0deg,
            rgba(247, 247, 247, 0) 9%,
            rgb(247, 247, 247) 9%,
            rgb(247, 247, 247) 56%,
            rgba(247, 247, 247, 0) 56%
          );

          &::after {
            width: 100%;
          }

          @include XGA {
            background-color: #f7f7f7;
            background: linear-gradient(
              0deg,
              rgba(247, 247, 247, 0) 19%,
              rgba(247, 247, 247, 1) 19%,
              rgba(247, 247, 247, 1) 86%,
              rgba(247, 247, 247, 0) 86%
            );
            justify-content: center;
            width: 100%;
            max-width: initial;
            grid-template-columns: 1fr 1fr;
            gap: 62px;
          }

          .answer {
            // padding: 11px 24px 9px;
            max-width: 242px;
            width: 100%;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include XGA {
              max-width: 400px;
              width: 400px;
              flex-direction: row-reverse;
              justify-content: center;
            }

            .input-wrapper {
              display: flex;
              align-items: center;
              gap: 6px;

              @include XGA {
                margin-left: -35px;
              }
            }

            &.selected {
              border-color: #ed8002;
              color: #ed8002;

              input[type="radio"] {
                min-height: 16px;
                min-width: 16px;
                appearance: none;
                border: 1px solid #ed8002;
                border-radius: 50%;
                position: relative;
                cursor: pointer;
                background-color: white;
              }
            }

            input[type="radio"] {
              min-height: 16px;
              min-width: 16px;
              appearance: none;
              border: 1px solid black;
              border-radius: 50%;
              position: relative;
              cursor: pointer;
              background-color: white;

              @include XGA {
                background-color: rgb(247, 247, 247);
              }
            }

            input[type="radio"]:checked:after {
              content: "";
              position: absolute;
              width: 8px;
              height: 8px;
              background-color: #ed8002;
              display: block;
              border-radius: 50%;
              top: 3px;
              left: 3px;
            }

            img {
              width: 85%;
              padding-left: 24px;
              padding-top: 12px;

              @include XGA {
                width: 143px;
                padding-left: 0px;
                padding-top: 0px;
                margin-right: 16px;
              }
            }

            &:first-child {
              img {
                @include XGA {
                  width: 145px;
                }
              }
            }
          }
        }

        .board {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));

          @include XGA {
            width: 100%;
            border-radius: 0 12px 12px 0;
            max-width: 500px;
            margin: 0;
          }

          .tile {
            aspect-ratio: 1/1;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            touch-action: none;
            cursor: pointer;

            @include XGA {
              &:first-child {
                border-radius: 12px 0 0 0;
              }

              &:nth-child(3) {
                border-radius: 0 12px 0 0;
              }

              &:nth-child(7) {
                border-radius: 0 0 0 12px;
              }

              &:last-child {
                border-radius: 0 0 12px 0;
              }
            }
          }
        }
      }

      .button-wrapper {
        @include XGA {
          padding-bottom: 48px;
          margin-top: 20px;
        }
      }
    }
  }

  &.reveal-image {
    margin-top: 16px;

    @include XGA {
      margin-top: 40px;
    }

    .desktop-wrapper {
      @include XGA {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--bg-image);
        // background-color: lime;
        min-height: 707px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline: 0;
      }
    }

    .inner {
      @include XGA {
        background-color: white;
        max-width: 750px;
        width: 100%;
        border-radius: 12px;
        padding: 64px 0;
        display: grid;
        place-items: center;
        padding: 48px 0 32px;
      }

      @include HD {
        max-width: 868px;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      h2.how-to {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #000000;
        max-width: 327px;
        margin: 0 auto 16px;
        padding-top: 8px;

        @include XGA {
          font-size: 24px;
          line-height: 32px;
          max-width: 578px;
        }
      }

      .board {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        position: relative;
        margin-top: 0;

        @media (max-width: 500px) {
          margin-left: -24px;
          width: calc(100% + 48px);
        }

        @media (min-width: 417px) {
          width: 417px;
          margin-left: calc(-31.5px + ((100vw - 417px) / 2));
        }

        @include XGA {
          margin: 0;
        }

        button {
          padding: 0;
          margin: 0;

          .img-wrapper {
            aspect-ratio: 1/1;

            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 0;
            }
          }
        }
      }

      .button-wrapper {
        width: 100%;
        margin: 24px 0 0;

        .button {
          max-width: 327px;
          width: 100%;
          margin-inline: auto;
          display: block;
        }
      }
    }
  }

  &.scratch-reveal {
    *:not(.canvas-wrapper) {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .desktop-wrapper-scratch-reveal {
      display: flex;
      flex-direction: column;
      margin: 20px 0;

      @include XGA {
        flex-direction: row-reverse;
        gap: 50px;
        padding: 70px 80px;
        background-color: #f7f7f7;
      }
      .desktop-image {
        display: none;
        img {
          border-radius: 0;
          width: 100%;
        }
        @include XGA {
          display: flex;
          min-width: 400px;
          width: 50%;
          height: fit-content;
        }
      }
      .inner {
        .progress {
          display: flex;
          justify-content: left;
          align-items: center;
          gap: 16px;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px;
            padding-top: 4px;
            width: 8px;
            height: 8px;
            border-radius: 24px;
            font-weight: 700;
            font-size: 15px;
            background-color: #f7f7f7;
            border: 1px solid #f7f7f7;
            color: $lightorange;
            @include XGA {
              background-color: #fff;
              border: 1px solid #fff;
            }
          }

          .active {
            background: linear-gradient(
              53.04deg,
              #ee4823 37.12%,
              #ee4f22 41.3%,
              #f06022 47.18%,
              #f37820 54.09%,
              #f7951d 61.71%,
              #faa61a 65.4%,
              #faa61a 65.47%
            );
            color: white;
            border: none;
            width: 24px;
            height: 8px;
          }
        }

        .title {
          font-family: Mont;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          h3 {
            text-align: left;
            margin: 0;
            padding: 16px 0;
          }
          @include XGA {
            font-size: 24px;
            line-height: 32px;
            max-width: 700px;
          }
          .question {
            max-width: none;
            @include XGA {
            }
          }
        }
      }
    }
    .game-container {
      .scratch-wrapper {
        width: 100vw;
        margin-left: -24px;

        @include XGA {
          width: initial;
          margin-left: initial;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 20px;
        }

        .canvas-wrapper {
          background-color: transparent;
          box-shadow: none;
          display: flex;
          min-width: 120px;
          justify-content: center;
          margin: 20px 0;
          @include XGA {
            margin: 0;
          }

          canvas {
            // height: 257;
            // max-width: 154px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            touch-action: none;
            cursor: crosshair;
            // cursor: url("./pazdziernik1_2022/img/cursor.png") 27 27, crosshair;

            // @media (max-width: 375px) {
            // margin-left: -24px;
            // }

            // @media (max-width: 420px) {
            // margin-left: calc(-24px + ((100vw - 375px) / 2));
            // }
          }
        }
        .canvas-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 30px;
          @include XGA {
            align-items: initial;
            padding: 0;
          }
          .canvas-name {
            margin: 0;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
          }
          .canvas-sub {
            margin: 0;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
      .color-selector {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: fit-content;
        gap: 10px;
        margin: 24px 0px;
        z-index: 1;

        @include XGA {
          margin-top: 32px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        button {
          max-width: 60px;
          height: 60px;
          padding: 0;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          overflow: hidden;

          &.selected {
            outline: 3px solid #ed8002;
          }

          img {
            border-radius: 0;
            object-fit: cover;
            display: block;
            height: 100%;
          }
        }
      }
    }

    .button-wrapper {
      display: flex;
      gap: 24px;
      margin-top: 32px;
      width: 100%;

      @include XGA {
        gap: 32px;
        flex-direction: row;
        justify-content: flex-end;
      }

      .button {
        max-width: 300px;
        flex-grow: 1;
        flex-basis: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        @include XGA {
          max-width: 200px;
        }
      }
    }
  }

  &.pick-reveal {
    *:not(.canvas-wrapper) {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    @include XGA {
      .desktop-wrapper {
        min-height: 707px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        box-shadow: none;
        margin-inline: 0;
        position: relative;
        z-index: 2;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: calc(min(100%, 707px));
          z-index: -1;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: var(--bg-image);
          background-color: lightgray;
        }

        .inner {
          max-width: 720px;
          min-height: 400px;
          background-color: white;
          border-radius: 12px;
          overflow: hidden;
          padding-top: 20px;
          width: 100%;
          margin-top: 40px;
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);

          @include HD {
            margin-top: 80px;
            max-width: 864px;
          }
        }
      }
    }

    .question {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      max-width: 327px;
      margin-top: 16px;

      @include XGA {
        max-width: 640px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        padding-top: 28px;
      }

      span {
        background-image: linear-gradient(
          90deg,
          rgba(229, 69, 31, 1) 0%,
          rgba(245, 170, 4, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .answers {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      width: fit-content;
      margin-inline: auto;
      margin-top: 24px;
      padding: 24px 0;
      box-shadow: 0 0 0 100vmax #e5e5e5;
      clip-path: inset(0 -100vmax);
      background-color: #e5e5e5;

      @include XGA {
        margin-top: 32px;
      }

      .answer {
        border: 2px solid #2a2c2c;
        border-radius: 24px;
        padding: 10px 0 7px;
        width: 150px;
        font-weight: 900;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #2a2c2c;

        &.selected {
          border-color: #ed8002;
          color: #ed8002;
        }
      }
    }

    .scratch-wrapper {
      width: 100vw;
      margin-left: -24px;

      @include XGA {
        width: initial;
        margin-left: initial;
      }

      .canvas-wrapper {
        background-color: #e5e5e5;
        box-shadow: 0 0 0 100vmax #e5e5e5;
        clip-path: inset(0 -100vmax);
        display: grid;
        place-items: center;
        min-width: 120px;

        canvas {
          // height: 257;
          // max-width: 154px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          touch-action: none;
          cursor: crosshair;
          // cursor: url("./pazdziernik1_2022/img/cursor.png") 27 27, crosshair;

          // @media (max-width: 375px) {
          // margin-left: -24px;
          // }

          // @media (max-width: 420px) {
          // margin-left: calc(-24px + ((100vw - 375px) / 2));
          // }
        }
      }

      .color-selector {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: fit-content;
        gap: 10px;
        margin: 24px 0px;
        z-index: 1;

        @include XGA {
          margin-top: 32px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        button {
          max-width: 60px;
          height: 60px;
          padding: 0;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          overflow: hidden;

          &.selected {
            outline: 3px solid #ed8002;
          }

          img {
            border-radius: 0;
            object-fit: cover;
            display: block;
            height: 100%;
          }
        }
      }
    }

    .button-wrapper {
      display: flex;
      gap: 24px;
      margin-top: 32px;
      width: 100%;

      @include XGA {
        gap: 32px;
        flex-direction: row;
      }

      .button {
        max-width: 160px;
        flex-grow: 1;
        flex-basis: 100%;
      }
    }

    .kwiecien2_2023 {
      .scratch-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 50px;
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          max-width: 245px;

          @include XGA {
            max-width: 500px;
          }
        }

        h3 {
          padding: 0;
          font-style: normal;
          font-weight: 900;
          font-size: 18px;
          line-height: 24px;
          text-align: center;

          @include XGA {
            font-weight: 900;
            font-size: 32px;
            line-height: 40px;
          }
        }
      }
    }

    .kwiecien3_2023 {
      .progress {
        display: none;
      }
    }
  }

  &.x2-poll {
    p.sub {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      margin: 0;
      margin-top: 8px;
    }

    .desktop-background {
      @include XGA {
        min-height: 707px;
        position: relative;
        z-index: 1;
        padding-top: 80px;

        &::before {
          position: absolute;
          content: "";
          max-height: 707px;
          height: 100%;
          width: 100%;
          background-image: var(--bg-image);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          z-index: -1;
          top: 0;
          left: 0;
        }

        .content {
          position: initial;
          transform: initial;
          margin: 0 auto;
        }
      }
    }

    .content {
      background-color: white;

      @include XGA {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
      }

      .answers-wrapper {
        @include XGA {
          grid-template-columns: repeat(var(--columns), minmax(135px, 150px));
          width: 100%;
          justify-content: center;
        }

        .answer {
          .inner {
            img {
              padding-top: 0;
            }

            .sub {
              position: relative;
              margin-top: -41px;
              min-height: 41px;
              display: grid;
              place-items: center;
            }
          }
        }
      }
    }
  }

  &.sub-range {
    .question {
      span {
        color: #e86215;
      }
    }

    .desktop-wrapper {
      @include XGA {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--bg-image);
        min-height: 777px;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner {
          max-width: 600px;
          min-height: 400px;
          background-color: #f7f7f7;
          border-radius: 12px;
          overflow: hidden;
          padding-top: 20px;
          width: 100%;
          transform: scale(1.2);

          @include HD {
            padding-bottom: 0;
            transform: scale(1.3);
            height: 100%;
            margin-top: 20px;
          }
        }
      }
    }

    .game-container {
      position: relative;
      padding-bottom: 24px;

      &::before {
        content: "";
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: #f7f7f7;
        z-index: -1;
        left: -24px;
      }

      .sub-names {
        margin-left: auto;
        margin-right: auto;
        max-width: 260px;
        padding-top: 24px;

        p {
          font-weight: 700;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          color: #868686;
          margin: 0;
          margin-bottom: 8px;
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;

          .name {
            padding: 0;

            .content {
              border: 1px solid #2a2c2c;
              border-radius: 24px;
              padding: 2px 7px 0px;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              color: #2a2c2c;
            }

            &.selected {
              .content {
                border-color: #ed8002;
                color: #ed8002;
              }
            }
          }
        }
      }

      .info {
        height: 16px;
        width: fit-content;
        margin: 13px auto;

        p {
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          color: #868686;

          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #e86215;
            margin-left: 16px;

            &:first-of-type {
              color: #f5aa04;
              margin-left: 0px;
            }

            &:last-of-type {
              color: #ed8002;
            }
          }
        }
      }

      .slide-content {
        max-width: 343px;
        position: relative;
        z-index: 5;
        overflow: hidden;
        background: #ffffff;
        border-radius: 12px;
        filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.16));

        &.count {
          &::before {
            background-color: #e86215;
          }

          .selected {
            color: #e86215 !important;

            &::after {
              filter: brightness(0) saturate(100%) invert(48%) sepia(49%)
                saturate(4454%) hue-rotate(356deg) brightness(96%) contrast(90%);
            }
          }
        }

        &.package {
          &::before {
            background-color: #ed8002;
          }

          .selected {
            color: #ed8002 !important;

            &::after {
              filter: brightness(0) saturate(100%) invert(52%) sepia(39%)
                saturate(4580%) hue-rotate(10deg) brightness(104%) contrast(98%);
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          background-color: #f5aa04;
          width: 531px;
          height: 142px;
          border-radius: 50%;
          top: -98px;
          left: -89px;
          z-index: -1;
        }

        .slide-name {
          font-weight: 700;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          color: #ffffff;
          margin-top: 16px;
          margin-bottom: 0;
        }

        .slider,
        .range-wrapper {
          max-width: 327px;
          margin: 0 auto;
        }

        .slider {
          margin-top: 25px;
          padding-bottom: 16px;

          .values {
            width: 100%;
            display: flex;
            justify-content: space-between;

            p {
              position: relative;
              width: 75px;
              height: 24px;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              color: #2a2c2c;
              margin: 0 auto;
              display: grid;
              place-items: center;

              &::before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: black;
                top: 41px;
                left: 34px;
                z-index: -1;
              }

              &.selected {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #ed8002;

                &::after {
                  content: url("./lipiec3_2022/img/pointer.svg");
                  position: absolute;
                  width: 18px;
                  height: 18px;
                  top: 58px;
                }
              }

              // &:first-of-type {
              //     &::before {
              //         left: 23px;
              //     }
              // }

              &:last-of-type {
                &::before {
                  left: 36px;
                }
              }
            }
          }

          .range-wrapper {
            padding: 0 36px;
          }

          .pointer-wrapper {
            padding: 0 32px;

            img {
              margin-top: 8px;
            }
          }

          input[type="range"] {
            width: 100%;
            margin: 4px 0;
            background-color: transparent;
            -webkit-appearance: none;
            padding-top: 15px;
            padding-bottom: 24px;
          }

          input[type="range"]:focus {
            outline: none;
          }

          input[type="range"]::-webkit-slider-runnable-track {
            background: #000000;
            border: 0;
            width: 100%;
            height: 1px;
            cursor: pointer;
          }

          input[type="range"]::-webkit-slider-thumb {
            margin-top: -4px;
            width: 9px;
            height: 9px;
            background: #000000;
            border: 0;
            border-radius: 50px;
            cursor: pointer;
            -webkit-appearance: none;
          }

          input[type="range"]:focus::-webkit-slider-runnable-track {
            background: #0d0d0d;
          }

          input[type="range"]::-moz-range-track {
            background: #000000;
            border: 0;
            width: 100%;
            height: 1px;
            cursor: pointer;
          }

          input[type="range"]::-moz-range-thumb {
            width: 9px;
            height: 9px;
            background: #000000;
            border: 0;
            border-radius: 50px;
            cursor: pointer;
          }

          input[type="range"]::-ms-track {
            background: transparent;
            border-color: transparent;
            border-width: 4px 0;
            color: transparent;
            width: 100%;
            height: 1px;
            cursor: pointer;
          }

          input[type="range"]::-ms-fill-lower {
            background: #000000;
            border: 0;
          }

          input[type="range"]::-ms-fill-upper {
            background: #000000;
            border: 0;
          }

          input[type="range"]::-ms-thumb {
            width: 9px;
            height: 9px;
            background: #000000;
            border: 0;
            border-radius: 50px;
            cursor: pointer;
            margin-top: 0px;
          }

          input[type="range"]:focus::-ms-fill-lower {
            background: #000000;
          }

          input[type="range"]:focus::-ms-fill-upper {
            background: #0d0d0d;
          }

          @supports (-ms-ime-align: auto) {
            input[type="range"] {
              margin: 0;
            }
          }
        }
      }

      .sub-range-swiper {
        overflow: visible;
        max-width: 343px;
      }

      .navigation {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;

        button {
          padding: 0;
          margin: 0;

          &.swiper-button-disabled {
            opacity: 0.5;
          }

          .prev,
          .next {
            border: 2px solid #ed8002;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: grid;
            place-items: center;

            img {
              display: block;
              width: initial;
            }
          }

          .next {
            transform: rotate(180deg);

            img {
              margin-left: -3px;
            }
          }

          .prev {
            img {
              margin-left: -3px;
            }
          }
        }

        .pagination {
          display: flex;
          gap: 8px;

          div {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ffffff;

            &.selected {
              width: 24px;
              background: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
              border-radius: 24px;
            }
          }
        }
      }
    }
  }

  &.pickone {
    &.game {
      @include XGA {
        padding-bottom: 50px;
      }
    }

    .game {
      position: relative;
      margin-top: 24px;

      @include XGA {
        margin-top: 0;
        min-height: 600px;
      }
    }
    .card {
      max-width: none;
      margin: 0 auto;
      box-shadow: none;
      border-radius: 0;

      @include XGA {
        padding: 64px 90px 0 90px;
        min-height: 400px;
        padding-bottom: 32px;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 70px;
      }

      .desktop-background {
        display: none;
        @include XGA {
          display: flex;
          justify-content: flex-end;
          max-width: 500px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .game-content {
        @include XGA {
          display: flex;
          flex-direction: column;
          width: 60%;
        }
      }

      .progress {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 16px;
        margin-top: 10px;
        @include XGA {
          margin-top: 0;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          padding-top: 4px;
          width: 8px;
          height: 8px;
          border-radius: 24px;
          font-weight: 700;
          font-size: 15px;
          background-color: #f7f7f7;
          border: 1px solid #f7f7f7;
          color: $lightorange;
          @include XGA {
            background-color: #fff;
            border: 1px solid #fff;
          }
        }

        .active {
          background: linear-gradient(
            53.04deg,
            #ee4823 37.12%,
            #ee4f22 41.3%,
            #f06022 47.18%,
            #f37820 54.09%,
            #f7951d 61.71%,
            #faa61a 65.4%,
            #faa61a 65.47%
          );
          color: white;
          border: none;
          width: 24px;
          height: 8px;
        }
      }

      .question {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 18px;
        line-height: 133%;
        width: 100%;
        text-align: left;
        margin: 20px 0;

        @include XGA {
          font-size: 24px;
          padding: 0px;
          margin-bottom: 24px;
          max-width: 659px;
          line-height: 133%;
        }
      }

      .answers-wrapper {
        position: relative;
        width: 100%;
        z-index: 2;
        background-color: transparent;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        gap: 16px;
        place-items: center;
        padding: 24px;
        box-shadow: none;
        // clip-path: inset(0 -100vmax);

        @include XGA {
          padding: 0;
          margin: 0;
          gap: 24px;
          grid-template-columns: 25fr 25fr;
          overflow: visible;
        }

        .answer {
          border: 2px solid #000000;
          border-radius: 50px;
          margin: 0 20px;
          max-width: 300px;
          width: 100%;
          font-weight: 900;
          height: initial !important;
          color: black;

          &.selected {
            color: #ed8002;
            border: 2px solid #ed8002;
          }
        }

        &.img-grid {
          grid-template-columns: 1fr 1fr;
          width: fit-content;

          @include XGA {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-width: 350px;
          }

          button.answer {
            margin: 0;
            padding: 0;
            max-width: none;
            max-height: none;
            border-radius: 12px;
            @include XGA {
              min-width: 90px;
              min-height: 90px;
              max-width: 150px;
              max-height: 150px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              display: block;
            }
          }
        }

        &.checkboxes {
          display: grid;
          width: fit-content;
          justify-items: flex-start;
          grid-template-columns: 1fr;
        }

        .checkbox {
          display: grid;
          grid-template-columns: 16px 1fr;
          column-gap: 12px;
          position: relative;

          &.selected {
            &::before {
              border-color: #ed8002;
            }

            p {
              color: #ed8002;
            }
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: 1px solid black;
          }

          input[type="checkbox"] {
            height: 0;
            width: 0;
            position: relative;

            &:checked::before {
              content: "";
              position: absolute;
              top: 3px;
              left: 3px;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              background-color: #ed8002;
            }
          }

          p {
            max-width: initial;
            width: fit-content;
            font-weight: 900;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            text-align: left;
            margin: 0;
          }
        }
      }

      .button-next {
        background-color: #ed8002;
        border-radius: 50px;
        width: 200px;
        height: 41px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 11px 24px 9px;
        color: white;
        font-weight: 900;
        margin: 24px auto;
      }

      .button-next.disabled {
        background-color: #c6c6c5;
      }

      .dropdown-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 48px;
        padding: 24px;
        margin-bottom: 200px;

        @include XGA {
          margin-bottom: 64px;
        }

        .dropdown-wrapper {
          max-width: 327px;
          width: 100%;
          position: relative;
        }

        .dropdown-question {
          margin: 0 0 2px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #000000;
        }

        button {
          padding: 0;
          width: 100%;

          .top {
            display: grid;
            // grid-template-columns: 36px 1fr;
            align-items: center;

            &.gray {
              opacity: 0.3;
            }

            &.active {
              img {
                transform: rotate(180deg);
              }
            }

            .answer {
              display: flex;
              border-bottom: 1px solid #c6c6c5;
              padding-bottom: 4px;
              max-width: 327px;

              p {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #000000;
                text-align: left;
              }

              img {
                margin-left: auto;

                @include XGA {
                  width: initial;
                }
              }
            }
          }
        }

        .selector {
          flex-direction: column;
          gap: 16px;
          width: 100%;
          padding: 16px 20px;
          background: #ffffff;
          border-bottom: 1px solid #c6c6c5;
          position: absolute;
          display: none;
          z-index: 2;
          top: calc(100% - 1px);

          @include XGA {
            top: calc(100% + 1px);
          }

          &.visible {
            display: flex;
          }

          button .dropdown-button-content {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            text-align: left;
          }
        }
      }
    }
    .button-wrapper {
      width: 100%;
      display: flex;
      @include XGA {
        justify-content: right;
      }
      .button {
        width: 100%;
        max-width: 400px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        @include XGA {
          max-width: 200px;
        }
      }
    }

    // &.list {
    //     display: grid;
    //     grid-template-columns: 1fr 1fr;
    //     column-gap: 16px;
    //     row-gap: 32px;

    //     @include XGA {
    //         row-gap: 64px;
    //     }

    //     @include HD {
    //         margin-top: 60px;
    //         margin-bottom: 40px;
    //         // grid-template-columns: repeat(4, 1fr);
    //     }

    //     @include UXGA {
    //         margin-top: 100px;
    //     }

    //     .answer {
    //         position: relative;
    //         padding-bottom: 0;
    //         display: flex;
    //         min-width: initial !important;

    //         &>* {
    //             min-width: initial !important;
    //         }

    //         &.selected {
    //             p {
    //                 color: #ED8002;
    //             }
    //         }

    //         p {
    //             font-weight: 900;
    //             font-size: 14px;
    //             line-height: 14px;
    //             padding: 0;
    //             margin: 0 auto;
    //             margin-top: auto;
    //             max-width: 104px;
    //             height: 28px;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             color: #2A2C2C;
    //         }

    //         .image {
    //             width: 64px;
    //             height: 80px;
    //             z-index: 2;
    //             position: absolute;
    //             top: -50%;
    //             left: 50%;
    //             transform: translateX(-50%);
    //             aspect-ratio: initial;
    //             min-height: initial;
    //             border-radius: initial;
    //         }
    //     }
    // }
  }

  &.true-false-image-layout {
    .card {
      box-shadow: initial;

      @include XGA {
        overflow: hidden;
      }
    }

    .game {
      position: relative;
      margin-top: 24px;

      @include XGA {
        min-height: 600px;
        margin-top: 40px;
        padding-top: 50px;
        margin-bottom: 24px;
      }

      .image {
        position: absolute;
        z-index: 1;
        border-radius: 12px;
        min-height: 600px;
        object-fit: cover;
        aspect-ratio: 16/7;
        width: 100%;
        margin-top: -50px;

        @include HD {
          aspect-ratio: 18/6;
        }

        // @include UXGA {
        //     padding-top: 50px;
        // }
      }
    }

    .card {
      max-width: 863px;
      margin: 0 auto;

      @include XGA {
        padding-top: 0;
        // padding: 64px 90px 0 90px;
        min-height: 400px;
        padding-bottom: 32px;
        // margin: 50px auto;
      }

      .inner {
        @include XGA {
          background-color: #f7f7f7;
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          justify-content: center;
          column-gap: 48px;
          padding: 32px 64px;
          align-items: center;
        }

        @include HD {
          column-gap: 100px;
        }

        .question-image {
          position: relative;
          padding: 24px 0;

          @include XGA {
            padding: 0;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -10vw;
            width: 120vw;
            height: 100%;
            background-color: #f7f7f7;
          }

          img {
            position: relative;
            z-index: 2;
            display: block;
            margin: 0 auto;
            height: 120px;

            @include XGA {
              height: 200px;
            }
          }
        }
      }

      .question {
        padding: 0px;
        margin-bottom: 16px;
        font-weight: 700;
        max-width: 327px;
        width: 100%;
        font-size: 18px;
        line-height: 133%;
        text-align: center;
        color: #000000;

        @include XGA {
          margin-bottom: 8px;
          max-width: 659px;
          font-size: 32px;
        }
      }

      .sub {
        font-weight: 700;
        font-size: 15px;
        line-height: 133%;
        width: fit-content;
        margin-inline: auto;
        text-align: center;
      }

      .answers-wrapper {
        position: relative;
        width: 100%;
        z-index: 2;
        display: block;

        @include XGA {
          width: 420px;
          margin: 0;
        }

        .answer {
          width: 100%;
          margin-bottom: 16px;
          height: auto;

          @include XGA {
            margin: 0;
            width: 100%;
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &.selected {
            color: #ed8002;
            border: 2px solid #ed8002;
          }

          &.wrong {
            color: #eb5757;
            border: 2px solid #eb5757;
          }
        }
      }

      .button-next {
        background-color: #ed8002;
        border-radius: 50px;
        width: 200px;
        height: 41px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 11px 24px 9px;
        color: white;
        font-weight: 900;
        margin: 24px auto;
      }

      .button-next.disabled {
        background-color: #c6c6c5;
      }
    }
  }

  &.true-false-pickone-layout {
    .game {
      @include XGA {
        display: flex;
        flex-direction: row-reverse;
        gap: 100px;
        padding: 64px;
        background-color: #f7f7f7;
      }
      .question-image-wrapper {
        display: flex;
        justify-content: center;
        width: 50%;
        .question-image {
          display: none;
          @include XGA {
            display: flex;
            width: 448px;
            height: 241px;
          }
        }
      }
      .card {
        margin: 0;
        @include XGA {
          width: 50%;
          background: transparent;
          min-height: 400px;
          padding-bottom: 32px;
          padding-top: 0px;
          border-radius: 0;
        }

        .progress {
          justify-content: left;
          margin-bottom: 16px;

          div {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #e5e5e5;
            border: none;
          }

          .active {
            background: linear-gradient(
              53.04deg,
              #ee4823 37.12%,
              #ee4f22 41.3%,
              #f06022 47.18%,
              #f37820 54.09%,
              #f7951d 61.71%,
              #faa61a 65.4%,
              #faa61a 65.47%
            );
            width: 24px;
            border-radius: 5px;
          }
        }

        .main-question {
          p {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            margin: 0;
            color: #000;
            width: 100%;
            max-width: none;
            @include XGA {
              font-size: 24px;
            }
          }
        }

        .divider {
          display: flex;
          width: 100%;
          height: 1px;
          background-color: #e5e5e5;
          margin: 20px 0;
        }

        .question {
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          margin: 0;
          color: #2a2c2c;
          width: 100%;
          max-width: none;
        }

        .sub {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          margin: 0;
        }

        .answers-wrapper {
          width: 100%;
          z-index: 2;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 16px;
          place-items: center;
          padding: 24px 0;
          max-width: 667px;
          background-color: transparent;
          box-shadow: none;
          @include XGA {
            display: grid;
            place-items: center;
            grid-auto-rows: max-content;
            padding: 0px;
            margin-top: 40px;
            gap: 24px;
            grid-template-rows: auto;
            grid-template-columns: 25fr 25fr;
          }

          .answer {
            border: 2px solid #000000;
            border-radius: 50px;
            margin: 0;
            max-width: 300px;
            font-weight: 900;
            height: initial !important;
            @include XGA {
              margin: 0 20px;
              width: 100%;
            }

            &.selected {
              color: #ed8002;
              border: 2px solid #ed8002;
            }
          }
        }
      }
    }

    .button-wrapper {
      @include XGA {
        display: flex;
        margin-right: 0;
      }
      .button {
        display: flex;
        gap: 10px;
        width: 250px;
        justify-content: center;
      }
    }
  }

  &.list-layout {
    .card {
      box-shadow: initial;

      @include XGA {
        overflow: hidden;
      }
    }

    .game {
      position: relative;
      margin-top: 24px;

      @include XGA {
        min-height: 600px;
        margin-top: 40px;
        padding-top: 50px;
        margin-bottom: 24px;
      }

      .image {
        position: absolute;
        z-index: 1;
        border-radius: 12px;
        min-height: 600px;
        object-fit: cover;
        aspect-ratio: 16/7;
        width: 100%;
        margin-top: -50px;

        @include HD {
          aspect-ratio: 18/6;
        }
      }
    }

    .card {
      max-width: 863px;
      margin: 0 auto;

      @include XGA {
        padding-top: 64px;
        // padding: 64px 90px 0 90px;
        min-height: 400px;
        padding-bottom: 32px;
        // margin: 50px auto;
      }

      .question {
        padding: 0px;
        margin-bottom: 16px;
        font-weight: 700;
        max-width: 327px;
        width: 100%;
        font-size: 18px;
        line-height: 133%;
        text-align: center;
        color: #000000;

        @include XGA {
          margin-bottom: 8px;
          max-width: 659px;
          font-size: 32px;
        }
      }

      .sub {
        font-weight: 700;
        font-size: 15px;
        line-height: 133%;
        width: fit-content;
        margin-inline: auto;
        text-align: center;
      }

      .answers-wrapper {
        position: relative;
        width: 100%;
        z-index: 2;
        background-color: #f7f7f7;
        display: block;
        padding: 24px 0;
        max-width: 420px;
        text-align: left;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -10vw;
          width: 120vw;
          height: 100%;
          background-color: #f7f7f7;
        }

        @include XGA {
          display: block;
          padding: 24px 32px;

          &::before {
            display: none;
          }
        }

        .answer {
          position: relative;
          width: 100%;
          border: none;
          padding: 0;
          height: auto;
          margin: 0;
          text-align: left;
          padding-left: 28px;
          margin-bottom: 8px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: #000 solid 1px;
          }

          &.selected {
            color: #ed8002;

            &::before {
              background-color: #ed8002;
            }
          }
        }
      }

      .button-next {
        background-color: #ed8002;
        border-radius: 50px;
        width: 200px;
        height: 41px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 11px 24px 9px;
        color: white;
        font-weight: 900;
        margin: 24px auto;
      }

      .button-next.disabled {
        background-color: #c6c6c5;
      }
    }
  }

  &.video {
    .desktop-wrapper {
      @include XGA {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--bg-image);
        min-height: 777px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline: 0;
      }
    }

    .inner {
      margin-top: 24px;

      @include XGA {
        background-color: white;
        max-width: 750px;
        width: 100%;
        border-radius: 12px;
        padding-top: 43px;
      }

      @include HD {
        max-width: 868px;
      }

      .video-wrapper {
        max-width: 639px;
        margin: 0 auto;

        video {
          width: 100%;
          aspect-ratio: 16/9;
          display: block;

          iframe {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }

      .question {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        max-width: 290px;
        margin-top: 24px;

        @include XGA {
          margin-top: 32px;
          max-width: 639px;
        }
      }

      .answers-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 16px;
        margin-top: 16px;
        padding: 24px 0;
        background-color: #f7f7f7;
        box-shadow: 0 0 0 100vmax #f7f7f7;
        clip-path: inset(0 -100vmax);
        max-width: 287px;
        width: 100%;

        @include XGA {
          box-shadow: none;
          clip-path: none;
          margin-top: 24px;
          max-width: 100%;
          grid-template-columns: 300px 300px;
          justify-content: center;
        }

        .answer {
          margin: 0;
          padding: 11px 5px 9px;
          height: fit-content;
          max-width: 155px;
          border: 2px solid #2a2c2c;
          border-radius: 24px;
          height: 100%;

          @include XGA {
            max-width: initial;
          }

          &.selected {
            border-color: #ed8002;

            .content {
              color: #ed8002;
            }
          }

          .content {
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            color: #2a2c2c;
          }
        }
      }

      .button-wrapper {
        margin-top: 24px;

        @include XGA {
          margin-bottom: 32px;
        }

        .button {
          width: 160px;
        }
      }
    }
  }

  &.ar-ankieta {
    .geocoder {
      border: 5px solid red !important;
    }

    .mapboxgl-ctrl-geocoder--input {
      margin-top: 0 !important;
      background-color: transparent !important;
    }

    .mapboxgl-ctrl-geocoder {
      max-width: 100%;
      width: 100%;
      box-shadow: none !important;
    }

    .mapboxgl-ctrl {
      background: transparent;
      border-radius: 0;
    }

    margin-left: -20px;
    margin-right: -20px;

    .sub {
      text-align: left;
      font-size: 15px;
      line-height: 20px;
      margin: 10px 20px;
      padding: 0;
      width: 110%;
    }

    .question {
      text-align: left;
      margin: 0;
      padding: 0 20px;
      max-width: 100%;
      font-size: 18px !important;
      line-height: 24px !important;

      @include XGA {
        padding: 0;
      }
    }

    .answers-wrapper-check {
      input {
        border-radius: 0 !important;
        border: 2px solid #ed8002 !important;
      }

      input[type="radio"]:checked:after {
        border-radius: 0 !important;
      }

      @include XGA {
        display: flex;
        flex-direction: column;
      }
    }

    .answers-wrapper-boolean {
      padding: 20px;
      background: #f7f7f7;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // border:1px solid red;

      button {
        width: 48%;
        height: 41px;
        border: 2px solid #0d0d0d;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.selected {
          background: #ed8002;
          color: #fff;
          border: 2px solid #ed8002;
        }
      }
    }

    .answers-wrapper-location {
      padding: 20px;
      background: #f7f7f7;
      display: flex;
      flex-direction: column;

      .input-wrapper {
        background: transparent;
        width: 100%;

        label {
          font-size: 12px;
          line-height: 16px;
        }

        input {
          width: 100%;
          background: transparent;
          border: none;
          font-size: 16px;
          margin-top: 5px;
          border-bottom: 1px solid #c6c6c5;
        }
      }
    }

    .answers-wrapper-boolean-multi {
      padding: 20px;
      background: #f7f7f7;
      display: flex;
      flex-direction: column;

      // border:1px solid red;

      .bullet {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #000000;
        margin-right: 14px;
        flex-shrink: 0;
      }

      button {
        display: flex;
        margin: 5px 0;
        text-align: left;
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;

        &.selected {
          .bullet {
            background: #ed8002;
          }
        }
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 90%;

      &.mobile {
        @include XGA {
          display: none;
        }
      }

      &.desktop {
        display: none;

        @include XGA {
          display: flex;
          padding: 0;
          width: 660px;
          transform: translateY(25px);
        }
      }

      .white {
        .icon {
          margin: 0;
          margin-right: 10px;

          svg {
            transform: rotate(180deg);

            path {
              stroke: #e5451f !important;
            }
          }
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &:disabled {
          svg {
            path {
              stroke: #fff;
            }
          }
        }

        .icon {
          align-self: center;
          margin-left: 10px;
          display: block;
          // width: 7px;
          // height: 10px;

          svg {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }

    .answers-wrapper {
      background: #f7f7f7;

      &.flex {
        display: flex;
      }
    }

    .card {
      box-shadow: none;
      display: flex;
      flex-direction: column;

      @include XGA {
        max-width: 860px;
        width: 100%;
        padding: 30px 100px;
        min-height: 330px;
        border-radius: 12px;
        margin-top: -100px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
      }
    }

    .top {
      border-top: 1px solid #ccc;
      padding-top: 20px;

      @include XGA {
        padding-top: 0;
        border-top: 0;
        padding-bottom: 20px;
      }

      .question {
        font-size: 24px;
        line-height: 32px;

        @include XGA {
          margin-top: 0;
        }
      }
    }

    .two-cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .cta {
        width: 45%;
        height: 41px;
        display: block;

        background: transparent;
        border: 2px solid #2a2c2c;
        border-radius: 50px;
        cursor: pointer;

        &.selected {
          border: 2px solid #ed8002;
          color: #fff;
          background: #ed8002;
        }
      }
    }

    .desktop-wrapper {
      @include XGA {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("./ankieta3_listopad/img/bg.jpg");
        min-height: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline: 0;
        // border:5px solid red;
        border-radius: 0;
        box-shadow: 0;
      }
    }

    .inner {
      margin-top: 24px;

      @include XGA {
        background-color: white;
        margin-top: 80px;
        display: grid;
        grid-template-columns: 250px 335px;
        padding: 56px 128px 40px;
        border-radius: 12px;
        align-self: flex-start;
        gap: 40px;

        &.swiper-container {
          display: block;
          max-width: 700px;
          padding-inline: 0;
        }
      }

      .point {
        position: absolute;
        font-weight: 700;
        font-size: 15px;
        line-height: 0;
        border: 1px solid #ed8002;
        width: 24px;
        height: 24px;
        color: #ed8002;
        display: grid;
        place-items: center;
        border-radius: 50%;
        cursor: pointer;

        &.gray {
          opacity: 0.3;
        }
      }

      .oval {
        background-color: #ffffff;
        width: 247px;
        aspect-ratio: 1/1;
        display: grid;
        place-items: center;
        border-radius: 50%;
        border: 4px solid #e5451f;
        position: relative;
      }

      .device-wrapper {
        display: grid;
        place-items: center;
        position: relative;
        z-index: 2;

        &::before {
          position: absolute;
          content: "";
          width: 100vw;
          height: 184px;
          background-image: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
          z-index: -1;

          @include XGA {
            display: none;
          }
        }

        .first {
          grid-area: first;
        }

        .second {
          grid-area: second;
        }

        .third {
          grid-area: third;
        }

        .fourth {
          grid-area: fourth;
        }

        .fifth {
          grid-area: fifth;
        }

        .oval {
          .point-0 {
            top: 20px;
            left: 71px;
          }

          .point-1 {
            top: 20px;
            right: 71px;
          }

          .point-2 {
            top: 50px;
            right: 50px;
          }

          .point-3 {
            top: 78px;
            right: 50px;
          }

          .point-4 {
            bottom: 45px;
            right: 50px;
          }

          .device {
            position: relative;
            z-index: 2;
            display: grid;
            grid-template-columns: 42px 42px;
            grid-template-areas:
              "first second"
              "first  third"
              "first  fourth"
              "fifth  fifth";

            &::before {
              position: absolute;
              content: "";
              width: 85%;
              height: 85%;
              left: 50%;
              transform: translateX(-50%);
              background-color: #393939;
              z-index: -1;
            }

            img {
              display: block;
              border-radius: 0;
            }
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 18px;

        @include XGA {
          align-self: center;
        }

        .item {
          position: relative;
        }

        button {
          padding: 0;
          width: 100%;

          .wrapper {
            display: grid;
            grid-template-columns: 36px 1fr;
            align-items: center;

            &.gray {
              opacity: 0.3;
            }

            &.active {
              img {
                transform: rotate(180deg);
              }
            }

            .point {
              position: static;
            }

            .answer {
              display: flex;
              border-bottom: 1px solid #c6c6c5;
              padding-bottom: 4px;

              p {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #000000;
                text-align: left;
              }

              img {
                margin-left: auto;

                @include XGA {
                  width: initial;
                }
              }
            }
          }
        }

        .selector {
          flex-direction: column;
          gap: 16px;
          width: calc(100% - 36px);
          padding: 16px;
          margin-left: 36px;
          background: #f7f7f7;
          border-radius: 0px 0px 4px 4px;
          position: absolute;
          display: none;
          z-index: 2;
          top: calc(100% - 1px);

          &.visible {
            display: flex;
          }

          button .content {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            text-align: left;
          }
        }
      }

      .button-wrapper {
        @include XGA {
          grid-column: 1/3;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .slider-wrapper {
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 100vw;
          height: 184px;
          background-image: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
          z-index: 0;
          left: -24px;
          top: 28px;

          @include XGA {
            width: 100%;
            left: 0;
          }
        }

        .meetx2-swiper {
          overflow: visible;

          @include XGA {
            overflow: hidden;
          }
        }
      }

      .button-wrapper-slider {
        margin-top: 32px;

        .navigation {
          display: flex;
          justify-content: space-around;

          button:not(.button) {
            width: 41px;
            height: 41px;
            background-color: #ed8002;
            border-radius: 50%;
            padding: 0;

            &.prev {
              img {
                transform: rotate(180deg);
              }
            }

            div {
              height: 100%;
              width: 100%;
              display: grid;
              place-items: center;

              img {
                padding-left: 5px;
                width: initial;
              }
            }
          }
        }
      }
    }
  }

  &.meet-x2 {
    .desktop-wrapper {
      @include XGA {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 707px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline: 0;
      }
    }

    .inner {
      margin-top: 24px;

      @include XGA {
        background-color: white;
        margin-top: 80px;
        display: grid;
        grid-template-columns: 250px 335px;
        padding: 56px 128px 40px;
        border-radius: 12px;
        align-self: flex-start;
        gap: 40px;

        &.swiper-container {
          display: block;
          max-width: 700px;
          padding-inline: 0;
        }
      }

      .point {
        position: absolute;
        font-weight: 700;
        font-size: 15px;
        line-height: 0;
        border: 1px solid #ed8002;
        width: 24px;
        height: 24px;
        color: #ed8002;
        display: grid;
        place-items: center;
        border-radius: 50%;
        cursor: pointer;

        &.gray {
          opacity: 0.3;
        }
      }

      .oval {
        background-color: #ffffff;
        width: 247px;
        aspect-ratio: 1/1;
        display: grid;
        place-items: center;
        border-radius: 50%;
        border: 4px solid #e5451f;
        position: relative;
      }

      .device-wrapper {
        display: grid;
        place-items: center;
        position: relative;
        z-index: 2;

        &::before {
          position: absolute;
          content: "";
          width: 100vw;
          height: 184px;
          background-image: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
          z-index: -1;

          @include XGA {
            display: none;
          }
        }

        .first {
          grid-area: first;
        }

        .second {
          grid-area: second;
        }

        .third {
          grid-area: third;
        }

        .fourth {
          grid-area: fourth;
        }

        .fifth {
          grid-area: fifth;
        }

        .oval {
          .point-0 {
            top: 20px;
            left: 71px;
          }

          .point-1 {
            top: 20px;
            right: 71px;
          }

          .point-2 {
            top: 50px;
            right: 50px;
          }

          .point-3 {
            top: 78px;
            right: 50px;
          }

          .point-4 {
            bottom: 45px;
            right: 50px;
          }

          .device {
            position: relative;
            z-index: 2;
            display: grid;
            grid-template-columns: 42px 42px;
            grid-template-areas:
              "first second"
              "first  third"
              "first  fourth"
              "fifth  fifth";

            &::before {
              position: absolute;
              content: "";
              width: 85%;
              height: 85%;
              left: 50%;
              transform: translateX(-50%);
              background-color: #393939;
              z-index: -1;
            }

            img {
              display: block;
              border-radius: 0;
            }
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 18px;

        @include XGA {
          align-self: center;
        }

        .item {
          position: relative;
        }

        button {
          padding: 0;
          width: 100%;

          .wrapper {
            display: grid;
            grid-template-columns: 36px 1fr;
            align-items: center;

            &.gray {
              opacity: 0.3;
            }

            &.active {
              img {
                transform: rotate(180deg);
              }
            }

            .point {
              position: static;
            }

            .answer {
              display: flex;
              border-bottom: 1px solid #c6c6c5;
              padding-bottom: 4px;

              p {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #000000;
                text-align: left;
              }

              img {
                margin-left: auto;

                @include XGA {
                  width: initial;
                }
              }
            }
          }
        }

        .selector {
          flex-direction: column;
          gap: 16px;
          width: calc(100% - 36px);
          padding: 16px;
          margin-left: 36px;
          background: #f7f7f7;
          border-radius: 0px 0px 4px 4px;
          position: absolute;
          display: none;
          z-index: 2;
          top: calc(100% - 1px);

          &.visible {
            display: flex;
          }

          button .content {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            text-align: left;
          }
        }
      }

      .button-wrapper {
        @include XGA {
          grid-column: 1/3;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .slider-wrapper {
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 100vw;
          height: 184px;
          background-image: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
          z-index: 0;
          left: -24px;
          top: 28px;

          @include XGA {
            width: 100%;
            left: 0;
          }
        }

        .meetx2-swiper {
          overflow: visible;

          @include XGA {
            overflow: hidden;
          }
        }
      }

      .button-wrapper-slider {
        margin-top: 32px;

        .navigation {
          display: flex;
          justify-content: space-around;

          button:not(.button) {
            width: 41px;
            height: 41px;
            background-color: #ed8002;
            border-radius: 50%;
            padding: 0;

            &.prev {
              img {
                transform: rotate(180deg);
              }
            }

            div {
              height: 100%;
              width: 100%;
              display: grid;
              place-items: center;

              img {
                padding-left: 5px;
                width: initial;
              }
            }
          }
        }
      }
    }
  }

  &.hedonisci-swiper {
    @include XGA {
      padding-bottom: 24px;
    }

    .game {
      min-height: initial;
      margin-top: 16px;

      @include XGA {
        min-height: 707px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-top: 24px;
        margin-top: 32px;
      }
    }

    .card {
      box-shadow: none;

      @include XGA {
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
        padding: 48px 0 24px;
      }

      &:after {
        display: none;
      }

      &.text,
      &.multiSelect {
        @include XGA {
          margin-top: 54px;
        }
      }

      .question {
        max-width: none;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        padding-top: 0;

        @include XGA {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          max-width: 520px;
        }
      }

      .answers-wrapper {
        box-shadow: 0 0 0 100vmax #f7f7f7;
        clip-path: inset(0 -100vmax);

        @include XGA {
          box-shadow: initial;
          clip-path: initial;
        }
      }

      .button-next {
        @include XGA {
          margin-bottom: 0;
        }
      }

      &.swiper {
        overflow: visible;

        @include XGA {
          overflow: hidden;
        }

        .question {
          margin-bottom: 8px;
        }

        .sub {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #000000;
          max-width: 459px;
          margin-inline: auto;
        }

        .button-next.last {
          border: 2px solid #2a2c2c;
          border-radius: 24px;
          background-color: transparent;
          color: #2a2c2c;
          width: fit-content;
          padding: 10px 22px 6px;

          @include XGA {
            margin-top: 16px;
          }
        }

        .main-wrapper {
          padding: 16px 0 24px;
          margin-top: 16px;
          background-color: #f7f7f7;
          box-shadow: 0 0 0 100vmax #f7f7f7;
          clip-path: inset(0 -100vmax);

          @include XGA {
            padding: 32px 0 32px;
            background-color: black;
          }

          .current-slide {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #000000;
            margin-bottom: 8px;

            @include XGA {
              display: none;
            }
          }
        }

        .hedonisci-swiper {
          width: 273px;
          overflow: visible;

          @include XGA {
            width: 416px;
          }
        }

        .swiper-slide {
          &-prev,
          &-next {
            img {
              scale: 0.9;
              transform-origin: center right;

              @include XGA {
                scale: 0.8;
              }
            }
          }

          &-next {
            img {
              transform-origin: center left;
            }
          }

          img {
            display: block;
            transition: all ease-in-out 300ms;
            max-width: 100%;
            border-radius: 12px;
          }

          p {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #2a2c2c;
            margin: 16px 0 0;

            @include XGA {
              display: none;
            }
          }
        }

        .desktop-navigation {
          margin-top: 24px;
          display: none;

          @include XGA {
            display: block;
          }

          .current-slide {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #f5aa04;
          }

          .grid-wrapper {
            display: grid;
            grid-template-columns: 32px 254px 32px;
            gap: 50px;
            margin: 0 auto;
            width: fit-content;
            height: 48px;

            button {
              width: 32px;
              height: 32px;
              padding: 0px;

              &:disabled > div {
                background: #c6c6c5;
                cursor: default;
              }
            }

            .next,
            .prev {
              background-color: #ed8002;
              border-radius: 50%;
              display: grid;
              place-items: center;
              padding-right: 2px;
              height: 100%;
            }

            .next {
              rotate: 180deg;
            }

            .names {
              align-self: center;
            }

            p {
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              color: #2a2c2c;
              max-width: 254px;
              margin: 0;
              min-width: initial;
            }
          }
        }
      }
    }
  }

  &.monthly-bonus {
    @include XGA {
      background-image: url("./monthly-bonus//bg.jpg");
      background-size: cover;
    }
    .desktop-wrapper {
      @include XGA {
        display: flex;
        justify-content: center;
        padding: 83px 144px 400px 144px;
        box-shadow: none;
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      min-width: fit-content;

      @include XGA {
        background-color: #fff;
        border-radius: 12px;
        width: 100%;
      }

      .monthly-bonus-header {
        margin-inline: auto;
        text-align: center;
        max-width: 340px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;

        @include XGA {
          margin-bottom: -10px;
        }
      }

      .monthly-bonus-content {
        @include XGA {
          margin: 0px;
          border-radius: 12px;
        }

        background: #f7f7f7;
        margin: -32px -24px;
        padding: 32px 24;

        li {
          display: flex;
          align-items: center;
          gap: 11px;
          width: 280px;

          &:last-child {
            align-items: flex-start;

            @include XGA {
              align-items: center;
            }
          }

          @include XGA {
            display: flex;
            flex-direction: column;
            width: 130px;
            text-align: center;
          }
        }

        ul {
          margin-top: 20px;

          @include XGA {
            display: flex;
            margin-inline: 10px;
          }
        }

        .monthly-bonus-component-li {
          @include XGA {
            display: flex;
            flex-direction: column;
          }
        }

        img {
          width: 24px;
          height: 24px;
          z-index: 10;
        }

        p {
          color: #868686;
          font-weight: 700;
          font-size: 15px;
          min-width: 72px;
          margin: 0;
        }

        font-style: normal;
        font-weight: 700;
        font-size: 15px;

        .monthly-bonus-content-progres {
          background-color: #fff;
          width: 10px;
          height: 32px;
          margin-left: 90px;
          margin-top: -6px;
          margin-bottom: -7px;

          @include XGA {
            height: 10px;
            width: 110px;
            margin: 40px -55px 0px -55px;
          }
        }

        .monthly-bonus-content-progres-red {
          background-color: #e5451f;
          width: 10px;
          height: 32px;
          margin-left: 90px;
          margin-top: -6px;
          margin-bottom: -7px;

          @include XGA {
            height: 10px;
            width: 110px;
            margin: 40px -55px 0px -55px;
          }
        }

        .monthly-bonus-component-header {
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          max-width: 320px;

          @include XGA {
            max-width: none;
          }
        }

        .monthly-bonus-component-subtitle {
          max-width: 320px;
          margin-top: 15px;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          text-align: center;

          @include XGA {
            max-width: none;
          }
        }

        .monthly-bonus-component-subtitle-thr {
          max-width: 320px;
          margin: 15px 0px;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          background: #e5451f;
          border-radius: 12px;
          color: #ffffff;
          padding: 15px;
          margin-inline: 5px;

          @include XGA {
            max-width: none;
            padding: 12px 32px;
          }
        }

        .monthly-bonus-component-info {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 16px;
        }

        .monthly-bonus-component-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 25px 0px;
        }

        .monthly-bonus-component-button {
          min-width: 300px;

          &.second {
            margin-top: 32px;
          }

          svg {
            margin-left: 14px;
          }
        }

        .monthly-bonus-component-button-img {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }
      }

      .monthly-bonus-terms {
        font-size: 12px;
        line-height: 12px;
        margin-top: 25px;
        padding-bottom: 15px;
        font-weight: 900;
        background: #f7f7f7;
        color: $lightorange;
        width: 100%;
        text-align: center;
        @include XGA {
          margin-top: 0px;
          padding-bottom: 15px;
          width: 100%;
          border-radius: 12px;
        }
      }
    }
  }
  &.truefalsetile {
    display: flex;
    flex-direction: column;
    .desktop-wrapper {
      @include XGA {
        margin: 40px 64px;
        display: flex;
        border-radius: 12px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        background-color: #f7f7f7;
        padding: 20px 30px;
      }
      .inner {
        @include XGA {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 120px;
        }
      }
      .copy {
        @include XGA {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 50%;
        }
        .how-to {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          margin-top: 20px;
          @include XGA {
            font-size: 24px;
            line-height: 32px;
            margin: 0px;
          }
        }
        .how-to-sub {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          @include XGA {
            font-size: 15px;
            line-height: 20px;
            margin: 5px 0;
          }
        }
      }
      .board {
        display: flex;
        flex-direction: column;
        margin: 60px 0;
        @include XGA {
          width: 50%;
        }
        button {
          padding: 0;
          margin: -1px 0;
          .img-wrapper {
            display: flex;
            justify-content: center;
            img {
              width: 100%;
              max-width: 600px;
              border-radius: 0;
            }
          }
        }
      }
    }
    .button-wrapper {
      display: flex;
      button {
        display: flex;
        gap: 5px;
        align-items: center;
        img {
          padding-bottom: 2px;
        }
      }
      @include XGA {
        display: flex;
        margin-right: 64px;
      }
    }
  }

  &.marzec2_2023,
  &.kwiecien1_2023 {
    margin-top: 48px !important;

    .card {
      position: relative;
      z-index: 2;

      @include XGA {
        min-height: 350px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100vw;
        height: 250px;
        top: -32px;
        left: -24px;
        background-color: #f7f7f7;
        z-index: -1;
      }

      &:after {
        bottom: -31px;
      }
    }

    .question {
      font-weight: 400 !important;
    }

    .answers-wrapper {
      @include XGA {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
      }
    }

    .button-next {
      @include XGA {
        margin-bottom: 0 !important;
      }
    }
  }

  &.kwiecien1_2023 {
    .question {
      font-weight: 700 !important;
    }

    .card {
      @include XGA {
        min-height: 610px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
      }
    }

    .answers-wrapper {
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 32px;
      flex-direction: column;

      @include XGA {
        flex-direction: row;
      }

      .answer {
        margin: 0 !important;
        max-width: 192px !important;
      }
    }
  }
}

.zapodaj-rymem {
  .mobile {
    display: block;

    @include XGA {
      display: none;
    }
  }

  .desktop {
    display: none;

    @include XGA {
      display: block;
    }
  }

  .link {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    a {
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      text-decoration-line: underline;
      color: #ed8002;
    }
  }

  .card {
    background-color: white;
    min-height: initial;

    @include XGA {
      margin-top: 0;
    }

    .image {
      margin-bottom: 24px;

      @include XGA {
        display: none;
      }

      img {
        width: 100%;
        border-radius: 12px 12px 0 0;
        display: block;
      }
    }
  }

  .reward {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 32px;

    @include XGA {
      max-width: 640px;
      border: none;
      border-radius: 12px;
      background-color: #f7f7f7;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      margin-top: 16px;
    }

    .sub {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 259px;

      @include XGA {
        max-width: initial;
        color: #2a2c2c;
      }
    }

    .points {
      margin-top: 0;
    }
  }

  &::after {
    top: 500px;
    z-index: -10;
  }

  .desktop-card {
    @include XGA {
      background-color: white;
      max-width: 863px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 48px;
      padding-bottom: 24px;
      border-radius: 12px;
    }
  }

  .content-wrapper {
    @include XGA {
      background-image: url("./konkurs3_cf/img/bg-mobile.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 12px;
      padding: 63px;
      margin-top: 32px;
    }
  }

  .intro {
    h2.title {
      @include XGA {
        max-width: 537px;
        width: 100%;
      }
    }

    p.sub {
      max-width: 277px;
      width: 100%;
      color: #2a2c2c;
      margin-top: 16px;
      margin-bottom: 0;

      @include XGA {
        max-width: 537px;
        color: #868686;
      }
    }

    .points {
      margin-top: 12px;
    }

    .button-wrapper {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .rewards {
    h1 {
      margin-top: 24px;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;

      @include XGA {
        margin-top: 0;
      }
    }

    .coupon {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 97px auto;
      border: 1px solid #f7f7f7;
      max-width: 295px;
      border-radius: 12px;
      margin-left: auto;
      margin-right: auto;

      @include XGA {
        max-width: 636px;
      }

      .coupon-image {
        height: 97px;
        background-color: #f7f7f7;
        display: grid;
        place-items: center;
        border-radius: 12px 0 0 12px;
      }

      .coupon-content {
        margin-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .places {
          font-weight: 700;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          color: #e5451f;
          width: 100%;
        }

        .text {
          margin-top: 2px;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          text-align: left;
          width: 100%;
          padding-right: 16px;
        }
      }
    }

    .button-wrapper {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .form {
    .card {
      box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      width: 100%;

      p.label {
        margin-left: 0;
      }

      .form {
        margin-top: 16px;
        padding-top: 0;
        padding-bottom: 0;

        .agreements {
          margin-top: 16px;
        }
      }

      .image {
        display: grid;

        img {
          @include XGA {
            display: none;
          }
        }

        & > * {
          grid-row: 1/2;
          grid-column: 1/2;
        }

        .text {
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
          max-width: 279px;
          align-self: center;
          margin-left: auto;
          margin-right: auto;
        }
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        margin: 0;
      }

      .text-area {
        padding-left: 12px;
        padding-right: 12px;
        margin-top: 12px;
        width: 100%;

        div {
          width: calc(min(280px, 100%));
          margin-left: auto;
          margin-right: auto;
        }

        label {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #000000;
        }

        textarea {
          resize: none;
          margin-top: 4px;
          border: 1px solid #868686;
          border-radius: 12px;
          display: block;
          width: calc(min(280px, 100%));
          padding: 10px;
          margin-left: auto;
          margin-right: auto;
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #2a2c2c;
          max-width: initial;
          width: 100%;
          margin-left: 0;
          text-align: left;
          margin-top: 8px;
        }
      }
    }

    &.content-wrapper {
      @include XGA {
        background-image: none;
        padding: 0;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 574px;
          background-image: url("./konkurs3_cf/img/bg-mobile.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          top: 0;
          border-radius: 12px;
        }
      }
    }

    @include XGA {
      .card {
        box-shadow: none;
      }

      h1 {
        padding-top: 48px;
      }

      .desktop-card {
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
      }

      .form,
      .button-wrapper {
        margin-bottom: 0;
      }

      .form {
        display: grid;
        grid-template-columns: auto 416px auto;

        .user-info {
          grid-column: 2/3;
        }
      }

      .area-wrapper {
        background: #f7f7f7;
        border-radius: 12px;
        padding-top: 32px;
        padding-bottom: 16px;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
        margin-bottom: 32px;
        display: grid;
        grid-template-columns: auto 416px auto;

        .text {
          background: linear-gradient(90deg, #e5451f 0%, #f5aa04 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          grid-column: 2/3;
          margin-bottom: 24px;
        }

        .text-area {
          grid-column: 2/3;
          padding: 0;
          margin-top: 0;

          div {
            width: calc(min(416px, 100%));
          }

          textarea {
            background-color: transparent;
            width: calc(min(416px, 100%));
            margin-top: 8px;
            max-height: 97px;

            &:focus-visible {
              border-color: #ed8002;
              outline-color: #ed8002;
            }
          }
        }
      }
    }
  }

  .summary {
    p.sub {
      margin-top: 8px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #2a2c2c;
    }

    @include XGA {
      .card {
        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #868686;
        }
      }

      .button-wrapper {
        margin-bottom: 0;
        margin-top: 32px;
      }
    }

    &.wrong {
      .button-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: fit-content;

        @include XGA {
          flex-direction: row;
        }
      }
    }
  }
}

.bonus6m {
  .card {
    .card-body {
      padding: 24px;
    }

    .card-img {
      width: 100%;
      height: 183px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .header {
    box-shadow: 0 0 0 100vmax white;
    clip-path: inset(0 -100vmax);
    background-color: white;
  }
}

section.hedonisci {
  .header {
    box-shadow: 0 0 0 100vmax white;
    clip-path: inset(0 -100vmax);
    background-color: white;
  }

  .background-desktop {
    @include XGA {
      background-image: var(--bg-image);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 80px;
      padding-bottom: 20px;
      min-height: 706px;
      margin-top: 32px;

      .card {
        max-width: 800px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        margin: 0 auto;
        min-height: initial;
        position: static;
        padding-top: 48px;
        padding-bottom: 32px;

        @include HD {
          max-width: 863px;
        }

        .content {
          position: static;
          transform: initial;
        }
      }
    }
  }

  .card {
    padding: 0;

    .image {
      img {
        width: 100%;
        display: block;
      }
    }

    .content {
      padding: 32px 16px;

      @include XGA {
        padding: 0;
      }

      h3.title {
        max-width: 279px;
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #2a2c2c;
        margin: 0 auto;

        @include XGA {
          color: #000000;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          max-width: 640px;
        }
      }

      p.sub {
        max-width: 255px;
        width: 100%;
        margin-top: 8px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #2a2c2c;

        @include XGA {
          margin-top: 24px;
          margin-bottom: 0;
          padding: 24px;
          background-color: #f7f7f7;
          overflow: hidden;
          width: 100%;
          max-width: 100%;
        }
      }

      .rewards-wrapper {
        margin-top: 12px;

        @include XGA {
          margin-top: 24px;
          padding: 32px 0;
          background-color: #f7f7f7;
        }

        .reward {
          display: grid;
          grid-template-columns: 97px 1fr;
          min-height: 97px;
          border: 1px solid #f7f7f7;
          border-radius: 12px;
          margin-top: 8px;
          gap: 16px;

          @include XGA {
            max-width: 440px;
            margin-left: auto;
            margin-right: auto;
            border-color: #e5e5e5;
            background-color: white;
          }

          * {
            margin-left: 0;
            width: fit-content;
            margin: 0;
            text-align: left;
          }

          &-content {
            padding-top: 10px;
            padding-bottom: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include XGA {
              justify-content: space-around;
            }
          }

          &-img {
            display: grid;
            place-items: center;
            background: #f7f7f7;
            border-radius: 12px 0px 0px 12px;
            width: 100%;

            img {
              max-width: 72px;
            }
          }

          &-place {
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            color: #e5451f;
          }

          &-title {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            margin-top: 2px;
          }

          &-points {
            background: #e5451f;
            border-radius: 24px;
            padding: 3px 8px 1px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
            min-width: initial;
          }
        }
      }

      &.end {
        h4 {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;

          @include XGA {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
          }
        }

        &.wrong {
          p {
            max-width: 279px;
            width: 100%;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #868686;
            margin-bottom: 0;
            margin-top: 8px;

            @include XGA {
              max-width: 580px;
            }
          }
        }
      }
    }
  }

  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2a2c2c;
    margin: 0 auto;
  }

  .reward-participate {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    padding: 16px;

    @include XGA {
      border: none;
      padding: 0;
      border-radius: 0;
    }

    &.end {
      @include XGA {
        padding: 24px 0;
        background-color: #f7f7f7;
      }

      p {
        max-width: 259px;

        @include XGA {
          max-width: initial;
        }
      }
    }

    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #2a2c2c;
      max-width: 143px;
      margin: 0;

      &.points {
        color: white;
        font-weight: 700;
        width: fit-content;
        min-width: initial;
      }
    }
  }

  .how-to-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;

    @include XGA {
      margin-top: 24px;
      padding: 32px 0;
      background-color: #f7f7f7;
    }

    div:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-weight: 700;
      font-size: 15px;
      border: 1px solid $lightorange;
      background: linear-gradient(
        53.04deg,
        #ee4823 37.12%,
        #ee4f22 41.3%,
        #f06022 47.18%,
        #f37820 54.09%,
        #f7951d 61.71%,
        #faa61a 65.4%,
        #faa61a 65.47%
      );
      color: white;
      border: none;
      grid-column: 1/2;
      margin: 0;
      opacity: 1;
      min-width: initial !important;
      max-width: initial !important;
      margin-top: 18px;

      @include XGA {
        width: 32px;
        height: 32px;
        font-size: 18px;
        line-height: 24px;
      }
    }

    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      max-width: 287px;
      margin-top: 10px;

      @include XGA {
        margin-top: 12px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        max-width: 472px;
      }
    }
  }

  .custom-game {
    margin-top: 0;
    padding-top: 28px;
    // full bleed
    box-shadow: 0 0 0 100vmax white;
    clip-path: inset(0 -100vmax);

    &::after {
      display: none;
    }

    .question {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      margin: 0;
      max-width: initial;
      width: 100%;

      @include XGA {
        max-width: 594px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .sub {
      max-width: initial;
      width: 100%;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      margin-top: 12px;
      margin-bottom: 18px;
    }

    .answers {
      margin-top: 20px;
      padding: 24px 20px;
      box-shadow: 0 0 0 100vmax #f7f7f7;
      clip-path: inset(0 -100vmax);
      background-color: #f7f7f7;
      display: grid;
      gap: 24px;
      padding-left: 36px;

      @include XGA {
        clip-path: none;
        box-shadow: none;
        padding-left: 304px;
      }

      &.textarea {
        padding-left: 0;
        padding-right: 0;

        @include XGA {
          .question {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
          }
        }

        textarea {
          resize: none;
          background-color: #f7f7f7;
          aspect-ratio: 327/120;
          width: 100%;
          border: 1px solid #c6c6c5;
          border-radius: 12px;
          padding: 12px 16px;

          @include XGA {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            aspect-ratio: 64/10;
          }
        }
      }

      .answer {
        margin: 0;
        padding: 0;
        border: 0;
        appearance: none;
        background-color: transparent;

        .answer-content {
          display: flex;
          align-items: center;
          gap: 12px;

          .radio {
            border: 1px solid #000000;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: relative;

            &.selected {
              &::before {
                content: "";
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: #000000;
              }
            }
          }

          .content {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            margin: 0;
            padding: 0;

            @include XGA {
              background-color: transparent;
              text-align: left;
            }
          }
        }
      }
    }

    .info {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #000000;
      margin-top: 16px;

      @include XGA {
        margin-top: 8px;
      }
    }

    .footer {
      p {
        max-width: 233px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: black;

        @include XGA {
          max-width: initial;
          margin-top: 24px;
        }
      }
    }
  }

  .link {
    width: fit-content;
    margin: 0 auto;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #ed8002;

    @include XGA {
      font-weight: 900;
      font-size: 14px;
      line-height: 20px;
      margin-top: 24px;
    }

    &.small {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    a {
      color: #ed8002;
    }
  }

  .button-wrapper {
    width: fit-content;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include XGA {
      margin-top: 32px;
      margin-bottom: 0;
    }
  }

  .mobile {
    @include XGA {
      display: none;
    }
  }

  .desktop {
    display: none;

    @include XGA {
      display: block;
    }
  }
}

section.game.full-bg {
  position: relative;
  z-index: 1;

  @include XGA {
    z-index: 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: calc(100% + 32px);
    background-image: url("./grudzien0_2022/img/bg-mobile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    left: 0;
    top: -24px;
    border-radius: 12px 12px 0 0;

    @include XGA {
      display: none;
    }
  }

  .header {
    button {
      img {
        filter: invert(51%) sepia(99%) saturate(1726%) hue-rotate(3deg)
          brightness(97%) contrast(98%);
      }
    }

    h2.title {
      color: white;

      @include XGA {
        color: black;
      }
    }
  }

  .calendar-december {
    margin-top: 16px;

    @include XGA {
      margin-top: 32px;
      margin-bottom: 80px;
      padding: 80px 80px;
      background-image: url("./grudzien0_2022/img/bg-desktop.webp");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    @include HD {
      padding-inline: 11%;
    }

    .main-wrapper {
      @media (max-width: 500px) {
        margin-left: -24px;
        width: calc(100% + 48px);
      }

      @media (min-width: 500px) {
        width: 500px;
        margin-left: calc(-24px + ((100vw - 500px) / 2));
      }

      @include XGA {
        width: 100%;
        margin-inline: auto;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
        border-top: 1px solid #4d6156;
        border-left: 1px solid #4d6156;

        @include XGA {
          grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        }

        @include HD {
          grid-template-columns: repeat(8, minmax(0px, 1fr));
        }

        button {
          aspect-ratio: 1/1;
          display: grid;
          place-items: center;
          font-weight: 700;
          font-size: 18px;
          line-height: 133%;
          border: 1px solid #ffb101;
          color: #ffb101;

          &:disabled {
            cursor: initial;
            border-left: none;
            border-top: none;
            border-bottom: 1px solid #4d6156;
            border-right: 1px solid #4d6156;
            color: #ffffff;
          }

          .redeemed {
            color: #868686;
          }
        }
      }
    }
  }
}

.wrapper-calendar-modal {
  border-radius: 12px !important;

  .wrapper-meetx2 {
    max-height: 400px !important;
    height: 100%;

    @include XGA {
      max-width: 327px !important;
      height: 353px;
    }

    .content {
      .calendar-modal {
        background: #ffffff !important;
        opacity: 0.9;

        .exit {
          svg {
            width: 14px;
            height: 14px;
          }
        }

        p,
        h2 {
          margin: 0;
          align-self: flex-end;
        }

        p,
        h2,
        img {
          margin-left: 24px;

          @include XGA {
            margin-inline: auto;
          }
        }

        .button {
          @include XGA {
            margin-inline: auto;
            margin-bottom: 32px;
          }
        }

        p {
          margin-bottom: 141px;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          color: #000000;

          @include XGA {
            margin-bottom: 157px;
          }
        }

        h2 {
          margin-bottom: 81px;
          font-weight: 900;
          font-size: 48px;
          line-height: 56px;
          letter-spacing: -0.02em;
          color: #000000;

          @include XGA {
            margin-bottom: 97px;
          }
        }

        img {
          margin-top: 24px;
          width: 72px;
          height: 72px;

          @include XGA {
            margin-top: 32px;
          }
        }
      }
    }
  }
}
