@import "../../../styles/vars";

.login-page {
    --height: 720px;
    position: relative;
    height: var(--height);
    color: white;
    display: grid;
    grid-template-rows: 1fr auto;
    transition: height 0.2s linear;
    min-height: 720px;

    @include XGA {
        min-height: 758px;
    }

    .versionId {
        margin: 0;
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 10px;
        color: #fff;
        opacity: 0.2;
    }

    .login-background {
        background-image: url(./img/bg-mobile.webp);
        background-position: left center;
        background-repeat: no-repeat;
        min-width: 0;
        display: grid;
        place-items: center;
        // background-size: 100% 100vh;
        background-size: cover;

        @include XGA {
            background-image: url(./img/bg-dekstop.webp);
            background-position: center;
            background-size: cover;
        }
    }

    .form-bottom {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        margin: 24px auto calc(max(3.5vh, 24px));
        max-width: 400px;
        width: 100%;

        .checkbox {
            input {
                display: none;
            }

            label {
                position: relative;
                font-size: 12px;
                font-weight: 700;
                padding-left: 28px;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -5px;
                    width: 20px;
                    height: 20px;
                    opacity: 0.8;
                    border: 1px solid #777776;
                    box-sizing: border-box;
                    border-radius: 5px;
                }
            }

            input:checked+label {
                &::before {
                    background-color: $lightorange;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: -2px;
                    width: 5px;
                    height: 10px;
                    border-bottom: #fff solid 1px;
                    border-right: #fff solid 1px;
                    transform: rotate(45deg);
                }
            }
        }
    }

    .login-page-inner {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        align-self: center;
        text-align: center;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        min-width: 0;
        transition: backdrop-filter 500ms ease-in-out;

        &.blur {
            backdrop-filter: blur(30px);
        }

        .logo {
            padding-top: calc(max(6vh, 40px));

            @include XGA {
                padding-top: 6vh;
            }

            img {
                width: calc(min(30vw, 140px));
            }
        }

        h2 {
            font-weight: 600;
        }

        form {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .selector {
            max-width: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;

            @include XGA {
                margin-top: auto;
            }

            div {
                width: 50%;
                color: rgba(255, 255, 255, 0.5);
                border-bottom: 1px solid white;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                padding-top: 9px;
                padding-bottom: 8px;

                a {
                    color: rgba(255, 255, 255, 0.5);
                    text-decoration: none;
                }

                &.selected {
                    border-radius: 12px 12px 0px 0px;
                    border: 1px solid white;
                    border-bottom: none;
                    color: white;
                }
            }
        }

        .text {
            margin-bottom: 24px;

            .welcome {
                margin-bottom: 16px;
                margin-top: 5vh;

                @include XGA {
                    margin-top: 5vh;
                }

                @include UXGA {
                    margin-top: 8vh;
                }

                h3 {
                    margin: 0;
                    text-transform: uppercase;
                    font-weight: 900;
                    font-size: 40px;
                    line-height: 40px;
                    max-width: 245px;
                    width: 100%;
                    margin-inline: auto;

                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            h4 {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                margin: 0 auto;
                max-width: 239px;
            }

            .logos {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 24px;
                margin-top: 4px;

                img {
                    display: block;
                }
            }

            .wrapper {
                padding: 12px 0;
                background-color: lightblue;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 12px;
                max-width: 400px;
                margin: 0 auto;

                .flex {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    margin-top: 2px;

                    h4 {
                        margin: 0;
                    }

                    img {
                        width: 67px;
                    }
                }
            }
        }

        .swiper-login,
        .desktop-svgs {
            .wrapper {
                h1 {
                    margin: 0;
                    font-family: 'Mont';
                    font-style: italic;
                    font-weight: 900;
                    font-size: 36px;
                    line-height: 36px;
                    text-transform: uppercase;
                    color: #F5F5F5;
                    text-align: left;
                    text-align: center;

                    @include XGA {
                        text-align: left;
                    }

                    img {
                        margin-right: -2px;
                        margin-left: 3px;
                    }
                }

                p {
                    margin: 0;
                    font-family: 'Mont';
                    font-style: italic;
                    font-weight: 900;
                    font-size: 10px;
                    line-height: 10px;
                    color: #F5F5F5;
                    text-align: left;
                    margin-top: 3px;
                    text-align: center;

                    @include XGA {
                        text-align: left;
                    }
                }
            }
        }

        .swiper-login {
            margin-bottom: auto;
            margin-top: auto;
            max-width: 100%;

            @include XGA {
                display: none;
            }

            .swiper-slide {
                .wrapper {
                    width: fit-content;
                    margin-inline: auto;
                }
            }
        }

        .desktop-svgs {
            display: none;
            margin: 0 11%;
            margin-top: auto;
            margin-bottom: 6.5vh;
            // position: relative;
            // justify-content: space-between;
            // align-items: center;
            
            @include XGA {
                display: block;
            }

            img {
                width: 100%;
            }

            // &::before {
            //     content: "";
            //     position: absolute;
            //     width: calc((100% - 645px) / 2);
            //     background-color: #F5F5F5;
            //     height: 5px;
            //     top: 23px;
            //     left: 211px;
            // }

            // &::after {
            //     content: "";
            //     position: absolute;
            //     width: calc((100% - 635px) / 2);
            //     background-color: #F5F5F5;
            //     height: 5px;
            //     top: 23px;
            //     left: calc(calc((100% - 645px) / 2) + 384px);
            // }

            // .wrapper-1 {
            //     position: relative;
            //     z-index: 2;

            //     &::before {
            //         content: "";
            //         position: absolute;
            //         width: 24px;
            //         height: 5px;
            //         background-color: #F5F5F5;
            //         top: 23px;
            //         left: -12px;
            //     }
            // }
        }

        .user-data {
            display: block;
            margin: 0 auto;
            max-width: 400px;
            width: 100%;

            .input {
                position: relative;

                .valid-icon {
                    position: absolute;
                    top: 20px;
                    right: 0;
                }

                .view-icon {
                    position: absolute;
                    right: 0;
                    top: 20px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    background-image: url(../ResetPage/img/preview.svg);
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    &.disable {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 24px;
                            height: 1px;
                            background-color: #fff;
                            transform: translate(-50%, -50%) rotate(30deg);
                        }
                    }
                }
            }

            input {
                display: block;
                width: 100%;
                margin-bottom: 12px;
                background: transparent;
                border: none;
                border-bottom: 1px solid white;
                padding: 4px 0px;
                color: white !important;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                outline: none;
                transition: all 0.2s $CubicEaseInOut;

                &:focus {
                    border-color: $lightorange;
                }

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    border-color: #fff;
                    background-color: transparent;
                    -webkit-text-fill-color: #fff;
                    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
                    transition: background-color 5000s ease-in-out 0s;
                }
            }

            input[type="password"] {
                line-height: 16px;
            }

            label {
                float: left;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: white !important;
            }
        }

        .buttons {
            @include XGA {
                padding-bottom: calc(max(3vh, 40px));
            }

            .button {
                width: 200px;
            }
        }

        .forgot {
            margin: 0;
            margin-left: auto;

            &:only-child {
                margin: 0 auto;

                span {
                    font-weight: 900;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                text-decoration: underline;
                color: white;
                cursor: pointer;
            }
        }

        .disclaimer {
            margin: 0 auto;
            display: block;
            width: 90%;
            max-width: 600px;
            font-size: 10px;
            margin-top: 24px;
        }

        .error {
            position: relative;
            top: -6px;
            font-size: 12px;
            color: #EA1D1D;
            text-align: left;

            &.long {
                font-size: 10px;

                @include vHD {
                    font-size: 12px;
                }
            }

            &:before {
                content: "!";
                position: relative;
                top: -2px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background-color: #EA1D1D;
                color: #fff;
                border-radius: 50%;
                margin-right: 6px;
                font-size: 9px;
                line-height: 15px;
                font-weight: 700;
                text-align: center;
            }
        }
    }

    footer {
        align-self: flex-end;
        margin-top: 0;

        .footer-bottom {
            background-color: white;
            padding-bottom: 24px;
        }
    }
}

.remind-password {
    .new-modal-header {
        position: relative;
        display: grid;
        place-items: center;
        width: 100%;
        height: 80px;
        background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
        border-radius: 12px 12px 0px 0px;

        button {
            position: absolute;
            top: 16px;
            right: 16px;
            border: none;
            background: transparent;
            padding: 0;
            filter: brightness(0) invert(1);
            cursor: pointer;
        }
    }

    h3 {
        font-size: 18px;
        margin: 0;
    }

    p {
        margin: 8px auto 17px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #2A2C2C;
        max-width: 424px;
        width: 100%;

        @include XGA {
            margin: 16px auto 25px;
        }
    }

    form {
        max-width: 280px;
        margin: 0 auto;
    }

    .error {
        position: relative;
        top: -6px;
        font-size: 12px;
        color: #EA1D1D;
        text-align: left;

        &.long {
            font-size: 8px;
        }

        &:before {
            content: "!";
            position: relative;
            top: -2px;
            display: inline-block;
            width: 14px;
            height: 14px;
            background-color: #EA1D1D;
            color: #fff;
            border-radius: 50%;
            margin-right: 6px;
            font-size: 9px;
            line-height: 15px;
            font-weight: 700;
            text-align: center;
        }
    }

    input {
        display: block;
        width: 100%;
        margin-bottom: 12px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #000;
        padding: 4px 0px;
        color: #000 !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        outline: none;
        transition: all 0.2s $CubicEaseInOut;

        &:focus {
            border-color: $lightorange;
        }
    }

    input[type="password"] {
        line-height: 16px;
    }

    label {
        float: left;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        color: #000 !important;
    }

    .button {
        margin-top: 32px;
    }

    &.m2-not-found {
        @include XGA {
            padding: 40px 80px 24px !important;
        }

        h3 {
            text-align: left;
            font-size: 48px;
            line-height: 56px;
            font-weight: 900;
            margin-bottom: 24px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
            text-align: left;
            margin: 0;

            &.hide {
                opacity: 0;
            }

            &.time {
                margin-top: 8px;
            }

            &.bottom {
                margin-top: 180px;

                @include XGA {
                    margin-top: 24px;
                }
            }
        }

        .button.orange {
            margin-top: 24px;
        }
    }
}