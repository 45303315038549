@import "../../../styles/vars";

.floating-wrapper {
	position: relative;
	z-index: 99;
	opacity: 1;
	transition: opacity .5s cubic-bezier(0.645, 0.045, 0.355, 1);

	&.hide {
		opacity: 0;
	}
}

.floating-button {
	position: fixed;
	right: 3%;
	bottom: 50px;
	background: linear-gradient(to bottom, #ED8002 0%, #F9B92B 100%);
	color: #fff;
	width: 140px;
	height: 140px;
	border-radius: 50%;
	padding: 6px;
	cursor: pointer;
	z-index: 100;
	box-shadow: 0px 34px 30px 10px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;

	@include XGA {
		width: 220px;
		height: 220px;
		padding: 10px;
		bottom: 10vh;
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: linear-gradient(to bottom, #ED8002 0%, #F9B92B 100%);
		box-shadow: 0px 2px 2px 3px rgba(0, 0, 0, 0.15);
		cursor: pointer;

		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
			width: 1px;
			height: 14px;
			background-color: #fff;
		}

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 1px;
			height: 14px;
			background-color: #fff;
		}

		@include XGA {
			width: 48px;
			height: 48px;

			&::before, &::after {
				height: 20px;
				width: 2px;
			}
		}
	}

	.content {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
		overflow: hidden;
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		align-items: center;
		border-radius: 50%;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			transform: translate(-45%, -35%);
			width: 120%;
			height: 120%;
			background-color: #fff;
			z-index: 0;
			border-radius: 50%;
			opacity: 0.1;
		}
	}

	&>div, img {
		position: relative;
		z-index: 2;
	}

	h4 {
		color: #000;
		margin: 0;
		font-size: 19px;
		line-height: 17px;
		font-weight: 700;

		@include XGA {
			font-size: 29px;
			line-height: 27px;
		}

		span {
			color: #fff;
		}
	}

	img {
		margin-right: 2px;
		width: 40px;
		left: 0%;

		@include XGA {
			width: auto;
			margin-right: 6px;
		}
	}

	p {
		margin: 0;
		font-size: 9px;
		line-height: 10px;
		font-weight: 700;

		@include XGA {
			font-size: 14px;
			line-height: 16px;
		}
	}

	.inner {
		width: fit-content;
		visibility: visible;
	}


	&.hidden {
		width: 50px;
		height: 50px;
		bottom: 15%;
		box-shadow: none;
		animation: pulse 2s infinite;

		img {
			left: 15.5%;
			height: 35px;
			width: 35px;
		}

		.inner {
			visibility: hidden;
			width: 0px;
		}

		.close {
			display: none;
		}
	}
	
	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 $lightorange;
		}
	
		70% {
			transform: scale(1);
			box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
		}
	
		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}

	@keyframes pulse-bronze {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 #57332E;
		}
	
		70% {
			transform: scale(1);
			box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
		}
	
		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}

	@keyframes pulse-black {
		0% {
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.45);
		}
	
		70% {
			box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
		}
	
		100% {
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}

	@keyframes pulse-red {
		0% {
			transform: scale(0.96);
			box-shadow: 0 0 0 0 $darkorange;
		}
	
		70% {
			transform: scale(1);
			box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
		}
	
		100% {
			transform: scale(0.96);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}

	&.new-variants {
		background: #DA3812;

		&.hidden {
			animation: pulse-bronze 2s infinite;
		}

		.inner {
			h4 {
				color: #FBBC2C;

				span {
					color: #fff;
				}
			}
		}

		.close {
			background: #DA3812;
		}
	}

	&.floating-new-colors {
		background: none;

		.close {
			z-index: 5;
			background: #5FA98E !important;
			border: none;
		}
		
		img {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}
}