@import "../../../../styles/vars";

.article-page {
  position: relative;
  margin-bottom: 24px;
  margin-top: 0;
  width: 100%;
  margin-top: -12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;

  .read-more {
    background-color: #000;
    padding: 32px 0;

    @include XGA {
      padding: 40px 0;
    }

    & > .container {
      position: relative;

      .like-wrapper {
        top: -48px;

        @include XGA {
          top: -57px;
        }
      }
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      margin: 0;
      margin-bottom: 24px;
      color: #fff;
      font-weight: 900;
    }

    .swiper-awards {
      overflow: visible;
      position: static;

      @include XGA {
        padding: 10px 10px 10px 0px;
      }

      @include HD {
        overflow: visible;
        left: -10px;
        width: calc(100% + 20px);
        padding: 10px;
        padding-left: 0px;
      }

      .swiper-slide {
        width: 320px;
        height: auto;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        margin-bottom: 8px;
        background-color: #fff;

        @include HD {
          min-width: calc(25% - 12px);
          width: calc(25% - 12px);
          max-width: calc(25% - 12px);
        }

        * {
          text-decoration: none;
        }

        a {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .img {
          height: 161px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 12px 12px 0 0;
        }

        .content {
          padding: 11px 16px 20px;
          display: flex;
          flex-direction: column;
          flex: 1;

          @include XGA {
            padding: 24px;
          }

          .article-data {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-bottom: 8px;

            & > div {
              color: #f5aa04;
              font-size: 10px;
              line-height: 16px;
              font-weight: 700;
              margin-right: 16px;

              @include UXGA {
                font-size: 12px;
              }

              &.article-category {
                text-transform: uppercase;
              }

              &.article-publication {
                font-weight: 300;
              }
            }
          }

          .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: black;
            margin-bottom: 16px;
          }

          .arrow {
            display: block;
            margin-top: auto;
          }
        }

        &:hover {
          .arrow {
            animation: arrow 1s $QuadEaseInOut infinite;
          }
        }
      }
    }
  }

  @include XGA {
    width: 100%;
    margin-top: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  img {
    max-width: 100%;
  }

  h1,
  h3,
  p {
    a {
      color: $lightorange;
    }
  }

  p,
  li {
    font-size: 15px;

    @include XGA {
      font-size: 16px;
      line-height: 22px;
    }

    @include UXGA {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .highlighted {
    p,
    li {
      max-width: 860px;
    }
  }

  .article-head {
    position: relative;
    margin-top: 32px;
    padding-bottom: 16px;
    margin-bottom: 24px;

    @include XGA {
      margin-top: 56px;
      padding-bottom: 24px;
      margin-bottom: 40px;
    }

    h2,
    h1 {
      margin: 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;

      @include XGA {
        font-size: 32px;
        line-height: 40px;
      }
    }

    h3 {
      margin: 0;
      font-size: 16px;
      line-height: 28px;
      font-weight: 900;

      @include XGA {
        font-size: 24px;
        line-height: 40px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 86px;
      height: 2px;
      background-color: #000;
    }
  }

  .article-top {
    position: relative;
    display: flex;
    padding: 24px 0;
    background-size: cover;
    background-position: center;
    height: 230px;

    @include XGA {
      height: 500px;
      padding: 32px 0;
    }

    @include UXGA {
      height: 560px;
    }

    .image-cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 0%,
        #666666 100%
      );
      mix-blend-mode: multiply;
    }

    .article-container {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: flex-start;
    }

    h1 {
      font-size: 32px;
      font-weight: 700;
      margin: 0;
      color: #fff;

      @include XGA {
        font-size: 72px;
      }
    }

    .go-back {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
    }

    .article-data {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin-bottom: 24px;
      margin-left: 3px;

      & > div {
        color: #fff;
        font-size: 10px;
        line-height: 16px;
        font-weight: 700;
        margin-right: 16px;

        @include XGA {
          font-size: 14px;
        }

        @include UXGA {
          font-size: 12px;
        }

        &.article-category {
          text-transform: uppercase;
        }

        &.article-publication {
          font-weight: 300;
        }
      }
    }

    .like-wrapper {
      bottom: -40px;

      @include XGA {
        bottom: -48px;
      }
    }
  }

  .article-content {
    .article-text {
      margin: 24px 0;

      h3 {
        font-size: 17px;

        @include XGA {
          font-size: 24px;
          line-height: 125%;
        }
      }

      &.with-border {
        position: relative;
        background-color: #ffffff;
        border: 2px solid $lightorange;
        border-radius: 12px;
        padding: 24px 24px;
        max-width: calc(100% - 24px);
        left: -12px;
        z-index: 2;
        margin-bottom: 48px;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100vw;
          left: 24px;
          top: 24px;
          z-index: -1;
          background-color: $lightorange;
          border-bottom-left-radius: 12px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          z-index: -1;
          border-radius: 12px;
        }

        @include XGA {
          margin-bottom: 64px;
          max-width: 85%;
          left: 0;
          padding: 40px 48px;

          &::before {
            width: 900px;
            border-radius: 12px;
            left: auto;
            right: -18%;
          }
        }
      }

      @include XGA {
        margin: 40px 0;
      }

      &.highlighted {
        position: relative;

        p,
        h1,
        h2,
        h3,
        ul {
          position: relative;
          padding-right: 15px;
          max-width: initial;
        }

        &::before {
          content: "";
          background-color: #f7f7f7;
          position: absolute;
          left: 30px;
          top: 48px;
          width: calc(100% - 30px);
          height: calc(100% - 24px);
          border-radius: 12px;

          @include XGA {
            top: -24px;
            height: calc(100% + 48px);
            width: calc(100% - 30px);
          }
        }
      }
    }

    .article-text-cta {
      margin: 24px 0;
      position: relative;
      align-items: center;

      .article-text-cta-text {
        width: 100%;

        p {
          max-width: 100%;
        }
      }

      p,
      h1,
      h2,
      h3,
      ul,
      .article-text-cta-buttons {
        position: relative;
      }

      .article-text-cta-buttons {
        display: block;
        margin: 0 auto;
        max-width: 240px;
        min-width: 240px;

        @include XGA {
          margin-left: 20px;
        }

        button,
        a {
          width: 240px;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @include XGA {
        margin: 40px 0;
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      &.highlighted {
        &::before {
          content: "";
          background-color: #f7f7f7;
          position: absolute;
          left: 30px;
          top: 48px;
          width: calc(100% - 30px);
          height: calc(100% - 24px);
          border-radius: 12px;

          @include XGA {
            top: -24px;
            height: calc(100% + 48px);
            width: calc(100% - 60px);
          }
        }

        .button {
          width: 240px;
        }
      }
    }

    .article-columns {
      &.force-mobile {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        & > .article-column {
          min-width: calc(50% - 16px);
          max-width: calc(50% - 16px);
          width: calc(50% - 16px);
        }
      }

      &.with-border {
        position: relative;
        background-color: #ffffff;
        border: 2px solid $lightorange;
        border-radius: 12px;
        padding: 24px 24px;
        max-width: calc(100% - 24px);
        left: -12px;
        z-index: 2;
        margin-bottom: 48px;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100vw;
          left: 24px;
          top: 24px;
          z-index: -1;
          background-color: $lightorange;
          border-bottom-left-radius: 12px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          z-index: -1;
          border-radius: 12px;
        }

        @include XGA {
          margin-bottom: 64px;
          max-width: 85%;
          left: 0;
          padding: 40px 48px;

          &::before {
            width: 900px;
            border-radius: 12px;
            left: auto;
            right: -18%;
          }
        }
      }

      &.highlighted {
        position: relative;
        z-index: 2;

        &::before {
          content: "";
          background-color: #f7f7f7;
          position: absolute;
          left: 30px;
          top: 48px;
          width: calc(100% - 30px);
          height: calc(100% - 24px);
          border-radius: 12px;
          z-index: -1;

          @include XGA {
            top: -24px;
            height: calc(100% + 48px);
            width: calc(100% - 60px);
          }
        }
      }

      @include XGA {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        & > .article-column-image,
        & > .article-column-text {
          min-width: calc(50% - 16px);
          max-width: calc(50% - 16px);
          width: calc(50% - 16px);
          font-size: 20px;
        }

        & > .article-column {
          min-width: calc(50% - 16px);
          max-width: calc(50% - 16px);
          width: calc(50% - 16px);
        }

        &.text-left {
          flex-flow: row-reverse;
        }
      }

      &.with-image {
        &.highlighted {
          margin: 24px 0;
          margin-bottom: 48px;
          padding-top: 24px;
          position: relative;
          z-index: 1;

          @include XGA {
            padding-top: 48px;
          }

          &::before {
            content: "";
            position: absolute;
            left: 86px;
            top: 0;
            height: 165px;
            width: 100vw;
            background-color: $lightorange;
            border-radius: 20px 0px 0px 20px;
            z-index: -1;

            @include XGA {
              height: calc(100% - 24px);
              top: -24px;
            }

            @include HD {
              height: calc(100% - 48px);
              left: 160px;
            }
          }

          &.text-left {
            &::before {
              right: 86px;
              left: auto;
              border-radius: 0px 20px 20px 0px;

              @include HD {
                right: 160px;
              }
            }
          }

          @include XGA {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin: 40px 0;
            color: #fff;

            h3,
            h2 {
              font-size: 50px;
              margin: 0;
              font-weight: 900;
            }

            & > .article-column-image {
              min-width: 62%;
              max-width: 62%;
              width: 62%;

              img {
                width: 100%;
              }
            }

            & > .article-column-text {
              top: -40px;
              position: relative;
              width: 65%;
              max-width: 640px;
              margin-right: -240px;
              padding-bottom: 40px;
            }

            &.text-left {
              flex-flow: row-reverse;
            }

            &.text-right {
              & > .article-column-text {
                margin-right: 0;
                margin-left: -240px;
              }
            }
          }

          @include HD {
            & > .article-column-text {
              top: 30px;
            }
          }
        }
      }
    }

    .article-columns-3 {
      &.force-mobile {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        & > .article-column {
          min-width: calc(33.33% - 16px);
          max-width: calc(33.33% - 16px);
          width: calc(33.33% - 16px);
        }
      }

      &.with-border {
        position: relative;
        background-color: #ffffff;
        border: 2px solid $lightorange;
        border-radius: 12px;
        padding: 24px 24px;
        max-width: calc(100% - 24px);
        left: -12px;
        z-index: 2;
        margin-bottom: 48px;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100vw;
          left: 24px;
          top: 24px;
          z-index: -1;
          background-color: $lightorange;
          border-bottom-left-radius: 12px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          z-index: -1;
          border-radius: 12px;
        }

        @include XGA {
          margin-bottom: 64px;
          max-width: 85%;
          left: 0;
          padding: 40px 48px;

          &::before {
            width: 900px;
            border-radius: 12px;
            left: auto;
            right: -18%;
          }
        }
      }

      &.highlighted {
        position: relative;

        &::before {
          content: "";
          background-color: #f7f7f7;
          position: absolute;
          left: 30px;
          top: 48px;
          width: calc(100% - 30px);
          height: calc(100% - 24px);
          border-radius: 12px;

          @include XGA {
            top: -24px;
            height: calc(100% + 48px);
            width: calc(100% - 60px);
          }
        }
      }

      @include XGA {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        & > .article-column-image,
        & > .article-column-text {
          min-width: calc(33.33% - 16px);
          max-width: calc(33.33% - 16px);
          width: calc(33.33% - 16px);
        }

        & > .article-column {
          min-width: calc(33.33% - 16px);
          max-width: calc(33.33% - 16px);
          width: calc(33.33% - 16px);
        }

        &.text-left {
          flex-flow: row-reverse;
        }
      }

      &.highlighted {
        margin: 24px 0;
        margin-bottom: 48px;
        padding-top: 24px;
        position: relative;
        z-index: 1;

        @include XGA {
          padding-top: 48px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 86px;
          top: 0;
          height: 165px;
          width: 100vw;
          background-color: $lightorange;
          border-radius: 20px 0px 0px 20px;
          z-index: -1;

          @include XGA {
            height: calc(100% - 24px);
            top: -24px;
          }

          @include HD {
            height: calc(100% - 48px);
            left: 160px;
          }
        }

        &.text-left {
          &::before {
            right: 86px;
            left: auto;
            border-radius: 0px 20px 20px 0px;

            @include HD {
              right: 160px;
            }
          }
        }

        @include XGA {
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin: 40px 0;
          color: #fff;

          h3,
          h2 {
            font-size: 50px;
            margin: 0;
            font-weight: 900;
          }

          & > .article-column-image {
            min-width: 62%;
            max-width: 62%;
            width: 62%;

            img {
              width: 100%;
            }
          }

          & > .article-column-text {
            top: -40px;
            position: relative;
            width: 65%;
            max-width: 640px;
            margin-right: -240px;
            padding-bottom: 40px;
          }

          &.text-left {
            flex-flow: row-reverse;
          }

          &.text-right {
            & > .article-column-text {
              margin-right: 0;
              margin-left: -240px;
            }
          }
        }

        @include HD {
          & > .article-column-text {
            top: 30px;
          }
        }
      }
    }

    .award {
      margin: 24px 0;
      margin-bottom: 48px;
      padding-top: 24px;
      position: relative;
      z-index: 1;

      @include XGA {
        padding-top: 48px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 86px;
        top: 0;
        height: 165px;
        width: 100vw;
        background-color: #e5451f;
        border-radius: 20px 0px 0px 20px;
        z-index: -1;

        @include XGA {
          height: calc(100% - 24px);
          top: -24px;
        }

        @include HD {
          height: calc(100% - 48px);
          left: 160px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 114px;
        height: calc(100% - 90px);
        width: 100vw;
        background-color: #f7f7f7;
        z-index: -2;

        @include XGA {
          display: none;
        }
      }

      &.text-left {
        &::before {
          right: 86px;
          left: auto;
          border-radius: 0px 20px 20px 0px;

          @include HD {
            right: 160px;
          }
        }
      }

      @include XGA {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 40px 0;
        color: #fff;

        h3,
        h2 {
          font-size: 50px;
          margin: 0;
          font-weight: 900;
        }

        & > .award-column-image {
          min-width: 62%;
          max-width: 62%;
          width: 62%;
        }

        & > .award-column-text {
          top: -40px;
          position: relative;
          width: 65%;
          max-width: 475px;
          margin-right: -140px;
          padding-bottom: 40px;
        }

        &.text-left {
          flex-flow: row-reverse;
        }

        &.text-right {
          & > .award-column-text {
            margin-right: 0;
            margin-left: -140px;
          }
        }
      }

      @include HD {
        & > .award-column-text {
          top: 0;
        }
      }
    }

    ul {
      list-style: initial;
      padding-left: 15px;

      li {
        list-style: initial;
        line-height: 1.5;
      }
    }

    .article-button {
      text-align: center;
      margin: 32px 0;
    }

    .article-image {
      display: flex;
      flex-flow: column;
      flex-basis: fit-content;
      justify-content: center;
      align-items: center;
      margin: 24px auto;

      img {
        max-width: 100%;
      }
    }

    .article-timer-img {
      position: relative;
    }

    .article-timer {
      position: relative;

      .timer-title {
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: 900;
      }

      .timer-inner {
        position: relative;
        width: 420px;
        font-size: 50px;
        display: inline-block;
        padding-bottom: 8px;

        span {
          position: relative;
          top: -20px;
          font-size: 15px;
          margin-left: 8px;
          margin-right: 24px;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #fff;
        }
      }
    }

    .article-video {
      position: relative;

      .wrapper {
        margin: 15px 0;
        text-align: left;

        video {
          width: 100%;
          max-width: 720px;
        }
      }

      &.highlighted {
        &::before {
          content: "";
          position: absolute;
          right: 86px;
          top: 12px;
          height: calc(100% - 24px);
          width: 100vw;
          background-color: $lightorange;
          border-radius: 0px 20px 20px 0px;
          z-index: -1;

          @include XGA {
            height: calc(100% - 60px);
            top: 30px;
          }

          @include HD {
            height: calc(100% - 60px);
            right: 160px;
          }
        }
      }
    }

    .article-gallery {
      position: relative;
      z-index: 10;

      @include UXGA {
        margin-top: 60px;
      }

      &.highlighted {
        &::before {
          content: "";
          position: absolute;
          right: 86px;
          top: -24px;
          height: 165px;
          width: 100vw;
          background-color: $lightorange;
          border-radius: 0px 20px 20px 0px;
          z-index: -1;

          @include XGA {
            height: 340px;
            top: -30px;
          }

          @include HD {
            height: 340px;
            right: 160px;
          }

          @include UXGA {
            height: 400px;
          }

          @include FHD {
            height: 420px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: 86px;
          bottom: 60px;
          height: 165px;
          width: 100vw;
          background-color: #f5aa04;
          border-radius: 20px 0px 0px 20px;
          z-index: -1;

          @include XGA {
            height: 340px;
            bottom: 100px;
          }

          @include HD {
            height: 340px;
            left: 160px;
          }

          @include UXGA {
            height: 400px;
          }

          @include FHD {
            height: 420px;
          }
        }
      }

      .article-gallery-image {
        display: block;
        margin-bottom: 24px;
        text-align: left;

        @include XGA {
          &:nth-child(odd) {
            text-align: right;
          }
        }

        img {
          width: 100%;

          @include XGA {
            width: 62%;
          }
        }
      }

      &.from-left {
        .article-gallery-image {
          @include XGA {
            text-align: left;

            &:nth-child(even) {
              text-align: right;
            }
          }
        }

        &.highlighted {
          &::before {
            left: 86px;
            border-radius: 20px 0px 0px 20px;

            @include HD {
              left: 160px;
            }
          }

          &::after {
            left: auto;
            right: 86px;
            border-radius: 0px 20px 20px 0px;

            @include HD {
              right: 160px;
            }
          }
        }
      }
    }

    .logo-list {
      margin: 32px 0;

      @include XGA {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 75%;
      }

      .logo-image {
        margin-bottom: 24px;

        @include XGA {
          min-width: 26%;
          max-width: 26%;
          width: 26%;
        }

        img {
          max-height: 50px;
        }
      }
    }
  }

  .gallery-title,
  .logo-title {
    font-size: 32px;
    font-weight: 900;

    @include XGA {
      font-size: 50px;
    }
  }

  p {
    a {
      color: $lightorange;
      font-weight: 600;
    }
  }
}
