@import "../../../../../styles/vars";

.campaign-outer {
    min-height: 100vh;
    background-size: cover;
    background-position: center;

    .container {
        @include XGA {
            min-height: 100vh;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }

    .tamplate-content {
        position: relative;
    }

    .logos {
        position: absolute;
        top: 24px;
        right: 0;
        display: flex;
        flex-flow: row-reverse;
        justify-content: flex-start;

        @include XGA {
            position: relative;
            top: 0;
            right: auto;
            flex-flow: row;
            padding-bottom: 32px;
        }

        li {
            &:first-child {
                margin-left: 16px;

                @include XGA {
                    margin-left: 0;
                    margin-right: 40px;
                }
            }
        }

        img {
            height: 24px;

            @include XGA {
                height: 48px;
            }
        }
    }

    .campaign-intro {
        padding: 32px 0;
        color: #fff;
        max-width: 670px;

        h1 {
            font-size: 48px;
            font-weight: 800;
            margin: 0;

            @include XGA {
                font-size: 72px;
            }
        }

        p {
            font-size: 16px;

            @include XGA {
                font-size: 20px;
            }

            strong {
                font-weight: 800;
            }
        }
    }
}

.campaign-register-page {
    position: relative;
    color: white;
    padding-bottom: 32px;

    @include XGA {
        padding-bottom: 0;
    }

    .mobile {
        @include XGA {
            display: none;
        }
    }

    .logo {
        margin-bottom: 6vh;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;

        @include XGA {
            gap: 31px;
        }

        img {
            width: 31vw;
            max-width: 208px;

            &:first-of-type {
                width: 16vw;
                max-width: 104px;


                @include XGA {
                    width: 9vw;
                }
            }
        }
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

    .organic {

        @include XGA {
            margin-top: 6vh;
        }
        
        .button {
            @include XGA {
                position: initial;
                transform: initial;

                margin-top: 8.5vh;
            }
        }
    }

    .register-form {
        label {
            float: left;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: white !important;

            a {
                color: #fff;
                font-weight: 700;
            }
        }

        input {
            color: white;
            display: block;
            width: 100%;
            margin-bottom: 12px;
            background: transparent;
            border: none;
            border-bottom: 1px solid white;
            padding: 4px 0px;
            color: white !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            outline: none;

            &::placeholder {
                color: #fff;
            }

            &:focus {
                border-color: $lightorange;
                transition: all 0.2s $CubicEaseInOut;
            }
        }

        h3 {
            text-align: center;
            font-size: 20px;
            line-height: 19px;
            font-weight: 700;
            margin: 0;
            margin-top: 5vh;
        }
        
        p {
            text-align: center;
            font-size: 12px;
            line-height: 19px;
            font-weight: 700;
            margin: 0;
        }

        .buttons {
            @include XGA {
                text-align: left;
            }
        }

        .button {
            width: 100%;
            max-width: 320px;
        }
    }

    .campaign-register-page-inner {
        text-align: left;

        svg {
            width: 80px;
            height: auto;
        }

        h2 {
            font-weight: 600;
        }

        .user-data {
            display: block;
            margin: 0;
            max-width: 400px;

            input {
                display: block;
                width: 100%;
                margin-bottom: 12px;
            }

            label {
                color: #000;
            }
        }

        .disclaimer {
            margin: 0;
            display: block;
            width: 90%;
            max-width: 600px;
            font-size: 10px;
            margin-top: 24px;
        }

        .error {
            position: relative;
            top: -6px;
            font-size: 12px;
            color: #EA1D1D;
            text-align: left;

            &:before {
                content: "!";
                position: relative;
                top: -2px;
                display: inline-block;
                width:14px;
                height: 14px;
                background-color: #EA1D1D;
                color: #fff;
                border-radius: 50%;
                margin-right: 6px;
                font-size: 9px;
                line-height: 15px;
                font-weight: 700;
                text-align: center;
            }
        }
    }

    .button {
        margin-top: 24px; 
        width: 200px;
    }

    .switch {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 40px;
        height: 24px;


        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(237, 128, 2, 0.1);
            transition: .4s;
            border-radius: 34px;
            border: 1px solid white;
            border-radius: 12px;


            &:before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 3px;
                bottom: 2px;
                background-color: white;
                transition: .2s;
                border-radius: 50%;
            }
        }

        input:checked + .slider {
            background-color: $lightorange;

            &:before {
                background-color: #FDF2E6;
            }
        }
          
        input:checked + .slider:before {
            transform: translateX(16px);
        }
    }

    .consents {
        .shapes {
            margin-bottom: 25px;
        }

        .info {
            width: 100%;
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
            margin-top: 15px;
            text-align: left;

            a {
                color: #fff;
                font-weight: 700;
            }
        }

        .checkbox {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            gap: 17px;
            margin-bottom: 7px;

            input {
                width: 40px;
            }

            p, label {
                text-align: left;
                width: 100%;
                font-size: 10px;
                line-height: 14px;
                font-weight: 400;
                padding-left: 54px;
                z-index: 10;
            }
        }
    }

    .thankyou {
        h3 {
            margin-top: 5.25vh
        }

        p {
            margin-bottom: 25px;
            font-size: 15px;
            line-height: 20px;
        }

        .small {
            font-size: 10px;
            line-height: 14px;
        }
    }
}