@import "../../../styles/vars";

.ReactModalPortal {
    opacity: 1;
}

.overlay {
    opacity: 1;
    z-index: 999;
    background-color: #3333;
    max-width: unset;
}

.overlay-code {
    padding: 0 !important;
}

.modal {
    width: 90%;
    // height: 80%;
    background-color: white;
    padding: 24px;
    max-width: unset;
    border-radius: 12px;

    @include XGA {
        width: 72%;
        // height: 70%;
    }

    &.wider {
        max-width: 1000px;
    }

    &.narrow {
        max-width: 640px;
    }

    .button-wrapper {
        display: flex;
        justify-content: right;

        .close-button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            padding: 0;

            img {
                padding: 5px;
            }
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.age-gate {
        width: fit-content;
        padding-left: 16px;
        padding-right: 16px;

        @include vHD {
            padding-left: 72px;
            padding-right: 72px;
        }
    }

    &.referral-success-modal {
        padding: 0;

        .modal-top-success {
            height: 80px;
            background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
            border-radius: 12px 12px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h2 {
            margin: 0;
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 8px;

            @include XGA {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 16px;
            }
        }

        p {
            margin: 0;
        }

        .referral-modal-buttons {
            margin-top: 24px;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;
            column-gap: 24px;
        }

        .content {
            padding: 24px;

            @include XGA {
                padding: 40px;
            }
        }
    }

    &.remind-password {
        padding: 0;
        max-width: 640px;

        .content {
            display: block;
        }

        .remind-password {
            padding: 32px 16px 24px;

            @include XGA {
                padding: 40px 113px 24px;
            }
        }
    }

    &.reveal-image {
        padding: 0;
        background-color: white;
        color: black;
        width: 100%;
        border-radius: 12px 22px 0 0;
        top: 100%;
        transform: translate(-50%, -100%);

        @include vHD {
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 12px;
            max-width: calc(min(864px, 90%));
        }

        .content {
            position: relative;
            
        }

        .upper {
            padding: 40px 24px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                display: block;
            }

            button {
                border: none;
                background-color: transparent;
                margin: 0;
                padding: 0;
                cursor: pointer;
                padding: 5px;
                width: 24px;
                height: 24px;

                @include vHD {
                    position: absolute;
                    right: 24px;
                    top: 24px;
                }
                
                .exit {
                    &::before {
                        content: "\2715";
                        color: #ED8002;
                        height: fit-content;
                        font-size: 18px;
                        font-weight: 900;
                        height: 100%;
                    }
                }
            }
        }

        .inner {
            padding: 24px 24px 32px;

            @include vHD {
                max-width: 640px;
                margin-inline: auto;
            }

            .question {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: left;
            }

            .answers {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 32px;
                gap: 14px;

                @include vHD {
                    gap: 32px;
                    justify-content: left;
                }

                .answer {
                    width: 100%;
                    max-width: 155px;
                    border: 2px solid black;
                    border-radius: 24px;
                    cursor: pointer;

                    button {
                        border: none;
                        appearance: none;
                        background-color: transparent;
                        color: black;
                        font-weight: 900;
                        font-size: 14px;
                        line-height: 20px;
                        width: 100%;
                        padding: 12px 0 9px;
                        cursor: pointer;
                    }
                }
            }


            .button-wrapper {
                margin-top: 16px;

                @include XGA {
                    margin-top: 28px;
                }

                .button {
                    width: 100%;

                    @include XGA {
                        width: fit-content;
                        margin: 0 auto;
                    }

                    &.disabled {
                        background-color: transparent;
                        color: #C6C6C5;
                        border: 1px solid #C6C6C5;
                    }
                }
            }
        }
    }

    &.reveal-color {
        padding: 0;
        width: 100%;
        transform: translate(-50%, -100%);
        background-color: transparent;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(min(864px, 90%));
        overflow-y: hidden;
        overflow-x: hidden;
        .upper {
            padding: 40px 24px 0;
            display: flex;

            img {
                display: block;
            }

            button {
                border: none;
                background-color: transparent;
                margin: 0;
                padding: 0;
                cursor: pointer;
                padding: 5px;
                width: 24px;
                height: 24px;
                position: absolute;
                right: 24px;
                top: 24px;

                .exit {
                    &::before {
                        content: "\2715";
                        color: #fff;
                        height: fit-content;
                        font-size: 18px;
                        font-weight: 900;
                        height: 100%;
                    }
                }
            }
        }

        .inner {
            padding: 24px 24px 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .device{
                width: 80%;
                margin: -40px 0;
                @include XGA {
                 margin: -80px 0;
                 width: 100%;
                }
            }
            @include vHD {
                max-width: 640px;
                margin-inline: auto;
            }
            .question {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: left;
                color: #fff;
            }
        }
    }

    &.truefalsetile {
        padding: 0;
        background-color: white;
        color: black;
        width: 100%;
        border-radius: 12px 22px 0 0;
        top: 100%;
        transform: translate(-50%, -100%);

        @include vHD {
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 12px;
            max-width: calc(min(864px, 90%));
        }

        .content {
            position: relative;
            
        }

        .upper {
            padding: 40px 24px 0;
            display: flex;
            background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }

        .inner {
            padding: 24px 24px 32px;

            @include vHD {
                max-width: 640px;
                margin-inline: auto;
            }

            .question {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: left;
            }

            .answers {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 32px;
                gap: 14px;

                @include vHD {
                    gap: 32px;
                    justify-content: center;
                }

                .answer {
                    width: 100%;
                    max-width: 155px;
                    cursor: pointer;

                    button {
                        border: 2px solid black;
                        border-radius: 24px;
                        appearance: none;
                        background-color: transparent;
                        color: black;
                        font-weight: 900;
                        font-size: 14px;
                        line-height: 20px;
                        width: 100%;
                        padding: 12px 0 9px;
                        cursor: pointer;
                    }
                    .active{
                        border: 2px solid #ED8002;
                        border-radius: 24px;
                        color: #ED8002;
                    }
                }
            }

            .disclaimer{
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                margin: 20px 0;
            }

            .divider{
                border: 1px solid #E5E5E5;
                margin: 20px 0 20px 0;
                width: 100%;
            }

        

            .button-wrapper {
                margin-top: 16px;

                @include XGA {
                    margin-top: 28px;
                }

                .button {
                    width: 100%;

                    @include XGA {
                        width: fit-content;
                        margin: 0 auto;
                    }

                    &.disabled {
                        background-color: transparent;
                        color: #C6C6C5;
                        border: 1px solid #C6C6C5;
                    }
                }
            }
        }
    }
}


.ReactModal__Content.image-modal {
    max-width: 90% !important;
    max-height: 90% !important;
    width: max-content !important;

    .image {
        img {
            width: 100%;
            height: 100%;
            max-height: calc(85vh - 57px);
        }
    }
}

.ReactModal__Content.modal-code {
    min-height: initial;
    min-width: initial;
    max-height: initial;
    max-width: initial;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    background: linear-gradient(0deg, $darkorange 0%, $yellow 100%);
    position: relative;
    display: block;
    opacity: 1;

    .button-wrapper {
        justify-content: left;

        button {
            img {
                transform: scale(1.5);
            }
        }
    }

    .content {
        flex-direction: column;
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, calc(-50% - 20px));

        p {
            font-size: 18px;
            line-height: 20px;
            color: white;
            font-weight: 600;
            margin-bottom: 16px;
        }

        .value {
            padding: 24px 64px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 80px;

            h2 {
                font-weight: 900;
                font-size: 64px;
                line-height: 64px;
                color: white;
                margin: 0;
                padding: 0;
            }
        }

        .partner-info {
            margin-top: 38px;

            .text {
                width: max-content;

                * {
                    margin: 0;
                    color: black;
                    font-size: 15px;
                    line-height: 20px;

                    strong {
                        font-weight: 600;
                    }
                }
            }

            img {
                margin-top: 4px;
            }
        }
    }

    @media (orientation: portrait) {
        & {
            transform: translateX(-50%) translateY(-50%) rotate(90deg);
            width: 100vh;
            height: 100vw;
        }
    }
}

.ReactModal__Content.vuse-join-modal {
    max-width: 640px;
    border-radius: 4px;

    .vuse-popup-inner {
        .button.violet {
            border-radius: 4px;
            background-color: #754FB2;
            color: #fff;
            margin-top: 8px;
        }

        p {
            margin: 16px auto;
            max-width: 460px;
        }

        h1 {
            margin-top: 0;
        }
    }
}