@import "../../../styles/vars";

.leave-modal {
    padding: 0;
    background-color: transparent;
    z-index: 99999;

    .button-wrapper {
        position: absolute;
        top: 20px;
        right: 20px;

        @include XGA {
            top: 24px;
            right: 24px
        }

        .close-button {
            img {
                filter: invert(1);
            }
        }
    }

    .content {
        width: 100%;
        display: block;
    }

    .leave-top {
        background-color: $darkorange;
        background: linear-gradient(266.29deg, #EE4823 25.3%, #EE4F22 36.21%, #F06022 51.58%, #F37820 69.62%, #F7951D 89.52%, #FAA61A 99.15%, #FAA61A 99.33%);
        padding: 20px 0;

        @include XGA {
            padding: 30px 0;
        }
    }

    .leave-content {
        background-color: #fff;
        padding: 24px 0;

        @include XGA {
            padding: 32px 0;
        }

        h3 {
            margin: 0;
            font-size: 18px;

            @include XGA {
                font-size: 24px;
            }
        }

        .leave-buttons {
            margin-top: 24px;

            button.orange {
                border: $lightorange solid 1px;
                max-width: 200px;
                color: $lightorange;
                text-align: center;
                font-weight: 700;
                background-color: transparent;
                margin: 0 8px;
    
                &:hover {
                    color: #fff;
                    background-color: $lightorange;
                }
            }
        }
    }
}

.confirm-modal {
    padding: 0 !important;
    background-color: transparent;
    z-index: 99999;

    .button-wrapper {
        position: absolute;
        top: 20px;
        right: 20px;

        @include XGA {
            top: 24px;
            right: 24px
        }

        .close-button {
            img {
                filter: invert(1);
            }
        }
    }

    .content {
        width: 100%;
        display: block;
    }

    .confirm-top {
        background-color: $darkorange;
        background: linear-gradient(266.29deg, #EE4823 25.3%, #EE4F22 36.21%, #F06022 51.58%, #F37820 69.62%, #F7951D 89.52%, #FAA61A 99.15%, #FAA61A 99.33%);
        padding: 20px 0;

        @include XGA {
            padding: 30px 0;
        }
    }

    .confirm-content {
        background-color: #fff;
        padding: 24px 0;

        @include XGA {
            padding: 32px 0;
        }

        h3 {
            margin: 0;
            font-size: 18px;

            @include XGA {
                font-size: 24px;
            }
        }

        .confirm-buttons {
            margin-top: 24px;
        }
    }
}

.profile-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;

    section {
        width: 100%;
    }
    
    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    .leave-program {
        margin-top: 24px;

        button.orange {
            border: $lightorange solid 1px;
            max-width: 200px;
            color: $lightorange;
            text-align: center;
            font-weight: 600;

            &:hover {
                color: #fff;
            }
        }
    }

    .top-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 14px;

        @include XGA {
            margin-top: 40px;
            justify-content: left;
            column-gap: 24px;
        }

        .header {
            @include XGA {
                font-size: 32px;
                margin: 0;
                margin-right: 40px;
                line-height: 40px;
            }
        }

        p, a {
            opacity: 0.4;
            font-size: 12px;
            padding: 4px 8px 2px;
            border: 1px solid transparent;
            cursor: pointer;
            text-decoration: none;
            font-weight: 500;
            color: black;

            @include XGA {
                padding: 6px 16px;
                font-size: 14px;
            }
        }

        .selected {
            color: $lightorange;
            padding: 4px 8px 2px;
            border: 1px solid $lightorange;
            border-radius: 24px;
            opacity: 1;
            font-weight: 600;

            @include XGA {
                padding: 6px 16px;
                font-weight: 800;
            }
        }
    }

    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #F3F3F3;
        margin-top: 16px;
        margin-bottom: 24px;
    }

    h4 {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        margin: 0;
    }

    p {
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        font-weight: 600;
    }

    .summarize {
        display: flex;
        gap: 21.5vw;
        margin-bottom: 24px;
    }

    .graph {

        h4 {
            margin-bottom: 16px;
        }

        .xAxis {
            .recharts-cartesian-axis-tick {    
                font-size: 10px;
                line-height: 11px;
                font-weight: 500;
            }
        }
        
        .yAxis {
            .recharts-cartesian-axis-tick {    
                font-size: 10px;
                line-height: 11px;
                font-weight: 600;
            }
        }
        
        .recharts-wrapper .recharts-cartesian-grid-horizontal line {
            &:last-child {
                stroke-opacity: 0;
            }
            &:nth-child(6) {
                stroke-opacity: 0;
            }
        }
        
        .recharts-cartesian-grid-horizontal {
            line {
                stroke: #F3F3F3;
            }
        }
        .conteiner {
            margin: 0 auto;
        }
    }

    .outer-red {
        margin-top: 11px;
        background: $darkorange;
        border-radius: 12px;
        padding: 4px 8px 2px;
        width: fit-content;
        color: white;
    }

    .show-history {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-top: 14px;
        margin-bottom: 24px;

        p {
            color: $lightorange;
            text-decoration: underline;
        }
    }

    .history {
        h4 {
            margin-bottom: 24px;
            margin-top: 14px;
        }
    }

    .activity {

        .content {
            display: flex;
            gap: 16px;
            align-items: center;
        }

        .image {
            object-fit: cover;
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .text {
            h4 {
                margin-bottom: 2px;
                margin-top: 0;
            }

            p {
                margin: 0;
            }
        }

        hr {
            margin-top: 12px;
            margin-bottom: 12px;
        }

        &:last-of-type {
            margin-bottom: 24px;

            hr {
                display: none;
            }
        }
    }

    &.change {

        hr {
            margin-bottom: 16px;
        }

        .user-data {
            color: black;
            display: block;
            text-align: left;

            @include XGA {
                max-width: 600px;
            }
        }

        .label {
            margin-top: 16px;
        }

        button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
        
        .field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin: 4px 0;
            padding-bottom: 4px;
            border-bottom: 1px solid rgba(0,0,0,0.4);

            img {
                margin-top: -4px;
            }

            &.password {
                -webkit-text-security: disc;
                font-family: "password";
                font-size: 8px;
            }
        }

        .inactive {
            cursor: initial;
        }

        .agreements {
            margin-top: 24px;

            p {
                font-weight: 500;
                margin-top: 8px;
                margin-bottom: 16px;
            }
        }

        .info {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            opacity: 0.4;
            margin-top: 16px;
        }

        .more {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            opacity: 0.8;
            margin-top: 8px;
            margin-bottom: 32px;

            a, span {
                font-weight: 600;
                color: $darkorange;
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .switches {
            .wrapper {
                display: grid;
                grid-template-columns: auto auto;
                row-gap: 8px;
                column-gap: 16px;

                @include XGA {
                    width: fit-content;
                }

                p {
                    margin: 0;
                    opacity: 0.8;
                }
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;


            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(237, 128, 2, 0.1);
                transition: .4s;
                border-radius: 34px;
                border: 1px solid $lightorange;
                border-radius: 12px;


                &:before {
                    position: absolute;
                    content: "";
                    height: 18px;
                    width: 18px;
                    left: 3px;
                    bottom: 2px;
                    background-color: $lightorange;
                    transition: .2s;
                    border-radius: 50%;
                }
            }

            input:checked + .slider {
                background-color: $lightorange;

                &:before {
                    background-color: #FDF2E6;
                }
            }
              
            input:checked + .slider:before {
                transform: translateX(16px);
            }
        }
    }

    &.modal-wrapper {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 0;

        h2 {
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            margin: 0;
        }

        p {
            font-weight: 500;
            margin-top: 24px;
            font-weight: 600;
        }

        .consent-content {
            .info {
                font-weight: 400;
                margin: 0;
            }

            .button {
                display: block;
                margin: 0 auto;
                width: 140px;
                margin-bottom: 12px;
            }

            ul {
                margin: 24px 0;

                li {
                    position: relative;
                    padding-left: 20px;
                    margin: 0;
                    margin-bottom: 12px;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 6px;
                        width: 6px;
                        height: 6px;
                        background-color: $darkorange;
                        border-radius: 50%;
                    }
                }
            }
        }

        .current {
            margin-top: 4px;
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
        }

        .password {
            font-family: "password";
            font-size: 8px;
        }

        .field-name {
            &::first-letter {
                text-transform: uppercase;
            }
        }

        input {
            border: none;
            border-bottom: 1px solid black;
            margin-top: 4px;
            margin-bottom: 45px;
            padding-bottom: 5px;
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
        }

        button {
            display: block;
            width: min-content;
            margin: 0 auto;
        }

        .user-data {
            display: block;
            margin: 24px auto;
            max-width: 400px;

            .input {
                position: relative;

                .view-icon {
                    position: absolute;
                    right: 0;
                    top: 20px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    background-image: url(../ResetPage/img/preview-black.svg);
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    &.disable {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 24px;
                            height: 1px;
                            background-color: #000;
                            transform: translate(-50%, -50%) rotate(30deg);
                        }
                    }
                }
            }

            .mobile-prefix {
                position: absolute;
                left: 0;
                top: 23px;
                color: #000;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                opacity: 0.7;
            }

            .error {
                position: relative;
                top: -16px;
                font-size: 12px;
                color: #EA1D1D;
                text-align: left;
    
                &:before {
                    content: "!";
                    position: relative;
                    top: -2px;
                    display: inline-block;
                    width:14px;
                    height: 14px;
                    background-color: #EA1D1D;
                    color: #fff;
                    border-radius: 50%;
                    margin-right: 6px;
                    font-size: 9px;
                    line-height: 15px;
                    font-weight: 700;
                    text-align: center;
                }
            }

            input {
                display: block;
                width: 100%;
                margin-bottom: 12px !important;
                background: transparent;
                border: none;
                border-bottom: 1px solid #000;
                padding: 4px 0px;
                color: #000 !important;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                outline: none;
                transition: all 0.2s $CubicEaseInOut;

                &:focus {
                    border-color: $lightorange;
                }
            }

            input[type="password"] {
                line-height: 16px;
            }

            input[name="mobilePhone"] {
                padding-left: 32px;
            }

            label {
                float: left;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: #000 !important;
            }
        }
    }

    &.my-points {
        padding-bottom: 0;

        .current {
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: #C6C6C5 solid 1px;

            @include XGA {
                border-bottom: none;
                border-right: #C6C6C5 solid 1px;
            }
        }

        .this-year {
            margin-bottom: 24px;
        }

        .points-outer {
            position: relative;
            z-index: 2;
            padding: 48px 0;
            padding-bottom: 64px;
            text-align: center;
            color: #fff;

            @include XGA {
                margin-bottom: 64px;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: center;
                text-align: left;
                align-items: center;
                padding: 64px;
            }

            @include HD {
                padding: 80px 112px;
            }

            .current, .details {
                @include XGA {
                    padding: 32px;
                    min-width: 50%;
                    max-width: 50%;
                    width: 50%;
                    margin-bottom: 0;
                }

                @include HD {
                    min-width: 45%;
                    max-width: 45%;
                    width: 45%;
                    padding: 48px;
                    padding-right: 0;
                }
            }

            .current {
                p {
                    @include XGA {
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -24px;
                width: 100vw;
                height: 100%;
                z-index: -1;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                background-size: cover;
                background-position: center;
                background-image: url(./img/my-points-mobile.webp);

                @include XGA {
                    width: 100%;
                    left: 0;
                    background-image: url(./img/my-points-desktop.webp);
                    border-radius: 12px;
                }
            }

            p {
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
                margin: 0;

                &.points {
                    color: $yellow;
                    margin-top: 8px;
                    font-weight: 900;

                    span {
                        @include XGA {
                            font-size: 32px;
                            line-height: 40px;
                        }
                    }
                }

                &.disclaimer {
                    font-size: 15px;
                    line-height: 20px;
                }
            }

            h3 {
                margin: 0;
                font-size: 90px;
                line-height: 96px;
                color: $yellow;
                font-weight: 900;
                margin-top: 8px;

                @include HD {
                    margin-top: 40px;
                }

                small {
                    display: block;
                    font-size: 18px;
                    line-height: 24px;

                    @include XGA {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.update-data-modal {
    z-index: 9999 !important;
}