@import "../../../styles/vars";

.main-menu {
    background-color: white;
    z-index: 998;
    position: fixed;
    top: 110px;
    border-radius: 12px;
    width: 191px;
    height: auto;
    padding-bottom: 18px;
    right: -290px;
    transition: all 0.6s ease-in-out;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
    // display: none;

    @include XGA {
        top: 150px;
        transform: scale(1.3);
    }

    @include FHD {
        top: 160px;
        transform: scale(1.4);
    }

    &.active {
        right: 23px;

        @include XGA {
            right: 63px;
        }

        .close-bg {
            display: block;
        }
    }

    .referral-wrapper {
        margin-top: 19px;
        max-width: 170px;
        margin-left: auto;
        margin-right: auto;

        p {
            text-align: center;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            opacity: 0.4;
            margin: 0;
        }

        .button {
            position: relative;
            margin-bottom: 7px;
            width: 100%;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 7px 16px 6px;
            font-size: 8.85px;
            line-height: 143%;
            text-align: center;
            gap: 5px;
            text-transform: lowercase;

            img {
                width: 10.5px;
                margin-top: -4px;
            }
        }
    }

    .push-notifications {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        margin: 12px 0;
        border: 1px solid #E5E5E5;
        border-left: none;
        border-right: none;

        p {
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            color: #000000;
            margin: 0;
            padding-top: 4px;
        }

        .switch {
            position: relative;
            left: 0;
            top: 0;
            display: inline-block;
            width: 29px;
            height: 18px;


            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid #ED8002;
                transition: .4s;
                border-radius: 34px;
                border-radius: 12px;

                &:before {
                    position: absolute;
                    content: "";
                    height: 12px;
                    width: 12px;
                    left: 2px;
                    bottom: 2px;
                    background-color: #ED8002;
                    transition: .2s;
                    border-radius: 50%;
                }
            }

            input:checked+.slider {
                background-color: $lightorange;

                &:before {
                    background-color: #FDF2E6;
                }
            }

            input:checked+.slider:before {
                transform: translateX(12px);
            }
        }
    }

    .button-wrapper {
        max-width: 170px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button.menu-small {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            &.subs a {
                color: $orange;
                text-decoration: none;
            }
        }
    }
}