@import "../../../styles/vars";

.ReactModal__Body--open {
    overflow: hidden;
  
    #root {
      overflow: hidden;
    }
  }
  
  // .overlay {
  //   z-index: 2000;
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   transition: opacity 400ms $CubicEaseInOut;
  //   background: rgba(0, 0, 0, 0.75);
  //   overflow-y: auto;
  //   width: 100%;
  
  //   &_after-open {
  //     opacity: 1;
  //   }
  
  //   &_before-close {
  //     opacity: 0;
  //   }
  // }
  
  // .modal {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  //   transition: transform 300ms $QuadEaseOut 0.1s, opacity 300ms $QuadEaseOut 0.1s;
  //   background: rgba(0, 0, 0, 0);
  //   outline: none;
  //   border: none;
  //   opacity: 0;
  //   overflow: auto;
  //   height: 100vh;
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   z-index: 999999;
  //   overflow: hidden;
  
  //   &_after-open {
  //     opacity: 1;
  //   }
  
  //   &_before-close {
  //     opacity: 0;
  //   }
  // }
  // .modal-blur {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 100%;
  //   backdrop-filter: blur(10px);
  //   -webkit-backdrop-filter: blur(10px);
  // }
  
  // .modal-close-btn-circle {
  //   position: absolute;
  //   right: 10px;
  //   top: 20px;
  //   width: 44px;
  //   height: 44px;
  //   cursor: pointer;
  //   z-index: 9999999;
  //   background: transparent;
  //   border: none;
  //   border: 1px solid #fff;
  //   border-radius: 50%;
  
  //   @include XGA {
  //     width: 52px;
  //     height: 52px;
  //     right: 30px;
  //   }
  //   @include HD {
  //     width: 60px;
  //     height: 60px;
  //   }
  
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     left: 50%;
  //     top: 50%;
  //     width: 55%;
  //     height: 2px;
  //     background-color: #fff;
  //     transform: translate(-50%, -50%) rotate(45deg);
  
  //     @include XGA {
  //       width: 30%;
  //     }
  //   }
  
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     left: 50%;
  //     top: 50%;
  //     width: 55%;
  //     height: 2px;
  //     background-color: #fff;
  //     transform: translate(-50%, -50%) rotate(-45deg);
  
  //     @include XGA {
  //       width: 30%;
  //     }
  //   }
  // }
  
  // .modal-close-btn {
  //   position: absolute;
  //   right: 10px;
  //   top: 10px;
  //   width: 24px;
  //   height: 24px;
  //   cursor: pointer;
  //   z-index: 9999999;
  //   background: transparent;
  //   border: none;
  
  //   @include XGA {
  //     width: 32px;
  //     height: 32px;
  //   }
  
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     top: 50%;
  //     width: 100%;
  //     height: 2px;
  //     background-color: rgb(0, 0, 0);
  //     transform: rotate(45deg);
  
  //     @include XGA {
  //       height: 2.5px;
  //     }
  //   }
  
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     top: 50%;
  //     width: 100%;
  //     height: 2px;
  //     background-color: rgb(0, 0, 0);
  //     transform: rotate(-45deg);
  
  //     @include XGA {
  //       height: 2.5px;
  //     }
  //   }
  
  //   &--white {
  //     &::before,
  //     &::after {
  //       background-color: #fff;
  //     }
  //   }
  // }
  
  .cookies-modal {
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 1080px;
    width: 100%;
    z-index: 999;
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
  }
  .cookies-modal-intro {
    font-size: 0.8em;
    padding: 20px;
    &__desc {
      text-align: center;
      @include vHD {
        padding: 0 15%;
      }
    }
    &__link {
      font-weight: 500;
      text-decoration: none;
      color: #000;
    }
  
    &__btn {
      margin-top: 15px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
  
      background: #fff;
      border: 2px solid $lightorange;
      max-width: 300px;
      padding: 14px 0;
      margin: 10px 0;
      width: 260px;
      border-radius: 50px;
      cursor: pointer;
  
      &:hover {
        background-color: $lightorange;
        color: #fff;
      }
    }
  
    &__btn-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      @include vHD {
        flex-direction: row;
        justify-content: space-between;
        max-width: 530px;
        margin: 0 auto;
      }
    }
  }
  .cookie-modal-step {
    @include HD {
      display: flex;
      // padding: 50px 50px 0;
    }
  
    &__btn {
      margin: 1rem auto 1rem;
      display: block;
      padding: 13px;
      height: 46px;
      border-radius: 50px;
      width: 200px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 15px;
      font-family: "Poppins", sans-serif;
      cursor: pointer;
      background: transparent;
      border: 2px solid #000;
    }
  
    &__tab {
      display: block;
      border: none;
      background: transparent;
      margin: 15px;
      cursor: pointer;
  
      &--active {
        font-weight: 700;
      }
    }
    &__header {
      background: $lightorange;
      padding: 10px 30px;
      font-size: 15px;
  
      @include HD {
        background: #fff;
        min-width: 300px;
      }
    }
    &__body {
      padding: 20px;
    }
    &__title {
      font-size: 1.5em;
      text-align: center;
  
      @include HD {
        text-align: left;
      }
  
      span {
        color: $lightorange;
      }
    }
    &__desc {
      text-align: center;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 0;
      @include HD {
        text-align: left;
        font-size: 0.9em;
      }
    }
  }
  
  .modal-cookie-switch {
    margin: 25px;
    display: flex;
    justify-content: center;
    @include HD {
      margin: 0;
      padding-bottom: 24px;
      justify-content: flex-end;
    }
  
    &__container {
      display: flex;
      justify-content: space-between;
      max-width: 300px;
      align-items: center;
      @include HD {
        margin-right: 150px;
      }
    }
  
    @include HD {
      margin-left: auto;
    }
  
    &__content {
      color: #000;
      font-size: 16px;
      margin-right: 20px;
    }
  
    &__btn {
      position: relative;
      width: 80px;
      height: 40px;
      border: 2px solid #fff;
      border-radius: 25px;
      background: transparent;
      cursor: pointer;
      background: #c2c6cb;
      &--active {
        background: #27e6a3;
      }
    }
    &__bullet {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      height: 30px;
      width: 30px;
      background: #fff;
      display: block;
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      &::before,
      &::after {
        content: "";
        position: absolute;
        height: 13px;
        width: 2px;
        background: #c2c6cb;
      }
      &::after {
        transform: rotate(-45deg);
      }
      &::before {
        transform: rotate(45deg);
      }
      &--active {
        left: auto;
        transform: translate(35px, -50%);
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 9px;
          height: 5px;
          content: "";
          height: 12px;
          width: 6px;
          background-color: transparent;
          border-bottom: 2px solid #27e6a3;
          border-right: 2px solid #27e6a3;
        }
        &::before {
          display: none;
        }
      }
    }
  }

  .cookies-modal {
    .switch {
      background-color: $green;
    
      .handle {
        transform: translate(40px, 0);
    
        &:after {
          left: 10px;
          top: 10px;
          width: 9px;
          height: 5px;
          background-color: transparent;
          border-bottom: $green solid 2px;
          border-left: $green solid 2px;
          transform: rotate(-45deg);
        }
    
        &:before {
          display: none;
        }
      }
    }
  }