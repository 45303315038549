@import "../../../styles/vars";

.join2-page {
    position: relative;
    height: 100vh;
    min-height: 600px;
    background-image: url(../LoginPage/img/bg-mobile.jpg);
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    @include XGA {
        background-image: url(../LoginPage/img/bg-dekstop.jpg);
        background-position: center;
    }

    .logo {
        margin-bottom: 6vh;
        padding-top: 0;

        img {
            width: 31vw;
            max-width: 208px;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

    .join-form {
        label {
            float: left;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: white !important;
        }

        input {
            color: white;
            display: block;
            width: 100%;
            margin-bottom: 12px;
            background: transparent;
            border: none;
            border-bottom: 1px solid white;
            padding: 4px 0px;
            color: white !important;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            outline: none;

            &:focus {
                border-color: $lightorange;
                transition: all 0.2s $CubicEaseInOut;
            }
        }

        h3 {
            text-align: center;
            font-size: 32px;
            line-height: 19px;
            font-weight: 700;
            margin: 0;
            margin-top: 5vh;
            margin-bottom: 3vh;

            @include HD {
                font-size: 28px;
            }

            @include FHD {
                font-size: 32px;
            }
        }
        
        p {
            text-align: center;
            font-size: 15px;
            line-height: 19px;
            font-weight: 700;
            margin: 0 auto;
            margin-bottom: 3vh;

            @include HD {
                font-size: 18px;
                max-width: 760px;
                line-height: 32px;
            }

            @include FHD {
                font-size: 24px;
            }
        }

        .button {
            width: 280px;
        }
    }

    .join2-page-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        height: auto;
        padding-left: 24px;
        padding-right: 24px;

        svg {
            width: 80px;
            height: auto;
        }

        h2 {
            font-weight: 600;
        }

        .user-data {
            display: block;
            margin: 0 auto;
            max-width: 400px;

            @include UXGA {
                max-width: 620px;
            }

            input {
                display: block;
                width: 100%;
                margin-bottom: 12px;
            }

            label {
                color: #000;
            }
        }

        .error {
            position: relative;
            top: -6px;
            font-size: 12px;
            color: #EA1D1D;
            text-align: left;
            margin: 0;

            &:before {
                content: "!";
                position: relative;
                top: -2px;
                display: inline-block;
                width:14px;
                height: 14px;
                background-color: #EA1D1D;
                color: #fff;
                border-radius: 50%;
                margin-right: 6px;
                font-size: 9px;
                line-height: 15px;
                font-weight: 700;
                text-align: center;
            }
        }
    }

    a {
        color: #fff;
        font-weight: 700;
    }

    .button {
        margin-top: 24px; 
        width: 200px;
    }

    .switch {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 40px;
        height: 24px;


        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(237, 128, 2, 0.1);
            transition: .4s;
            border-radius: 34px;
            border: 1px solid white;
            border-radius: 12px;


            &:before {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 3px;
                bottom: 2px;
                background-color: white;
                transition: .2s;
                border-radius: 50%;
            }
        }

        input:checked + .slider {
            background-color: $lightorange;

            &:before {
                background-color: #FDF2E6;
            }
        }
          
        input:checked + .slider:before {
            transform: translateX(16px);
        }
    }

    .consents {
        .shapes {
            margin-bottom: 25px;
        }

        .info {
            width: 100%;
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
            margin-top: 15px;
        }

        .checkbox {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            gap: 17px;
            margin-bottom: 7px;

            input {
                width: 40px;
            }

            p, label {
                text-align: left;
                width: 100%;
                font-size: 10px;
                line-height: 14px;
                font-weight: 400;
                padding-left: 54px;
                z-index: 10;

                @include UXGA {
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }
    }
}