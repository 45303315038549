@import "../../../styles/vars";

.popup-window {
  background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
  border: none;
  border-radius: 12px;
  min-width: 266px;
  max-width: 266px;
  z-index: 300;
  position: absolute;
  top: auto;
  .popup-inner {
    position: relative;
    padding: 16px 24px;
    p.popup-p,
    h4.popup-h4 {
      margin: 0;
      white-space: pre-wrap;
      text-align: left;
      color: #fff;
    }

    h4.popup-h4 {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      p.popup-p {
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.12em;
      }

      button.popup-close {
        padding-inline: 8px;
        margin-right: -12px;
        width: fit-content !important;
        cursor: pointer;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .popup-wrapper {
      p.popup-p {
        margin-top: 4px !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px !important;

        .button.transparent {
          background-color: transparent;
          border-color: white;
          border-width: 2px;
          padding: 11px 40px 11px 40px;
        }
      }
    }
  }
}

.popup {
  button:not(.button) {
    appearance: none;
    border: none;
    background-color: transparent;
  }
}

