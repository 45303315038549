@import "../../../styles/vars";

.awards-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    .top-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 16px;

        @include XGA {
            margin-top: 40px;
            justify-content: left;
            column-gap: 24px;
        }

        .header {
            @include XGA {
                font-size: 32px;
                margin: 0;
                margin-right: 40px;
                line-height: 40px;
            }
        }

        p,
        a {
            position: relative;
            opacity: 0.7;
            font-size: 12px;
            padding: 4px 8px;
            border: 1px solid transparent;
            cursor: pointer;
            text-decoration: none;
            font-weight: 500;
            color: black;
            margin-top: 0;
            margin-bottom: 0;
            white-space: nowrap;

            @include XGA {
                padding: 8px 20px;
                font-size: 14px;
                margin: 0 !important;
            }

            .available-badge {
                position: absolute;
                top: -5px;
                right: -8px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: $orange;
                color: #fff;
                text-align: center;
                line-height: 16px;
                font-size: 10px;
                font-weight: 900;

                @include XGA {
                    right: 4px;
                    top: 4px;
                }
            }
        }

        .selected {
            color: $lightorange;
            padding: 4px 8px 2px;
            border: 1px solid $lightorange;
            border-radius: 24px;
            opacity: 1;
            line-height: 1.2;
            font-weight: 600;

            @include XGA {
                padding: 8px 20px;
                font-weight: 800;
            }
        }
    }

    .swiper {
        position: static;
        width: 320px;
        margin-left: 0;
        margin-right: 16px;
        overflow: initial;

        @include XGA {
            width: calc(25% - 12px);
        }
    }

    .swiper-button {

        &-prev,
        &-next {
            display: none;

            @include XGA {
                display: block;
                border: 1px solid $lightorange;
                ;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;

                path {
                    stroke: $lightorange;
                }

                &:hover,
                &:focus {
                    cursor: pointer;
                    border: 1px solid $orange;
                    ;

                    path {
                        stroke: $orange;
                    }
                }
            }
        }

        &-prev {
            top: -2px;
            right: 96px;

            @include XGA {
                top: 4px;
                right: 128px;
            }
        }

        &-next {
            top: -2px;
            right: 0px;

            @include XGA {
                top: 4px;
            }
        }
    }

    .swiper-pagination {
        top: 1px;
        right: 0;
        margin-left: auto;
        height: min-content;
        width: fit-content;
        position: absolute;

        @include XGA {
            right: 36px;
            top: 7px
        }

        &-bullet {
            background-color: $yellow;
            opacity: 1;
        }

        &-bullet-active {
            background-color: $darkorange;
            opacity: 1;
        }
    }

    .sales,
    .card {
        font-weight: 600;
        position: relative;

        @include XGA {
            margin-top: 24px;
        }

        h2 {
            font-size: 18px;
            margin-bottom: 16px;

            @include XGA {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .points {
            font-size: 12px;
            background: $darkorange;
            color: white;
            border-radius: 12px;
            padding: 4px 8px 2px;
            display: inline-block;
            margin-left: 16px;
            margin-bottom: 12px;
            font-weight: 600;

            @include XGA {
                margin-left: 24px;
                margin-top: 24px;
                margin-bottom: 4px;
            }
        }


        .title {
            font-size: 12px;
            margin-left: 16px;

            @include XGA {
                margin-left: 24px;
                margin-right: 24px;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .swiper-slide {
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            height: auto;

            .slide-link {
                text-decoration: none;
                color: black;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            img {
                width: 100%;
                border-radius: 12px 12px 0 0;
            }

            .text-wrapper {
                display: grid;
                height: 100%;
                grid-template-rows: repeat(2, auto);
                align-content: space-between;

                @include XGA {
                    grid-template-rows: repeat(3, auto);
                    align-content: space-between;
                }

                .points {
                    height: min-content;
                    width: fit-content;
                }
            }
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            column-gap: 32px;
            margin-top: 16px;

            .element {
                flex: 1 0 21%;
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                font-size: 14px;
                text-decoration: none;
                color: black;

                .img {
                    height: min-content;

                    img {
                        border-radius: 12px 12px 0 0;
                        width: 100%;
                    }
                }

                // .points {
                //     font-size: 12px;
                //     background: $darkorange;
                //     color: white;
                //     border-radius: 12px;
                //     padding: 4px 8px;
                //     display: inline-block;
                //     margin-top: 20px;
                //     margin-left: 24px;
                //     margin-bottom: 16px;
                // }

                .title {
                    margin-top: 16px;
                    margin: 0;
                    margin-left: 24px;
                    font-size: 14px;
                }

                .arrow {
                    margin-top: 8px;
                    margin-left: 24px;
                    margin-bottom: 24px;

                    // img {

                    // }
                }
            }
        }

        .arrow-img {
            width: 53px !important;
            margin-left: 24px;
            margin-bottom: 24px;
            border-radius: 0 !important;
        }
    }

    .drops {
        margin-top: 32px;

        .swiper-button {

            &-prev,
            &-next {
                top: -37px;
            }

            &-prev {
                right: 72px;

                @include XGA {
                    right: 96px;
                }
            }
        }

        .swiper-pagination {
            top: -34px;
        }

        @include XGA {
            margin-top: 40px;
        }

        h2 {
            font-size: 18px;

            @include XGA {
                margin-bottom: 16px;
                font-size: 24px;
                line-height: 32px;
            }
        }

        &-wrapper {
            // display: flex;
            // column-gap: 15px;
            position: relative;

            @include XGA {
                column-gap: 16px;
                margin-top: 16px;
            }

            .swiper {
                width: calc(50% - 8px);
                margin-bottom: 32px;

                @include XGA {
                    width: calc(50% - 16px);
                }
            }

            .swiper-wrapper {
                width: 100%;
            }

            .swiper-slide {
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                height: initial;
            }

            .element {
                width: 100%;
                font-weight: 600;
                text-decoration: none;
                color: black;

                @include XGA {
                    margin-bottom: 64px;
                }

                .img {
                    background-color: black;
                    padding-bottom: 100%;
                    border-radius: 12px 12px 0 0;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    @include XGA {
                        padding-bottom: 53%;
                    }
                }

                .title {
                    margin-top: 16px;
                    font-size: 12px;

                    @include XGA {
                        margin-top: 0;
                        margin-bottom: 8px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                .points,
                .title,
                .arrow {
                    margin-left: 16px;

                    @include XGA {
                        margin-left: 24px;
                    }
                }

                .points {
                    @include XGA {
                        margin-top: 24px;
                    }
                }

                .arrow {
                    @include XGA {
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }

    .points {
        font-size: 12px;
        background: $darkorange;
        color: white;
        border-radius: 12px;
        padding: 4px 8px 2px;
        display: inline-block;
        margin-top: 8px;
        margin-bottom: 16px;
        font-weight: 600;
        line-height: 16px;

        &.pulse-animation {
            animation: pulse-red 2s infinite;
        }

        @include XGA {
            // margin-top: 32px;
            margin-bottom: 16px;
        }
    }

    .line {
        margin-top: 16px;
        margin-bottom: 24px;
        border-top: 1px solid #F3F3F3;

        @include XGA {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .clouds-fest-custom {
        background-color: white;

        &>img {
            width: 100%;
            border-radius: 12px 12px 0 0;
            display: block;
            margin-bottom: 32px;

            @include XGA {
                border-radius: 12px 0 0 12px;
                height: 100%;
                object-fit: cover;
                margin-bottom: 0;
            }
        }

        .content {

            .disclaimer {
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                color: #000000;
                text-align: left;
                margin: 8px 16px 0;
            }

            @include XGA {
                grid-column: 2/4;
                align-self: center;
            }

            .valid-until {
                margin-bottom: 8px;
            }

            .reward-name>p {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                color: #2A2C2C;
                margin: 0;
            }

            .availability {
                --color: red;
                display: flex;
                justify-content: center;
                margin-top: 12px;
                // align-items: center;

                p {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: #2A2C2C;
                    margin: 0;

                    &:first-of-type {
                        margin-right: 8px;
                    }
                }

                .state {
                    color: var(--color);
                    margin-left: 4px;
                }

                .dot {
                    background-color: var(--color);
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                }
            }

            .boxes {
                padding-left: 16px;
                padding-right: 16px;
                margin-top: 24px;

                div {
                    background-color: #F7F7F7;
                    border-radius: 12px 12px 0 0;
                    height: 47px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include XGA {
                        max-width: 500px;
                        margin: 0 auto;
                    }

                    img {
                        width: fit-content;
                        margin-right: 8px;
                    }

                    p {
                        margin: 0;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        color: #000000;
                        opacity: 0.8;
                        width: fit-content;

                        &.redeem {
                            font-weight: 500;

                            strong {
                                font-weight: 700;
                            }
                        }
                    }

                    &:last-of-type {
                        margin-top: 3px;
                        border-radius: 0 0 12px 12px;
                    }

                    &:only-child {
                        border-radius: 12px;
                    }
                }
            }

            .button-wrapper {
                margin-top: 24px;
                padding-bottom: 24px;
            }
        }

        .consents {
            margin-top: 32px;
            margin-bottom: 32px;
            padding: 0 16px;
            text-align: left;

            @include XGA {
                max-width: calc(min(640px, 80%));
            }

            .checkbox {
                margin-top: 8px;

                input {
                    order: 0;
                    width: 12px;
                    height: 12px;
                }

                label {
                    order: 1;
                }
            }

            .select-all {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #ED8002;
            }

            p {
                margin-top: 8px;
            }

            p,
            label {
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                color: #000000;

                a {
                    color: black;
                }
            }

            input {
                display: none;
            }

            label {
                position: relative;
                width: 100%;
                display: block;
                padding-left: 30px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -3px;
                    width: 20px;
                    height: 20px;
                    opacity: 0.8;
                    border: 1px solid $lightorange;
                    box-sizing: border-box;
                }
            }

            input:checked+label {
                &::before {
                    background-color: $lightorange;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 0px;
                    width: 5px;
                    height: 10px;
                    border-bottom: #fff solid 1px;
                    border-right: #fff solid 1px;
                    transform: rotate(45deg);
                }
            }
        }

        .consents-info {
            padding-top: 24px;
            padding-bottom: 24px;
            position: relative;

            @include XGA {
                display: none;
            }

            &::before {
                content: "";
                position: absolute;
                width: 1100px;
                height: 100%;
                background-color: #F7F7F7;
                top: 0;
                left: -400px;
            }

            p.info {
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                color: #4F4F4F;
                position: relative;
                z-index: 2;

                &:last-of-type {
                    margin-top: 8px;
                }
            }
        }
    }

    .clouds-fest-cho-custom {
        &>img {
            width: 100%;
            aspect-ratio: 327/176;
            object-fit: cover;
            border-radius: 12px 12px 0 0;
            display: block;
        }

        h2.title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #2A2C2C;
            margin-top: 32px;
        }

        h3.title {
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #2A2C2C;
            margin-top: 24px;
        }

        .statute {
            opacity: 1 !important;
            margin-top: 24px !important;
            margin-bottom: 24px !important;

            a {
                font-weight: 900 !important;
                font-size: 14px !important;
                line-height: 20px !important;
                text-align: center;
                text-decoration: underline;
                color: #ED8002;
            }
        }

        .info {
            background-color: #F7F7F7;
            border-radius: 12px;
            opacity: 1;
            margin: 16px 16px 32px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .date {
                img {
                    width: 20px;
                }
            }

            div {
                opacity: 1;
                margin: 0;
                padding: 18px 0 16px;

                img {
                    opacity: 1;
                }

                p {
                    margin: 0;
                    margin-top: 9px;
                }
            }
        }

        .button-wrapper {
            padding-bottom: 32px;
        }
    }
}

.all-awards {

    p,
    a {
        margin-bottom: 0;
        margin-top: 12px;
        ;
    }

    // .limited {
    //     order: 1;

    //     @include XGA {
    //         display: flex;
    //         flex-direction: column;

    //         .points-progres {
    //             align-self: flex-start;
    //         }
    //     }
    // }

    .points-progres {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        margin: 8px 0 0px 16px;
        padding-bottom: 16px;

        @include XGA {
            align-items: center;
            height: min-content;
            padding-bottom: 0;
            align-self: center;
            margin-left: 24px;
        }

        .points {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: white;
            background-color: $darkorange;
            padding: 5px 8px 2px;
            border-radius: 12px;
            margin: 0 !important;
            height: 24px;
            order: 1;
            height: 24px !important;
        }

        .progres {
            margin-left: 8px;
            color: #2F988A;
            font-size: 12px;
            line-height: 16px;
            border-radius: 12px;
            padding: 4px 8px 2px;
            font-weight: 600;
            border: 1px solid #2F988A;
            display: flex;
            justify-content: center;
            align-items: baseline;
            position: relative;
            height: min-content;
            order: 2;
            overflow: hidden;

            p {
                margin: 0;
                z-index: 2;
            }

            p {
                margin: 0;
                z-index: 2;
            }

            img {
                margin-left: 8px;
                animation: rotate 2s ease-in-out infinite;
                z-index: 2;

                @keyframes rotate {
                    0% {
                        transform: rotate(0);
                    }

                    80% {
                        transform: rotate(0);
                    }

                    100% {
                        transform: rotate(-180deg)
                    }
                }
            }

            &.possible {
                color: white;
                background: #0066FF;
                border-color: #0066FF;

                img {
                    animation: none;
                }
            }

            .progres-bar {
                position: absolute;
                width: 0;
                height: 100%;
                z-index: 0;
                background-color: #E5E5E5;
                top: 0;
                left: 0;
                border-radius: 12px;
                transition: width .6s $CubicEaseInOut 1s;
            }
        }
    }

    // .limited-text {
    //     margin-left: 16px;
    //     font-weight: 600;
    //     font-size: 10px;
    //     line-height: 16px;
    //     letter-spacing: 0.12em;
    //     text-transform: uppercase;
    //     background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
    //     background-clip: text;
    //     -webkit-text-fill-color: transparent;

    //     @include XGA {
    //         margin-left: 24px;
    //     }
    // }

    .reward-selector {
        display: flex;
        align-items: center;
        column-gap: 16px;

        p,
        a {
            opacity: 0.4;
            font-size: 12px;
            padding: 8px 16px 3px;
            border: 1px solid transparent;
            cursor: pointer;
            text-decoration: none;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: 24px;

            @include XGA {
                font-size: 14px;
            }

            &::before {
                display: block;
                content: attr(title);
                font-weight: 600;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }
        }

        .selected {
            color: $lightorange;
            padding: 8px 16px 4px;
            opacity: 1;
            font-weight: 600;
            border: 1px solid rgba(#000000, 0.1);
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px 12px 0 0;
            position: relative;

            @include XGA {
                font-size: 14px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 25px;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: white;
                visibility: visible;
            }
        }
    }

    .wrapper {
        border: 1px solid rgba(#000000, 0.1);
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        border-radius: 0 12px 12px 12px;
        padding: 16px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 18px;

        @include XGA {
            // display: flex;
            // flex-wrap: wrap;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 32px;
            margin-bottom: 0;

            @include UXGA {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        a {
            text-decoration: none;
            color: black;

            @include XGA {
                margin-bottom: 0;
                margin-top: 0;
                width: 100%;
            }

            &.disabled {
                position: relative;
                display: block;

                .card {
                    opacity: 0.3;
                }

                .points {
                    animation: none;
                }

                &:after {
                    content: "Nagroda wyczerpana";
                    position: absolute;
                    left: 50%;
                    top: 56px;
                    color: #3A3A3A;
                    background-color: rgba($color: #fff, $alpha: .5);
                    white-space: nowrap;
                    padding: 16px 32px;
                    border-radius: 24px;
                    transform: translateX(-50%);
                    font-weight: 600;
                    font-size: 14px;

                    @include HD {
                        top: 76px;
                    }
                }

                &.limit {
                    &::after {
                        content: "Nagroda odebrana";
                    }
                }
            }

            &:last-child {
                .card {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .card {
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
            border-radius: 20px;
            background-color: #fff;
            padding-bottom: 12px;
            overflow: hidden;

            @include XGA {
                width: unset;
                height: 100%;
                margin-top: 0;
                display: grid;
                grid-template-rows: auto 1fr auto auto;
                margin-bottom: 0;
            }

            &>*:not(.image) {
                margin-inline: 16px;
            }

            .image {
                aspect-ratio: 304/163;
                object-fit: cover;
                width: 100%;
                border-radius: 0 0 20px 20px;
            }

            .title {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #151522;
                margin-top: 12px;
                min-height: 48px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            .availability {
                margin-top: 8px;
                padding-top: 12px;
                border-top: 1px solid rgba(228, 228, 228, 0.6);

                p {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: #151522;
                    margin: 0;

                    span {
                        font-weight: 700;
                        text-transform: uppercase;
                        // color: #168B5A;
                        margin-left: 8px;
                    }
                }

                .scale {
                    background-color: #f1f2ff;
                    border-radius: 7.5px;
                    height: 11px;
                    margin-top: 4px;
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    overflow: hidden;
                    position: relative;

                    .bar {
                        position: absolute;
                        height: 100%;
                        left: 0;
                        background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                        border-radius: 0 7.5px 7.5px 0;
                        z-index: 1;
                    }

                    .element.active {
                        position: relative;
                        background-color: #E3E6FF;

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 2px;
                            height: 100%;
                            background-color: #F1F3FF;
                            opacity: 0.5;
                            right: -1px;
                            z-index: 2;
                        }


                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .progress {
                display: flex;
                align-items: center;
                margin-top: 12px;
                padding-top: 6px;
                border-top: 1px solid rgba(228, 228, 228, 0.6);

                @include XGA {
                    padding-top: 12px;
                }

                .cart {
                    width: 36px;
                    height: 36px;
                    display: grid;
                    place-items: center;

                    &>* {
                        grid-row: -1/1;
                        grid-column: -1/1;
                    }

                    svg {
                        display: block;
                    }

                    svg.svg-progress {
                        width: 36px;
                        height: 36px;
                        transform: rotate(-90deg);
                        

                        .track,
                        .indicator {
                            cx: 18px;
                            cy: 18px;
                            r: 16px;
                            fill: transparent;
                            stroke-width: 2px;
                        }

                        .track {
                            stroke: #E4E4E4;
                            opacity: 0.7;
                        }

                        .indicator {
                            stroke: #E5451F;
                            stroke-linecap: round;
                        }
                    }
                }

                .price {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    margin-right: auto;
                    margin-left: 16px;

                    p {
                        margin: 0;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        color: #E5451F;
                    }

                    div {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                        color: #868686;

                        .flex-wrapper {
                            font-weight: 800;
                            font-size: 12px;
                            line-height: 20px;
                            color: #ED8002;
                            display: flex;
                            gap: 8px;
                            align-items: center;
                        }
                    }
                }
            }



            .limited-yellow {
                position: absolute;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: white;
                background: #F5AA04;
                border-radius: 24px;
                top: 16px;
                left: 16px;
                padding: 3px 8px 1px;
                display: flex;
                align-items: center;
                animation: pulse-yellow 2s infinite;
            }

            &.drop {
                background-color: #2A2C2C;
                border: 3px solid #E5451F;
                position: relative;
                overflow: hidden;

                .title {
                    color: white;
                }

                .limited {
                    position: absolute;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: white;
                    background: #E5451F;
                    border-radius: 24px;
                    top: 16px;
                    left: 16px;
                    padding: 3px 8px 1px;
                    display: flex;
                    align-items: center;
                    animation: pulse-red 2s infinite;

                    img {
                        padding-bottom: 2px;
                        padding-left: 5px;
                    }
                }

                .availability {
                    p {
                        color: white;
                    }
                }

                .progres {
                    border-color: #E5451F;
                    color: white;

                    img {
                        filter: brightness(0) saturate(100%) invert(48%) sepia(77%) saturate(4722%) hue-rotate(347deg) brightness(90%) contrast(99%);
                    }

                    &-bar {
                        background-color: #E5451F;
                    }

                    &.possible {
                        border-color: #0066FF;

                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }

                img.arrow-desktop {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    .border-modifier {
        border-radius: 12px;
    }
}

.received-awards {

    .selecotr {
        display: none;
    }

    .no-results {
        text-align: center;
    }

    .code-pin-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 16px;

        @include XGA {
            margin-bottom: 8px;
        }

        .code,
        div {
            margin: 0 !important;
            width: fit-content !important;
        }

        div {
            pointer-events: none;
            // border-color: $secondary;
            // color: $secondary;
        }
    }

    @include XGA {
        .selector {
            display: flex;
            align-items: center;
            column-gap: 16px;

            p,
            a {
                opacity: 0.4;
                font-size: 12px;
                padding: 8px 16px 3px;
                border: 1px solid transparent;
                cursor: pointer;
                text-decoration: none;
                font-weight: 500;
                margin-bottom: 0;
                margin-top: 24px;

                @include XGA {
                    font-size: 14px;
                }

                &::before {
                    display: block;
                    content: attr(title);
                    font-weight: 600;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
            }

            .selected {
                color: $lightorange;
                padding: 8px 16px 4px;
                opacity: 1;
                font-weight: 600;
                border: 1px solid rgba(#000000, 0.1);
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                border-radius: 12px 12px 0 0;
                position: relative;

                @include XGA {
                    font-size: 14px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 25px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    visibility: visible;
                }
            }
        }
    }

    .top-menu {

        a,
        p {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .sort {
        display: flex;
        gap: 16px;

        @include XGA {
            grid-column: 1 / span 4;
        }

        @include HD {
            margin-bottom: -8px;
        }

        .header {
            font-size: 12px;
            line-height: 16px;
            color: $lightorange;
            font-weight: 600;
        }

        .select-box {
            margin-bottom: 16px;

            @include XGA {
                margin-left: 48px;
                margin-top: 36px;
                margin-bottom: 0;
            }
        }

    }

    .wrapper {
        margin-top: 16px;

        @include XGA {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 24px;
            margin-top: 0;
            margin-bottom: 16px;
            border: 1px solid rgba(#000000, 0.1);
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            padding: 16px;
            margin-top: 32px;
        }

        @include HD {
            gap: 32px;
        }

        .card {
            border-radius: 12px;
            margin-bottom: 24px;
            padding-bottom: 24px;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            background-color: #fff;

            &.letnie-brzmienia {
                background-color: #E5451F;
                color: white;

                .title {
                    color: black;

                    @include XGA {
                        color: white;
                    }
                }

                .code {
                    background-color: white;
                    color: #E5451F;
                }

                .valid-until {
                    opacity: 1;

                    img {
                        filter: brightness(0) invert(1);
                    }
                }
            }

            @include XGA {
                margin: 0;
                display: grid;
            }

            .flex-wrapper {
                display: flex;

                @include XGA {
                    flex-direction: column;
                }

                .image {
                    aspect-ratio: 608/328;
                    object-fit: cover;
                    width: 33%;
                    border-radius: 12px 0 0 0;

                    @include XGA {
                        width: 100%;
                        border-radius: 12px 12px 0 0;
                    }
                }

                .title {
                    font-weight: 600;
                    font-size: 15px;
                    width: 100%;
                    line-height: 18px;
                    background-color: #F3F3F3;
                    border-radius: 0 12px 0 0;
                    margin-left: 0;
                    padding: 12px 0 12px 16px;

                    @include XGA {
                        background: none;
                        margin-top: 24px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 0 16px;
                    }
                }
            }

            .code {
                margin-top: 16px;

                @include XGA {
                    margin-top: 7px;
                }
            }

            .valid-until {
                margin: 0 auto;
                margin-top: 12px;
                width: fit-content;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                opacity: 0.8;

                @include XGA {
                    margin-top: 9px;
                }

                img {
                    margin-right: 12px;
                    width: 16px;
                    margin-bottom: -3px;
                    opacity: 1;
                }

                strong {
                    font-weight: 600;
                    opacity: 1;
                }
            }

            .desktop-end {
                @include XGA {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    align-self: flex-end;

                    .valid-until {
                        margin-top: auto;
                    }
                }
            }
        }
    }
    .disabled {
        pointer-events: none;
        opacity: 0.4!important;
    }
}

.referral-awards {
    width: 100%;
    padding-bottom: 0;
    
    .top-menu {
        padding: 0 24px;

        @include XGA {
            padding: 0 64px;
        }
    }

    .wrapper {
        background-color: #000;
        background-position: top center;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(./img/refferal-bg-top-mobile.jpg);
        padding: 32px 24px;
        color: #fff;
        min-height: 480px;

        @include XGA {
            background-image: url(./img/refferal-bg-top-desktop.jpg);
            padding: 64px;
        }

        .referral-awards-top {
            h2 {
                margin: 0;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 8px;
                font-weight: 900;

                @include XGA {
                    font-size: 48px;
                    line-height: 56px;
                }

                span {
                    font-weight: 500;
                }
            }

            p {
                margin: 0;
                font-size: 18px;
                line-height: 20px;
                font-weight: 600;
                color: #C6C6C5;

                strong {
                    font-weight: 900;
                    color: #fff;
                }
            }

            .button {
                line-height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                margin-top: 16px;
    
                @include XGA {
                    margin-left: 0;
                    margin-right: 0;
                    gap: 8px;
                    max-width: 320px;
                }
            }

            .referral-copy-code {
                margin-top: 260px;

                @include XGA {
                    margin-top: 32px;
                }
            }
        }

        .referrals-list {
            margin-top: 180px;

            @include vHD {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                column-gap: 16px;
            }

            @include XGA {
                margin-top: 80px;
            }

            @include HD {
                column-gap: 32px;
            }

            li {
                filter: drop-shadow(0px 4px 20px rgba(238, 72, 35, 0.16));
                margin-bottom: 16px;
                cursor: pointer;

                @include vHD {
                    min-width: calc( 50% - 8px );
                    max-width: calc( 50% - 8px );
                    width: calc( 50% - 8px );
                }

                @include XGA {
                    min-width: calc( 33.33% - 12px );
                    max-width: calc( 33.33% - 12px );
                    width: calc( 33.33% - 12px );
                }

                @include HD {
                    min-width: calc( 33.33% - 22px );
                    max-width: calc( 33.33% - 22px );
                    width: calc( 33.33% - 22px );
                    margin-bottom: 32px;
                }

                @include UXGA {
                    min-width: calc( 25% - 24px );
                    max-width: calc( 25% - 24px );
                    width: calc( 25% - 24px );
                }

                a {
                    text-decoration: none;
                    color: #fff;
                }

                .thumb {
                    border-radius: 12px 12px 0 0;
                    overflow: hidden;

                    img {
                        max-width: 100%;
                        display: block;
                        margin: 0;
                    }
                }

                .copy {
                    background-color: #2A2C2C;
                    padding: 16px;
                    border: #E5451F solid 2px;
                    border-top: none;
                    border-radius: 0 0 12px 12px;

                    p {
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 500;
                        margin-bottom: 4px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        strong {
                            font-weight: 700;
                        }

                        &.title {
                            font-size: 15px;
                            font-weight: 700;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

.collect-reward {
    text-align: center;

    .header {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        margin-top: 12px;

        .content {
            margin: 0;
            text-align: center;
            flex: 1 0 auto;
        }
    }

    .limited-yellow {
        position: absolute;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: white;
        background: #F5AA04;
        border-radius: 24px;
        top: 16px;
        left: calc(50% - 32px);
        padding: 3px 8px 1px;
        display: flex;
        align-items: center;
        animation: pulse-yellow 2s infinite;

        @include XGA {
            left: 16px;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 5% 90% 5%;

        .content {
            text-align: center;
            grid-column: 2/3;

            @include XGA {
                font-size: 32px;
            }
        }

        button {
            padding: 0;
        }
    }

    button {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;

        @include XGA {
            img {
                width: 13px;
            }
        }
    }

    h3 {
        margin: 0;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .image {
        aspect-ratio: auto;
        width: 100%;
        border-radius: 12px;
    }

    .card {
        border-radius: 12px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        margin-bottom: 16px;
        margin-top: 16px;
        background-color: white;

        @include XGA {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 57% 21.5% 21.5%;
        }

        .image {
            aspect-ratio: auto;
            border-radius: 12px 12px 0 0;
            width: 100%;

            @include XGA {
                grid-column: 1;
                grid-row: 1/6;
                border-radius: 12px 0 0 12px;
                background-size: cover;
                background-position: center;
            }
        }

        .full-radius {
            border-radius: 12px;
        }

        .text {
            margin-top: 12px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            white-space: pre-line;
            opacity: 0.8;
            margin-bottom: 0;

            @include XGA {
                grid-column: 2 / 4;
                grid-row: 1;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                margin-top: 20px;
                opacity: 1;
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        .grid-points {
            @include XGA {
                grid-row: 3/4;
                // margin-left: 64px;
                align-self: center;
            }
        }

        .price {
            // margin-top: 12px;
            text-align: center;
            // margin-top: 24px;

            @include XGA {
                font-size: 15px;
            }
        }

        .points {
            margin: 0;
            margin-top: 4px;
            padding: 9px 16px 7px 16px;
            border-radius: 24px;
        }

        .line {
            margin-left: 24px;
            margin-right: 24px;
            margin-bottom: 16px;

            @include XGA {
                grid-column: 2/4;
                // margin: 10px 40px;
                align-self: center;
            }
        }

        .second {
            @include XGA {
                grid-column: 2/4;
            }
        }


        .svg {
            width: 24px;
            display: block;
            margin: 0 auto;
            margin-top: 16px;
            margin-bottom: 8px;

            @include XGA {
                margin-top: 0;
            }
        }

        .use-in {
            display: grid;
            place-items: center;

            @include XGA {
                grid-column: 3/4;
                grid-row: 3;
                // margin-right: 64px;
            }

            h2 {
                font-weight: 600;
                font-size: 15px;
                margin: 0;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                opacity: 0.8;
                margin: 0;
                margin-bottom: 8px;

                &:only-child {
                    margin-bottom: 0;
                }
            }
        }

        .grid-icons {
            @include XGA {
                // display: flex;
                // flex-direction: row;
                // justify-content: space-around;
                // grid-column: 2/4;
                margin-bottom: 20px;
                margin-top: 20px;
                // margin-left: 40px;
                // margin-right: 40px;
                // justify-content: space-around;
                // align-items: center;
            }
        }

        .br-desktop {
            display: none;

            @include XGA {
                display: block;
            }
        }

        .date {
            @include XGA {
                grid-column: 2/3;
                align-self: center;
            }
        }

        .statute {
            @include XGA {
                grid-column: 3/4;
                align-self: center;
            }
        }

        .date,
        .cart,
        .statute {
            margin-top: 24px;
            font-size: 12px;
            font-weight: 500;
            opacity: 0.8;
            margin-bottom: 16px;

            @include XGA {
                // max-width: 133px;
                height: fit-content;
                margin-top: 0px;

                p {
                    margin-bottom: 0;
                }
            }

            strong {
                font-weight: 600;
            }

            a {
                font-weight: 600;
                font-size: 12px;
                color: $lightorange;
            }
        }

        .cart {
            .svg {
                width: 22px;
            }
        }

        .statute {
            .svg {
                width: 30px;
            }
        }

        .get-award {
            margin-top: 32px;
            padding-bottom: 24px;
        }
    }

    p.limit {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #2A2C2C;
        margin-top: 0;
        margin-bottom: 16px;

        @include XGA {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .get-award {
        @include XGA {
            margin-top: 40px;
            margin-bottom: 32px;
        }
    }

    .padding-content {
        padding-left: 24px;
        padding-right: 24px;
    }

    .card .exit {
        margin-right: 0;
        margin-left: auto;
        display: block;
        padding-top: 24px;
    }

    .price-name {
        margin-top: 8px;
        font-weight: 600;
        font-size: 12px;
        opacity: 0.8;
    }

    .points-wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;
        background: rgba(243, 243, 243, 0.90);
        border-radius: 12px;
        padding-bottom: 12px;
        margin-bottom: 24px;
        margin-top: 24px;

        .points {
            margin: 0;
            margin-top: 16px;
        }

        .points-info {
            font-size: 12px;
            margin: 0;
            margin-top: 8px;
            opacity: 0.8;
            line-height: 16px;
            font-weight: 500;
        }
    }

    .points-wrapper+.get-award {
        margin-top: 24px;
    }

    .valid-until {
        margin: 0 auto;
        margin-top: 4px;
        width: fit-content;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        opacity: 0.8;

        img {
            margin-right: 12px;
            width: 16px;
            margin-bottom: -3px;
            opacity: 1;
        }

        strong {
            font-weight: 600;
            opacity: 1;
        }
    }

    .code {
        // margin: 0 auto;
        margin-top: 16px;
        // padding: 11px 28px 9px 28px;
        // background: linear-gradient(90deg, $darkorange 0%, $yellow 100%);
        // border-radius: 24px;
        // width: fit-content;
        // color: white;
        // font-weight: 600;
        // letter-spacing: 0.08em;

        // strong {
        //     font-weight: 800;
        // }
    }

    .code-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 16px 0 11px;
        gap: 16px;
        width: 100%;

        @include XGA {
            grid-column: 2/4;
        }

        @include HD {
            margin: 24px 0;
            flex-direction: row;
        }

        .code {
            margin: 0 !important;
        }
    }

    .info {
        opacity: 0.4;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        margin-top: 10px;
    }

    .buttons {
        margin-top: 24px;
        padding-bottom: 24px;

        .button {
            display: block;
            margin-left: auto;
            margin-right: auto;

            @include XGA {
                margin-left: 0;
                margin-right: 0;
            }
        }

        a:first-child {
            margin-bottom: 16px;
        }

        a {
            width: 215px;

            @include XGA {
                height: min-content;
                margin-bottom: 32px;
            }
        }

        @include XGA {
            display: flex;
            flex-direction: row;
            flex-flow: row-reverse;
            justify-content: center;
            gap: 80px;
            margin-top: 80px;
        }
    }

    .min-height {
        height: min-content;
    }

    &.last-step {
        .code-pin-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-top: 16px;
            max-width: 90%;
            margin-inline: auto;

            @include XGA {
                margin-top: 24px;
                width: 100%;
                grid-column: 2/4;
                margin-bottom: 16px;
            }

            * {
                width: fit-content !important;
            }

            button {
                margin: 0;
            }

            div {
                pointer-events: none;
                padding: 11px 16px 9px 16px;
            }
        }

        @include XGA {

            .card {
                grid-template-rows: 1fr repeat(4, auto) 1fr;
            }

            .valid-until,
            .price,
            .code,
            .info,
            .text {
                grid-column: 2/4;
                height: min-content;
            }

            .text {
                margin-top: auto;
                margin-bottom: 14px;
            }

            .image {
                grid-row: 1/7;
            }

            .price {
                margin-top: 8px;
            }

            .valid-until {
                margin-top: 10px;
            }

            .code {
                margin-top: 24px;
            }

            .info {
                margin-top: 8px;
                opacity: 1;
            }
        }
    }

    &.modal-content {
        @include XGA {
            margin: 0;
            display: grid;
            grid-template-columns: .9fr 1.2fr .9fr;
            width: 100%;
        }

        @include HD {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .card {
            @include XGA {
                grid-column: 2/3;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                padding: 0;
                margin-top: 24px;
                margin-bottom: 40px;

                .exit {
                    display: none;
                }

                .content {
                    margin-bottom: 24px;
                    font-size: 24px;
                    line-height: 32px;
                }

                .price-name {
                    margin-top: 16px;
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 600;
                    opacity: 1;
                }

                .get-award {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    &.drop {
        .text {
            font-weight: 700 !important;
        }

        .sub {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #000000;
            opacity: 0.8;
            margin-top: 12px;

            @include XGA {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                color: #000000;
                opacity: 1;
                margin-top: 16px;
            }
        }

        .price {
            @include XGA {
                margin-top: 0 !important;
            }
        }

        .line {
            margin-top: 24px !important;
            margin-bottom: 24px !important;
        }

        h3,
        h1,
        h2 {
            font-size: 18px;
            margin-top: 10px;

            @include XGA {
                font-size: 24px;
            }
        }

        form {
            margin-top: 24px;

            @include XGA {
                margin-top: 40px;
            }

            .inputs {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                column-gap: 32px;
                row-gap: 17px;

                div {
                    height: fit-content;

                    label {
                        margin-top: 0;
                    }

                    input[name=postalCode] {
                        margin-bottom: 0;
                    }
                }
            }

            .house-location {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: 16px;
            }

            label,
            input {
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            label {
                margin-top: 16px;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }

            input {
                display: block;
                width: 100%;
                background: transparent;
                border: none;
                border-bottom: 1px solid black;
                padding: 4px 0px;
                color: black;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                outline: none;
                transition: all 0.2s $CubicEaseInOut;

                &:focus {
                    border-color: $lightorange;
                }
            }

            .postal-code {
                input {
                    margin-bottom: 24px;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 6px;

                    @include XGA {
                        margin-bottom: 40px;
                    }

                    &:focus {
                        border: none;
                    }
                }
            }

            // .postal-code {
            //     background-image: url("./img/postal.svg");
            //     background-repeat: no-repeat;

            //     @include XGA {
            //         margin-bottom: 40px;
            //     }


            //     input {
            //         border: none;
            //         // font-size: 14px;
            //         // letter-spacing: 14.5px;
            //         // padding-left: 4.5px;

            //         // @include XGA {
            //         //     letter-spacing: 17px;
            //         //     padding-left: 5px;
            //         // }
            //     }
            // }
        }

        .flex-wrapper {
            display: flex;
            justify-content: space-between;

            div {
                width: 190px;
            }
        }

        &.collect-reward.modal-content {
            @include XGA {
                grid-template-columns: .5fr 1fr .5fr;
            }
        }

        &.collect-reward {
            .card {
                background-color: white;

                @include XGA {
                    grid-template-columns: 57% 43%;
                }
            }

            .text {
                opacity: 1;
                color: #2A2C2C;
                padding: 0 23px 0;

                h3,
                h1,
                h2 {
                    margin-bottom: 4px;
                    margin-top: 24px;

                    @include XGA {
                        margin-top: 37px;
                    }
                }

                p:first-of-type:not(p:only-child) {
                    // font-weight: 700;
                    // font-size: 10px;
                    // line-height: 16px;
                    // text-align: center;
                    // letter-spacing: 0.12em;
                    // text-transform: uppercase;
                    // margin-top: 4px;
                    // margin-bottom: 16px;
                    // color: black;
                }

                *:only-child {
                    padding-left: 16px;
                    padding-right: 16px;
                    font-weight: 500;
                    margin-top: 16px;
                    opacity: 1;
                    font-weight: 700;

                    @include XGA {
                        font-size: 18px;
                        line-height: 24px;
                        text-align: center;
                        color: #2A2C2C;
                    }
                }

                p {
                    padding-left: 16px;
                    padding-right: 16px;
                    font-weight: 500;
                    margin-top: 16px;
                    opacity: 1;

                    @include XGA {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        color: #2A2C2C;
                    }
                }
            }

            .reward-info>* {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                padding-left: 16px;
                padding-right: 16px;
                margin-top: 25px;
                color: #2A2C2C;
            }

            .desktop-grid {
                @include XGA {
                    width: 100%;
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    padding: 0 40px 0 20px;

                    &>div {
                        min-width: 50%;
                        max-width: 50%;

                        &.statute {
                            a {
                                @include XGA {
                                    display: inline-block;
                                    max-width: 120px;
                                }
                            }
                        }

                        &.reward-info {
                            p {
                                max-width: 220px;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }

            .grid-icons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 17px;

                .date,
                .statute {
                    margin-top: 0;
                    margin-bottom: 0;

                    img {
                        margin-top: 0;
                    }

                    p,
                    a {
                        margin-bottom: 0;
                        max-width: 115px;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }

            .statute,
            .date {
                @include XGA {
                    max-width: 143px;
                    padding: 0 10px;
                    margin-bottom: 37px;
                }
            }

            .date {
                @include XGA {
                    margin-left: auto;
                }
            }

            .form-text {

                h1,
                h2 {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                    color: #000000;
                    text-align: left;
                    margin: 0;
                    margin-top: 32px;

                    @include XGA {
                        margin-top: 0;
                    }
                }

                p {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: #000000;
                    text-align: left;
                    margin: 0;
                    margin-top: 8px;
                }
            }

            .agreements {
                margin-top: 8px;

                @include XGA {
                    margin-bottom: 16px;
                }

                .checkbox {
                    position: relative;

                    input[type="checkbox"] {
                        display: none;
                    }

                    &.select-all {
                        label {
                            margin-top: 16px;
                            margin-bottom: 16px;
                        }

                        h3 {
                            color: #ED8002;
                            text-align: left;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            margin-bottom: 10px;
                            padding-top: 4px;
                        }
                    }

                    label {
                        position: relative;
                        display: block;
                        width: 100%;
                        padding-left: 30px;
                        margin-bottom: 12px;
                        min-height: 24px;
                        cursor: pointer;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 20px;
                            height: 20px;
                            border: $darkorange solid 1px;
                        }
                    }

                    input[type="checkbox"]:checked+label {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 7px;
                            top: 4px;
                            width: 5px;
                            height: 8px;
                            border-right: $darkorange solid 2px;
                            border-bottom: $darkorange solid 2px;
                            transform: rotate(35deg);
                        }
                    }
                }

                .switches {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }

                p {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    text-align: left;
                    max-width: 100%;
                    width: 100%;
                    margin-top: 0;

                    &.remove-margin {
                        margin-top: -10px;
                    }
                }

                .link {
                    display: inline;
                    color: black;
                    font-weight: 500;
                }

                .wrapper {


                    h3 {
                        color: #ED8002;
                        text-align: left;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        margin-bottom: 10px;
                        padding-top: 3px;
                    }
                }

                &>p:last-of-type {
                    margin-top: 8px;
                }
            }

            &.modal-content {
                grid-template-columns: 1fr;
                padding-bottom: 0;

                .card {
                    margin: 0;
                }
            }

            .form-separation {
                display: grid;
                grid-template-columns: 44% 56%;

                .grey {
                    position: relative;
                    background-color: #F7F7F7;

                    &::before {
                        content: "";
                        width: calc(100% + 20px);
                        height: calc(100% + 66px);
                        background-color: #F7F7F7;
                        position: absolute;
                        top: -46px;
                        left: 0;
                        z-index: -1;
                    }

                    form {
                        margin: 0 auto;
                        max-width: 414px;
                    }
                }

                .form-text {
                    @include XGA {
                        padding: 11px 64px 64px 48px;

                        h4 {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 32px;
                            margin-top: 0;
                        }

                        p {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 20px;

                            strong {
                                font-weight: 700;
                            }
                        }

                        img {
                            display: block;
                            width: 100%;
                            border-radius: 12px;
                            margin-top: 18px;
                            margin-bottom: 18px;
                        }
                    }
                }
            }
        }
    }

    &.referral-reward {
        width: 100%;
        padding-bottom: 0;

        .header {
            padding: 0 24px;

            @include XGA {
                padding: 0 64px;
            }

            .content {
                font-size: 15px;
                line-height: 20px;

                @include XGA {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }

        .wrapper {
            @include XGA {
                background-position: center;
                background-size: cover;
                background-image: url(./img/refferal-bg-top-desktop.jpg);
                padding: 80px 0;
                margin-top: 24px;
            }

            @include UXGA {
                padding: 120px 0;
            }
        }

        .card {
            background-color: transparent;
            box-shadow: none;
            margin: 0 auto;
            max-width: 1080px;
            margin-top: 24px;
            padding: 0 24px;

            @include XGA {
                background-color: #fff;
                display: block;
                margin-top: 0;
                width: 90%;
                padding: 32px 0;
            }

            .get-award {
                display: block;

                @include vHD {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    column-gap: 32px;
                }

                @include XGA {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                .button {
                    width: 100%;
                    max-width: 310px;
                    margin-bottom: 16px;

                    @include vHD {
                        max-width: 180px;
                    }
                }
            }

            h3 {
                @include XGA {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 700;
                    margin-bottom: 32px;
                }
            }

            &.confirm {
                h3.content {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 700;
                }

                .price-name {
                    font-size: 15px;
                    line-height: 20px;
                }

                .image {
                    max-width: 310px;
                }
            }

            .referral-awards-list {
                @include XGA {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    column-gap: 24px;
                }

                @include UXGA {
                    column-gap: 32px;
                }

                li {
                    border-radius: 12px;
                    border: #2A2C2C solid 2px;
                    overflow: hidden;
                    background-color: #2A2C2C;
                    margin: 0 auto;
                    margin-bottom: 12px;
                    max-width: 310px;
                    cursor: pointer;

                    @include XGA {
                        margin: 0;
                    }

                    &.selected {
                        background-color: $orange;
                        border-color: $orange;

                        .copy {
                            background-color: $orange;
                        }
                    }

                    .thumb {
                        img {
                            display: block;
                            margin: 0;
                            border-radius: 0;
                        }
                    }

                    .copy {
                        background-color: #2A2C2C;
                        color: #fff;
                        padding: 16px 0;

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}