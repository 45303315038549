@import "../../../styles/_vars.scss";

.clouds-fest-notify {
    .count-down {
        background-image: url("./img/background.jpg");
        width: calc(100% + 40px);
        margin-top: -20px;
        margin-left: -20px;
        height: 80px;
        display: grid;
        place-items: center;
        position: relative;

        @include XGA {
            height: 88px;
        }

        button {
            width: fit-content;
            height: fit-content;
            background-color: transparent;
            border: none;
            margin-left: auto;
            top: 16px;
            right: 16px;
            position: absolute;
            cursor: pointer;

            @include XGA {
                top: 20px;
                right: 24px;
            }

            .exit {
                &::before {
                    content: "\2715";
                    color: white;
                    height: fit-content;
                    font-size: 18px;
                    line-height: 0;
                    height: 100%;
                }
            }
        }

        p {
            color: white;
            margin: 0;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            margin-top: 6px;

            sup {
                font-weight: 700;
                font-size: 12px;
                top: -1em;
                margin-right: 8px;
                margin-left: 4px;

                &:last-of-type {
                    margin-right: 0;
                }

                @include XGA {
                    margin-top: 16px;
                }
            }
        }
    }

    .text {
        h2 {
            margin: 0;
            padding-top: 32px;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            text-align: center;

            @include XGA {
                font-size: 24px;
                line-height: 32px;
            }
        }

        p {
            margin-bottom: 0;
            margin-top: 8px;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #2A2C2C;
        }
    }

    .button-wrapper {
        margin-top: 24px;
        justify-content: center;
    }
}

.new-flavour {
    .upper {
        width: calc(100% + 40px);
        aspect-ratio: 327/176;
        margin-left: -20px;
        margin-top: -20px;
        background-color: black;
        display: grid;

        * {
            grid-row: 1/2;
            grid-column: 1/2;
        }

        img {
            width: 100%;
        }

        button {
            width: 12px;
            height: 12px;
            justify-self: right;
            margin-top: 16px;
            margin-right: 16px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            padding: 0;

            @include XGA {
                margin-top: 20px;
                margin-right: 24px;
            }
        }
    }

    .content {
        display: block;
        margin-top: 32px;
        padding: 0 30px;

        h2 {
            margin: 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #000000;
            max-width: 573px;
            margin-inline: auto;
        }

        .button {
            margin-top: 16px;
            margin-bottom: 12px;
        }
    }
}