@import "../../../../styles/vars";
@import "../../../../styles/index";


.winners-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    white-space: pre-wrap;
    margin-bottom: 32px;
    white-space: pre-wrap;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 24px;
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;

        @include XGA {
            margin-top: 40px;
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
        }
    }

    .header {
        display: grid;
        grid-template-columns: 20px auto 20px;
        margin-top: 12px;

        @include XGA {
            display: flex;
            gap: 32px;
            align-items: center;
        }

        .title {
            text-align: center;

            @include XGA {
                margin: 0;
                font-weight: 600;
            }
        }

        button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: left;
            height: fit-content;
            width: 50%;
            padding: 0;

            @include XGA {
                width: fit-content;
            }
        }
    }

    button {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
        text-align: left;
        // height: fit-content;
        width: 50%;
        padding: 0;
        line-height: initial;
        display: flex;
        justify-content: left;
        align-items: center;

        @include XGA {
            width: fit-content;
            display: block;
        }
    }

    .content-wrapper {
        @include XGA {
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 12px;
            padding: 80px 70px;
            margin-top: 40px;
            display: grid;
            place-items: center;
            position: relative;
            z-index: 2;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-height: 720px;
                background-image: var(--bg-image);
                background-size: cover;
                border-radius: 12px 12px 0 0;
                z-index: -10;
            }
        }

        @include HD {
            padding: 80px 105px;
        }
    }

    .content {
        @include XGA {
            background-color: white;
            border-radius: 12px;
            padding: 64px 75px 40px;
            max-width: 1088px;
            width: 100%;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        }

        @include HD {
            padding: 64px 112px 40px;
        }
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        // margin-bottom: 8px;

        @include XGA {
            margin-top: 40px;
            font-size: 24px;
            line-height: 40px;
            font-weight: 700;
        }

        @include HD {
            font-size: 32px;
        }
    }

    h3 {
        margin: 0;
        margin-top: 24px;
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
    }

    .link {
        color: $lightorange;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
    }

    .content-header {
        @include XGA {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
        }
    }

    .sub {
        @include XGA {
            margin-top: 40px;
        }
    }

    .number {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
        width: 24px;
        height: 24px;
        border-radius: 50%;

        @include XGA {
            width: 32px;
            height: 32px;
            padding-top: 2px;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .rewards {
        margin-top: 24px;

        @include XGA {
            margin-top: 40px;
        }

        .element {
            display: flex;
            justify-content: space-between;
            background-color: white;
            margin-top: 16px;
            border: 1px solid #F7F7F7;
            border-radius: 12px;

            &.border-modifier {
                border-radius: 12px 12px 0 0;

                img {
                    border-radius: 12px 0 0 0;
                }
            }

            @include XGA {
                justify-content: flex-start;
            }

            .reward-img {
                aspect-ratio: 1/1;
                width: 89px;
                height: 100%;
                border-radius: 12px 0 0 12px;
                display: block;

                @include XGA {
                    width: 78px;
                }
            }

            .text {
                margin-left: 24px;
                margin-right: 10px;
                align-self: center;
                width: 100%;

                .place {
                    background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                }

                .rewared-content {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 2px;
                    margin-bottom: 0;

                    @include XGA {
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                        margin-top: 4px;
                    }
                }
            }

            button {
                margin-right: 16px;
                margin-top: 12px;
                font-size: 24px;
                line-height: 24px;
                color: $lightorange;
                background: none;
                cursor: pointer;
                outline: none;
                border: none;
                text-align: left;
                height: fit-content;
                padding: 0;
                display: flex;
                justify-content: left;
                align-items: center;
                min-width: 12px;
                width: fit-content;

                @include XGA {
                    font-size: 32px;
                    align-self: center;
                    margin-top: 0;
                    margin-right: 26px;
                    margin-left: auto;
                }
            }
        }

        .winner-images {
            background-color: white;
            padding-left: 16px;
            padding-right: 16px;
            border: 1px solid #F7F7F7;
            border-top: none;
            border-radius: 0 0 12px 12px;
            margin-bottom: 24px;

            @include XGA {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 16px;
                padding: 40px 40px;
            }

            @include HD {
                gap: 32px;
            }

            @include UXGA {
                padding-left: 121px;
                padding-right: 121px;
            }

            .card {
                cursor: pointer;
                padding-top: 16px;
                background-color: white;

                .img img {
                    display: block;
                    width: 100%;
                }

                .text {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 12px;

                    .place {
                        color: #868686;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    .name {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                &:last-child {
                    padding-bottom: 16px;
                }
            }

        }
    }

    &.dzien-glo-contest-winners{
        .category-list{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 25px;
            gap: 10px;
            .button{
            margin: 40px 0px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3px 8px 1px 8px;
            font-size: 12px;
            background-color: transparent;
            border-radius: 24px;
            border: 2px solid var(--very-dark-grey, #2A2C2C);
            max-width: 150px;
            @include XGA {
                padding: 11px 24px 9px 24px;
                font-size: 14px;
            }
            }
            .active{
                border-radius: 24px;
                border: 2px solid var(--primary, #ED8002);
                color: #ED8002;
            }
        }
        .summer-winner{
            width: 100%;
            border-radius: 12px;
            border: 2px solid #F7F7F7;
            margin: 20px 0;
            .head{
                display: flex;
                align-items: center;
                border-bottom: 2px solid #F7F7F7;
                .reward-img{
                    width: 75px;
                    height: 75px;
                }
                .container{
                    display: flex;
                    margin-left: 15px;
                    flex-direction: column;
                    .reward-title{
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 24px;
                        margin: 0 10px 0 0;
                        @include XGA {
                            font-size: 18px;
                        }
                    }
                    .reward-points{
                        margin: 0;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 16px;
                        color: #ED8002;
                    }
                }
            }
            .rewared-wrapper{
                display: flex;
                padding: 30px 20px;
                gap: 20px;
                flex-direction: column;
                align-items: center;
                text-align: center;
                @include XGA {
                    flex-direction: row;
                    text-align: left;
                }
                .rewared-container{
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    @include XGA {
                        width: 33%;
                    }
                    .rewared-place{
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 20px;
                        color: var(--dark-grey, #868686);
                        margin: 0;
                    }
                    .rewared-name{
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 20px;
                        margin: 0;
                    }
                    .rewared-img{
                        margin-bottom: 10px;
                        background: rgb(247, 247, 247);
                    }
                }
                &.second{
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    @include XGA {
                        justify-content:left;
                    }
                    .rewared-container{
                        width: 120px;
                        text-align: center;
                        @include XGA {
                            text-align: left;
                            width: 200px;
                        }
                    }
                }
            }
            
        }
    }

    &.limited-edition-winners{
        .category-list{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 25px;
            gap: 10px;
            @include XGA {
               gap: 20px;
            }
            .button{
            margin: 40px 0px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3px 8px 1px 8px;
            font-size: 12px;
            background-color: transparent;
            border-radius: 24px;
            border: 2px solid var(--very-dark-grey, #2A2C2C);
            max-width: 150px;
            @include XGA {
                padding: 11px 24px 9px 24px;
                font-size: 14px;
                min-width: 150px;
            }
            }
            .active{
                border-radius: 24px;
                border: 2px solid var(--primary, #ED8002);
                color: #ED8002;
            }
        }
        .summer-winner{
            width: 100%;
            border-radius: 12px;
            border: 2px solid #F7F7F7;
            margin: 20px 0;
            .head{
                display: flex;
                align-items: center;
                border-bottom: 2px solid #F7F7F7;
                .container{
                    display: flex;
                    margin-left: 15px;
                    flex-direction: column;
                    .reward-title{
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 24px;
                        margin: 0;
                        margin-inline: auto;
                        padding: 20px 0;
                        @include XGA {
                            font-size: 18px;
                        }
                    }
                    .reward-points{
                        margin: 0;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 16px;
                        color: #ED8002;
                    }
                }
            }
            .rewared-wrapper{
                display: flex;
                padding: 30px 20px;
                gap: 20px;
                flex-direction: column;
                align-items: center;
                text-align: center;
                @include XGA {
                    flex-direction: row;
                    text-align: left;
                }
                .rewared-container{
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    @include XGA {
                        width: 33%;
                    }
                    .rewared-place{
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 20px;
                        color: var(--dark-grey, #868686);
                        margin: 0;
                    }
                    .rewared-name{
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 20px;
                        margin: 0;
                    }
                    .rewared-img{
                        margin-bottom: 10px;
                        background: rgb(247, 247, 247);
                    }
                }
            }
            
        }
    }

    &.summer-contest-winners{
        .category-list{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: 300px;
            margin-inline: auto;
            margin-bottom: 25px;
            .button{
            margin: 40px 10px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 20px;
            background-color: transparent;
            border: 2px solid #595757;
            border-radius: 12px;
            }
            .active{
                border: 2px solid #ED8002;
                color: white;
            }
        }
        .summer-winner{
            width: 100%;
            border-radius: 12px;
            border: 2px solid #F7F7F7;
            margin: 20px 0;
            .head{
                display: flex;
                align-items: center;
                border-bottom: 2px solid #F7F7F7;
                .reward-img{
                    width: 75px;
                    height: 75px;
                }
                .container{
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    flex-wrap: wrap;
                    .reward-title{
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 24px;
                        margin: 0 10px 0 0;
                        @include XGA {
                            font-size: 18px;
                        }
                    }
                    .reward-points{
                        padding: 5px 0;
                        margin: 0;
                        font-size: 12px;
                        padding: 3px 6px;
                        color: #fff;
                        font-weight: 700;
                        line-height: 16px;
                        border-radius: 24px;
                        background: var(--secondary, #E5451F);
                    }
                }
            }
    
            .text{
                padding-inline: 40px;
                .rewared-content{
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .rewared-name{
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                color: #000000;
                }
            }
        }
    }
    

    &.clouds-fest-winners {
        .place-selector {
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            margin-bottom: 24px;

            @include XGA {
                margin-top: 18px;
            }

            .active {
                font-weight: 900;
                color: #ED8002;
                border: 1px solid #ED8002;
                border-radius: 12px;
                padding: 4px 8px 2px;
            }

            button {
                width: fit-content;
                font-size: 12px;
                line-height: 16px;
                align-items: center;
                color: #868686;
                padding: 4px 8px 2px;
                border: 1px solid transparent;
            }
        }

        .winner-images {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
            column-gap: 9px;

            @include XGA {
                padding: 40px 32px;
            }

            .card {
                cursor: initial !important;
            }

            .img {
                img {
                    width: 100%;
                }
            }
        }
    }

    &.atrakcyjny-weekend-winners {
        .winner-texts {
            background-color: white;
        }

        .card {
            padding-top: 0;
            padding-top: 48px;
            padding-left: 16px;
            padding-right: 16px;

            @include XGA {
                display: grid;
                grid-template-columns: 32px 1fr;
                column-gap: 32px;
            }

            &:first-of-type {
                padding-top: 24px;
            }

            &:last-of-type {
                padding-bottom: 24px;
            }

            .place {
                display: none;

                @include XGA {
                    display: block;
                }
            }

            .content {
                @include XGA {
                    box-shadow: none;
                    padding: 0;
                }

                h3,
                p,
                h4 {
                    margin: 0;
                    text-align: left;
                }

                h3.winner {
                    color: #ED8002;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;

                    @include XGA {
                        margin-top: 5px;
                        color: black;
                    }
                }

                h4.title {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                    color: #000000;
                }

                p.sub-gray {
                    margin-top: 16px;
                    margin-bottom: 4px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: #868686;
                }

                p.winner-text {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: #000000;

                    @include XGA {
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &.relaks-z-kawa-winners {
        .content-wrapper {
            background: #FFFFFF;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            margin-top: 16px;

            @include XGA {
                padding-bottom: 32px;
                background-color: transparent;
                box-shadow: none;
            }

            img {
                width: 100%;
                display: block;
            }

            .content {
                padding: 0 16px 16px;

                @include XGA {
                    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                    padding-bottom: 32px;
                }

                @include HD {
                    padding-inline: 64px;
                    max-width: 1100px;
                }

                @include UXGA {
                    padding-inline: 121px;
                }

                .content-header {
                    margin-top: 32px;

                }

                .desc {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 0;
                    margin-top: 16px;
                }
            }
        }

        .rewards {
            @include XGA {
                margin-top: 24px;
            }

            .element {
                display: grid;
                grid-template-columns: 97px 1fr auto;

                @include XGA {
                    margin-top: 32px;
                }

                img {
                    width: 100%;
                    padding: 16px;
                    background-color: #F7F7F7;
                }

                .text {
                    align-self: flex-start;
                    margin-top: 10px;

                    .place {
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 16px;
                        letter-spacing: 0.12em;
                        text-transform: uppercase;
                        color: #E5451F;
                    }

                    p {
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 20px;
                        color: #000000;
                        max-width: 90%;
                    }
                }
            }

            .winner-texts {
                padding: 8px 8px 16px;
                border: 1px solid #F7F7F7;
                border-radius: 0 0 12px 12px;
                border-top: none;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
                gap: 16px;

                @include XGA {
                    gap: 32px;
                    padding: 32px 32px 24px;
                }

                .card {
                    .inner {
                        @include XGA {
                            box-shadow: none;
                        }

                        .winner {
                            margin-top: 8px;

                            @include XGA {
                                margin-top: 16px;
                            }

                            p {
                                margin: 0;
                            }

                            .place {
                                font-weight: 700;
                                font-size: 10px;
                                line-height: 16px;
                                text-align: center;
                                letter-spacing: 0.12em;
                                text-transform: uppercase;
                                color: #E5451F;

                                @include XGA {
                                    font-weight: 500;
                                    font-size: 15px;
                                    line-height: 20px;
                                    color: #868686;
                                    letter-spacing: normal;
                                    text-transform: initial;
                                    text-align: left;
                                }
                            }

                            .name {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                                text-align: center;
                                color: #000000;

                                @include XGA {
                                    margin-top: 2px;
                                    font-weight: 700;
                                    font-size: 15px;
                                    line-height: 20px;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.slow-life {
        .content-wrapper {
            background: #FFFFFF;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            margin-top: 16px;

            @include XGA {
                padding-bottom: 32px;
                background-color: transparent;
                box-shadow: none;
            }

            img {
                width: 100%;
                display: block;
            }

            .content {
                padding: 0 16px 16px;

                @include XGA {
                    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                    padding-bottom: 32px;
                }

                @include HD {
                    padding-inline: 64px;
                    max-width: 1100px;
                }

                @include UXGA {
                    padding-inline: 121px;
                }

                .content-header {
                    margin-top: 32px;

                }

                .desc {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 0;
                    margin-top: 16px;
                }
            }
        }

        .rewards {
            @include XGA {
                margin-top: 24px;
            }

            .element {
                display: grid;
                grid-template-columns: 97px 1fr auto;

                @include XGA {
                    margin-top: 32px;
                }

                img {
                    width: 100%;
                    padding: 16px;
                    background-color: #F7F7F7;
                }

                .text {
                    align-self: flex-start;
                    margin-top: 10px;

                    .place {
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 16px;
                        letter-spacing: 0.12em;
                        text-transform: uppercase;
                        color: #E5451F;
                    }

                    p {
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 20px;
                        color: #000000;
                        max-width: 90%;
                    }
                }
            }

            .winner-texts {
                padding: 8px 8px 16px;
                border: 1px solid #F7F7F7;
                border-radius: 0 0 12px 12px;
                border-top: none;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
                gap: 16px;

                @include XGA {
                    gap: 32px;
                    padding: 32px 32px 24px;
                }

                .card {
                    .inner {
                        @include XGA {
                            box-shadow: none;
                        }

                        .winner {
                            margin-top: 8px;

                            @include XGA {
                                margin-top: 16px;
                            }

                            p {
                                margin: 0;
                            }

                            .place {
                                font-weight: 700;
                                font-size: 10px;
                                line-height: 16px;
                                text-align: center;
                                letter-spacing: 0.12em;
                                text-transform: uppercase;
                                color: #E5451F;

                                @include XGA {
                                    font-weight: 500;
                                    font-size: 15px;
                                    line-height: 20px;
                                    color: #868686;
                                    letter-spacing: normal;
                                    text-transform: initial;
                                    text-align: left;
                                }
                            }

                            .name {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                                text-align: center;
                                color: #000000;

                                @include XGA {
                                    margin-top: 2px;
                                    font-weight: 700;
                                    font-size: 15px;
                                    line-height: 20px;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }

            .winner-texts-column-1 {
                padding: 8px 8px 16px;
                border: 1px solid #F7F7F7;
                border-radius: 0 0 12px 12px;
                border-top: none;
                display: grid;
                gap: 16px;
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

                @include XGA {
                    grid-template-columns: repeat(auto-fill, minmax(639px, 1fr));
                    gap: 32px;
                    padding: 32px 32px 24px;
                }
            }

            .winner-texts-column-2 {
                padding: 8px 8px 16px;
                border: 1px solid #F7F7F7;
                border-radius: 0 0 12px 12px;
                border-top: none;
                display: grid;
                gap: 16px;
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

                @include XGA {
                    grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
                    gap: 32px;
                    padding: 32px 32px 24px;
                }
            }

            .winner-texts-column-3 {
                padding: 8px 8px 16px;
                border: 1px solid #F7F7F7;
                border-radius: 0 0 12px 12px;
                border-top: none;
                display: grid;
                gap: 16px;
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

                @include XGA {
                    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
                    gap: 32px;
                    padding: 32px 32px 24px;
                }
            }
        }

        &.valentine-winners {
            .winner-texts-column-2 {
                @include XGA {
                    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
                }
            }
        }
    }

    &.slow-10 {
        .content-wrapper {
            background: #FFFFFF;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            margin-top: 16px;

            @include XGA {
                padding-bottom: 32px;
                background-color: transparent;
                box-shadow: none;
            }

            img {
                width: 100%;
                display: block;
            }

            .content {
                padding: 0 16px 16px;

                @include XGA {
                    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                    padding-bottom: 32px;
                }

                @include HD {
                    padding-inline: 64px;
                    max-width: 1100px;
                }

                @include UXGA {
                    padding-inline: 121px;
                }

                .content-header {
                    margin-top: 32px;

                    @include XGA {
                        margin-top: 56px;
                    }

                }

                .hoodie {
                    width: 64px;
                    height: 64px;
                    margin: 16px auto;
                }

                .desc {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: #000000;
                    text-align: center;
                    margin-bottom: 0;
                    margin-top: 16px;
                }
            }
        }

        .rewards {
            @include XGA {
                margin-top: 24px;
                display: grid;
                grid-template-columns: 400px 400px;
                justify-content: center;
                column-gap: 32px;
                row-gap: 1em;
            }

            .winner-card {
                border: 2px solid #E5E5E5;
                border-radius: 12px;
                max-width: 416px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 16px 24px;
                margin: 0 auto;
                margin-top: 8px;

                @include XGA {
                    padding: 16px 32px;
                    margin-top: 0;
                    width: 402px;
                }

                p {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 700;
                    margin: 0;
                }

                .text {
                    background-image: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }

                .name {
                    margin-top: 4px;
                }
            }
        }

        .show-hide-btn {
            width: auto;
            display: flex;
            color: $lightorange;
            border: 3px solid $lightorange;
            border-radius: 24px;
            padding: 12px 24px;
            font-weight: 900;
            margin: 32px auto;

            .show-hide-arrow {
                width: 16px;
                margin-left: 8px;
            }
        }
    }

    &.hedonisci-winners {
        .content-wrapper>img {
            border-radius: 12px 12px 0 0;
        }

        .element {
            img {
                object-fit: contain;
                width: 100%;
            }

            .text {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin: 0 !important;
                padding: 10px 5px 8px 24px;

                @include XGA {
                    padding-bottom: 10px;
                    padding-right: 10px;
                }

                p.points {
                    padding: 3px 8px 1px;
                    margin: 0;
                    background-color: #E5451F;
                    border-radius: 24px;
                    width: fit-content;
                    color: white;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }

        .rewards .winner-texts {
            padding: 24px;
            display: block;

            @include XGA {
                padding: 32px 40px;
            }

            p.text {
                margin: 0;
                background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;

                @include XGA {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            p.name {
                font-size: 12px;
                line-height: 16px;
                font-weight: 700;
                color: #000000;
                margin: 8px 0 0;

                @include XGA {
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }
    }

    &.glo-second-birthday {
        .rewards {
            .element {
                .text {
                    @include XGA {
                        padding-top: 8px;
                        justify-content: center;
                    }
                }
            }

            .winner-texts {
                padding: 16px 0;
                display: grid;
                grid-template-columns: 1fr;
                gap: 16px;

                .card {
                    padding-inline: 24px;

                    p {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        color: #868686;
                    }
                    
                    p.text {
                        background: transparent;
                        background-clip: initial;
                        text-fill-color: initial;
                        -webkit-text-fill-color: initial;
                        -webkit-background-clip: initial;
                    }

                    p.name {
                        margin-top: 4px;
                        color: #E5451F;
                    }
                }

                .card:not(:last-child) {
                    border-bottom: 1px solid #F7F7F7;
                    padding-bottom: 16px;
                }
            }
        }
    }
}