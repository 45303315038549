@import "../../../styles/vars";

.ReactModalPortal {
    opacity: 1;
}

.error-overlay {
    z-index: 99000;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 40px;
	transition: opacity 1000ms $CubicEaseInOut;
    background: transparent;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.92;
    }

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.error-modal-z-index {
    z-index: 100000 !important;
}

.error-modal {
    position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: transform 300ms $CubicEaseInOut, opacity 300ms $CubicEaseInOut;
    outline: none;
    border: none;
    width: 90%;
    max-width: 760px;
    height: auto;
    max-height: 90vh;
    text-align: center;
    padding: 20px;
    overflow: auto;
    background-color: #fff;
    border-radius: 12px;

    &_after-open {
    	opacity: 1;
        transform: translate(-50%, -50%);
    }
    &_before-close {
    	opacity: 0;
    }

    @include XGA {
        padding: 40px;
    }

    .button-wrapper {
        display: flex;
        justify-content: right;
    
        .close-button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            padding: 0;
    
            img {
                padding: 5px;
            }
        }
    }

    .content {
        h2 {
            margin-top: 0;
        }

        img {
            display: block;
            margin: 24px auto;
        }

        .error-description {
            margin: 24px 0;

            p {
                margin: 12px 0;
            }
        }

        p {
            a {
                color: $darkorange;
            }
        }

        .error-code {
            color: $darkorange;

            h4 {
                margin-bottom: 0;
            }

            p {
                margin-top: 8px;
                font-weight: 800;
            }
        }
    }
}