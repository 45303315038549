@import "../../../styles/_vars.scss";

.code {
    display: block;
    margin: 0 auto;
    padding: 11px 28px 9px 28px;
    border-radius: 24px;
    font-weight: 600;
    font-size: unset;
    // width: fit-content;

    @include XGA {
        font-size: 13px;
        padding: 11px 16px 9px 16px;
    }

    @include HD {
        font-size: unset;
    }

    strong {
        font-weight: 800;
    }
}