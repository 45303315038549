@import "../../../styles/vars";

.join-page {
    position: relative;
    height: var(--height);
    color: white;
    display: grid;
    grid-template-rows: 1fr auto;
    transition: height 0.2s linear;
    min-height: 720px;

    @include XGA {
        min-height: 758px;
    }

    .versionId {
        margin: 0;
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 10px;
        color: #fff;
        opacity: 0.2;
    }

    .join-background {
        background-image: url(../LoginPage/img/bg-mobile.webp);
        background-position: left center;
        background-repeat: no-repeat;
        min-width: 0;
        display: grid;
        place-items: center;
        // background-size: 100% 100vh;
        background-size: cover;
        padding-bottom: 48px;

        @include XGA {
            background-image: url(../LoginPage/img/bg-dekstop.webp);
            background-position: center;
            background-size: cover;
        }
    }

    .join-page-inner {
        align-self: center;
        text-align: center;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        min-width: 0;
        transition: backdrop-filter 500ms ease-in-out;

        &.blur {
            backdrop-filter: blur(30px);
        }

        .logo {
            padding-top: calc(max(6vh, 40px));

            @include XGA {
                padding-top: 6vh;
            }

            img {
                width: calc(min(30vw, 140px));
            }
        }

        h2 {
            font-weight: 600;
        }

        form {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .text {
            margin-bottom: 24px;

            .welcome {
                margin-bottom: 16px;
                margin-top: 5vh;

                @include XGA {
                    margin-top: 5vh;
                }

                @include UXGA {
                    margin-top: 8vh;
                }

                h3 {
                    margin: 0;
                    text-transform: uppercase;
                    font-weight: 900;
                    font-size: 40px;
                    line-height: 40px;
                    max-width: 245px;
                    width: 100%;
                    margin-inline: auto;

                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            h4 {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                margin: 0 auto;
                max-width: 239px;
            }

            .logos {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 24px;
                margin-top: 4px;

                img {
                    display: block;
                }
            }

            .wrapper {
                padding: 12px 0;
                background-color: lightblue;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 12px;
                max-width: 400px;
                margin: 0 auto;

                .flex {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    margin-top: 2px;

                    h4 {
                        margin: 0;
                    }

                    img {
                        width: 67px;
                    }
                }
            }
        }

        .user-data {
            display: block;
            margin: 0 auto;
            max-width: 400px;
            width: 100%;

            a {
                color: $orange;
                text-decoration: none;
            }

            .input {
                position: relative;

                .valid-icon {
                    position: absolute;
                    top: 20px;
                    right: 0;
                }

                .view-icon {
                    position: absolute;
                    right: 0;
                    top: 20px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    background-image: url(../ResetPage/img/preview.svg);
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    &.disable {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 24px;
                            height: 1px;
                            background-color: #fff;
                            transform: translate(-50%, -50%) rotate(30deg);
                        }
                    }
                }

                &.phone {
                    h4 {
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 12px;
                    }
                }
            }

            .checkbox {
                position: relative;
                display: flex;
                flex-direction: row-reverse;
                gap: 17px;
                margin-bottom: 7px;
                margin-bottom: 12px;
    
                input {
                    width: 40px;
                }
    
                label {
                    text-align: left;
                    width: 100%;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14px;
                    padding-left: 54px;
                    z-index: 10;
                    min-height: 24px;
                }
            }

            .switch {
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                width: 40px;
                height: 24px;
        
        
                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
        
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(237, 128, 2, 0.1);
                    transition: .4s;
                    border-radius: 34px;
                    border: 1px solid white;
                    border-radius: 12px;
        
        
                    &:before {
                        position: absolute;
                        content: "";
                        height: 18px;
                        width: 18px;
                        left: 3px;
                        bottom: 2px;
                        background-color: white;
                        transition: .2s;
                        border-radius: 50%;
                    }
                }
        
                input:checked+.slider {
                    background-color: $lightorange;
        
                    &:before {
                        background-color: #FDF2E6;
                    }
                }
        
                input:checked+.slider:before {
                    transform: translateX(16px);
                }
            }
        
            .consents {
                .shapes {
                    margin-bottom: 16px;
                    margin-top: 32px;
                }
        
                h3 {
                    margin-top: 16px;
                    margin-bottom: 8px;
                }
        
                p {
                    margin-top: 0;
                    margin-bottom: 16px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                }
        
                .consent-required {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 14px;
                    color: #FFFFFF;
                    text-align: left;
                    margin: 16px 0;
                }
        
                .info {
                    width: 100%;
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: 400;
                    margin-top: 16px;
                    text-align: left;
        
                    &:last-child {
                        margin-bottom: 24px;
                    }
        
                    a {
                        text-decoration: none;
                        color: $lightorange;
                    }
                }
        
                .link {
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 14px;
                    text-decoration-line: underline;
                    color: #ED8002;
                    text-align: left;
                }
        
                .checkbox {
                    position: relative;
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 17px;
                    margin-bottom: 7px;
                    margin-bottom: 12px;
        
                    input {
                        width: 40px;
                    }
        
                    label {
                        text-align: left;
                        width: 100%;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 14px;
                        padding-left: 54px;
                        z-index: 10;
                        min-height: 24px;
                    }
                }
            }

            input {
                display: block;
                width: 100%;
                margin-bottom: 12px;
                background: transparent;
                border: none;
                border-bottom: 1px solid white;
                padding: 4px 0px;
                color: white !important;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                outline: none;
                transition: all 0.2s $CubicEaseInOut;

                &:focus {
                    border-color: $lightorange;
                }

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    border-color: #fff;
                    background-color: transparent;
                    -webkit-text-fill-color: #fff;
                    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
                    transition: background-color 5000s ease-in-out 0s;
                }
            }

            input[type="password"] {
                line-height: 16px;
            }

            label {
                float: left;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: white !important;
            }
        }

        .buttons {
            @include XGA {
                padding-bottom: calc(max(3vh, 40px));
            }

            .button {
                width: 200px;
            }
        }

        .disclaimer {
            margin: 0 auto;
            display: block;
            width: 90%;
            max-width: 600px;
            font-size: 10px;
            margin-top: 24px;
        }

        .error {
            position: relative;
            top: -6px;
            font-size: 12px;
            color: #EA1D1D;
            text-align: left;

            &.long {
                font-size: 10px;

                @include vHD {
                    font-size: 12px;
                }
            }

            &:before {
                content: "!";
                position: relative;
                top: -2px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background-color: #EA1D1D;
                color: #fff;
                border-radius: 50%;
                margin-right: 6px;
                font-size: 9px;
                line-height: 15px;
                font-weight: 700;
                text-align: center;
            }
        }
    }

    footer {
        align-self: flex-end;
        margin-top: 0;

        .footer-bottom {
            background-color: white;
            padding-bottom: 24px;
        }
    }
}