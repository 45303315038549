@import "../../../styles/_vars.scss";

.wrapper-x3points {
    padding: 0;
    border-radius: 12px;

    .content {
        display: block;
        height: 100%;
    }

    &.ReactModal__Content {
        max-width: calc(min(350px, 90vw));
        min-width: 289px;
        max-height: 90%;
        width: 100%;

        @include vHD {
            max-width: 640px;
        }
    }
}

.x3points-modal {
    .top-bar {
        button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            appearance: none;
            padding: 5px;
            margin-top: 16px;
            margin-right: 12px;
            margin-left: auto;
            display: block;
            height: 24px;

            @include vHD {
                margin-top: 20px;
                margin-right: 24px;
            }

            svg {
                path {
                    fill: black;
                }
            }
        }
    }

    .content {
        padding: 0 24px 24px;

        @include vHD {
            padding: 0 64px 40px;
        }

        h2 {
            font-weight: 900;
            font-size: 48px;
            line-height: 52px;
            letter-spacing: -0.02em;
            color: #2A2C2C;
            text-align: left;
            margin-top: 24px;
            margin-bottom: 24px;

            @include vHD {
                margin-top: 12px;
                max-width: 485px;
                margin-bottom: 14px;
            }
        }

        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #2A2C2C;
            text-align: left;
            margin: 7px 0 0;

            @include vHD {
                max-width: 475px;
            }

            &:last-of-type {
                margin: 0;
            }

            strong {
                font-weight: 900;
            }

            span {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
                padding: 4px 8px 4px;
                background-color: #E5451F;
                border-radius: 12px;
            }
        }

        .line {
            margin: 24px 0 16px;
            border-bottom: 1px solid #2A2C2C;

            @include vHD {
                max-width: 280px;
            }
        }

        .button {
            width: 100%;
            margin-top: 28px;

            @include vHD {
                margin-top: 32px;
                width: fit-content;
                display: block;
                margin-left: 0;
            }
        }
    }

    .br-mobile {
        display: block;

        @include vHD {
            display: none;
        }
    }
}