@import "../../../styles/vars";

.top-menu {
    position: relative;
    &:after {
        // content: "";
        position: absolute;
        left: 0;
        top: -12px;
        height: 12px;
        width: 100vw;
        background-color: #241f47;;
        border-radius: 12px 12px 0 0;
    }
}

.discover-glo {
    width: calc(100% - 48px);
    // border-radius: 12px 12px 0px 0px;
    margin: 0 auto;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    p,
    h4,
    h3 {
        margin: 0;
        padding: 0;
    }

    h3 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;

        @include XGA {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }
    }

    p:not(.active) {
        font-size: 15px;
        line-height: 20px;

        &.sub {
            opacity: 0.6;
            font-weight: 400;
        }
    }

    h4 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #2A2C2C;
    }

    hr {
        width: 100%;
        margin-top: -8px;
        margin-bottom: 20px;
        border: none;
        border-bottom: 1px solid #F3F3F3;

        @include XGA {
            display: none;
        }
    }

    .led-controls {
        margin: 32px 0;

        h3.title {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            text-align: center;
        }

        .led-switch {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;
            column-gap: 16px;
            margin-top: 16px;

            .button.small {
                padding: 4px 8px;

                @include XGA {
                    padding: 12px 41px 12px 41px;

                    &.transparent {
                        color: $lightorange;
                        border-color: $lightorange;

                        &:hover {
                            border: $lightorange solid 1px;
                            color: $orange;
                            border-color: $orange;
                        }
                    }
                }
            }
        }

        .led-container {
            position: relative;
            z-index: 2;
            margin: 24px 0;
            padding: 56px 0;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 0;
                width: 100vw;
                height: 100%;
                transform: translateX(-50%);
                background-color: #F7F7F7;
                z-index: -1;
            }

            .led-list {
                width: fit-content;
                margin: 0 auto;

                &.swiper {
                    position: relative;
                    width: 100%;
                    overflow: initial;

                    .swiper-button-next, .swiper-button-prev {
                        position: absolute;
                        top: -8px;
                        left: 0;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        border: $lightorange solid 2px;
                        z-index: 10;

                        @include XGA {
                            top: calc( 50% - 16px );
                        }

                        &.swiper-button-disabled {
                            filter: grayscale(1);
                            opacity: 0.7;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            left: 57%;
                            top: 50%;
                            transform: translate(-50%, -50%) rotate(45deg);
                            width: 8px;
                            height: 8px;
                            border-left: $lightorange solid 3px;
                            border-bottom: $lightorange solid 3px;
                        }
                    }

                    .swiper-button-next {
                        left: auto;
                        right: 0;

                        &::before {
                            transform: translate(-50%, -50%) rotate(-135deg);
                            left: 43%;
                        }
                    }
                }
                
                li {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    column-gap: 24px;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .color {
                        display: inline-block;
                        width: 58px;
                        min-width: 58px;
                        height: 18px;
                        border-radius: 20px;
                    }
                }

                .swiper-slide {
                    width: 100%;
                    display: block;
                    text-align: center;

                    @include XGA {
                        padding: 0 10%;
                    }

                    .color {
                        margin: 0 auto;
                        margin-bottom: 32px;
                        display: block;
                        width: 58px;
                        min-width: 58px;
                        height: 18px;
                        border-radius: 20px;
                    }

                    strong {
                        display: block;
                    }
                }

                &.led-list-0 {
                    @include XGA {
                        display: flex;
                        flex-flow: row;
                        flex-wrap: nowrap;
                        justify-content: center;
                        column-gap: 15%;
                        padding: 80px 0;

                        li {
                            margin-bottom: 0;

                            span {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                &.led-list-1 {
                    @include XGA {
                        max-width: 600px;
                        display: flex;
                        flex-flow: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding: 59px 0;
                        padding-bottom: 35px;

                        li {
                            margin-bottom: 24px;
                            min-width: 40%;
                            max-width: 40%;
                            width: 40%;

                            span {
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }

    .top-section {
        margin-left: -24px;
        margin-right: -24px;
        margin-top: -25px;
        padding: 0 24px;
        position: relative;
        z-index: 10;
        background-size: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        @include XGA {
            // display: grid;
            // grid-template-columns: 40% 50%;
            // justify-content: space-between;
            margin-top: 32px;
            padding: 40px 64px;

            background-repeat: no-repeat;
            background-size: cover;
            min-height: 754px;

            border-radius: 12px;
        }

        .header {
            font-size: 15px;
            color: white;
            text-align: center;
            padding-top: 24px;
            margin-bottom: 20px;
        }

        .top-menu {
            a {
                color: white;
                opacity: 1;
            }
        }

        button:not(.button):not(.popup-close) {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            padding: 0;

            @include XGA {
                height: min-content;
                min-width: 264px;
            }
        }

        .selector {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @include XGA {
                display: flex;
                align-items: flex-end;
            }


            div {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                border-inline: none;
                border-bottom: 1px solid #ED8002;
                ;
                padding-bottom: 4px;
                padding-top: 13px;

                img {
                    height: 37px;

                    @include XGA {
                        height: 40px;
                    }
                }

                p {
                    color: #E5E5E5;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    margin: 0;
                    margin-left: 11px;
                    text-align: left;

                    @include XGA {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 20px;
                        margin-left: 12px;
                    }
                }
            }

            hr {
                display: inherit;
                margin: 0;
                border-bottom: 1px solid orange;
            }

            .selected {
                border-radius: 12px 12px 0 0;
                border: 1px solid #ED8002;
                border-bottom: transparent;

                p {
                    color: #ED8002;
                }
            }
        }

        .fixed-title {
            h2 {
                margin: 0;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;

                @include XGA {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        .hero-img {
            margin-top: 16px;

            @include XGA {
                margin-top: 0;
                grid-row: 1/3;
                grid-column: 2/3;
            }

            img {
                border-radius: 12px 12px 0 0;
                width: 100%;
                display: block;
                min-height: 176px;
                object-fit: cover;

                @include XGA {
                    border-radius: 12px;
                    min-height: 300px;
                }
            }
        }

        .info {
            margin-top: 200px;
            padding-bottom: 32px;

            @include XGA {
                margin-top: 275px;
                max-width: 546px;
                margin-left: auto;
                background: rgba(255, 255, 255, 0.1);
                padding: 32px 48px;
                border-radius: 12px;

                p {
                    br {
                        display: none;
                    }

                    strong {
                        margin-right: 4px;
                    }
                }
            }

            h3 {
                margin-bottom: 16px;
                color: white;

                @include XGA {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                }
            }

            div {
                margin-top: 11px;
                display: grid;
                grid-template-columns: 28px auto;

                p {
                    font-weight: 400;
                    color: #E5E5E5;

                    strong {
                        font-weight: 700;
                        color: white;
                    }
                }
            }

            .additional-info {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #F7F7F7;
                margin-top: 12px;
            }
        }
    }

    .video-section {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 24px;

        @include XGA {
            margin-top: 64px;
            flex-direction: row;
            column-gap: 24px;
        }

        video {
            margin: 0 auto;

            @include XGA {
                margin: 0;
                width: 100%;
            }
        }

        .video-container {
            margin: 0 auto;

            @include XGA {
                margin: 0;
                width: auto;
            }
        }

        .disclamer {
            color: #000000;
            margin-bottom: 24px;
            font-size: 10px;
            @include XGA {
                margin-bottom: 0;
                font-size: 10px;
                padding-top: 2px;
            }
        }

        .play-list-container {
            @include XGA {
                min-width: 414px;
                display: flex;
                flex-direction: column;
                /* align-items: flex-start; */
                width: 100%;
                justify-content: center;
                max-width: 414px;
                margin: 0 auto;
            }
        }

        .pretitle {
            color: #868686;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .title {
            font-size: 24px;
            line-height: 32px;
            color: black;
            margin: 12px 0;
        }

        ul {
            div {
                display: flex;
                height: 22px;
                align-items: center;

                p {
                    padding-left: 16px;
                    line-height: normal;
                }
                svg {
                    opacity: 0;
                }
            }
            li {
                font-size: 12px;
                font-weight: 700;
                padding: 10px 0;
                border-top: 1px solid grey;
                display: flex;
                align-items: center;
                cursor: pointer;
                justify-content: space-between;
                &:hover {
                    color: $yellow;
                    svg {
                        opacity: 1;
                    }
                }
                span {
                    font-weight: 400;
                    color: $darkgray;
                    line-height: normal;
                    @include XGA {
                        padding-right: 0;
                    }
                }

            }
            .selected {
                color: $lightorange;
                svg {
                    opacity: 1;
                }
            }
            li:first-child {
                border-top: none;
            }
        }
    }

    .number {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .how-to-use {
        margin-top: 40px;

        @include XGA {
            display: grid;
            grid-template-columns: 56% calc(min(41%, 800px));
            justify-content: space-between;
            margin-top: 64px;
        }

        h3 {
            @include XGA {
                grid-column: 1/3;
            }
        }

        .inner {
            margin-top: 16px;
            display: grid;
            grid-template-columns: 40px auto;
            row-gap: 16px;

            @include XGA {
                margin-top: 24px;
            }

            .content {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    height: 100%;
                    width: 2px;
                    background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                    transform: rotate(180deg);
                    left: -29px;
                    top: 16px;
                }

                &:last-of-type {
                    &::before {
                        display: none;
                    }
                }
            }

            .sub {
                margin-top: 4px;
            }
        }

        .clue {
            margin-top: 40px;

            @include XGA {
                margin-top: 10px;
            }

            h4 {
                padding-top: 24px;
                padding-left: 32px;
                border: 1px solid #ED8002;
                border-right: none;
                border-bottom: none;
                border-top-left-radius: 12px;

                @include UXGA {
                    padding-top: 40px;
                    padding-left: 40px;
                }
            }

            .inner {
                row-gap: 12px;

                @include XGA {
                    padding-left: 10px;
                }

                @include UXGA {
                    padding-left: 40px;
                }

                .sub {
                    margin-top: 0;
                }
            }
        }
    }

    .maintenance-grid {
        margin-top: 40px;

        @include XGA {
            margin-top: 64px;
        }

        &.uniq>.inner {
            @include XGA {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        .inner {
            margin-top: 16px;

            @include XGA {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 32px;
                margin-top: 24px;
            }

            &>div {
                background: #FFFFFF;
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                margin-bottom: 24px;
                padding-bottom: 24px;

                @include XGA {
                    margin-bottom: 0;
                }

                .img {
                    display: grid;
                    margin-bottom: 32px;

                    &>* {
                        grid-row: 1/2;
                        grid-column: 1/2;
                    }

                    h3 {
                        color: white;
                        align-self: flex-end;
                        margin-left: 25px;
                        margin-bottom: 15px;
                    }

                    img {
                        width: 100%;
                        border-radius: 12px 12px 0 0;
                    }
                }

                &>.content {
                    padding: 0px 24px 16px;
                    display: grid;
                    grid-template-columns: 36px auto;
                    row-gap: 16px;
                    display: none;

                    &.show {
                        display: grid;
                    }

                    @include XGA {
                        display: grid;
                        padding: 0px 16px 0px;
                    }

                    @include HD {
                        padding-inline: 32px;
                    }

                    @include FHD {
                        padding-top: 10px;
                        padding-inline: 48px;
                    }

                    .content {
                        .sub {
                            margin-top: 4px;
                        }
                    }
                }
            }

            .cleaning {
                .content {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        z-index: 0;
                        height: 100%;
                        width: 2px;
                        background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                        transform: rotate(180deg);
                        left: -25px;
                        top: 20px;
                    }

                    &:last-of-type {
                        &::before {
                            display: none;
                        }
                    }
                }
            }

            .panel {
                .content {
                    display: none;

                    &.show {
                        display: block;
                    }

                    @include XGA {
                        display: block;
                    }

                    img {
                        width: 100%;
                        border-radius: 12px;
                        margin-top: 12px;

                        @include XGA {
                            margin-top: 24px;
                        }
                    }
                }
            }
        }

        .more {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            padding: 0;
            color: #ED8002;
            opacity: 0.8;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-decoration: underline;
            margin-right: 24px;
            margin-left: auto;
            display: block;

            @include XGA {
                display: none;
            }

            img {
                margin-left: 4px;
            }

            .rotated {
                transform: rotate(180deg);
            }
        }
    }

    .download {
        margin-top: 32px;

        @include XGA {
            margin-top: 64px;
            padding-top: 40px;
            padding-left: 47px;
            border: 1px solid #ED8002;
            border-right: none;
            border-bottom: none;
            border-top-left-radius: 12px;
            display: flex;
        }

        h4 {
            padding-top: 24px;
            padding-left: 32px;
            border: 1px solid #ED8002;
            border-right: none;
            border-bottom: none;
            border-top-left-radius: 12px;

            @include XGA {
                padding: 0;
                border: none;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
            }
        }

        .inner {
            margin-top: 16px;
            padding-left: 32px;

            @include XGA {
                margin: 0;
                margin-left: 83px;
                display: flex;
                align-items: center;
                align-self: flex-end;
            }

            a {
                font-weight: 900;
                font-size: 12px;
                line-height: 12px;
                color: #ED8002;

                @include XGA {
                    height: min-content;
                    margin-left: 24px;
                }
            }
        }
    }
}