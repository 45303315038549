@import "../../../styles/vars";

.double-points-bar {
    position: relative;
    height: 60px;
    background-size: cover;
    background-position: center left;
    background-image: url(./img/bar-bg-mobile.png);
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    @include XGA {
        height: 60px;
        background-image: url(./img/bar-bg-desktop.png);
    }

    @keyframes glow {
        from {
            text-shadow: 0 0 5px #ccc, 0 0 10px $yellow, 0 0 20px $orange, 0 0 30px $orange, 0 0 40px $orange, 0 0 50px $orange, 0 0 60px $orange;
        }

        to {
            text-shadow: 0 0 10px $yellow, 0 0 20px $darkorange, 0 0 30px $darkorange, 0 0 40px $darkorange, 0 0 50px $darkorange, 0 0 60px $darkorange, 0 0 70px $darkorange;
        }
    }

    @keyframes wave {
        to {
            background-position: 200% center;
        }
    }

    p {
        margin: 0;
        color: #fff;
        font-size: 18px;
        line-height: 17px;
        margin-left: 25%;
        // background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(42, 44, 44, 1) 40%, rgba(42, 44, 44, 1) 60%, rgba(255, 255, 255, 1) 80%);
        background-image: linear-gradient(90deg, rgba(255,255,255,1) 35%, rgba(42,44,44,1) 50%, rgba(255,255,255,1) 65%);
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        color: transparent;
        animation: wave 2s infinite linear forwards reverse;

        @include XGA {
            font-size: 26px;
            line-height: 26px;
            margin-left: 23%;
        }

        @include FHD {
            margin-left: 25%;
        }

        strong {
            font-weight: 900;
        }

        br {
            @include XGA {
                display: none;
            }
        }
    }

    .close-bar {
        position: absolute;
        right: 24px;
        color: #fff;
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;

        @include XGA {
            right: 64px;
        }

        span {
            font-size: 8px;
            font-weight: 900;
            margin-right: 8px;
        }
    }
}