@import "../../../styles/vars";

.about-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    margin-top: 12px;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 8px;

        @include XGA {
            margin-top: 40px;
            font-size: 16px;
            line-height: 40px;
            font-weight: 700;
            white-space: nowrap;
        }

        @include UXGA {
            font-size: 18px;
        }

        @include FHD {
            font-size: 24px;
        }
    }

    .title {
        width: max-content;
        margin-left: auto;
        margin-right: auto;

        @include XGA {
            font-size: 24px;
        }

        @include HD {
            font-size: 28px;
        }

        @include FHD {
            font-size: 32px;
        }
    }

    .subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        opacity: 0.4;
        margin: 0;

        @include XGA {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            opacity: 1;
            text-align: center;
            margin-bottom: 24px;
        }
    }

    .content-wrapper {
        position: relative;
        z-index: 2;

        @include HD {
            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
            gap: 32px;
        }
    }

    .image {
        aspect-ratio: 109/40;
        object-fit: cover;
        object-position: 50% 25%;
        width: 100%;
        z-index: -1;
        border-radius: 12px;

        @include HD {
            aspect-ratio: 3/4;
            object-position: 50% 0%;
        }
    }

    .element:last-child {
        .image {
            object-position: 50% 0%;
        }
    }

    .element {
        display: grid;
        margin-bottom: 16px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 88.03%);
        border-radius: 12px;
        text-decoration: none;
        height: min-content;

        @include HD {
            // box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.12);
            background: linear-gradient(180deg, #0000009e 0%, rgba(196, 196, 196, 0) 50%);
        }

        &>* {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
            max-width: 100%;
        }

        .content {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            height: fit-content;
            padding: 24px 16px 0 16px;
            
            @include HD {
                padding: 24px 16px 0 24px;
            }

            @include FHD {
                padding: 40px 0 0 32px;
            }

            .text {
                margin: 0;
                color: white;
                text-decoration: none;
            }

            img {
                align-self: center;
            }

            h2 {
                white-space: initial;
                line-height: 111%;

                img {
                    margin-left: 16px;
                }
            }
        }

        .disclaimer {
            // position: absolute;
            // bottom: 12px;
            // left: 16px;
            color: #fff;
            text-decoration: none;
            font-size: 12px;
            line-height: 16px;
            align-self: flex-end;
            margin-left: 16px;

            @include HD {
                font-weight: 700;
            }

            // @include HD {
            //     left: 24px;
            //     bottom: 24px;
            // }

            // @include FHD {
            //     bottom: 32px;
            //     left: 40px;
            // }
        }

        &:first-of-type {
            margin-top: 16px;

            @include XGA {
                margin-top: 0;
            }
        }

        &:last-of-type {
            margin-bottom: 24px;

            @include XGA {
                margin-bottom: 0;
            }
        }
    }

    .header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @include XGA {
            display: flex;
            gap: 32px;
            align-items: center;
        }

        .title {
            text-align: center;

            @include XGA {
                margin: 0;
                font-weight: 600;
            }
        }

        button:not(.button):not(.close) {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: left;
            height: fit-content;
            width: 50%;
            padding: 0;

            @include XGA {
                width: fit-content;
            }
        }
    }

    .questions-wrapper {
        margin-top: 24px;

        @include XGA {
            margin-top: 32px;
        }

        .question {
            padding: 16px 24px 16px 24px;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            margin-bottom: 16px;
            background-color: #fff;

            @include XGA {
                padding: 40px 32px 40px 40px;
                margin-bottom: 24px;
            }

            &:last-of-type {
                margin-bottom: 24px;
            }

            .main {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                font-size: 15px;
                line-height: 20px;
                // opacity: 0.8;
                font-weight: 500;
                cursor: pointer;

                &.active {
                    color: $lightorange;
                    font-weight: 700;
                }

                @include XGA {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                }

                button {
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    width: 24px;
                    height: 24px;
                    background: none;
                    padding: 0;

                    img {
                        width: 8px;
                        height: 4px;

                        @include XGA {
                            width: 16px;
                            height: 9px;
                        }
                    }
                }

                .more {
                    width: 24px;
                    min-width: 24px;
                    max-width: 24px;
                    height: 24px;
                    max-height: 24px;
                    min-height: 24px;
                    border-radius: 50%;
                    background-color: $orange;
                    display: flex;
                    flex: 0 0 auto;
                    border: 1px solid transparent;
                    justify-content: center;
                    align-items: center;

                    @include XGA {
                        background: none;
                    }
                }

                .less {
                    border: 1px solid $orange;
                    background-color: white;

                    @include XGA {
                        background: none;
                        border: none;
                    }
                }
            }

            .subquestions {

                hr {
                    display: block;
                    height: 1px;
                    border: 0;
                    border-top: 1px solid #F3F3F3;
                    margin-top: 16px;
                    margin-bottom: 22px;

                    @include XGA {
                        margin-top: 32px;
                        margin-bottom: 32px;
                    }
                }

                .subquestion {

                    // &-wrapper {
                    // }

                    &-content {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 600;
                        display: flex;
                        gap: 16px;
                        align-items: baseline;
                        justify-content: space-between;
                        color: #2A2C2C;
                        cursor: pointer;

                        &.active {
                            color: $lightorange;
                        }

                        @include XGA {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    &-button {
                        background: none;
                        cursor: pointer;
                        outline: none;
                        border: none;
                        padding: 0;
                        font-size: 15px;
                        line-height: 20px;
                        color: $orange;
                        margin-right: 8px;
                    }

                    &-answer {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 20px;
                        margin-top: 4px;
                        margin-bottom: 0;
                        padding-bottom: 2px;
                        color: #2A2C2C;

                        @include XGA {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 600;
                            margin-top: 19px;
                            max-width: 70%;
                        }
                    }
                }
            }
        }
    }

    &.contact {
        @include XGA {
            display: grid;
            grid-template-columns: 1fr 2fr;
            column-gap: calc(10% + 50px);
        }

        .header {
            @include XGA {
                grid-column: 1/3;
                margin-bottom: 32px;
            }
        }

        .boxes {
            grid-column: 2/3;
            grid-row: 2/3;
        }

        p {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            margin: 0;
        }

        .top {
            margin-top: 16px;

            @include XGA {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .sub {
            font-weight: 600;
            line-height: 20px;
            opacity: 0.8;
            margin-top: 4px;
            font-weight: 500;

            @include XGA {
                opacity: 1;
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
            }
        }

        form {

            @include XGA {
                grid-column: 1/3;
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-column-gap: 10%;
                background: rgba(243, 243, 243, 0.65);
                padding: 64px 10% 24px;
                margin-bottom: 32px;
            }

            .user-info {
                margin-top: 16px;
                margin-bottom: 24px;

                @include XGA {
                    margin: 0;
                }
            }

            .label {
                margin-bottom: 3px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
            }

            input[type=text] {
                width: 100%;
                border: none;
                border-bottom: 1px solid black;
                margin-bottom: 16px;
                font-size: 14px;

                @include XGA {
                    background: rgba(243, 243, 243, 0.65);
                }

                &::placeholder {
                    font-size: 12px;
                    line-height: 16px;
                    color: rgba(0, 0, 0, 0.4);
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            textarea {
                width: 100%;
                border-radius: 12px;
                padding: 12px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                height: 100px;
                resize: none;
                font-size: 14px;

                @include XGA {
                    height: 100%;
                }

                &::placeholder {
                    color: rgba(0, 0, 0, 0.4);
                }
            }

            .checkbox {
                margin-top: 16px;
                display: flex;
                align-items: center;
                gap: 24px;
                justify-content: center;

                @include XGA {
                    margin: 0;
                    gap: 12px;
                    justify-content: left;
                    padding-left: 20px;
                    grid-column: 1/2;
                }

                input[type=checkbox] {
                    width: 20px;
                    height: 20px;
                }

                .label-checkbox {
                    font-size: 10px;
                    opacity: 0.8;
                    max-width: 75%;
                    line-height: 14px;
                    font-weight: 500;

                    @include XGA {
                        max-width: calc(100% - 32px);
                        font-size: 10px;
                        line-height: 15px;
                    }
                }
            }

            .info-wrapper {
                @include XGA {
                    grid-row: 2/3;
                    grid-column: 1/3;

                    .info {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }

            .info {
                font-size: 9px;
                line-height: 11px;
                margin-top: 10px;
                opacity: 0.8;

                a {
                    color: $lightorange;
                    font-weight: 600;
                    font-size: 10px;
                }

                @include XGA {
                    font-size: 10px;
                    line-height: 15px;

                    a {
                        font-size: 11px;
                    }
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: center;

                @include XGA {
                    grid-column: 2/3;
                    align-items: flex-start;
                    height: 100%;
                }

                .button {
                    margin-bottom: 24px;
                    margin-top: 24px;

                    @include XGA {
                        margin-bottom: 0;
                        margin-top: 0;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }

            .default {
                color: rgba(0, 0, 0, 0.4);
            }

            .desktop-wrapper {
                @include XGA {
                    grid-column: 2/3;
                    display: grid;
                    grid-template-columns: auto 175px;
                    column-gap: 40px;
                    align-items: center;
                    margin-left: auto;
                    margin-top: 24px;
                }
            }
        }

        .boxes {
            padding-top: 33px;
            padding-bottom: 33px;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            position: relative;

            @include XGA {
                flex-direction: row;
                gap: 10%;
            }

            .box {
                display: flex;
                gap: 16px;
            }

            .sub {
                @include XGA {
                    font-size: 12px;
                    line-height: 20px;
                    margin-top: 4px;
                }
            }

            &::after {
                content: '';
                width: 150%;
                height: 100%;
                position: absolute;
                background-color: rgba(243, 243, 243, 0.65);
                z-index: -1;
                left: -25%;
                top: 0px;

                @include XGA {
                    display: none;
                }
            }
        }
    }

    &.statute {

        .future,
        .current {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            @include XGA {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                column-gap: 32px;
                row-gap: 24px;
                margin-top: 32px;
            }

            & .tile {
                background: linear-gradient(90deg, $darkorange 0%, $yellow 100%);
                color: white;
                cursor: pointer;
            }
        }

        .current {
            margin-bottom: 24px;

            @include XGA {
                margin-top: 40px;
                margin-bottom: 32px;
            }

            & .tile {
                background: white;
                color: black;
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            }
        }

        .subheader {
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
            opacity: 0.8;
            margin: 0;

            @include XGA {
                grid-column: 1/4;
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;
                opacity: 1;
            }
        }

        .tile {
            padding: 24px 24px 24px 16px;
            border-radius: 12px;

            .title {
                font-size: 15px;
                line-height: 20px;
                margin: 0;
                opacity: 0.8;
                font-weight: 600;
            }

            .title-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 4px;
            }

            .date {
                font-size: 12px;
                line-height: 16px;
                opacity: 0.8;
                font-weight: 500;
                margin: 0;

                strong {
                    font-weight: 700;
                }
            }

        }
    }

    .desktop {
        display: none;

        @include XGA {
            display: block;
        }
    }

    &.modal-wrapper {
        display: grid;
        place-items: center;
        margin-top: 0;

        .button {
            margin-top: 20px;
            width: fit-content;
        }
    }

    &.complaints {
        width: 100%;
        padding-bottom: 0;

        .header {
            margin: 0 24px;

            @include XGA {
                margin: 0 64px;
            }
        }

        h4 {
            margin-bottom: 10px;
            margin-top: 24px;
        }

        .top-tips {
            font-weight: 900;
            color: $lightorange;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;

            @include XGA {
                display: none;
            }
        }

        .steps-mobile {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            margin: 24px;
            margin-bottom: 12px;

            p {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                margin: 0;
            }

            .dots {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                column-gap: 8px;
                margin: 0;

                li {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #E5E5E5;

                    &.active {
                        background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                        border-radius: 24px;
                        width: 24px;
                    }
                }
            }
        }

        .steps-desktop {
            margin: 16px 0;

            @include XGA {
                margin: 16px 64px;
            }

            .items {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                column-gap: 40px;

                li {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 10px;

                    p {
                        margin: 0;
                        font-size: 10px;
                        line-height: 16px;
                        letter-spacing: 0.12em;
                        color: #C6C6C5;
                    }

                    span {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        line-height: 20px;
                        text-align: center;
                        border: 1px solid #C6C6C5;
                        color: #C6C6C5;
                        font-size: 12px;
                        margin-right: 12px;
                    }

                    &.active {
                        span {
                            background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                            border: none;
                            color: #fff;
                        }

                        p {
                            color: #000;
                        }
                    }
                }
            }
        }

        .content-outer {
            @include XGA {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                margin: 0 64px;
                padding-bottom: 40px;
            }
        }

        .tips {
            width: 320px;
            min-width: 320px;
            max-width: 320px;
            margin-top: -40px;
            margin-left: -40px;

            @include HD {
                width: 420px;
                min-width: 420px;
                max-width: 420px;
            }

            .tips-top {
                position: relative;
                background-size: cover;
                background-position: center;
                height: 180px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;

                @include HD {
                    height: 220px;
                }

                h3 {
                    position: absolute;
                    left: 24px;
                    bottom: 24px;
                    color: #fff;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 700;
                    margin: 0;
                }
            }

            .tips-content {
                background: #FFFFFF;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                padding: 24px 0;

                .separator {
                    width: 100%;
                    height: 1px;
                    background-color: #E5E5E5;
                    margin: 16px 0;
                }

                .disclaimer {
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: 500;
                    color: #868686;
                    padding: 0 24px;
                    margin: 0;

                    a {
                        text-decoration: underline;
                        color: #ED8002;
                        font-weight: 700;
                    }
                }

                ul {
                    margin: 0;
                    padding: 0 24px;

                    li {
                        position: relative;
                        text-align: left;
                        padding-left: 36px;
                        margin-bottom: 12px;

                        span {
                            position: absolute;
                            left: 0;
                            top: 0;
                            display: block;
                            margin: 0 auto;
                            width: 24px;
                            height: 24px;
                            color: #fff;
                            font-weight: 700;
                            font-size: 15px;
                            line-height: 26px;
                            text-align: center;
                            background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                            border-radius: 50%;
                            margin-bottom: 8px;
                        }

                        h4 {
                            font-size: 15px;
                            line-height: 20px;
                            margin: 0;
                            margin-bottom: 4px;
                        }

                        p {
                            font-size: 12px;
                            color: #2A2C2C;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .content-wr {
            background-color: #F7F7F7;
            padding: 24px;
            padding-top: 1px;

            @include XGA {
                margin: 0;
                border-radius: 12px;
                width: 100%;
                padding-right: 64px;
            }
        }

        .buttons-center {
            margin-top: 32px;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            column-gap: 16px;

            @include XGA {
                justify-content: flex-start;
            }

            .button {
                font-size: 12px;

                @include XGA {
                    font-size: 14px;
                }

                img {
                    position: relative;
                    top: 2px;
                    margin-left: 8px;
                }
            }

            .back-button {
                position: relative;
                height: 24px;
                width: 24px;
                min-width: 24px;
                max-width: 24px;
                border: $lightorange solid 1px;
                border-radius: 50%;
                cursor: pointer;

                &:before {
                    content: "";
                    position: absolute;
                    left: 8px;
                    top: 7px;
                    width: 6px;
                    height: 6px;
                    border-bottom: $lightorange solid 2px;
                    border-left: $lightorange solid 2px;
                    transform: rotate(45deg);
                }
            }
        }

        form {
            max-width: 740px;

            .error {
                font-size: 10px;
                margin: 0;
                color: #f44;
                margin-top: 4px;
            }

            .checkboxes {
                margin-top: 32px;

                .checkbox {
                    position: relative;
                    margin-bottom: 12px;

                    input {
                        display: none;
                    }

                    label {
                        display: block;
                        font-size: 10px;
                        line-height: 14px;
                        font-weight: 500;
                        padding-left: 28px;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: -3px;
                            width: 16px;
                            height: 16px;
                            border: $lightorange solid 1px;
                        }
                    }

                    input:checked+label {
                        &:after {
                            content: "";
                            position: absolute;
                            left: 5px;
                            top: 2px;
                            width: 8px;
                            height: 8px;
                            background-color: $lightorange;
                        }
                    }
                }
            }

            .form-inner {
                width: 100%;

                @include XGA {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    &>div {
                        min-width: calc(50% - 16px);
                        max-width: calc(50% - 16px);
                        width: calc(50% - 16px);
                    }
                }
            }

            .flex-inputs {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;

                .input {
                    min-width: calc(50% - 8px);
                    max-width: calc(50% - 8px);
                    width: calc(50% - 8px);

                    @include HD {
                        min-width: calc(50% - 16px);
                        max-width: calc(50% - 16px);
                        width: calc(50% - 16px);
                    }
                }
            }

            .input {
                margin-bottom: 16px;
                max-width: 400px;
                position: relative;

                input {
                    width: 100%;
                    font-size: 16px;
                    border: none;
                    border-bottom: 1px solid #C6C6C5;
                    padding: 6px 12px;
                    background-color: transparent;

                    @include XGA {
                        max-width: 400px;
                    }

                    &:focus {
                        outline: none;
                        border-color: $lightorange;
                    }
                }

                .select-box {
                    width: 100%;
                    max-width: 400px;
                    overflow: hidden;
                    position: relative;
                    padding: 0;

                    &::after {
                        content: "";
                        position: absolute;
                        right: 16px;
                        top: 8px;
                        width: 6px;
                        height: 6px;
                        border-left: $darkorange solid 1px;
                        border-bottom: $darkorange solid 1px;
                        transform: rotate(-45deg);
                    }

                    &:active {
                        &::after {
                            transform: rotate(135deg);
                            top: 12px;
                        }
                    }

                    select {
                        width: calc( 100% + 20px );
                        font-size: 16px;
                        border: none;
                        border-bottom: 1px solid #C6C6C5;
                        padding: 6px 12px;
                        background-color: transparent;
    
                        &:focus {
                            outline: none;
                            border-color: $lightorange;
                        }
                    }
                }

                textarea {
                    border: 1px solid #868686;
                    border-radius: 12px;
                    padding: 8px 12px;
                    font-size: 16px;
                    width: 100%;
                    min-height: 80px;
                    margin-top: 8px;
                    background: transparent;

                    @include XGA {
                        max-width: 560px;
                    }
                }

                label {
                    font-size: 12px;
                    font-weight: 700;
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;

                    span {
                        display: inline-block;
                        font-size: 12px;
                        color: #868686;
                        font-weight: 500;
                    }
                }

                .disclaimer-button {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background-color: #ED8002;
                    color: white;
                    display: grid;
                    place-items: center;
                    border-radius: 50%;
                    right: 12px;
                    top: 23px;
                    z-index: 2;
                    appearance: none;
                    border: none;
                    padding: 0;
                    cursor: pointer;

                    p {
                        margin: 0;
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: 600;
                        padding-top: 2px;
                    }
                }

                .disclamier {
                    background: #FFFFFF;
                    border-radius: 4px;
                    position: absolute;
                    z-index: 3;
                    padding: 12px 16px 8px;
                    max-width: 327px;
                    top: 58px;
                    right: 0;
                    transition: opacity 500ms ease-in-out;
                    visibility: hidden;
                    opacity: 0;

                    @include XGA {
                        top: -8px;
                        right: -367px;
                    }

                    &.show {
                        visibility: visible;
                        opacity: 1;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-image: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                        left: -8px;
                        top: 10px;
                    }

                    p {
                        margin: 0;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        padding-right: 24px;

                        span {
                            font-weight: 900;
                            background-image: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                        }
                    }

                    button.exit {
                        position: absolute;
                        right: 8px;
                        top: 8px;
                        border: 0;
                        background-color: transparent;
                        appearance: none;
                        cursor: pointer;
                    }
                }

            }

            .custom-type {
                padding-left: 28px;

                textarea {
                    margin-top: 0;
                }
            }

            .input-file {
                margin-bottom: 16px;
                max-width: 400px;

                input {
                    border: none;
                    padding: 0;
                    background: none;
                    color: $lightorange;
                    margin-top: 8px;
                    display: none;
                }

                label {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    font-size: 12px;
                    font-weight: 700;

                    @include XGA {
                        justify-content: flex-start;
                        column-gap: 32px;
                    }

                    span {
                        display: inline-block;
                        font-size: 12px;
                        color: #868686;
                        font-weight: 500;
                        margin-top: auto;
                    }

                    &.select-file {
                        color: $lightorange;
                        font-weight: 800;
                        font-size: 14px;
                        margin-top: 8px;
                        cursor: pointer;

                        &[data-disabled=true] {
                            pointer-events: none;
                            color: #868686;
                        }
                    }
                }

                .more-info {
                    font-size: 10px;
                    line-height: 14px;
                }

                .added-files {
                    font-size: 12px;
                    margin: 0;
                    margin-top: 8px;
                    font-weight: 700;
                }

                .file-list {
                    margin-top: 8px;
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @include HD {
                        gap: 8px;
                    }

                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        p {
                            margin: 0;
                        }

                        .delete { 
                            border: none;
                            background-color: transparent;
                            align-self: flex-start;
                            height: fit-content;
                        }
                    }
                }

                .disclaimer {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14px;
                    color: #000000;
                }
            }

            .radio-input {
                position: relative;
                margin-bottom: 8px;
                padding-left: 28px;

                input {
                    display: none;
                }

                label {
                    color: #2A2C2C;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 500;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 1px solid #2A2C2C;
                    }
                }

                input:checked+label {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 5px;
                        top: 5px;
                        width: 8px;
                        height: 8px;
                        background-color: $lightorange;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    &.complaints-status {
        padding-bottom: 0;

        .status-bar {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin: 16px 0;
            max-width: 720px;

            @include XGA {
                margin-left: 40px;
            }

            li {
                position: relative;
                min-width: calc( 33.33% - 7px );
                max-width: calc( 33.33% - 7px );
                width: calc( 33.33% - 7px );

                span {
                    position: relative;
                    display: block;
                    width: 20px;
                    height: 20px;
                    color: #C6C6C5;
                    border-radius: 50%;
                    font-size: 12px;
                    line-height: 20px;
                    padding-top: 0px;
                    font-weight: 700;
                    text-align: center;
                    background: #fff;
                    border: #C6C6C5 solid 1px;
                    z-index: 2;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 8px;
                    right: 100%;
                    width: 100%;
                    height: 4px;
                    background-color: #F7F7F7;
                }

                &:first-child {
                    &::after {
                        display: none;
                    }
                }

                &.active {
                    span {
                        border: none;
                        color: #fff;
                        background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                    }

                    &::after {
                        background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
                    }
                }
            }
        }

        .complaints-content {
            position: relative;
            background-color: #F7F7F7;
            z-index: 2;
            padding: 24px 0;

            @include XGA {
                background-color: transparent;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                margin-bottom: 32px;
            }

            &::before {
                content: "";
                background-color: #F7F7F7;
                position: absolute;
                width: 100vw;
                height: 100%;
                top: 0;
                left: -24px;
                z-index: -1;

                @include XGA {
                    display: none;
                }
            }

            .complaints-info {
                @include XGA {
                    background-color: #F7F7F7;
                    border-radius: 12px;
                    padding: 32px 40px;
                    padding-right: 80px;
                    max-width: 1000px;
                }

                @include HD {
                    padding-right: 160px;
                }
            }

            h4, h2 {
                margin: 0;
            }

            h4 {
                color: #868686;
                font-size: 12px;
                font-weight: 700;
                line-height: 16px;

                @include XGA {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            h2 {
                color: #F5AA04;
                font-size: 32px;
                line-height: 40px;
                font-weight: 700;
                margin: 8px 0;

                @include XGA {
                    font-size: 48px;
                    line-height: 56px;
                    font-weight: 900;
                }
            }

            .info-list {
                padding-bottom: 4px;
                border-bottom: #E5E5E5 solid 1px;

                @include XGA {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    column-gap: 24px;
                    margin: 16px 0;
                }

                li {
                    p {
                        margin: 0;
                        color: #868686;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 700;
                        margin-bottom: 8px;

                        strong {
                            color: #2A2C2C;
                            font-size: 15px;
                            font-weight: 700;
                            line-height: 20px;
                        }
                    }
                }
            }

            .disclaimer {
                font-size: 10px;
                line-height: 14px;
                font-weight: 500;

                a {
                    color: $orange;
                }
            }

            .complaints-image {
                @include XGA {
                    position: relative;
                    margin-left: -40px;
                    margin-top: -20px;
                }

                @include HD {
                    min-width: 400px;
                    max-width: 400px;
                    width: 400px;
                    margin-left: -80px;
                    margin-top: -60px;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

.complaints-tips {
    .complaints-tips-inner {
        h3 {
            font-size: 18px;
            line-height: 24px;
        }

        .confirm-content {
            padding: 24px;

            .code {
                font-size: 200%;
                margin: 0 auto;
                padding: 0;
                line-height: 1;
            }
        }

        ul {
            margin: 18px 0;

            li {
                span {
                    display: block;
                    margin: 0 auto;
                    width: 24px;
                    height: 24px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 26px;
                    text-align: center;
                    background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                    border-radius: 50%;
                    margin-bottom: 8px;
                }

                h4 {
                    font-size: 15px;
                    line-height: 20px;
                    margin: 0;
                    margin-bottom: 4px;
                }

                p {
                    font-size: 12px;
                    color: #2A2C2C;
                    line-height: 16px;
                    margin: 0;
                }
            }
        }
    }
}