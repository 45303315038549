@import "../../../styles/_vars.scss";

.referral-award-modal {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 32px;

    .close-button {
        margin-right: 20px;
    }

    .referral-award-modal-inner {
        width: 100%;
    }

    .referral-award-modal-buttons {
        margin-top: 24px;

        @include vHD {
            margin-top: 24px;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;
            column-gap: 24px;
        }

        .button {
            margin-bottom: 16px;
        }
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 8px;
    }

    p {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin: 0 auto;
        width: 90%;
        max-width: 480px;
        margin-bottom: 24px;

        span {
            display: inline-block;
            background-color: #E5451F;
            color: #fff;
            border-radius: 24px;
            padding: 3px 8px 1px;
        }
    }

    .referral-award-modal-image {
        background-color: #F7F7F7;
        padding: 20px 0;

        img {
            display: block;
            margin: 0 auto;
            height: 70px;

            @include XGA {
                height: 100px;
            }
        }
    }
}