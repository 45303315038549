@import "../../../../styles/vars";
@import "../../../../styles/index";


.winners-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    white-space: pre-wrap;
    margin-bottom: 32px;
    white-space: pre-wrap;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 24px;
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;

        @include XGA {
            margin-top: 40px;
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
        }
    }

    .header {
        display: grid;
        grid-template-columns: 20px auto 20px;
        margin-top: 12px;

        @include XGA {
            display: flex;
            gap: 32px;
            align-items: center;
        }

        .title {
            text-align: center;

            @include XGA {
                margin: 0;
                font-weight: 600;
            }
        }

        button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: left;
            height: fit-content;
            width: 50%;
            padding: 0;

            @include XGA {
                width: fit-content;
            }
        }
    }

    button {
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
        text-align: left;
        // height: fit-content;
        width: 50%;
        padding: 0;
        line-height: none;
        display: flex;
        justify-content: left;
        align-items: center;

        @include XGA {
            width: fit-content;
            display: block;
        }
    }

    .content-wrapper {
        @include XGA {
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 12px;
            padding: 80px 70px;
            margin-top: 40px;
            display: grid;
            place-items: center;
            position: relative;
            z-index: 2;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-height: 720px;
                background-image: var(--bg-image);
                background-size: cover;
                border-radius: 12px 12px 0 0;
                z-index: -10;
            }
        }

        @include HD {
            padding: 80px 105px;
        }
    }

    .content {
        @include XGA {
            background-color: white;
            border-radius: 12px;
            padding: 64px 75px 40px;
            max-width: 1088px;
            width: 100%;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        }

        @include HD {
            padding: 64px 112px 40px;
        }
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        // margin-bottom: 8px;

        @include XGA {
            margin-top: 40px;
            font-size: 24px;
            line-height: 40px;
            font-weight: 700;
        }

        @include HD {
            font-size: 32px;
        }
    }

    h3 {
        margin: 0;
        margin-top: 24px;
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
    }

    .link {
        color: $lightorange;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
    }

    .content-header {
        @include XGA {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
        }
    }

    .sub {
        @include XGA {
            margin-top: 40px;
        }
    }

    .rewards {
        margin-top: 24px;

        @include XGA {
            margin-top: 40px;
        }

        .element {
            display: flex;
            justify-content: space-between;
            background-color: white;
            margin-top: 16px;
            border: 1px solid #F7F7F7;
            border-radius: 12px;

            &.border-modifier {
                border-radius: 12px 12px 0 0;

                img {
                    border-radius: 12px 0 0 0;
                }
            }

            @include XGA {
                justify-content: flex-start;
            }

            .reward-img {
                aspect-ratio: 1/1;
                width: 89px;
                height: 100%;
                border-radius: 12px 0 0 12px;
                display: block;

                @include XGA {
                    width: 78px;
                }
            }

            .text {
                margin-left: 24px;
                margin-right: 10px;
                align-self: center;
                width: 100%;

                .place {
                    background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                }

                .rewared-content {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 2px;
                    margin-bottom: 0;

                    @include XGA {
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                        margin-top: 4px;
                    }
                }
            }

            button {
                margin-right: 16px;
                margin-top: 12px;
                font-size: 24px;
                line-height: 24px;
                color: $lightorange;
                background: none;
                cursor: pointer;
                outline: none;
                border: none;
                text-align: left;
                height: fit-content;
                padding: 0;
                display: flex;
                justify-content: left;
                align-items: center;
                min-width: 12px;
                width: fit-content;

                @include XGA {
                    font-size: 32px;
                    align-self: center;
                    margin-top: 0;
                    margin-right: 26px;
                    margin-left: auto;
                }
            }
        }

        // .winner-images {
        //     background-color: white;
        //     padding-left: 16px;
        //     padding-right: 16px;
        //     border: 1px solid #F7F7F7;
        //     border-top: none;
        //     border-radius: 0 0 12px 12px;
        //     margin-bottom: 24px;

        //     @include XGA {
        //         display: grid;
        //         grid-template-columns: repeat(3, 1fr);
        //         gap: 16px;
        //         padding: 40px 40px;
        //     }

        //     @include HD {
        //         gap: 32px;
        //     }

        //     @include UXGA {
        //         padding-left: 121px;
        //         padding-right: 121px;
        //     }

        //     .card {
        //         cursor: pointer;
        //         padding-top: 16px;
        //         background-color: white;

        //         .img img {
        //             display: block;
        //             width: 100%;
        //         }

        //         .text {
        //             display: flex;
        //             justify-content: space-between;
        //             margin-top: 12px;

        //             .place {
        //                 color: #868686;
        //                 font-weight: 700;
        //                 font-size: 12px;
        //                 line-height: 16px;
        //             }

        //             .name {
        //                 font-weight: 600;
        //                 font-size: 12px;
        //                 line-height: 16px;
        //             }
        //         }

        //         &:last-child {
        //             padding-bottom: 16px;
        //         }
        //     }

        // }
    }

    // &.clouds-fest-winners {
    //     .place-selector {
    //         display: flex;
    //         gap: 8px;
    //         justify-content: center;
    //         align-items: center;
    //         margin-top: 16px;
    //         margin-bottom: 24px;

    //         @include XGA {
    //             margin-top: 18px;
    //         }

    //         .active {
    //             font-weight: 900;
    //             color: #ED8002;
    //             border: 1px solid #ED8002;
    //             border-radius: 12px;
    //             padding: 4px 8px 2px;
    //         }

    //         button {
    //             width: fit-content;
    //             font-size: 12px;
    //             line-height: 16px;
    //             align-items: center;
    //             color: #868686;
    //             padding-left: 8px;
    //             padding-right: 8px;

    //             &:not(.active) {
    //                 padding-top: 2px;
    //             }
    //         }
    //     }

    //     .winner-images {
    //         display: grid;
    //         grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    //         column-gap: 9px;

    //         @include XGA {
    //             padding: 40px 32px;
    //         }

    //         .img {
    //             img {
    //                 width: 100%;
    //             }
    //         }
    //     }
    // }
}

// NEW CLASSES

.winners-page {

    .ticket-mobile-img {
        max-width: 327px;
        margin: 0 auto;
        margin-top: 25px;
    }

    .header-flex {
        display: flex;
        flex-direction: column;
    }

    .header-wrapper {
        display: grid;
        grid-template-columns: 20px auto 20px;
        margin-top: 12px;


        @include XGA {
            width: 100%;
            display: flex;
            gap: 32px;
            align-items: center;
            justify-content: space-between;
        }
    }

    .content-wrapper-overlap {
        @include XGA {
            padding: 0 70px;
            margin-top: -40px
        }
    }

    .title-smaller {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;

        // margin-bottom: 8px;
        @include XGA {
            margin-top: 40px;
            font-size: 24px;
            line-height: 40px;
            font-weight: 700;
        }
    }

    .content-header-bigger {
        @include XGA {
            font-weight: 900;
            font-size: 56px;
        }
    }

    .content-subheader {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        max-width: 295px;
        margin: 15px auto;

        @include XGA {
            max-width: 612px;
        }
    }

    .card-mb {
        margin-bottom: 24px;
    }

    .card {
        .author {
            border-top: 1px solid #E5E5E5;

            p {
                margin-top: 24px;
                margin-bottom: 7px;
                font-size: 18px;
                font-weight: 700;
            }
        }

        .lyrics {
            p {
                font-size: 12px;
            }
        }

        @include XGA {
            .author {
                border-radius: 12px 12px 0 0;
                height: calc(36px + 10px);
                margin-bottom: -10px;
                background: linear-gradient(90deg, rgba(229, 69, 31, 1) 0%, rgba(245, 170, 4, 1) 100%);

                p {
                    color: white;
                    font-size: 12px;
                    margin: 0 112px;
                    padding-top: 12px;
                }
            }

            .lyrics {
                border: #C6C6C5 solid 1px;
                border-radius: 12px;
                color: $darkgray;
                background-color: white;

                p {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 300;
                    margin: 24px auto;
                    max-width: 416px;
                }
            }
        }
    }
}