// ------------------------------------------------
// Site Name:
// Author:
// ------------------------------------------------

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
}

body,
html {
  margin: 0;
}

body {
  font-family: $main;
}

.app {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

p {
  line-height: 1.4;
}

.page-with-bg {
  background-size: 40% auto, 45% auto;
  background-position: left 96px, right 170px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(../img/section-bg-left.svg),
    url(../img/section-bg-right.svg);

  &:has(.subscription-form-page) {
    background-color: white;
    background-image: initial;
  }
}

.container,
.article-container {
  position: relative;
  width: 85%;
  max-width: 1280px;
  margin: 0 auto;
}

section {
  padding-bottom: 24px;
  width: calc(100% - 48px);
  margin: 0 auto;

  @include XGA {
    width: calc(100% - 128px);
    // margin-top: 24px;
  }

  @include XGA {
    margin-top: 45px;
    padding-bottom: 48px;
  }

  & > .header {
    display: grid;
    grid-template-columns: 5% 90% 5%;
    margin-top: 12px;

    @include XGA {
      display: flex;
      gap: 32px;
      align-items: center;
    }

    .title {
      text-align: center;

      @include XGA {
        margin: 0;
        font-weight: 600;
      }
    }

    h2 {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;

      @include XGA {
        margin-top: 40px;
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
      }
    }

    button:not(.button):not(.close) {
      background: none;
      cursor: pointer;
      outline: none;
      border: none;
      text-align: left;
      height: fit-content;
      width: 50%;
      padding: 0;

      @include XGA {
        width: fit-content;
      }
    }

    .reward {
      @include XGA {
        display: inline-flex;
        align-items: center;
        margin-top: 0;
        gap: 16px;
        margin-left: auto;

        .points {
          margin: 0;
        }
      }
    }
  }
}

.page-holder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // margin-top: 50px;

  @include FHD {
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1920px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
    overflow: auto;
    overflow-x: hidden;
  }
}

.top-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 24px;

  @include XGA {
    margin-top: 40px;
    justify-content: left;
    column-gap: 24px;
    margin-bottom: 36px;
  }

  .header {
    @include XGA {
      font-size: 32px;
      margin: 0;
      margin-right: 40px;
      line-height: 40px;
    }
  }

  p,
  a {
    opacity: 0.4;
    font-size: 12px;
    padding: 4px 4px 2px;
    border: 1px solid transparent;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    color: black;

    @include XGA {
      padding: 6px 16px;
      font-size: 14px;
    }
  }

  .active {
    color: $lightorange;
    padding: 4px 4px 2px;
    border: 1px solid $lightorange;
    border-radius: 24px;
    opacity: 1;
    font-weight: 600;
    line-height: 1.2 !important;
    margin: 0;

    @include XGA {
      padding: 6px 16px;
      font-weight: 800;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $lightorange;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.45);
  }

  70% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-oragne-transform-off {
  0% {
    box-shadow: 0 0 0 0 $lightorange;
  }

  70% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-green-transform-off {
  0% {
    box-shadow: 0 0 0 0 #5fa98e;
  }

  70% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.96);
    box-shadow: 0 0 0 0 $darkorange;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.96);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-red-big {
  0% {
    transform: translate3d(53px, 0, 0) scale(1.46);
    box-shadow: 0 0 0 0 $darkorange;
  }

  70% {
    transform: translate3d(53px, 0, 0) scale(1.5);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }

  100% {
    transform: translate3d(53px, 0, 0) scale(1.46);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.96);
    box-shadow: 0 0 0 0 $yellow;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.96);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: $skeletonStart;
  }

  100% {
    background-color: $skeletonEnd;
  }
}
