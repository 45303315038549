.ReactModal__Body--open {
    overflow: hidden;
}

.overlay {
    z-index: 99000;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 40px;
	transition: opacity 1000ms $CubicEaseInOut;
    background: transparent;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.82;
    }

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.modal {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: transform 300ms $CubicEaseInOut, opacity 300ms $CubicEaseInOut;
    outline: none;
    border: none;
    width: 90%;
    max-width: 760px;
    height: auto;
    max-height: 90vh;
    text-align: center;
    padding: 20px;
    overflow: auto;

    &_after-open {
    	opacity: 1;
        transform: translate(-50%, -50%);
    }
    &_before-close {
    	opacity: 0;
    }

    &.tax-form {
        max-width: 900px;
        background-color: #fff;
        padding: 0;
    }

    .close-modal {
        position: absolute;
        right: 30px;
        top: 30px;
        width: 20px;
        height: 20px;
        z-index: 10;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 100%;
            height: 2px;
            background-color: $black;
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 100%;
            height: 2px;
            background-color: $black;
        }
    }
}