@import "../../../styles/vars";

.logout-page {
    height: 100vh;
    background-image: url("./img/bg_mobile.webp");
    background-position: center;
    background-size: cover;

    @include XGA {
        background-image: url("./img/bg_desktop.webp");
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        // background: linear-gradient(0deg, #000000 0%, rgba(106, 106, 106, 0) 50%);

        .logo {
            width: calc(min(30vw, 140px));
        }

        .image {
            width: calc(min(26vw, 200px));
            margin-top: 12.5vh;

            @include XGA {
                width: 14.5vw;
                margin-top: 11vh;
            }
        }

        .text {
            color: white;
            margin-top: 13vh;
            max-width: 400px;

            @include XGA {
                margin-top: 11vh;
                max-width: 480px;
            }
        }
        
        h2 {
            font-weight: 600;
            font-size: 40px;
            line-height: 40px;
            color: white;
            text-align: center;
            margin: 0;
            margin-bottom: 16px;

            @include XGA {
                font-size: 32px;
            }
        }

        p {
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            font-weight: 600;
            margin: 0;
            padding: 0 38px;

        }

        .button-wrapper {
            margin-top: 3.5vh;
        }
    }
    
    .desktop {
        display: none;

        @include XGA {
            display: block;
        }
    }
}