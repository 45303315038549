@import "../../../styles/vars";

.maintenance {
    position: relative;
    height: 100vh;
    min-height: 600px;
    background-image: url(./img/maintenance-bg.jpg);
    background-position: center;
    background-size: cover;
    color: #fff;

    @include XGA {
        background-position: center top;
        background-image: url(./img/maintenance-bg.jpg);
    }

    .maintenance-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;

        img {
            height: 52px;

            @include XGA {
                height: 40px;
            }
        }

        .service-img {
            display: block;
            margin: 40px auto;
            margin-top: 100px;
            height: 40px;

            @include XGA {
                height: 64px;
                margin-top: 120px;
            }
        }

        h2 {
            font-size: 32px;
            text-transform: uppercase;
            font-weight: 900 !important;

            @include XGA {
                font-size: 40px;
                line-height: 40px;
            }
        }

        p {
            font-size: 15px;
            font-weight: 500;

            br {
                @include XGA {
                    display: none;
                }
            }

            strong {
                font-weight: 700;
            }
        }

        .social-title {
            margin-top: 100px;

            @include XGA {
                margin-top: 60px;
            }
        }

        .social {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;

            li {
                margin: 0 12px;

                img {
                    height: 48px;
                }
            }
        }
    }
}