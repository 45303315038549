@import "../../../styles/vars";

.invite-bonus-bar {
  position: relative;
  min-height: 60px;
  background-color: #E5451F;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
  padding: 9px 24px 5px;
  z-index: 2;
  overflow: hidden;

  @include XGA {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 64px 5px;
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    margin: 2px 0 0;

    @include XGA {
      font-size: 18px;
      line-height: 24px;
    }

    strong {
      font-weight: 900;
    }
  }

  .duration {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;

      @include XGA {
        font-size: initial;
        line-height: initial;
        font-weight: initial;
      }
    }
  }

  button {
    appearance: none;
    all: unset;
    padding-inline: 5px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 20px;
    height: 14px;

    @include XGA {
      position: relative;
      inset: initial;
    }
  }

  svg.bg-blob {
    position: absolute;
    z-index: -1;

    &.desktop {
      display: none;

      @include XGA {
        display: block;
      }
    }

    &.mobile {
      @include XGA {
        display: none;
      }
    }

    &.blob-1 {
      left: 0;
    }

    &.blob-2 {
      &.mobile {
        left: 50%;
        transform: translateX(-50%);
      }

      &.desktop {
        @include XGA {
          left: 50%;
          transform: translateX(-50%);
        }

        @include QHD {
          left: 33%;
        }
      }
    }

    &.blob-3 {
      &.mobile {
        right: 0;
      }

      &.desktop {
        @include XGA {
          display: none;
        }

        @include QHD {
          display: block;
          left: 55%;
        }
      }
    }

    &.blob-4 {
      &.desktop {
        right: 16px;
      }
    }
  }
}