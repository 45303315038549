@import "../../../styles/vars";

.coupon-page {
    position: relative;
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    margin-top: 12px;
    padding-bottom: 0;
    width: 100%;
    margin-top: -12px;
    z-index: 100;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
        padding-bottom: 0;
        z-index: initial;
    }

    .map {
        margin: 40px 24px;

        @include HD {
            margin: -43px 0 0;
            padding: 110px 0 64px;
            display: grid;
            grid-template-columns: 1fr 327px;
            gap: 105px;
            position: relative;
            z-index: 2;
        }

        &::before {
            @include HD {
                content: "";
                display: block;
                position: absolute;
                width: 1920px;
                height: 100%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #E5451F;
                z-index: -1;
            }
        }

        h4 {
            color: #2A2C2C;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            margin: 0;

            @include HD {
                color: #FFF;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                margin-bottom: 24px;
            }
        }

        .side-content {
            &>svg {
                display: none;

                @include HD {
                    display: block;
                    margin-top: 32px;
                }
            }


            .button-wrapper {
                margin-top: 16px !important;

                @include HD {
                    width: 100%;
                }

                .button {
                    display: flex !important;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 8px;
                    line-height: 20px;

                    @include HD {
                        width: 100%;
                        max-width: initial !important;
                    }
                }
            }
        }
    }

    .map-container {
        position: relative;

        .map-outer {
            position: relative;
        }

        .preloader {
            position: absolute;
            top: calc(50% - 16px);
            left: calc(50% - 16px);
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;

            &.show {
                opacity: 1;
                visibility: visible;
            }

            span {
                animation: spin 1s $QuadEaseInOut infinite;
                display: block;
                width: 32px;
                height: 32px;
                border: $lightorange solid 4px;
                border-bottom: $darkorange solid 4px;
                border-radius: 50%;
            }
        }

        .map-cover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.7);
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;
            border-radius: 12px;

            &.show {
                opacity: 1;
                visibility: visible;
            }
        }

        #map {
            position: relative;
            width: 100%;
            height: 400px;
            // border-radius: 12px;
            margin: 16px 0 40px;

            .leaflet-control-zoom {
                display: none;
            }

            .leaflet-popup-content {
                img {
                    max-width: 100%;
                    max-height: 40px;
                }
            }

            .cluster {
                background-color: #000;
                border-radius: 50%;
                text-align: left;
                color: white;
                font-weight: 400;
                box-shadow: rgba(0, 0, 0, 0.45) 3px 3px 10px;
            }

            .digits-1 {
                font-size: 13px;
                height: 26px;
                width: 26px;
                line-height: 1;
                margin-top: 0;
                margin-left: -10px;
                padding-top: 7px;
                padding-left: 9px;
            }

            .digits-2 {
                font-size: 13px;
                height: 30px;
                width: 30px;
                line-height: 1;
                margin-top: 0;
                margin-left: -12px;
                padding-top: 9px;
                padding-left: 8px;
            }

            .digits-3 {
                font-size: 12px;
                height: 30px;
                width: 30px;
                line-height: 1;
                margin-top: 0;
                margin-left: -15px;
                padding-top: 10px;
                padding-left: 5px;
            }

            .digits-4 {
                font-size: 12px;
                height: 40px;
                width: 40px;
                line-height: 1;
                margin-top: 0;
                margin-left: -20px;
                padding-top: 15px;
                padding-left: 6px;
            }
        }

        .search {
            position: absolute;
            top: 60px;
            left: 8px;
            background-color: #fff;
            width: calc(100% - 16px);
            max-width: 330px;
            z-index: 100;
            padding: 16px;

            @include XGA {
                left: 40px;
                top: 110px;
            }

            .search-inner {
                display: none;

                &.show {
                    display: block;
                }
            }

            .filters-top {
                position: relative;

                h4 {
                    margin-bottom: 0;
                }

                .show {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 14px;
                    height: 14px;
                    border-left: $lightorange solid 2px;
                    border-bottom: $lightorange solid 2px;
                    transform: rotate(-45deg);
                }

                &.open {
                    .show {
                        top: 4px;
                        transform: rotate(135deg);
                    }

                    h4 {
                        margin-bottom: 12px;
                    }
                }
            }

            h4 {
                margin: 0;
                margin-bottom: 8px;
                font-weight: 500;
            }

            .search-input {
                position: relative;

                input {
                    display: block;
                    width: 100%;
                    border: none;
                    border-bottom: #000 solid 1px;
                }

                .get-loc {
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                }
            }

            .map-nav {
                display: block;
                margin-top: 8px;

                .filters {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    margin-top: 12px;

                    h4 {
                        font-weight: 700;
                        font-size: 12px;
                        width: 60px;
                        min-width: 60px;
                        margin: 0;
                    }
                }

                ul {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    gap: 12px;

                    li {
                        position: relative;
                        font-size: 12px;
                        color: #868686;
                        padding-left: 20px;
                        cursor: pointer;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 12px;
                            height: 12px;
                            border: #868686 solid 1px;
                        }

                        &.active {
                            color: $lightorange;

                            &::before {
                                border-color: $lightorange;
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                left: 5px;
                                top: 2px;
                                width: 3px;
                                height: 6px;
                                border-bottom: $lightorange solid 1px;
                                border-right: $lightorange solid 1px;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }

                .filters.radio {
                    ul {
                        li {
                            &::before {
                                border-radius: 50%;
                            }

                            &.active {
                                &::after {
                                    left: 4px;
                                    top: 4px;
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 50%;
                                    border: none;
                                    background-color: $lightorange;
                                    transform: rotate(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    p {
        margin: 0;
        padding: 0;
    }

    h3 {
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        // margin-bottom: 8px;

        @include XGA {
            margin-top: 40px;
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
        }
    }

    p.error {
        position: relative;
        top: -8px;
        font-size: 10px;
        line-height: 14px;
        font-weight: 500;
        color: #E5451F;

        &::before {
            content: "";
            position: relative;
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 4px;
            background-color: #E5451F;
        }
    }

    .send-again {
        margin-top: 24px;
        max-width: 640px;

        @include HD {
            position: relative;
            margin-top: 40px;
            padding-top: 40px;
            display: block;
            z-index: 2;

            &::before {
                content: "";
                position: absolute;
                width: 1600px;
                height: 300px;
                background-color: #F7F7F7;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                z-index: -1;
            }
        }

        p {
            margin: 0;
            font-size: 14px;

            @include XGA {
                font-size: 24px;
                line-height: 32px;
            }

            &:first-child {
                margin-bottom: 4px;
            }
        }

        .wait-until {
            @include XGA {
                font-size: 14px;
            }
        }

        .send-code-button {
            color: $lightorange;
            font-weight: 900;

            @include XGA {
                font-size: 14px;
            }
        }
    }

    .code-inputs {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        column-gap: 16px;
        margin: 20px 0;

        .code-input {
            input {
                border: $lightorange solid 1px;
                border-radius: 12px;
                text-align: center;
                width: 70px;
                height: 80px;
                font-size: 32px;
                line-height: 80px;
                font-weight: 700;
            }
        }
    }

    .content-wrapper {
        margin-left: auto;
        margin-right: auto;

        @include XGA {
            max-width: 100%;
            position: relative;
            margin-top: -48px;
            display: flex;
            flex-flow: row-reverse;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &.otp,
        &.second-step {
            .form-container {
                @include HD {
                    padding-top: 40px !important;
                }
            }
        }

        .thumb {
            margin-top: 16px;
            margin-bottom: 16px;
            max-width: 409px;

            @include XGA {
                margin-top: 40px;
                margin-left: -76px;
                z-index: 2;
            }

            img {
                width: 100%;
                max-width: 375px;
                display: block;
                border-radius: 12px;

                @include XGA {
                    width: 409px;
                    height: auto;
                    border-radius: 0;
                    max-width: initial;
                }

                @include HD {
                    transform: initial;
                }
            }
        }

        .main {
            @include XGA {
                width: 100%;
                padding-right: 48px;
            }

            @include HD {
                padding-right: 100px;
            }

            @include UXGA {
                padding-left: 121px;
                padding-right: 121px;
            }

            h1.title {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #000000;
                margin: 0;
                text-align: center;

                @include XGA {
                    font-weight: 900;
                    font-size: 40px;
                    line-height: 117%;
                    letter-spacing: -0.02em;
                    color: #000000;
                    text-align: left;
                }

                @include UXGA {
                    font-size: 48px;
                }
            }

            p.subtitle {
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                margin: 0;
                margin-top: 8px;
                text-align: center;

                @include XGA {
                    text-align: left;
                }
            }

            .orange-box {
                position: relative;
                width: 330px;
                background-color: #E5451F;
                border-radius: 12px;
                padding: 12px;
                padding-bottom: 9px;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: -12px;
                margin-top: -24px;

                @include HD {
                    position: absolute;
                    margin-top: 0;
                    top: 48px;
                    right: 72px;
                }

                &.box-disclaimer {
                    margin-top: 12px;
                    
                    @include HD {
                        position: relative;
                        top: auto;
                        right: auto;
                    }
                }

                p {
                    color: #fff;
                    margin: 0;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                }

                img {
                    margin-right: 12px;
                }
            }

            .form-container {
                padding: 0 24px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                @include HD {
                    position: relative;
                    width: 65%;
                    min-width: 65%;
                    max-width: 712px;
                    background-color: #fff;
                    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
                    border-radius: 12px;
                    margin-right: 32px;
                    margin-top: -130px;
                    margin-left: 40px;
                    padding: 70px 72px 48px;
                    align-self: self-start;
                    overflow: hidden;
                    z-index: 3;
                    margin-bottom: 24px;
                }

                h4 {
                    color: #2A2C2C;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;

                    &:first-of-type {
                        margin: 8px 0 0;
                    }

                    &:last-of-type {
                        margin: 16px 0 0;
                    }
                }

                .store-box {
                    border-radius: 12px;
                    outline: 1px solid #F7F7F7;
                    background-color: #FFF;
                    padding: 12px;
                    padding-right: 28px;
                    position: relative;

                    @include XGA {
                        padding-right: 48px;
                    }

                    &::before {
                        content: url("./img/dropdown-icon.svg");
                        display: block;
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 14px;
                        right: 12px;
                        transform: rotate(180deg);
                    }

                    &.main {
                        @include HD {
                            margin-top: 16px;
                        }

                        .title {
                            &::before {
                                border-color: #ED8002;
                            }

                            &::after {
                                background-color: #ED8002 !important;
                            }
                        }
                    }

                    &.selected {
                        outline: 2px solid #ED8002;

                        &::before {
                            transform: rotate(0deg);
                        }

                        .title {
                            color: #ED8002;

                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 4px;
                                top: 4px;
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                background-color: #000;
                            }
                        }

                        ul.info-list,
                        p.disclaimer {
                            display: block;
                        }
                    }

                    .title {
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000;
                        position: relative;
                        display: flex;
                        gap: 8px;

                        &::before {
                            content: "";
                            display: block;
                            position: relative;
                            width: 16px;
                            height: 16px;
                            border: 1px solid #2A2C2C;
                            border-radius: 50%;
                            // margin-top: -1px;
                            flex-basis: 16px;
                            flex-shrink: 0;
                        }
                    }

                    .logos {
                        display: flex;
                        gap: 16px;
                        margin: 8px 0 0 24px;

                        svg,
                        img {
                            display: block;
                            height: 22px;
                        }
                    }

                    ul.info-list {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        padding-left: 13px;
                        display: none;
                        margin-top: 8px;

                        li {
                            list-style-type: disc;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 16px;
                        }
                    }

                    p.disclaimer {
                        margin-top: 4px;
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 14px;
                        display: none;
                    }
                }


                .button-wrapper {
                    margin-top: 8px;

                    .button {
                        @include XGA {
                            min-width: 327px;
                            max-width: initial;
                        }
                    }
                }

                &.form-container-your-coupon {
                    padding: 0;

                    @include HD {
                        padding: 0;
                    }

                    .buy-online {
                        padding: 0 24px;

                        @include HD {
                            padding: 48px 72px;
                        }

                        h3 {
                            font-size: 24px;
                            line-height: 32px;
                            font-weight: 700;
                        }

                        p {
                            margin: 16px 0;
                            display: flex;
                            flex-flow: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            color: #2A2C2C;

                            img {
                                margin-right: 8px;
                            }
                        }

                        .button {
                            margin-top: 16px;
                            width: 100%;

                            @include XGA {
                                max-width: 200px;
                            }
                        }
                    }

                    .buy-locations {
                        background-size: cover;
                        background-position: bottom right;
                        background-image: linear-gradient(180deg, rgba(0,0,0,0) 31%, rgba(11,11,11,0.6790393013100436) 100%), url(./img/locations-bg-mobile.jpg) ;
                        padding: 42px 24px;
                        margin-top: 32px;

                        @include XGA {
                            background-image: url(./img/locations-bg-desktop.jpg);
                        }

                        @include HD {
                            margin-top: 0;
                            padding: 48px 72px;
                        }

                        h4 {
                            color: #fff;
                            font-size: 15px;
                            line-height: 20px;
                            font-weight: 700;
                            margin: 0;
                        }

                        p {
                            color: #fff;
                            font-size: 15px;
                            line-height: 20px;
                            font-weight: 500;
                            margin: 0;

                            @include HD {
                                max-width: 70%;
                            }
                        }

                        ul {
                            margin: 16px 0;
                            display: flex;
                            flex-flow: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            column-gap: 24px;

                            li {
                                color: #fff;

                                img {
                                    height: 20px;
                                }

                                span {
                                    position: relative;
                                    display: inline-block;
                                    margin-left: 6px;
                                    top: -8px;
                                }
                            }
                        }

                        .button.transparent {
                            border-color: #fff;
                            color: #fff;
                            background-color: transparent;
                            margin-top: 32px;
                        }
                    }
                }
            }

            .coupon-top-section {
                position: relative;
                background-color: #F7F7F7;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                padding: 48px 24px;
                margin-bottom: 48px;
                padding-bottom: 0;

                @include XGA {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    padding: 64px 48px;
                    padding-bottom: 0;
                    margin-top: 24px;
                }

                @include HD {
                    padding: 80px 100px;
                    padding-bottom: 130px;
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: 29% auto;
                    background-image: url(./img/glo-x2-air-desktop.jpg);
                }

                .image-glo {
                    position: relative;
                    width: 100vw;
                    max-width: 500px;
                    left: -24px;
                    margin-top: 16px;

                    img {
                        max-width: 100%;
                    }
                }

                .code-outer {
                    margin-top: 12px;

                    @include XGA {
                        display: flex;
                        flex-flow: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 16px;
                    }

                    .use-info {
                        margin-top: 20px;
                        max-width: 80%;
                        display: flex;
                        gap: 8px;
                        align-items: flex-start;

                        @include XGA {
                            margin-top: 0;
                            align-items: center;
                        }

                        p {
                            color: #2A2C2C;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 18px;

                            @include XGA {
                                br {
                                    display: none;
                                }
                            }
                        }

                        .points {
                            display: inline-block;
                            margin-left: 2px;
                            background-color: #E5451F;
                            color: #fff;
                            border-radius: 24px;
                            padding: 3px 8px 1px;

                            @include XGA {
                                margin-left: 8px;
                            }
                        }
                    }

                    button {
                        margin: 0;

                        @include XGA {
                            margin-right: 32px;
                        }
                    }
                }

                .only-mobile {
                    @include XGA {
                        display: none;
                    }
                }

                .title {
                    text-align: left;
                    font-weight: 900;
                    font-size: 32px;
                    line-height: 36px;

                    @include XGA {
                        font-size: 43px;
                        line-height: 52px;
                    }

                    @include UXGA {
                        font-size: 48px;
                        line-height: 56px;
                    }
                }

                .subtitle {
                    text-align: left;
                    font-weight: 400;
                    color: #2A2C2C;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;

                    @include XGA {
                        font-size: 15px;
                        line-height: 20px;
                    }
                }

                .price-badge {
                    position: absolute;
                    right: 12px;
                    bottom: 124px;

                    @include vHD {
                        right: 48px;
                    }

                    @include XGA {
                        bottom: auto;
                        top: 32px;
                    }

                    @include HD {
                        top: -6px;
                        right: -44px;
                    }
                }
            }

            .coupon-bottom-section {
                @include HD {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    // margin-bottom: 48px;
                }
            }

            .phone-number {
                margin-top: 24px;
                position: relative;

                @include HD {
                    margin-top: 0;
                }

                &::before {
                    content: "+48";
                    position: absolute;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 16px;
                    width: 20px;
                    height: 16px;
                    left: 0;
                    top: 25px;
                }

                label {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    display: block;
                }

                input {
                    border: none;
                    border-bottom: 1px solid #C6C6C5;
                    width: 100%;
                    padding: 4px 0;
                    padding-left: 34px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    margin-top: 2px;
                    background: transparent;
                    outline: none;

                    @include XGA {
                        max-width: 325px;
                    }

                    @include HD {
                        max-width: 190px;
                    }

                    &:focus {
                        border-color: #ED8002;
                    }
                }

                &.otp {
                    &::before {
                        display: none;
                    }

                    input {
                        padding-left: 0;
                    }
                }
            }

            .show-consents-button {
                margin-top: 24px;
                cursor: pointer;

                span {
                    color: $lightorange;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                }

                img {
                    position: relative;
                    top: 4px;
                }

                &.shown {

                    img {
                        transform: rotate(180deg);
                    }
                }
            }

            .consents {
                margin-top: 32px;
                margin-bottom: 32px;

                @include XGA {
                    max-width: calc(min(640px, 80%));
                }

                .checkbox {
                    margin-top: 12px;

                    &.error {
                        label::before{
                            border-color: #E5451F;
                        }
                    }

                    input {
                        display: none;
                    }

                    label {
                        position: relative;
                        display: block;
                        width: 100%;
                        padding-left: 28px;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: -3px;
                            width: 16px;
                            height: 16px;
                            border: $lightorange solid 2px;
                        }
                    }

                    input:checked+label {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 7px;
                            top: 0;
                            width: 4px;
                            height: 8px;
                            border-bottom: $lightorange solid 2px;
                            border-right: $lightorange solid 2px;
                            transform: rotate(45deg);
                        }
                    }

                    &.all {
                        margin-top: 18px;
                        margin-bottom: 18px;
                    }

                    p.error {
                        margin: 12px 0 0 28px;
                        color: #E5451F;
                    }
                }

                .select-all {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: #ED8002;
                }

                p {
                    margin-top: 8px;
                }

                p,
                label {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14px;
                    color: #000000;

                    a {
                        color: black;
                    }
                }
            }

            .button-wrapper {
                margin-top: 24px;
                // margin-bottom: 32px;

                .button {
                    display: block;
                    width: 100%;

                    @include HD {
                        max-width: 190px;
                    }

                    img {
                        margin-bottom: -3px;
                        margin-left: 4px;
                    }
                }

                @include XGA {
                    max-width: 304px;
                }
            }
        }

        .consents-info {
            position: relative;
            margin-top: 24px;
            padding: 24px;

            &::before {
                content: "";
                position: absolute;
                width: 100vw;
                height: 100%;
                background-color: #F7F7F7;
                top: 0;
                left: 50%;
                transform: translateX(-50%);

                @include XGA {
                    transform: translateX(calc(-50% + 24px));
                }

                @include HD {
                    display: none;
                }
            }

            @include HD {
                margin-top: 24px;
                margin: 0;
                padding: 0;
            }

            .separator {
                position: relative;
                width: 100%;
                height: 1px;
                background-color: #E5E5E5;
            }

            p.info {
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                color: #4F4F4F;
                position: relative;
                z-index: 2;
                margin: 8px 0;

                a {
                    color: $black;
                    font-weight: 700;
                }

                strong {
                    font-weight: 700;
                }
            }
        }

        &.wait-step {
            text-align: left;

            .main {

                .title,
                .subtitle {
                    text-align: left;
                }

            }

            .spinner {
                position: relative;
                margin-top: 42px;
                margin-bottom: 42px;

                span {
                    display: block;
                    width: 36px;
                    height: 36px;
                    border: 4px solid #FFFFFF;
                    border-radius: 50%;
                    border-bottom: $orange solid 4px;
                    animation: spin 1s $QuadEaseInOut infinite;
                }
            }
        }
    }

    &.coupon-page-your-coupon {
        .consents-info {
            margin-top: 0;
        }
    }
}

.your-coupon {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    margin-top: 12px;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    p {
        margin: 0;
        padding: 0;
    }

    h3 {
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        // margin-bottom: 8px;

        @include XGA {
            margin-top: 40px;
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
        }
    }

    .header {
        display: flex;
        gap: 16px;
        align-items: center;

        @include XGA {
            gap: 32px;
            align-items: left;
        }

        .title {
            text-align: center;
            width: 100%;

            @include XGA {
                margin: 0;
                font-weight: 600;
            }
        }

        button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: left;
            height: fit-content;
            width: fit-content;
            padding: 0;
        }
    }

    .points {
        .earned-points {
            background-color: $darkorange;
            color: #fff;
            border-radius: 24px;
            font-size: 12px;
            text-align: center;
            padding: 4px 0;
            margin: 12px auto;
            font-weight: 700;
            max-width: 90px;
        }

        .points-desc {
            font-weight: 700;
            font-size: 12px;

            span {
                color: $darkorange;
            }
        }
    }

    .buttons {
        text-align: center;
        margin: 24px 0;

        button,
        a {
            margin: 16px;
        }
    }

    .main {
        margin-top: 24px;
        background: #FFFFFF;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        overflow: hidden;

        .image {
            @include XGA {
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                display: block;
                margin: 0;
                object-fit: cover;
            }
        }

        .content {
            padding: 24px;
            text-align: center;

            @include XGA {
                padding: 0 55px;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
            }

            a {
                font-weight: 900;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-decoration-line: underline;
                color: #ED8002;
                opacity: 0.8;
                margin-top: 12px;
                display: inline-block;
            }

            .title {
                font-size: 24px;
                line-height: 32px;

                @include XGA {
                    margin-top: 0;
                }
            }

            .separator {
                margin: 24px 0;
                width: 100%;
                height: 1px;
                background-color: #EEEEEE;
            }

            .sub {
                font-size: 12px;
                line-height: 16px;
            }

            .button.code {
                margin: 12px auto;
                background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
            }

            .additional {
                @include XGA {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                }

                &>div {
                    @include XGA {
                        max-width: 230px;
                    }
                }

                .valid-to {
                    @include XGA {
                        margin-right: 30px;
                    }

                    img {
                        @include XGA {
                            margin-top: 10px;
                        }
                    }

                    p {
                        font-size: 12px;

                        br {
                            display: none;

                            @include XGA {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        @include XGA {
            display: grid;
            grid-template-columns: 60% 1fr;
            background-color: #fff;
        }
    }

    .additional-points {
        margin: 24px 0;
        text-align: center;

        h2 {
            display: none;

            @include XGA {
                display: block;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 8px;
            }
        }
    }

    .where {
        margin-top: 24px;
        background: #FFFFFF;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        overflow: hidden;
        padding: 24px;
        text-align: center;

        @include XGA {
            display: grid;
            grid-template-columns: 1fr 1fr;
            place-items: center;

            div {
                .button {
                    display: block;
                    margin: 0 auto !important;
                }

                p {
                    height: initial;
                }
            }
        }

        .ecomm {
            .where-inner {
                @include XGA {
                    margin: 0;
                }
            }
        }

        .retail {
            margin-top: 20px;

            @include XGA {
                margin-top: 0;
            }
        }

        .retail-inner {
            @include XGA {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: center;
            }
        }

        .button.orange {
            @include XGA {
                margin-left: 25px;
                margin-top: 12px;
            }
        }

        p {
            font-size: 12px;
        }

        h2 {
            display: block;

            @include XGA {
                display: none;
            }
        }

        .where-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            p {
                width: 100%;
                text-align: center;
                margin: 8px 0;

                @include XGA {
                    width: auto;
                }
            }

            .logo {
                min-width: 50%;
                max-width: 50%;
                width: 50%;

                @include XGA {
                    min-width: initial;
                    max-width: initial;
                    width: auto;
                    margin-right: 12px;
                }

                img {
                    max-height: 25px;
                }
            }
        }
    }
}

.modal.select-device-modal {
    width: 100%;
    height: 90vh;
    max-height: 90vh;
    border-radius: 0;
    padding: 24px 0;

    @include vHD {
        height: auto;
        width: 90%;
        max-width: 760px;
        border-radius: 12px;
    }

    .close-button {
        position: absolute;
        top: 24px;
        right: 24px;
        // margin-right: 24px;
    }

    .device-list-modal {
        width: 100%;

        h2,
        p {
            margin: 0 auto;
            max-width: 290px;

            @include vHD {
                max-width: 480px;
            }
        }

        h2 {
            font-size: 24px;
            line-height: 32px;
            font-weight: 700;
            margin-bottom: 8px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
        }

        .device-switch {
            margin-top: 16px;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;
            column-gap: 16px;

            .button.small {
                padding: 4px 8px;

                @include XGA {
                    padding: 12px 41px 12px 41px;

                    &.transparent {
                        color: $lightorange;
                        border-color: $lightorange;

                        &:hover {
                            border: $lightorange solid 1px;
                            color: $orange;
                            border-color: $orange;
                        }
                    }
                }
            }
        }
    }

    .buttons-wrapper {
        padding: 0 24px;
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .prev,
    .next {
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin: 0;
        padding: 0;

        @include XGA {
            position: relative;
            top: -180px;
        }
    }

    .swiper-holder {
        background-color: #F7F7F7;
    }

    .swiper {
        background-color: #F7F7F7;
        margin: 40px 0;
        padding: 32px 0;

        @include vHD {
            padding: 32px 0;
            margin: 40px auto;
            max-width: 620px;
        }

        .swiper-wrapper {
            margin: 0 auto;
            width: 100%;
            height: 240px;

            @include vHD {
                height: 192px;
            }

            .swiper-slide {
                width: 240px;
                height: 240px;
                transform: scale(0.7);
                transition: transform .3s $QuadEaseInOut;

                @include vHD {
                    transform: scale(1);
                    height: 192px;
                    width: 192px;
                }

                &.swiper-slide-active {
                    transform: scale(1);
                }

                .device-list-modal__list-item {
                    position: relative;
                    margin: 0 auto;
                    width: 240px;
                    height: 240px;
                    border-radius: 12px;
                    border: $black solid 1px;
                    cursor: pointer;

                    @include vHD {
                        height: 192px;
                        width: 192px;
                    }

                    &.active {
                        border-color: $orange;
                    }

                    .device-list-modal__list-img {
                        height: 100%;
                    }
                }

                img {
                    position: relative;
                    top: 0;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}