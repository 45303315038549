@import "../../../styles/vars";

.main-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);

    @include XGA {
        position: relative;
        background-color: transparent;
        box-shadow: none;
        z-index: 1;
    }

    .nav-container {
        margin: 0 auto;
        width: calc( 100% - 48px );
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        @include XGA {
            width: 360px;
            margin-left: 72px;
        }

        @include HD {
            width: 440px;
        }
    }

    ul {
        width: 100%;
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        // li:first-child {
        //     a {
        //         text-decoration: none;

        //         &.active {
        //             p {
        //                 color: $darkorange;
        //             }

        //             svg {
        //                 path {
                            
        //                 }
        //             }
        //         }
                
        //         &:hover {
        //             p {
        //                 color: $lightorange;
        //             }

        //             svg {
        //                 path {

        //                 }
        //             }
        //         }
        //     }
        // }

        li {
            -webkit-tap-highlight-color: transparent;
            
            @include XGA {
                position: relative;

                &:hover, &:focus {
                    .sub-nav {
                        display: flex;
                    }
                }
            }

            a {
                text-decoration: none;
                position: relative;

                &.active {
                    p {
                        color: $darkorange;
                    }

                    svg:not(.glo) {
                        path {
                            fill: $darkorange;
                        }
                    }
                }

                // 2.A
                
                // &:hover {
                //     @keyframes shake {
                //         0% {
                //             transform: translateX(0);
                //         }
                //         25% {
                //             transform: translateX(-20%);
                //         }
                //         75% {
                //             transform: translateX(20%);
                //         }
                //         100% {
                //             transform: translateX(0);
                //         }
                //     }

                //     svg {
                //         animation: shake 1s ease-in-out;
                //     }

                //     p {
                //         color: $lightorange;
                //     }

                //     svg:not(.glo)  {
                //         path {
                //             fill: $lightorange;
                //         }
                //     }
                // }
                
                
                // 2.B
                
                &:hover {
                    .menu-icon::before {
                        @include XGA {
                            content: "";
                            position: absolute;
                            top: -5px;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            animation: pulse-oragne-transform-off 1.5s infinite;
                        }
                    }

                    p {
                        color: $lightorange;
                    }

                    svg:not(.glo)  {
                        path {
                            fill: $lightorange;
                        }
                    }
                }
                
            }

            button {
                padding: 0;
                border: none;
                background-color: transparent;
                cursor: pointer;
            }

            a, button {
                display: block;
                text-align: center;
                padding: 18px 0;

                @include XGA {
                    padding: 0;
                }
            }

            svg {
                position: absolute;
                left: initial;

                path {
                    transition: fill $CubicEaseInOut .2s;
                }
            }

            p {
                font-size: 10px;
                color: #3A3A3A;
                margin: 0;
                font-weight: 600;
                transition: color $CubicEaseInOut .2s;

                @include XGA {
                    font-size: 12px;
                }
            }

            .menu-icon {
                height: 20px;
                margin-bottom: 8px;
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .sub-nav {
        display: none;

        @include XGA {
            display: none;
            position: absolute;
            top: 44px;
            left: 50%;
            transform: translate(-50%, 0);
            height: min-content;
            // width: 330px;
            width: 440px;
            gap: 16px;
            justify-content: center;
            padding-top: 12px;
        }
    }

    // &.new-variants {
    //     .nav-container {
    //         ul {
    //             li {
    //                 a.active {
    //                     p {
    //                         color: #E37E55;
    //                     }
    //                 }

    //                 &:hover {
    //                     .menu-icon::before {
    //                         @include XGA {
    //                             content: "";
    //                             position: absolute;
    //                             top: -5px;
    //                             width: 32px;
    //                             height: 32px;
    //                             border-radius: 50%;
    //                             animation: pulse-oragne-transform-off 1.5s infinite;
    //                         }
    //                     }
    
    //                     p {
    //                         color: #E37E55;
    //                     }
    
    //                     svg:not(.glo)  {
    //                         path {
    //                             fill: #E37E55;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .disabled {
        opacity: 0.33;
        pointer-events: none;
    }
}