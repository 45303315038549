@import "../../../styles/vars";

.correct-answer-popup-window {
  background: linear-gradient(90deg, #E5451F 0%, #F5AA04 100%);
  border: none;
  border-radius: 12px;
  max-width: 300px;
  z-index: 300;
    .popup-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 5px;
      text-align: center;
      p.popup-p {
        margin-top: 4px !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
        max-width: 200px;
        @include XGA {
          min-width: 250px;
      }
      }

      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px !important;

        .button.transparent {
          background-color: transparent;
          border-color: white;
          border-width: 2px;
          padding: 11px 40px 11px 40px;
        }
      }
    }
}


