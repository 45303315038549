@import "../../../styles/vars";
@import "../../../styles/index";

.activities-page {

    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
    }

    margin-left: auto;
    margin-right: auto;

    @include XGA {
        display: grid;
        column-gap: 20px;
        grid-template-columns: 55% 45%;
        grid-template-rows: auto auto auto auto 1fr;
    }

    @include UXGA {
        column-gap: 140px;
        grid-template-columns: 2fr 1fr;
    }

    white-space: pre-wrap;

    .terms {
        display: block;
        text-align: center;
        margin: 24px 0;

        @include XGA {
            text-align: left;
        }

        a {
            color: $orange;
            font-weight: 700;
        }
    }

    .slider {
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        p,
        a {
            font-size: 12px;
            cursor: pointer;
            opacity: 0.4;
            padding: 4px 8px;
            border: 1px solid transparent;
            color: black;
            text-decoration: none;

            @include XGA {
                font-size: 14px;
                padding: 6px 16px;
                margin: 0;
            }

            // @include FHD {
            //     font-size: 16px;
            // }
        }

        .selected {
            color: $lightorange;
            padding: 4px 8px;
            border-radius: 24px;
            border: 1px solid $lightorange;
            opacity: 1;
            line-height: 1.2;
            margin-bottom: 0;
            margin-top: 0;
            font-weight: 600;

            @include XGA {
                padding: 6px 16px;
                font-weight: 800;
            }
        }

        @include XGA {
            margin: 0;
            margin-left: 24px;
            gap: 24px;
        }

        // @include FHD {
        //     margin-left: 80px;
        // }
    }

    .activities-header {
        display: none;

        @include XGA {
            display: flex;
            justify-content: space-between;
            align-items: center;
            grid-column: 1 / 3;
            margin-bottom: 24px;

            h2 {
                font-size: 32px;
                line-height: 40px;
                margin: 0;
                margin-right: 40px;
            }
        }

        .available-points {
            @include XGA {
                margin-right: 0;
                margin-top: 0;
                margin-bottom: 0;

                .text {
                    color: $darkorange;
                    font-weight: 600;
                    opacity: 1;
                    font-size: 12px;
                }

                .points {
                    font-size: 24px;
                    padding: 6px 16px 4px 16px;
                    border-radius: 24px;
                    color: white;
                    background-color: $darkorange;

                    // @include FHD {
                    //     color: $darkorange;
                    //     background-color: transparent;
                    //     font-size: 40px;
                    //     padding-left: 0px;
                    // }
                }
            }

            @include FHD {
                gap: 11px;
            }
        }
    }

    .selected-activity {
        margin-top: 24px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding-bottom: 24px;
        background-color: #fff;
        position: relative;

        @include XGA {
            margin-top: 0;
            height: min-content;
        }

        .img {
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            aspect-ratio: 1/1;

            @include XGA {
                background-size: cover;
                border-radius: 12px 12px 0 0;
                aspect-ratio: 47/36;
            }
        }

        .points {
            position: absolute;
            top: 16px;
            left: 16px;
            padding: 4px 8px 2px;
            background: $darkorange;
            border-radius: 12px;
            font-size: 12px;
            font-weight: 600;
            color: white;

            &.pulse-animation {
                animation: pulse-red 2s infinite;
            }
        }

        &>.points {
            @include XGA {
                position: initial;
                width: fit-content;
                margin-left: 40px;
                margin-top: 16px;
            }
        }

        .contest {
            top: 16px;
            right: 16px;
            left: initial;

            @include XGA {
                left: 40px;
                top: 24px;
                right: initial;
            }
        }

        .soon {
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: -16px;
            text-align: center;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            color: #E5451F;

            @include XGA {
                margin-left: 40px;
                margin-top: 24px;
                margin-bottom: -8px;
                text-align: left;
            }
        }

        .title {
            margin: 0 auto;
            margin-top: 24px;
            text-align: center;
            font-weight: 600;
            font-size: 18px;

            @include XGA {
                margin-left: 40px;
                margin-top: 16px;
                font-size: 24px;
                text-align: left;
            }
        }

        .sub {
            margin: 0 auto;
            margin-top: 8px;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            opacity: 0.4;
            max-width: 192px;

            @include XGA {
                text-align: left;
                margin-left: 40px;
                max-width: 80%;
                font-size: 14px;
            }
        }

        .button-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 16px;

            @include XGA {
                justify-content: left;
                margin-left: 40px;
            }
        }
    }

    .selected-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .selected-activity {
            .img {
                @include XGA {
                    aspect-ratio: 94/27;
                }
            }

            &:only-child {
                .img {
                    @include XGA {
                        aspect-ratio: 47/36;
                    }
                }
            }
        }

    }

    .available-points {
        margin: 24px auto 24px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 16px;

        .text {
            text-align: right;
            font-size: 12px;
            line-height: 16px;
            opacity: 0.4;
        }

        .points {
            font-weight: 900;
            font-size: 40px;
        }
    }

    .activities-list {

        @include XGA {
            grid-row: 2/6;
            // grid-row: 2/5;
            grid-column: 2;
        }

        .type {
            color: $darkorange;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            margin-top: 5px;
            margin-bottom: 4px;
            font-weight: 700;

            @include XGA {
                margin-top: 0;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }

        // .activity:nth-child(1){
        //  background: #E5451F;
        //  color: #fff;   
        //  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        //  border: solid #E5451F 2px;
        // }

        .activity {
            display: flex;
            padding: 12px 16px 12px 12px;
            // justify-content: space-between;
            margin-bottom: 8px;
            column-gap: 12px;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            text-decoration: none;
            color: black;
            background-color: #fff;
            overflow: hidden;
            border-radius: 12px;

            @include XGA {
                margin-bottom: 56px;
                padding: 0;
                column-gap: 24px;
                min-height: 160px;
            }

            @include FHD {
                min-height: 260px;
                font-size: 16px;
                column-gap: 32px;
            }

            .img {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 100%;
                max-width: 88px;
                position: relative;
                aspect-ratio: 82/116;

                @include XGA {
                    max-width: 160px;
                    aspect-ratio: auto;
                }

                @include FHD {
                    max-width: 260px;
                }

                .points {
                    position: absolute;
                    top: 6px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    width: fit-content;
                    padding: 4px 8px 4px;
                    background: $darkorange;
                    border-radius: 12px;
                    font-size: 12px;
                    font-weight: 600;
                    color: white;

                    @include XGA {
                        top: 16px
                    }

                    @include FHD {
                        transform: translate(0%, 0%);
                        left: calc(100% + 32px);
                        top: 32px;
                        width: max-content;
                    }
                }
            }

            .content {

                @include XGA {
                    padding: 20px 16px 12px 0;
                }

                @include FHD {
                    padding: 70px 32px 22px 0;
                }

                .title {
                    font-size: 12px;
                    margin-bottom: 8px;
                    font-weight: 600;

                    @include XGA {
                        width: 215px;
                        font-size: 14px;
                    }

                    @include FHD {
                        font-size: 16px;
                    }
                }

                .sub {
                    opacity: 0.4;
                    line-height: 16px;
                    font-size: 12px;
                    font-weight: 400;
                    width: 205px;

                    @include XGA {
                        width: 215px;
                        font-size: 14px;
                    }

                    @include FHD {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                .arrow {
                    margin-top: 12px;
                }
            }

            &:hover {
                .arrow {
                    animation: arrow 1s $QuadEaseInOut infinite;
                }
            }

            &.highlighted {
                border: 2px solid #E5451F;
                background: #E5451F;
                .type {
                    color: #F5AA04;
                }

                .title,
                .sub {
                    color: #FFFFFF;
                }
                .sub{
                    opacity: 0.7;
                }
                .points {
                    @include FHD {
                        border: 2px solid #fff;
                    }
                }
            }
        }
    }

    .archive {
        margin-bottom: 16px;
        position: relative;

        @include XGA {
            margin-bottom: 90px;
            margin-top: 40px;
        }

        p {
            font-size: 18px;
            font-weight: 600;
            text-align: center;

            @include XGA {
                font-size: 32px;
                text-align: left;
                margin-bottom: 24px;
                margin-top: 0;
            }
        }

        &-wrapper {
            display: flex;
            column-gap: 16px;

            &>div {
                width: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                display: flex;
                flex-direction: column;
                row-gap: 4px;
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    padding-top: 100%;

                    @include XGA {
                        padding-top: 20%;
                    }
                }

                .content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    line-height: 100%;
                    height: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: -10px;
                    text-decoration: none;

                    @include XGA {
                        margin-top: -2px;
                    }

                    p {
                        color: white;
                        font-size: 12px;
                        line-height: 16px;

                        @include XGA {
                            br {
                                display: none;
                            }
                        }
                    }

                    img {
                        margin: -10px auto;
                        width: 30%;

                        @include XGA {
                            width: 15%;
                        }
                    }

                    &::before {
                        display: none;
                    }
                }
            }

            .prev-competition {
                background-image: url("./img/competition-mobile.jpg");
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 12px;
                max-height: 156px;

                @include XGA {
                    background-image: url("./img/competition.jpg");
                    min-height: 174px;
                    max-height: 174px;
                }

                p {
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;

                    @include XGA {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }

                    strong {
                        font-weight: 900;
                    }

                    br {
                        display: block !important;
                    }
                }
            }

            .prev-activities {
                background-image: url("./img/activities.png");
                border-radius: 12px;
            }
        }
    }

    a[disabled] {
        // pointer-events: none;
        // cursor: initial;
        position: relative;
        display: block;

        &.activity {
            &>* {
                opacity: 0.3;
            }
        }

        &:after {
            content: "Aktywność wykonana";
            position: absolute;
            left: 50%;
            top: 50%;
            color: #3A3A3A;
            background-color: rgba($color: #fff, $alpha: .5);
            white-space: nowrap;
            padding: 16px 32px;
            border-radius: 24px;
            transform: translate(-50%, -50%);
            font-weight: 600;
            font-size: 14px;
        }
    }

    &.glo-ar {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 0;

        .top-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 16px;

            @include XGA {
                margin-top: 40px;
                justify-content: left;
                column-gap: 24px;
                margin-left: 64px;
            }

            .header {
                @include XGA {
                    font-size: 32px;
                    margin: 0;
                    margin-right: 40px;
                    line-height: 40px;
                }
            }

            p,
            a {
                opacity: 0.4;
                font-size: 12px;
                padding: 4px 8px;
                border: 1px solid transparent;
                cursor: pointer;
                text-decoration: none;
                font-weight: 500;
                color: black;
                margin-top: 0;
                margin-bottom: 0;

                @include XGA {
                    padding: 6px 16px;
                    font-size: 14px;
                    margin: 0 !important;
                }
            }

            .active {
                color: $lightorange;
                padding: 4px 8px 2px;
                border: 1px solid $lightorange;
                border-radius: 24px;
                opacity: 1;
                line-height: 1.2;
                font-weight: 600;

                @include XGA {
                    padding: 6px 16px;
                    font-weight: 800;
                }
            }
        }

        .ar-content {
            padding: 56px 24px;

            @include XGA {
                padding: 56px 64px;
            }

            .sub-title {
                margin-top: 50vw;

                @include XGA {
                    margin-top: 24px;
                }
            }
        }

        .ar-content {
            position: relative;
            background-size: 100%;
            background-color: #000;
            background-repeat: no-repeat;
            background-position: top center;
            background-image: url(./img/ar-bg-mobile.jpg);
            color: #fff;
            text-align: center;

            @include XGA {
                background-image: url(./img/ar-bg-desktop.jpg);
                background-size: cover;
            }

            .buttons {
                margin-top: 40px;
            }

            .ar-copy,
            .disclaimer {
                position: relative;

                @include XGA {
                    width: 50%;
                    max-width: 560px;
                }
            }

            .qr-code-wrapper {
                position: absolute;
                top: 56px;
                right: 64px;
                padding: 6px;
                background: #fff;
                border-radius: 12px;
                box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);

                &::after {
                    content: "Zeskanuj mnie i otwórz świat AR";
                    font-size: 12px;
                    position: absolute;
                    left: 0;
                    top: calc(100% + 16px);
                    width: 100%;
                    background-color: #fff;
                    color: #000;
                    padding: 8px 0;
                    font-weight: 800;
                    text-align: center;
                    border-radius: 12px;
                    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                background-color: #fff;
                margin: 24px 0;
                max-width: 560px;
            }

            h2 {
                margin: 0;
                margin-bottom: 24px;
                font-size: 32px;
                font-weight: 900;

                @include XGA {
                    font-size: 36px;
                }

                @include HD {
                    font-size: 62px;
                }

                @include FHD {
                    font-size: 72px;
                }
            }

            p {
                font-size: 18px;
                line-height: 24px;

                &.disclaimer {
                    font-size: 12px;
                    line-height: 16px;
                    opacity: 0.6;

                    @include XGA {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            @include XGA {
                text-align: left;
            }

            ul {
                li {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 12px;

                    img {
                        margin-right: 12px;
                    }

                    p {
                        margin: 0;
                        text-align: left;
                        font-size: 15px;

                        @include XGA {
                            font-size: 16px;
                        }

                        @include UXGA {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .cloud-fest {
        margin-bottom: 24px;

        .img {
            background-image: url("./Activities/konkurs3_cf/img/intro-mobile.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 12px 12px 0 0;

            @include XGA {
                background-image: url("./Activities/konkurs3_cf/img/intro-desktop.jpg");
            }
        }
    }
}

.prev-activities-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    margin-top: 12px;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    .header {
        display: grid;
        grid-template-columns: 10% 80% 10%;

        @include XGA {
            display: flex;
            gap: 32px;
            align-items: center;
        }

        .title {
            text-align: center;

            @include XGA {
                margin: 0;
                font-weight: 600;
            }
        }

        button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: left;
            // height: fit-content;
            width: 50%;
            padding: 0;
            line-height: none;
            display: flex;
            justify-content: left;
            align-items: center;

            @include XGA {
                width: fit-content;
                display: block;
            }
        }
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        // margin-bottom: 8px;

        @include XGA {
            margin-top: 40px;
            font-size: 24px;
            line-height: 40px;
            font-weight: 700;
        }

        @include HD {
            font-size: 32px;
        }
    }

    .content-wrapper {
        margin-top: 24px;
        position: relative;

        @include XGA {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            gap: 24px;
        }

        .card {
            border-radius: 12px;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
            z-index: 2;
            background-color: white;
            margin-bottom: 24px;

            @include XGA {
                max-width: 416px;
                margin-bottom: 0;
                width: 100%;
                // display: inline-block;
                // margin-left: 24px;
            }

            // &:first-of-type {
            //     @include XGA {
            //         margin-left: 0;
            //     }
            // }

            .image {
                aspect-ratio: 1/1;
                // height: 100%;
                width: 100%;
                border-radius: 12px 12px 0 0;
                object-fit: cover;

                @include XGA {
                    aspect-ratio: 208/159;
                    // height: initial
                }
            }

            .content {
                padding: 24px 16px;

                @include XGA {
                    padding: 24px 30px 24px 40px;

                    * {
                        text-align: left !important;
                    }
                }

                .expired {
                    text-align: center;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 0.12em;
                    text-transform: uppercase;
                    color: $darkorange;
                }

                .title {
                    margin-top: 12px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: center;

                    @include XGA {
                        font-size: 15px;
                        line-height: 20px;
                        margin-top: 10px;
                        height: 40px;
                    }
                }

                .sub {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    color: #000000;
                    opacity: 0.4;
                    margin-top: 8px;

                    @include XGA {
                        font-size: 14px;
                        line-height: 20px;
                        height: 60px;
                    }
                }

                .button-wrapper {
                    margin-top: 24px;
                    display: flex;
                    justify-content: center;

                    @include XGA {
                        justify-content: left;
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}

.winner-page {
    width: calc(100% - 48px);
    border-radius: 12px 12px 0px 0px;
    margin: 0 auto;
    margin-top: 12px;

    @include XGA {
        width: calc(100% - 128px);
        border-radius: 0px;
        margin-top: 40px;
    }

    .header {
        display: grid;
        grid-template-columns: 10% 80% 10%;

        @include XGA {
            display: flex;
            gap: 32px;
            align-items: center;
        }

        .title {
            text-align: center;

            @include XGA {
                margin: 0;
                font-weight: 600;
            }
        }

        button {
            background: none;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: left;
            // height: fit-content;
            width: 50%;
            padding: 0;
            line-height: none;
            display: flex;
            justify-content: left;
            align-items: center;

            @include XGA {
                width: fit-content;
                display: block;
            }
        }
    }

    .content-wrapper {
        @include XGA {
            // background-image: url("./img/contest-winner.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 12px;
            padding: 80px 70px;
            margin-top: 40px;
            display: grid;
            place-items: center;
            position: relative;
            z-index: 2;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 720px;
                background-image: url("./img/contest-winner.jpg");
                border-radius: 12px 12px 0 0;
                z-index: -1;
            }
        }

        @include HD {
            padding: 80px 105px;
        }
    }

    .content {
        @include XGA {
            background-color: white;
            border-radius: 12px;
            padding: 64px 75px 40px;
            max-width: 1088px;
            width: 100%;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
        }

        @include HD {
            padding: 64px 112px 40px;
        }
    }

    h2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        // margin-bottom: 8px;

        @include XGA {
            margin-top: 40px;
            font-size: 24px;
            line-height: 40px;
            font-weight: 700;
        }

        @include HD {
            font-size: 32px;
        }
    }

    h3 {
        margin: 0;
        margin-top: 24px;
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
    }

    .link {
        color: $lightorange;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
    }

    .content-header {
        @include XGA {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
        }
    }

    .sub {
        @include XGA {
            margin-top: 40px;
        }
    }

    .rewards {
        margin-top: 24px;

        @include XGA {
            margin-top: 40px;
        }

        .element {
            display: flex;
            justify-content: space-between;
            background-color: white;
            margin-top: 16px;
            border: 1px solid #F7F7F7;
            border-radius: 12px;

            &.border-modifier {
                border-radius: 12px 12px 0 0;

                img {
                    border-radius: 12px 0 0 0;
                }
            }

            @include XGA {
                justify-content: flex-start;
            }

            .reward-img {
                aspect-ratio: 1/1;
                width: 89px;
                height: 100%;
                border-radius: 12px 0 0 12px;

                @include XGA {
                    width: 78px;
                }
            }

            .text {
                margin-left: 24px;
                margin-right: 30px;
                align-self: center;


                .place {
                    background: linear-gradient(53.04deg, #EE4823 37.12%, #EE4F22 41.3%, #F06022 47.18%, #F37820 54.09%, #F7951D 61.71%, #FAA61A 65.4%, #FAA61A 65.47%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                }

                .rewared-content {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 2px;
                    margin-bottom: 0;

                    @include XGA {
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                        margin-top: 4px;
                    }
                }
            }

            button {
                margin-right: 16px;
                margin-top: 12px;
                font-size: 24px;
                line-height: 24px;
                color: $lightorange;
                background: none;
                cursor: pointer;
                outline: none;
                border: none;
                text-align: left;
                height: fit-content;
                padding: 0;
                line-height: none;
                display: flex;
                justify-content: left;
                align-items: center;
                min-width: 12px;

                @include XGA {
                    font-size: 32px;
                    align-self: center;
                    margin-top: 0;
                    margin-right: 26px;
                    margin-left: auto;
                }
            }
        }

        .winner-images {
            background-color: white;
            padding-left: 16px;
            padding-right: 16px;
            border: 1px solid #F7F7F7;
            border-top: none;
            border-radius: 0 0 12px 12px;
            margin-bottom: 24px;

            @include XGA {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 16px;
                padding: 40px 40px;
            }

            @include HD {
                gap: 32px;
            }

            @include UXGA {
                padding-left: 121px;
                padding-right: 121px;
            }

            .card {
                cursor: pointer;
                padding-top: 16px;
                background-color: white;

                .img img {
                    width: 100%;
                }

                .text {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 12px;

                    .place {
                        color: #868686;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    .name {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                &:last-child {
                    padding-bottom: 16px;
                }
            }

        }
    }
}