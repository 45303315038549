@import "../../../styles/_vars.scss";

.wrapper-meetx2 {
    padding: 0;
    border-radius: 0;

    .content {
        display: block;
        height: 100%;
    }

    &.ReactModal__Content {
        aspect-ratio: 327/534;
        max-width: calc(min(327px, 90vw));
        min-width: 309px;
        max-height: 90%;
        width: 100%;

        @include XGA {
            aspect-ratio: 640/360;
            max-width: 640px;
        }
    }
}

.meetx2-modal {
    background-color: transparent;
    height: 100%;
    background-image: url("./img/bg-mobile.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;

    @include XGA {
        background-image: url("./img/bg-desktop.webp");
    }

    * {
        grid-row: 1/2;
        grid-column: 1/2;
        width: fit-content;
        height: fit-content;
    }


    h2.text {
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        text-align: left;
        max-width: 295px;
        margin-left: 16px;
        align-self: flex-end;
        margin-bottom: 81px;

        @include XGA{ 
            margin-bottom: 76px;
            margin-left: 40px;
            max-width: 486px;
        }
    }

    .button {
        align-self: flex-end;
        margin-left: 16px;
        margin-bottom: 24px;

        @include XGA {
            margin-left: 40px;
            margin-bottom: 19px;
        }
    }

    button {
        border: none;
        background-color: transparent;
        align-self: flex-start;
        justify-self: end;
        margin-top: 20px;
        margin-right: 24px;
        padding: 0;
        cursor: pointer;
    }
}