@import "../../../styles/vars";

.reset-page {
    --height: 660px;

    position: relative;
    height: var(--height);
    background-image: url(../LogoutPage/img/bg_mobile.webp);
    background-position: left center;
    // background-size: 100% 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    transition: height 0.2s linear;

    @include XGA {
        background-image: url(../LogoutPage/img/bg_desktop.webp);
        background-position: center;
        background-size: cover;
        // display: grid;
        // align-items: center;
    }

    .blur {
        height: 100%;
        display: grid;
        align-items: center;
        transition: backdrop-filter 500ms ease-in-out;

        &.active {
            backdrop-filter: blur(30px);
        }
    }

    .reset-page-inner {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include XGA {
            height: fit-content;
        }

        .logo {
            // margin-bottom: 8.5vh;
            padding-top: calc(max(6vh, 40px));

            img {
                // width: 31vw;
                width: calc(min(30vw, 140px));
                // max-width: 208px;
            }
        }

        h2 {
            font-weight: 600;
        }


        form {
            display: flex;
            flex-direction: column;
            height: 100%;

            .button-wrapper {
                margin-top: auto;
                padding-bottom: calc(max(6vh, 40px));

                @include XGA {
                    margin-top: 6vh !important;
                }
            }
        }

        .password-validation {
            margin: 30px auto 0;
            max-width: 400px;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                position: relative;
                min-width: 40%;
                max-width: 40%;
                width: 40%;
                text-align: left;
                height: 24px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;

                @include XGA {
                    min-width: 40%;
                    max-width: 40%;
                    width: 40%;
                }

                &::before {
                    content: "";
                    position: relative;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 2px;
                    border: #ED8002 solid 2px;
                    margin-right: 8px;
                }

                &.valid {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 9px;
                        top: 5px;
                        width: 6px;
                        height: 10px;
                        border-right: #ED8002 solid 2px;
                        border-bottom: #ED8002 solid 2px;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .text {
            margin-top: auto;

            @include XGA {
                margin-top: calc(max(5vh, 32px)) !important;
            }

            h3 {
                text-align: center;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                margin: 0;
            }
        }

        .user-data {
            display: block;
            margin: 0 auto;
            max-width: 400px;
            width: 100%;
            margin-top: 25px;

            .input {
                position: relative;

                .view-icon {
                    position: absolute;
                    right: 0;
                    bottom: 6px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    background-image: url(./img/preview.svg);
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    &.disable {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 24px;
                            height: 1px;
                            background-color: #fff;
                            transform: translate(-50%, -50%) rotate(30deg);
                        }
                    }
                }
            }

            input {
                display: block;
                width: 100%;
                margin-bottom: 12px;
                background: transparent;
                border: none;
                border-bottom: 1px solid white;
                padding: 4px 0px;
                color: white !important;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                outline: none;

                &:focus {
                    border-color: $lightorange;
                    transition: all 0.2s $CubicEaseInOut;
                }
            }

            input[type="password"] {
                line-height: 16px;
            }

            label {
                float: left;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: white !important;
            }
        }

        .buttons {
            margin-top: auto;

            .button {
                width: 200px;
            }
        }

        .error {
            position: relative;
            top: 0px;
            font-size: 12px;
            color: #EA1D1D;
            text-align: left;

            &:before {
                content: "!";
                position: relative;
                top: -2px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background-color: #EA1D1D;
                color: #fff;
                border-radius: 50%;
                margin-right: 6px;
                font-size: 9px;
                line-height: 15px;
                font-weight: 700;
                text-align: center;
            }
        }
    }
}